export function areas(state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
      return [];
      case 'GET_AREAS':
          let NewData = action.areas.data
          action.areas.data = NewData
          return action.areas
      case 'ADD_AREAS':
        let S_data = state.data.filter(m => m.id !== action.areas.id)
        let item = action.areas
        return S_data.concat(item)
      case 'REMOVED_AREAS':
        let alldata = state.data.filter(m => m.id !== action.areas.id)
        return alldata
      default:
        return state;
    }
  }

  export function selectedAreas (state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
        return []
      case 'AREA_USER_WISE_SELECTED_LIST':
        return action.areas
      case 'UPDATE_AREA_USER_WISE_SELECTED_LIST':
        let updatedata = state.map(m => {
          if (m.id !== action.areas.id) {
            return m
          } else {
            return action.areas
          }
        })
        return updatedata
  
      case 'ADD_AREA_USER_WISE_SELECTED_LIST':
        return state.concat(action.areas)
      case 'REMOVED_AREA_USER_WISE_SELECTED_LIST':
        let rData = state.filter(m => m.id !== action.areas.id)
        return rData
      default:
        return state
    }
  }


  
  
  
  