import React, { Component } from 'react'
import Routes from './Routes'
import withTranslationWrapper from './HOC/withTranslation'
import de from './lang/de.json'
import en from './lang/en.json'
import fr from './lang/fr.json'
import fa from './lang/fa.json'
import gu from './lang/gu.json'
import he from './lang/he.json'
import hi from './lang/hi.json'
import hu from './lang/hu.json'
import id from './lang/id.json'
import it from './lang/it.json'
import ja from './lang/ja.json'
import urdu from './lang/urdu.json'
import arabic from './lang/arabic.json'
import ku from './lang/kurdish.json'
import km from './lang/khmer.json'
import ko from './lang/ko.json'
import mn from './lang/mn.json'
import my from './lang/my.json'
import ne from './lang/ne.json'
import pt from './lang/pt.json'
import ru from './lang/ru.json'
import sq from './lang/sq.json'
import sr from './lang/sr.json'
import te from './lang/te.json'
import th from './lang/th.json'
import tr from './lang/tr.json'
import zh from './lang/zh.json'
import am from './lang/amharic.json'
import spanish from './lang/es.json'
import Loader from './Layout/Loader'
import Axios from 'axios'
import instance from './axios'
import { errorHandler } from './Helpers'
import { connect } from 'react-redux'
import { ServerInfo } from './Actions/serverSetting'
import { withTranslation } from 'react-i18next'
import i18n from "./i18n";
const onMissingTranslation = ({ translationId }) => translationId

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      whiteLabling: '',
      callResponse: false,
      loading: false,
      isDown: false,
      mainClass: ''}
  }

  checkWebsite = () => {

    this.setState({ loading: true, callResponse: false }, () => {

      // fetch(`/api/accounts/labeling?hostUrl=${window.location.host}`, {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/accounts/labeling`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          hostUrl: window.location.host
        }
      })
      // .then(response => {
      //   this.setState({loading: false});
      //   if (response.ok) {
          // response.json()
          .then(res => {
              this.setState({
                whiteLabling: res,
                callResponse: true,
                loading: false,
                serverinfo:{attributes:{...res}}
              },()=>{
                      this.props.dispatch(ServerInfo(this.state.serverinfo))
              })
              document.title = res.serviceTitle
              let domElements = document.getElementById('favicon')
              if (res && res.favIcon && domElements) {
                domElements.setAttribute('href', res.favIcon)
              }
        //   })
        // } else {
        //   this.setState({
        //     isDown: true
        //   })
        //   console.log('Server is Down')
        //     throw response
        //   }
        }).catch(error => {
          this.setState({
            loading: false,
            isDown: false,
            callResponse: true,
          })
          // errorHandler(error, this.props.dispatch, this.errorCallBack)  
        })

    })
    
  }
  // errorCallBack = (data) => {
  //   this.setState({
  //     loading: false,
  //     isDown: true
  //   })
  // }

  setCookie (name, value, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie =
      name + '= ' + encodeURIComponent(value) + ';' + expires
  }

  componentWillMount () {
    this.checkWebsite();
    // this.saveIp();
  }
  saveIp = async () => {
    const response = await Axios.get('https://api.ipify.org/?format=json');
    if(response.status === 200 && response.data && response.data.ip) {
      this.setCookie('UIP', response.data.ip, 1);
    }
  }
  myref = e => {
    if(e) {
      this.setState({
        mainClass: 'scroll-width-'+(e.offsetWidth - e.scrollWidth)
      })
    }
  }

  setActiveLanguage = (lng) => {
    localStorage.setItem("SelectedLanguage", lng);
    i18n.changeLanguage(lng);
  };
  render () {
    if (this.state.callResponse) {
      return (
        <div className={'fms '+this.state.mainClass} >
          <Routes {...this.state} {...this.props} translate={this.props.translate} setActiveLanguage={this.setActiveLanguage}/>
          <div
            id='scrollable-div'
            style={{overflow: 'scroll', opacity: 0, position: 'absolute', left: '-200%', top: '-200%'}}
            ref={this.myref}
          ></div>
        </div>
      )
    } 
    if(this.state.loading) {
      return <Loader />
    } else {
      if (this.state.isDown) {
        return (
          <h2 style={{ textAlign: 'center' }}>
            Oops Something Went Wrong Try Again
          </h2>
        )
      } else {
        return null
      }
    }
  }
}
export default connect()(withTranslationWrapper(App))