export const getVehicles = vehicles => ({
  type: 'GET_VEHICLES',
  vehicles
})
export const addvehicle = vehicle => ({
  type: 'ADD_VEHICLES',
  vehicle
})
export const removedvehicle = vehicle => ({
  type: 'REMOVED_VEHICLE',
  vehicle
})
export const updatevehicle = vehicle => ({
  type: 'UPDATE_VEHICLE',
  vehicle
})

export const searchvehicle = vehicle => ({
  type: 'SEARCH_VEHICLE',
  payload: vehicle.vehicle
})
export const logout = reset => ({
  type: 'RESET_APP',
  reset
})
