import React, { Fragment } from 'react'
import CustomDialog from './../../Components/common/Dialog'
import { SketchPicker } from 'react-color'
import TextField from './../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import Button from '../common/Button'
import SingleSelect from '../common/SingleSelect'
import FormControlLabel from '@mui/material/FormControlLabel'
;
import withTranslationWrapper from '../../HOC/withTranslation'

const styles = theme => ({})

class addAttributes extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    const {
      formData,
      selectedType,
      htmlTag,
      selectTagData,
      classes
    } = this.props
    let is_drAttriubtes = formData[0].key === 'simpleAttributes' ? true : false

    return (
      <CustomDialog
        title={this.props.translate('sharedAttribute')}
        themecolors={this.props.themecolors}
        visable={true}
        onClose={this.props.onCloseModal}
        draggable={true}
        cancelText={this.props.translate('sharedCancel')}
        isVisableBtn={this.props.isVisableBtn}
        headerActions={
          <Button
            disabled={!this.props.form.name}
            size='small'
            variant='contained'
            color='secondary'
            onClick={() => this.props.handleSubmit()}
          >
            {' '}
            {this.props.translate('sharedAdd')}
          </Button>
        }
        bodyPadding={20}
      >
        <div>
          {is_drAttriubtes ? (
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              placeholder=''
              value={this.props.form.name}
              onChange={this.props.handleChange.bind(this, 'name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : (
            <TextField
              id='name'
              select
              label={this.props.translate('sharedName')}
              value={this.props.form.name}
              onChange={this.props.handleChange.bind(this, 'name')}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>{this.props.translate('none')}</em>
              </MenuItem>
              {formData.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          )}

          {htmlTag === '' || selectedType === '' ? (
            <TextField
              id='value'
              label={this.props.translate('sharedValue')}
              type='text'
              placeholder=''
              value={this.props.form.value}
              onChange={this.props.handleChange.bind(this, 'value')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : null}
          {htmlTag === 'input' &&
          (selectedType === 'text' || selectedType === 'string') ? (
            <TextField
              id='value'
              label={this.props.translate('sharedValue')}
              type='text'
              placeholder=''
              value={this.props.form.value}
              onChange={this.props.handleChange.bind(this, 'value')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : null}
          {htmlTag === 'input' && selectedType === 'boolean' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.form.value}
                  onChange={this.props.handleChange.bind(this, 'value')}
                  color='primary'
                />
              }
              label={this.props.translate('sharedValue')}
            />
          ) : null}
          {htmlTag === 'input' && selectedType === 'number' ? (
            <div>
              <TextField
                id='value'
                label={this.props.translate('sharedValue')}
                type='number'
                placeholder=''
                value={this.props.form.value}
                onChange={this.props.handleChange.bind(this, 'value')}
                variant='outlined'
                margin='dense'
                fullWidth
              />
              {this.props.form.name === 'speedLimit' && this.props.form.value
                ? (this.props.form.value * 1.852).toFixed(2) + 'Km/h'
                : null}
            </div>
          ) : null}
          {htmlTag === 'select' && selectedType === 'string' ? (
            <Fragment>
              {this.props.form.name === 'timezone' ||
              this.props.form.name === 'decoder.timezone' ? (
                <SingleSelect
                  array={selectTagData}
                  selectName={this.props.form.name}
                  label={this.props.translate('Timezone')}
                  value={
                    this.props.form.value
                      ? {
                          key: this.props.form.value,
                          id: this.props.form.value,
                          label: this.props.translate(this.props.form.value)
                        }
                      : ''
                  }
                  handleChange={this.props.handleChange.bind()}
                />
              ) : (
                <TextField
                  id='value'
                  select
                  label={this.props.translate('sharedValue')}
                  value={this.props.form.value}
                  onChange={this.props.handleChange.bind(this, 'value')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value=''>
                    <em>{this.props.translate('none')}</em>
                  </MenuItem>
                  {selectTagData.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {this.props.translate(option.key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Fragment>
          ) : null}
          {htmlTag === 'color' ? (
            <SketchPicker
              color={this.props.form.value ? this.props.form.value : ''}
              onChangeComplete={this.props.handleChange.bind(this, 'value')}
            />
          ) : null}
        </div>
      </CustomDialog>
    )
  }
}

const AddAttributes = withStyles(styles)
export default AddAttributes(withTranslationWrapper(addAttributes))
