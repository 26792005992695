export const themes  = {
  "1": {
    id: 1,
    'name': 'Theme 1',
    "themeDarkColor": "#161f35",
    "themeInverse": "#ffffff",
    "themeLightColor": "#00cbd7",
    "themeLightInverse": "#222222",
    "themeListingColor": "#384360",
    "themeListingInverse": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "backgroundColor": "#161f35",
    "textColor": "#ffffff",
    "menuActiveColor": "222222",
    "menuActiveBackground": "#00cbd7",
    "menuBackgroundColor": "#161f35",
    "menuTextColor": "#ffffff",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "2": {
    id: 2,
    'name': 'Theme 2',
    "themeDarkColor": "#303A49",
    "themeInverse": "#ffffff",
    "themeLightColor": "#1E72B8",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#1E72B8",
    "themeListingInverse": "#303A49",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#303A49",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#6FBD43",
    "menuBackgroundColor": "#222C3C",
    "menuTextColor": "#ffffff",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "3": {
    id: 3,
    'name': 'Theme 3',
    "themeDarkColor": "#303A49",
    "themeInverse": "#ffffff",
    "headingColor": "#ffffff",
    "themeInverse": "#ffffff",
    "themeLightColor": "#1E72B8",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#1E72B8",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#212C3C",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#ffffff",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#6FBD43",
    "menuBackgroundColor": "#222C3C",
    "menuTextColor": "#ffffff",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  // "3": {
  //   id: 3,
  //   'name': 'Theme 3',
  //   "themeDarkColor": "#ee4b49",
  //   "themeInverse": "#ffffff",
  //   "themeLightColor": "#ed5650",
  //   "themeLightInverse": "#ffffff",
  //   "themeListingColor": "#171c4f",
  //   "themeListingInverse": "#ffffff",
  //   "backgroundColor": "#26295e",
  //   "dashboardBackgroundColor": "#0a83a1",
  //   "textColor": "#ffffff",
  //   "menuActiveColor": "#ffffff",
  //   "menuActiveBackground": "#ed5650",
  //   "menuBackgroundColor": "#26295e",
  //   "menuTextColor": "#ffffff",
  //   "error": "#ff424c",
  //   "success":'#4BB543',
  // },
  "4": {
    id: 4,
    'name': 'Theme 4',
    "themeDarkColor": "#ee4b49",
    "themeInverse": "#ffffff",
    "themeLightColor": "#f7625e",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#ebecf1",
    "themeListingInverse": "#000000",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#000000",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#f7625e",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#000000",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "5": {
    id: 5,
    'name': 'Theme 5',
    "themeDarkColor": "#5c45db",
    "themeInverse": "#ffffff",
    "themeLightColor": "#6e59e2",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#efeffb",
    "themeListingInverse": "#000000",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#000000",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#6e59e2",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#000000",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "6": {
    id: 6,
    'name': 'Theme 6',
    "themeDarkColor": "#5c45db",
    "themeInverse": "#ffffff",
    "themeLightColor": "#6e59e2",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#2f2f2f",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#111111",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#ffffff",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#6e59e2",
    "menuBackgroundColor": "#111111",
    "menuTextColor": "#ffffff",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  
  "7": {
    id: 7,
    'name': 'Theme 7',
    "themeDarkColor": "#045266",
    "themeInverse": "#ffffff",
    "themeLightColor": "#0a83a1",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#0a83a1",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#222222",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#0a83a1",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#222222",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "8": {
    id: 8,
    'name': 'Theme 8',
    "themeDarkColor": "#007549",
    "themeInverse": "#ffffff",
    "themeLightColor": "#00c179",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#00c179",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#222222",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#00c179",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#222222",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "9": {
    id: 9,
    'name': 'Theme 9',
    "themeDarkColor": "#2a5fc1",
    "themeInverse": "#ffffff",
    "themeLightColor": "#377dff",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#377dff",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#222222",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#377dff",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#222222",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "10": {
    id: 10,
    'name': 'Theme 10',
    "themeDarkColor": "#d2170e",
    "themeInverse": "#ffffff",
    "themeLightColor": "#ff1d13",
    "themeLightInverse": "#ffffff",
    "themeListingColor": "#ff1d13",
    "themeListingInverse": "#ffffff",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#222222",
    "menuActiveColor": "#ffffff",
    "menuActiveBackground": "#ff1d13",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#222222",
    "error": "#ff424c",
    "success":'#4BB543',
  },
  "11": {
    id: 11,
    'name': 'Theme 11',
    "themeDarkColor": "#00bac6",
    "themeInverse": "#000000",
    "themeLightColor": "#00c0ce",
    "themeLightInverse": "#000000",
    "themeListingColor": "#e9ebf0",
    "themeListingInverse": "#000000",
    "backgroundColor": "#ffffff",
    "dashboardBackgroundColor": "#0a83a1",
    "textColor": "#000000",
    "menuActiveColor": "000000",
    "menuActiveBackground": "#00c0ce",
    "menuBackgroundColor": "#ffffff",
    "menuTextColor": "#000000",
    "error": "#ff424c",
    "success":'#4BB543',
  },
 "12": {
    id: 12,
    'name': 'Theme 12',
    "themeDarkColor": "#4C0055",  //overall theme bg-color, header/footer, sidebar
    "themeInverse": "white",  // text color of topbar/navbar
    "themeLightColor": "#FC6440",  //orange color  , buttons bg color , search field border, small icons, 
    "themeLightInverse": "white",  //buttons text color
    "themeListingInverse": "#000000",   //sidebar  text color // black
    "backgroundColor": "white",  //sidebar  bg color
    "textColor": "black",           //overall/forms text color
    "menuActiveColor": "white",   //selected menu tab text color,monitoring cnterbar overspeed, idling etc  
    "menuActiveBackground": "#FC6440",  // purple , selected menu tab bg color, 
    "menuTextColor": "white",  // monitoring selected unit detail text color with bg
    'themeListingActiveInverse': "yellow",
    "themeListingColor": "#9a9a9a", 
    "error": "#ff424c",
    "success":'#4BB543',
    // "dashboardBackgroundColor": "#0a83a1",

     // themeListingColor
    // "menuBackgroundColor": "yellow",
    // 'themeListingActiveColor': "red",
    // 'InputBorderColor': "red",
    // "dashboardBackgroundColor": "red",
    // 'tableBorderColor': "red",
    // 'tabBgColor': "red",
    // 'tabTextColor': "red",
  },
}
export const themeColorsArray = (state = [], action) => {
  let a = Object.keys(themes);
  return a.map(b => { return {'color': themes[b]['themeDarkColor'], name: themes[b]['name'], id: b}})
}
export const themeColors = (state = {...themes[2]}, action) => {
  switch (action.type) {
    case 'fetchThemeColors':    
      return themes[2]
    
    case 'UPDATE_THEME':
      return themes[parseInt(action.payload) || 2]

    case 'MODIFY_THEME':
      return action.payload
    default:
      return state
  }
}