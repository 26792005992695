import { combineReducers } from 'redux'
import {
  tags,
  driverTags,
  filterList,
  filterDriversList,
  filterTagsList,
  filterCatList,
  devices,
  devices2,
  devices3,
  positions,
  searchRecently,
  deviceId,
  trackId,
  category,
  allNotifications,
  engineHourSummery,
  groupWiseDevices,
  deviceRelatedData,
  allComputedAttributes,
  groupBy,
  groupNames,
  resourceGroups,
  tail,
  tailSegments,
  tailEnabled,
  dashboards,
  widgets
} from './Devices'
import {
  notifications,
  notification,
  notificationType,
  notificationChannel,
  notificationDeviceWise,
  selectednotification,
  selectedgeofence,
  selectedgroups,
  selecteddriver,
  selectedtrailer,
  selecteddashboard,
  selectedwidget,
  selectedvehicle,
  selecteduser,
  selecteddevices,
  events,
  geofenceDeviceWise,
  notificationSettings,
  footerNotifications,
  notificationCount
} from './Notifications'
import { groups, deviceIdsList } from './Groups'
import { templates, selectedtemplates } from './Templates'
import {
  bounds,
  markerEnabled,
  bounded,
  mapLayer,
  insideGeofence,
  mapTraffic
} from './Maps'
import { calendars, searchcalendar } from './Calendars'
import { themeColors, themeColorsArray } from './Theme'
import { geoFence, isGeofenceLoad } from './Geofence'
import { POI } from './POI'
import { drivers, searchDriver, isDriverLoad } from './Drivers'
import {trailers,searchTrailer,isTrailerLoad} from './Trailer'
import {trash} from './Trash'
import { users, searchUser, roles, selecteduseruser } from './Users'
import {
  computedAttributes,
  computedAttributesDeviceWise,
  selectedComputedAttributes
} from './computedAttributes'
import {
  commands,
  commandsTypes,
  commandsDeviceWise,
  selectedcommands
} from './Commands'
import {
  maintenance,
  maintenanceDeviceWise,
  selectedMaintenance
} from './Maintenance'
import { garages, selectedGarages } from './Garages'
import { areas, selectedAreas } from './Areas'
import {services, selectedServices} from './Services'
import { logInUsers } from './logInUser'
import { ServerSetting } from './ServerSetting'
import { vehicles, searchvehicle } from './Vehicle'
import { jobs, searchJob } from './Jobs'

// import { reducer as notificationSystem } from 'react-notification-system-redux'
 // toastnotification

export default combineReducers({
  tags,
  driverTags,
  filterList,
  filterCatList,
  filterTagsList,
  deviceIdsList,
  filterDriversList,
  engineHourSummery,
  ServerSetting,
  logInUsers,
  devices,
  devices2,
  devices3,
  allNotifications,
  positions,
  notifications,
  notification,
  notificationType,
  notificationChannel,
  selectednotification,
  notificationDeviceWise,
  selectedgeofence,
  selecteddevices,
  selecteddriver,
  selectedtrailer,
  selecteddashboard,
  selectedwidget,
  selectedvehicle,
  selecteduser,
  selecteduseruser,
  geofenceDeviceWise,
  events,
  roles,
  jobs,
  searchJob,
  category,
  groups,
  templates,
  calendars,
  searchcalendar,
  geoFence,
  isGeofenceLoad,
  markerEnabled,
  deviceId,
  trackId,
  searchRecently,
  bounds,
  bounded,
  mapLayer,
  themeColors,
  themeColorsArray,
  drivers,
  searchDriver,
  isDriverLoad,
  trailers,
  isTrailerLoad,
  trash,
  searchTrailer,
  users,
  searchUser,
  computedAttributes,
  computedAttributesDeviceWise,
  selectedComputedAttributes,
  commands,
  commandsTypes,
  commandsDeviceWise,
  selectedcommands,
  maintenance,
  maintenanceDeviceWise,
  notificationSettings,
  selectedMaintenance,
  POI,
   // toastnotification
  // notificationSystem,
  footerNotifications,
  selectedgroups,
  vehicles,
  searchvehicle,
  groupWiseDevices,
  insideGeofence,
  deviceRelatedData,
  groupBy,
  groupNames,
  resourceGroups,
  mapTraffic,
  allComputedAttributes,
  notificationCount,
  selectedtemplates,
  tail,
  tailSegments,
  tailEnabled,
  dashboards,
  widgets,
  garages,
  selectedGarages,
  areas,
  selectedAreas,
  services,
  selectedServices
})
