import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from './../common/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Dialog from '../../Components/common/Dialog'
import EventIcon from '@mui/icons-material/Event'
import SettingsIcon from '@mui/icons-material/Settings'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { ReactComponent as TimemachineIcon } from '../../assets/nav/time-machine.svg'
import { ReactComponent as TracksIcon } from '../../assets/nav/tracks.svg'
import { ReactComponent as CommandIcon } from '../../assets/nav/command.svg'
import { ReactComponent as StreetViewIcon } from '../../assets/nav/street-view.svg'
import { checkPrivileges, errorHandler, prepareStreeView } from '../../Helpers'
import Style from 'style-it'
import { SendCommand } from './../../Pages/Units/Components/sendCommand'
import Tracks from '../../Pages/Tracks'
import instance from '../../axios'

let win;

export default class UnitMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      commandModal: false,
      list: []
    }
  }

  onCloseModal = () => {
    this.setState(
      {
        commandModal: false
      },
      () => {
        this.handleClose()
      }
    )
  }

  showModal = e => {
    this.fetchData(this.props.data.id)
    this.setState({
      commandModal: true
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  removeDevice = () => {
    this.props.removeDevice(this.props.data)
  }

  editDevice = () => {
    this.props.editDevice(this.props.data)
  }

  handleDeviceSettings = () => {
    this.props.openDeviceSettings()
    this.handleClose()
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ anchorEl: NextProps.anchorEl })
  }

  fetchData = id => {
    this.setState(
      { commandsLoading: true, typesLoading: true, list: [] },
      () => {
        // fetch(`/api/commands/send?deviceId=${id}`, {
        //   method: `GET`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/commands/send`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            deviceId: id
          }
          })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(commands => {
              const list = []
              if (commands.length) {
                commands.map(item => {
                  list.push({ ...item, key: item.id, name: item.description })
                  return null
                })
              }
              this.setState({
                list,
                commandsLoading: false
              })
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    )
  }

  closePrevious = () => {
    win.close();
    win = null;
    this.showStreetView();
  }

  showStreetView = () => {
    if(win && win.closed === false) {
      this.closePrevious()
    }
    else {
      if(this.props.relatedData && this.props.relatedData.exists) {
        win = window.open(prepareStreeView(this.props.relatedData.latitude, this.props.relatedData.longitude),"newWindow");
      }
    }
  }

  render () {
    const styles = {
      ListItemIcon: {
        fontSize: 18,
        fill: 'currentColor'
      },
      ListItemIconWrap: {
        marginRight: 10,
        color: 'inherit',
        minWidth: 18
      }
    }
    return (
      <Style>
        {`
    .custom-menu-paper {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    `}
        <div>
          <Menu
            id={this.props.id}
            anchorEl={this.props.anchorEl}
            open={Boolean(this.props.anchorEl)}
            onClose={this.handleClose}
            classes={{ paper: 'custom-menu-paper' }}
          >
            {checkPrivileges('device') && (
              <MenuItem
                component={Link}
                to={'/events/' + this.props.data.id}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <EventIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('eventHistory')}
              </MenuItem>
            )}
            {checkPrivileges('track') && (
              <MenuItem
                component={Link}
                to={'/tracks/'+ this.props.data.id}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <TracksIcon
                    width={14}
                    height={14} 
                    style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('Tracks')}
              </MenuItem>
            )}
            {checkPrivileges('deviceUpdate') && (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.handleDeviceSettings}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <SettingsIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('openDeviceSettings')}
              </MenuItem>
            )}
            {checkPrivileges('report') && (
              <MenuItem
                component={Link}
                to='/reports'
                style={styles.MenuItem}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <InsertDriveFileIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('openReports')}
              </MenuItem>
            )}
            {checkPrivileges('command') ? (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.showModal}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <CommandIcon
                    width={14}
                    height={14}
                    style={styles.ListItemIcon}
                  />
                </ListItemIcon>
                {this.props.translate('sendCommand')}
              </MenuItem>
            ) : null}
            {this.props.relatedData && this.props.relatedData.exists ? (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.showStreetView}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <StreetViewIcon
                    width={14}
                    height={14}
                    style={styles.ListItemIcon}
                  />
                </ListItemIcon>
                
                {this.props.translate('streetView')}
              </MenuItem>
            ) : null}
          </Menu>
          {this.state.commandModal ? (
            <Dialog
              open
              title={this.props.translate('sendCommand')}
              themeColors={this.props.themecolors}
              disableFooter
              onClose={this.onCloseModal}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <SendCommand
                id={this.props.id}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.state.list}
              />
            </Dialog>
          ) : null}
        </div>
      </Style>
    )
  }
}
