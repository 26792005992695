import React, { memo } from 'react';
import { useDrop } from 'react-dnd';
import { Trans as Translate} from 'react-i18next';
import { ReactComponent as DnDIcon } from '../../assets/move.svg'
export const Dustbin = memo(function Dustbin({ accept, lastDroppedItem, onDrop, form }) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    return (<div className={isOver ? 'dnd-drop dnd-hover dnd-dropable' : canDrop ? 'dnd-drop dnd-dropable' : 'dnd-drop'} ref={drop} role="Dustbin">
			{lastDroppedItem && lastDroppedItem.length ? <>{form && form.attribute}: {lastDroppedItem.map(r => <span className="tag">{r.name}</span>)} {isOver || canDrop ? <span className="dropable-placehoder"></span> : null}</> : <div className="dnd-empty"><h2><Translate i18nKey="dragDrop"/></h2><DnDIcon /></div>}
		</div>);
});
