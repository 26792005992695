import React, { Component } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import ChangedPasword from "./changedPassword";
import ServicePreferences from "./servicePerferences";
import UserDefault from "./userDefault";
//import EmailGatway from './emailGatway'
import Permissions from "./Permissions";

class accountManagements extends Component {
  constructor() {
    super();
    this.state = {
      value: "tab1",
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { value } = this.state;
    if (this.props.logInUser.id) {
      return (
        <div style={{ paddingTop: 50 }}>
          <AppBar
            position="static"
            color="inherit"
            style={{ background: "none", boxShadow: "none" }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              TabIndicatorProps={{
                style: { background: this.props.themecolors.backgroundColor },
              }}
              scrollButtons="on"
              classes={{
                root: "custom-tabs-root",
                flexContainer: "custom-tabs",
                scrollable: "custom-tabs-scrollable",
                indicator: "custom-indicator",
                scrollButtons: "scrollable-buttons",
              }}
            >
              <Tab
                value="tab1"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                }}
                label={this.props.translate("sharedPreferences")}
              />
              {this.props.logInUser && this.props.logInUser.userType !== 1 ? (
                <Tab
                  value="tab2"
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                  }}
                  label={this.props.translate("sharedPermissions")}
                />
              ) : null}
              <Tab
                value="tab3"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                }}
                label={this.props.translate("sharedUserDefault")}
              />
              <Tab
                value="tab4"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                }}
                label={this.props.translate("sharedChangedPassword")}
              />
            </Tabs>
          </AppBar>
          <Paper
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              minHeight: 270,
              background: "none",
              color: "inherit",
            }}
            className="custom-menu-paper"
            square={true}
            elevation={0}
          >
            {value === "tab1" && (
              <div>
                <ServicePreferences {...this.props} />
              </div>
            )}
            {this.props.logInUser &&
            this.props.logInUser.userType !== 1 &&
            value === "tab2" ? (
              <div>
                <Permissions {...this.props} />
              </div>
            ) : null}
            {value === "tab3" && (
              <div>
                <UserDefault {...this.props} />
              </div>
            )}
            {value === "tab4" && (
              <div>
                <ChangedPasword {...this.props} />
              </div>
            )}
          </Paper>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapState = (state) => ({
  users: state.users,
  devices: state.devices.data,
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export const AcManagements = mapStateToProps(accountManagements);
