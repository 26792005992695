import React from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'


export default class MarkersClusterGroup extends React.PureComponent {
  render () {
    return <MarkerClusterGroup {...this.props} showCoverageOnHover={false} />
  }
}
