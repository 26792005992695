import React, { Component } from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@mui/material/Grid'
import Button from '../../../Components/common/Button'
import Checkbox from '../../../Components/common/Checkbox'
import Table from '../../../Components/common/TableMultiCheckbox'
import { timeZonesTypes } from '../../../Components/Attributes/timezone'
import { checkPrivileges } from '../../../Helpers'
import SingleSelect from '../../../Components/common/SingleSelect'

export default class SavedCommands extends Component {
  constructor (props) {
    super(props)
    this.savedCommands_handleChange = this.savedCommands_handleChange.bind(this)
  }

  savedCommands_handleChange (name, value) {
    if (this.props.savedCommandsHandle) {
      this.props.savedCommandsHandle(name, value)
    }
  }

  render () {
    /* let commandsTypes = this.props.commandsTypes.map(t => {
      return { key: t.type, name: this.props.translate('commands.' + t.type) }
    }) */
    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item sm={this.props.visableSavedCommands ? 8 : 12} xs={12}>
          <Table
            createButton={
              checkPrivileges('commandCreate') && (
                <div style={{ marginLeft: 10 }}>
                  {this.props.sendCommand
                    ? checkPrivileges('commandSend') && (
                        <Button
                          style={{ marginRight: 16 }}
                          size='small'
                          onClick={() => this.props.sendCommandsModal()}
                        >
                          {' '}
                          {this.props.translate('sendCommand')}
                        </Button>
                      )
                    : null}
                  <Button onClick={() => this.props.onCreateNewSavedCommands()}>
                    {this.props.translate('sharedCreate')}
                  </Button>
                </div>
              )
            }
            title={this.props.translate('sharedSavedCommand')}
            insideDialog
            disableHead
            showCheckbox
            showGroupAccess={this.props.showGroupAccess}
            checkHandleChange={this.props.savedCommands_Selection}
            rows={this.props.commandsData}
            isEditable
            selectAll={this.props.selectAll}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            themecolors={this.props.themecolors}
            searchable
            canRemove={this.props.canAssign}
            canAssign={this.props.canRemove}
            canUpdate={checkPrivileges('commandUpdate')}
            canDelete={checkPrivileges('commandDelete')}
            className='custom-table2'
            rowsPerPage={this.props.rowsPerPage}
            handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            rowDefinition={[
              {
                id: 'description',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedDescription')
              },
              {
                id: 'type',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedType'),
                translate: true,
                prefix: 'commands.'
              }
            ]}
          />
        </Grid>
        {this.props.visableSavedCommands && (
          <Grid item sm='4' xs='12'>
            <div
              style={{
                padding: '10px 10px 1px',
                borderLeft: '2px dashed ' + this.props.themecolors.themeListingColor+ '7A',
                height: '100%'
              }}
            >
              <h4>{this.props.translate('sharedSavedCommand')}</h4>
              <TextField
                id='description'
                margin='dense'
                label={this.props.translate('sharedDescription')}
                variant='outlined'
                fullWidth
                value={this.props.savedCommandsForm.description}
                onChange={this.props.savedCommands_handleChange('description')}
              />
              {/* <Checkbox
                checked={this.props.savedCommandsForm.textChannel}
                onChange={this.props.savedCommands_handleChange('textChannel')}
                color='primary'
                label={this.props.translate('commands.sendSms')}
              /> */}
                <SingleSelect
                    array={this.props.formType}
                    selectName='type'
                    label={this.props.translate('sharedType')}
                    value={this.props.savedCommandsForm && this.props.savedCommandsForm.type? 
                      {
                        key: this.props.savedCommandsForm.type,
                        id: this.props.savedCommandsForm.type,
                        value: this.props.savedCommandsForm.type,
                        label: this.props.savedCommandsForm.type
                      } :''
                    }
                    handleChange={this.savedCommands_handleChange}
                    canRemove={true}
                    canAssign={true}
                />
              {[
                 'custom',
                 'silenceTime',
                 'setPhonebook',
                 'voiceMessage',
                 'outputControl',
                 'setIndicator',
                 'configuration',
                 'setOdometer',
                 'alarmClock',
                 'alarmSpeed',
                 'alarmVibration',
                 'setSilenceTime'
              ].includes(this.props.savedCommandsForm.type) ? (
                <TextField
                  id='data'
                  name='data'
                  margin='dense'
                  label={this.props.translate('commandData')}
                  variant='outlined'
                  fullWidth
                  value={this.props.savedCommandsForm.attributes.data}
                  onChange={this.props.savedCommands_handleChange('attributes')}
                />
              ) : null}
              {this.props.savedCommandsForm.type === 'positionPeriodic' && (
                <div>
                  <TextField
                    id='frequency'
                    name='frequency'
                    type='number'
                    margin='dense'
                    label={this.props.translate('commandFrequency')}
                    variant='outlined'
                    fullWidth
                    value={this.props.savedCommandsForm.attributes.frequency}
                    onChange={this.props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                  <TextField
                    id='frequencyType'
                    name='frequencyType'
                    select
                    value={
                      this.props.savedCommandsForm.attributes.frequencyType
                    }
                    onChange={this.props.savedCommands_handleChange(
                      'attributes'
                    )}
                    margin='dense'
                    fullWidth
                  >
                    {frequencyType.map(option => (
                      <MenuItem key={option.type} value={option.type}>
                        {option.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {this.props.savedCommandsForm.type === 'setTimezone' && (
                <TextField
                  id='timezone'
                  name='timezone'
                  select
                  label={this.props.translate('commandTimezone')}
                  value={this.props.savedCommandsForm.timezone}
                  onChange={this.props.savedCommands_handleChange('attributes')}
                  margin='dense'
                  fullWidth
                >
                  {timeZonesTypes.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {[
                'sendSms',
                'sendUssd',
                'sosNumber',
                'outputControl',
                'message'
              ].includes(this.props.savedCommandsForm.type) ? (
                <div>
                  {['sosNumber', 'outputControl'].includes(
                    this.props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='index'
                      name='index'
                      type='number'
                      margin='dense'
                      label={this.props.translate('commandIndex')}
                      variant='outlined'
                      fullWidth
                      value={this.props.savedCommandsForm.index}
                      onChange={this.props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                  {['sendSms', 'sendUssd', 'sosNumber'].includes(
                    this.props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='phoneNumber'
                      margin='dense'
                      name='phoneNumber'
                      label={this.props.translate('commandPhone')}
                      variant='outlined'
                      fullWidth
                      value={this.props.savedCommandsForm.phoneNumber}
                      onChange={this.props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                  {['sendSms', 'message'].includes(
                    this.props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='messages'
                      name='messages'
                      margin='dense'
                      label={this.props.translate('Messages')}
                      variant='outlined'
                      fullWidth
                      value={this.props.savedCommandsForm.messages}
                      onChange={this.props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                </div>
              ) : null}
              {[
                'alarmFall',
                'alarmRemove',
                'alarmBattery',
                'modeDeepSleep',
                'modePowerSaving',
                'setAgps',
                'voiceMonitoring',
                'modifyPowerSaving',
                'modifyDeepSleep',
                'alarmSos'
              ].includes(this.props.savedCommandsForm.type) ? (
                <Checkbox
                  checked={this.props.savedCommandsForm.enable}
                  onChange={this.props.savedCommands_handleChange('attributes')}
                  color='primary'
                  label={this.props.translate('commandEnable')}
                  name='enable'
                />
              ) : null}
              {this.props.savedCommandsForm.type === 'setConnection' && (
                <div>
                  <TextField
                    id='server'
                    name='server'
                    margin='dense'
                    label={this.props.translate('commandServer')}
                    variant='outlined'
                    fullWidth
                    value={this.props.savedCommandsForm.server}
                    onChange={this.props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                  <TextField
                    id='port'
                    name='port'
                    type='number'
                    margin='dense'
                    label={this.props.translate('commandPort')}
                    variant='outlined'
                    fullWidth
                    value={this.props.savedCommandsForm.port}
                    onChange={this.props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                </div>
              )}
              {this.props.savedCommandsForm.type === 'novementAlarm' && (
                <TextField
                  id='radius'
                  name='radius'
                  margin='dense'
                  label={this.props.translate('commandRadius')}
                  variant='outlined'
                  fullWidth
                  value={this.props.savedCommandsForm.radius}
                  onChange={this.props.savedCommands_handleChange('attributes')}
                />
              )}
              <div style={{ textAlign: 'right', marginTop: 10 }}>
                <Button
                  size='small'
                  style={{ marginRight: 15 }}
                  className='button-white'
                  onClick={() => this.props.onCloseC_Att_Modal()}
                >
                  {this.props.translate('sharedCancel')}{' '}
                </Button>
                {checkPrivileges(this.props.isHaveAccess) && (
                  <Button
                    disabled={!this.props.isAddVisableBtn}
                    size='small'
                    onClick={() => this.props.addSavedCommands()}
                  >
                    {this.props.translate(this.props.addButtonStatus)}{' '}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    )
  }
}

const frequencyType = [{ type: 's' }, { type: 'm' }, { type: 'h' }]
