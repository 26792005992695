import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { connect } from 'react-redux'
import UncheckIcon from './../../assets/nav/uncheck-box.svg'
import CheckIcon from './../../assets/nav/check-box.svg'
import Style from 'style-it'
import { toast } from 'react-toastify';
import { notifyError } from '../../Utils/CustomNotifcations'

const mapStateToProps = (state, ownProps) => ({
  themecolors: state.themeColors
})

const styles = theme => ({
  root: {
    color: 'inherit',
    margin: 0,
    padding: 0,

    '&$label': {
      color: 'inherit'
    }
  },
  label: { color: 'inherit' }
})

class CheckboxLabels extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked,
      indeterminate: props.indeterminate || false
    }
  }

  handleChange = name => event => {
    if (event.target.checked === true && !this.props.canAssign) {
        this.props.dispatch(
          notifyError("'No Access for assign'"))
    }
    if (event.target.checked === false && !this.props.canRemove) {
        this.props.dispatch(
          notifyError("'No Access for assign'"))
    }
    if (event.target.checked === true && this.props.canAssign) {
      this.setState({ checked: event.target.checked })
      this.props.onChange(event, name)
    } else if (event.target.checked === false && this.props.canRemove) {
      this.setState({ checked: event.target.checked })
      this.props.onChange(event, name)
    }
  }

  componentWillReceiveProps (n) {
    this.setState({
      checked: n.checked,
      indeterminate: n.indeterminate || false
    })
  }

  render () {
    const { checked, indeterminate } = this.state
    return (
      <Style>
        {`
    .theme-input-checkbox {
      color: inherit;
      padding: 2px;
    }
    .theme-input-custom-label:not(:empty) {
      margin-left: 10px;
      font-size: 0.875rem;
    }
    `}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                icon={
                  <svg stroke='currentColor' width={18} height={18}>
                    <use xlinkHref={`${UncheckIcon}#icon`} />
                  </svg>
                }
                checkedIcon={
                  <svg stroke='currentColor' width={18} height={18}>
                    <use xlinkHref={`${CheckIcon}#icon`} />
                  </svg>
                }
                disableRipple
                checked={checked ? true : false}
                indeterminate={indeterminate}
                readOnly={this.props.readonly}
                onChange={this.handleChange(this.props.value || this.props.id)}
                classes={{
                  checked: 'theme-input-checkbox',
                  root: 'theme-input-checkbox'
                }}
                color='default'
              />
            }
            classes={{
              ...this.props.classes,
              label: this.props.classes.label + ' theme-input-custom-label'
            }}
            label={this.props.label || ''}
          />
        </div>
      </Style>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CheckboxLabels))
