import React, { Component } from 'react'
import isEqual from 'react-fast-compare'
;
import L from 'leaflet'
import { MapContainer as Map, TileLayer, ZoomControl, Circle,
  Polygon,
  Tooltip,
  Polyline } from 'react-leaflet'
import { connect } from 'react-redux'
import moment from 'moment'
import * as turf from '@turf/turf'
import * as jQuery from 'jquery'
import vis from 'vis'
// import Timeline from 'react-visjs-timeline'
import '../../leaflet-plugin-trackplayback/control.trackplayback/control.playback.css'
import '../../leaflet-plugin-trackplayback/control.trackplayback'
import '../../leaflet-plugin-trackplayback/leaflet.trackplayback'
import Loader from './../../Layout/Loader'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Grid, IconButton } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import ReplayIcon from '@mui/icons-material/Replay'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import parse from 'html-react-parser';import { MapFilters } from './MapFilters'
import Button from '../common/Button'
class TimemachineMap extends Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = this.initializeState(props);
    // Bind methods
    this.mapRef = this.mapRef.bind(this);
    this.viewData = this.viewData.bind(this);
    this.setBoundOptions = this.setBoundOptions.bind(this);
    this.timelineRef = this.timelineRef.bind(this);
    this.onRangeChangeHandler = this.onRangeChangeHandler.bind(this);
    this.removeTimemachine = this.removeTimemachine.bind(this);
    this.map = null;
    this.playbacktrack = null;
    this.trackplaybackControl = null;
    this.timelineEl = null;
  }
  initializeState(props) {
    let serverTimeZoneName = this.getTimeZoneName(props);
    let serverTimeZone = moment.tz(serverTimeZoneName).utcOffset();
    return {
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      maxZoom: 16,
      reset: 0,
      presistZoom: false,
      MarkerDOM: null,
      fitBounds: {},
      bounds: [],
      loading: true,
      applied: false,
      showControls: true,
      custom: { speed: 13, play: false, tracks: true, points: false },
      selectGroups: {},
      options: this.initializeTimelineOptions(serverTimeZone),
    };
  }

  initializeTimelineOptions(serverTimeZone) {
    return {
      selectable: false,
      width: '100%',
      stack: false,
      showMajorLabels: true,
      zoomMin: 10000 * 60 * 10,
      type: 'range',
      showTooltips: true,
      tooltip: {
        followMouse: true,
      },
      format: {
        minorLabels: {
          minute: 'HH:mm',
          hour: 'HH',
        },
      },
      moment: function (date) {
        return date ? vis.moment(date).utcOffset(serverTimeZone) : vis.moment().utcOffset(serverTimeZone);
      },
    };
  }

  getTimeZoneName(props) {
    let serverTimeZoneName = 'Asia/Dubai';
    if (props.ServerSetting && props.ServerSetting.attributes && props.ServerSetting.attributes.timezone) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone;
    }
    if (props.logInUser && props.logInUser.attributes && props.logInUser.attributes.timezone) {
      serverTimeZoneName = props.logInUser.attributes.timezone;
    }
    return serverTimeZoneName;
  }

  componentDidMount() {
      // Initialize map
      this.setState({ loading: false });
    
    this.setState({
      maxZoom: this.props.mapLayer.maxZoom,
    });
  }
  

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.timemachineArray, prevProps.timemachineArray)) {
      this.handleTimemachineArrayUpdate(prevProps);
    }
  }

  handleTimemachineArrayUpdate(prevProps) {
    this.map=null
    this.setState({ loading: false }, () => {
      this.cleanupPreviousPlayback();
      this.initializePlayback(prevProps);
    });
  }

  cleanupPreviousPlayback() {
    if (this.trackplaybackControl?.trackPlayBack?.tracks) {
      this.trackplaybackControl._closeBtn.click();
    }
    if (this.timelineEl?.groupsData?.length) {
      const ids = this.timelineEl.groupsData.getIds();
      this.clickHandler({ group: ids[0] });
    }
    if (this.map) {
      this.resetMapZoomBasedOnBounds();
    }
  }

  resetMapZoomBasedOnBounds() {
    if (this.props.timemachineBounds?.length) {
      if (!this.state.presistZoom) {
        this.map.setMaxZoom(16);
      }
      this.map.fitBounds(this.props.timemachineBounds);
    }
  }

  initializePlayback(prevProps) {
    const timemachineArray = this.props.timemachineArray.length ? this.props.timemachineArray : prevProps.timemachineArray;
    const themeDarkColor = this.props.themecolors?.themeDarkColor || prevProps.themecolors?.themeDarkColor;
    const menuActiveBackground = this.props.themecolors?.menuActiveBackground || prevProps.themecolors?.menuActiveBackground;

    if (timemachineArray?.length&&this.map) {
      this.playbacktrack = L.trackplayback(timemachineArray, this.map, {
        clockOptions: {
          speed: this.state.custom.speed,
        },
        trackLineOptions: {
          isDraw: true,
          stroke: true,
          color: themeDarkColor,
          weight: 4,
          fill: false,
          opacity: 1,
          optionsMulty: [
            { color: '#b1b1b1' },
            { color: '#06a9f5' },
            { color: '#202020' },
            { color: '#D10B41' },
            { color: '#78c800' },
          ],
        },
        targetOptions: {
          useImg: true,
          imgUrl: '/assets/category/default/arrowtop.svg',
          width: 50,
          height: 50,
        },
        trackPointOptions: {
          isDraw: false,
          useCanvas: false,
          stroke: false,
          color: themeDarkColor,
          fill: true,
          fillColor: menuActiveBackground,
          opacity: 1,
          radius: 6,
          imgUrl: '/assets/category/default/arrowtop.svg',
        },
      });

      this.setupPlaybackEvents();
      this.initializeTrackPlaybackControl();
    } else {
      this.removeTimemachine();
    }
  }

  setupPlaybackEvents() {
    this.playbacktrack.on('tick', (e) => {
      if (e?.target?.clock && e.target.getEndTime() === e.time) {
        this.setState({
          custom: { ...this.state.custom, play: false },
        });
      }

      if (this.timelineEl?.dom && e?.target?.tracks) {
        this.timelineEl.setCustomTime(e.time, 1);
      }
    });
  }

  initializeTrackPlaybackControl() {
    this.trackplaybackControl = L.trackplaybackcontrol(this.playbacktrack, {
      autoPlay: this.state.custom.play,
      position: 'bottomright',
    });

    this.trackplaybackControl.addTo(this.map);
    if (this.state.custom.play) {
      this.trackplaybackControl._play();
    }
    if (this.state.custom.points) {
      this.trackplaybackControl._showTrackPoint({
        target: { checked: true },
      });
    }
    if (this.state.custom.tracks) {
      this.trackplaybackControl._showTrackLine({
        target: { checked: true },
      });
    }

    if (this.timelineEl?.dom) {
      let serverTimeZoneName = 'Asia/Dubai';
      if (this.props.ServerSetting?.attributes?.timezone) {
        serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
      }
      if (this.props.logInUser?.attributes?.timezone) {
        serverTimeZoneName = this.props.logInUser.attributes.timezone;
      }
      const serverTimeZone = moment.tz(serverTimeZoneName).utcOffset();
      const options = {
        ...this.state.options,
        moment: (date) => moment(date).utcOffset(serverTimeZone),
        ...this.props.options,
      };

      this.timelineEl.setOptions(options);
    }
  }

  removeTimemachine() {
    if (this.timelineEl && this.timelineEl.setGroups) {
      this.timelineEl.setGroups([]);
      this.timelineEl.setItems([]);
    }
    this.cleanupPreviousPlayback();
    this.props.onDismiss()

    this.setState({
      applied: false,
      showControls: false,
      custom: { speed: 13, play: false, tracks: true, points: true }
    })
  }


  timelineRef (e) {
    if (e && e.$el) {
      this.timelineEl = e.$el
      this.timelineEl.addCustomTime(moment().valueOf(), 1)
    }
  }

  onRangeChangeHandler (e) {
     if (this.trackplaybackControl) {
      this.trackplaybackControl._slider.value = e.time
        ? moment.utc(e.time).valueOf()
        : moment.utc().valueOf()
    }
    if (this.playbacktrack && this.playbacktrack.tracks) {
      this.playbacktrack.setCursor(
        e.time ? moment.utc(e.time).valueOf() : moment.utc().valueOf()
      )
    }
  }

  componentWillUnmount () {
    this.removeTimemachine()
  }

  componentWillMount() {
    this.setState({
      maxZoom: this.props.mapLayer.maxZoom
    })
  }

  mapRef (el) {
    if (!this.map) {
      this.map = el
      if(this.map){
        this.map.setMaxZoom(16);
        if (jQuery('.leaflet-control-layers-selector')) {
          jQuery('.leaflet-control-layers-selector').click()
        }
      }
   
    }
  }
  //   removeTimemachine () {
  //   if (this.playbacktrack && this.playbacktrack.clock) {
  //     if (this.timelineEl && this.timelineEl.dom && this.timelineEl.clear) {
  //       this.timelineEl.clear()
  //     }

  //     this.trackplaybackControl._closeBtn.click()
  //   }

  //   this.props.onDismiss()

  //   this.setState({
  //     applied: false,
  //     showControls: false,
  //     custom: { speed: 13, play: false, tracks: true, points: true }
  //   })
  // }
  
  // mapRef(map) {
  //   if (!this.map) {
  //     this.map = map;
  //     if (this.map) {
  //       this.map.setMaxZoom(16);
  //       this.map.invalidateSize(true);
  //       this.map.on('click', () => this.map.scrollWheelZoom.enable());
  //       this.map.on('mouseover', () => this.map.scrollWheelZoom.enable());
  //       this.map.on('mouseout', () => this.map.scrollWheelZoom.disable());
  //       this.map.scrollWheelZoom.disable();
  //     }
  //   }
  // }

  setBoundOptions () {
    return {}
  }

  viewData (row, e) {
    let points = e.target._latlngs.map(latlng => [latlng.lng, latlng.lat])
    var line = turf.lineString(points)
    var pt = turf.point([e.latlng.lng, e.latlng.lat])
    var nearestPoint = turf.nearestPointOnLine(line, pt, { units: 'meters' })

    var nlatlng = this.props.routes[row.startPositionId]['positions'][
      nearestPoint['properties']['index']
    ]

    let t = moment(nlatlng.fixTime).format('YYYY-MM-DD HH:mm')

    let html =
      '<div className="position-box"><strong>' +
      row.deviceName +
      '</strong><div className="position-box-body">Timemachine Address: ' +
      nlatlng.address +
      ' <br />Time: ' +
      t +
      '<br/> Speed : ' +
      (nlatlng.speed * 1.852).toFixed(2) +
      ' Km/h <br />Coordinates: ' +
      e.latlng.lat +
      ', ' +
      e.latlng.lng +
      '</div></div>'

    e.target.bindPopup(html).openPopup(e.latlng)
  }

  valuetext = value => {
    return `${value}°C`
  }

  showTracks = e => {
    this.trackplaybackControl._showTrackLine(e)
    this.setState({
      custom: {
        ...this.state.custom,
        tracks: e.target.checked
      }
    })
  }
  showPoints = e => {
    this.trackplaybackControl._showTrackPoint(e)
    this.setState({
      custom: {
        ...this.state.custom,
        points: e.target.checked
      }
    })
  }

  setSpeed = type => {
    if (type) {
      this.trackplaybackControl._quick()
    } else {
      this.trackplaybackControl._slow()
    }

    this.setState({
      custom: {
        ...this.state.custom,
        speed: type ? this.state.custom.speed + 1 : this.state.custom.speed - 1
      }
    })
  }
  restart = () => {
    this.setState({ custom: { ...this.state.custom, play: true } }, () => {
      this.trackplaybackControl._restart()
    })
  }
  close = () => {
    this.trackplaybackControl._close()
    this.setState({
      custom: { speed: 13, play: false, tracks: true, points: true }
    })
  }

  playToggle = () => {
    let state = this.state.custom.play

    this.trackplaybackControl._play()

    this.setState({
      custom: {
        ...this.state.custom,
        play: !state
      }
    })
  }
  // removeTimemachine () {
  //   if (this.playbacktrack && this.playbacktrack.clock) {
  //     if (this.timelineEl && this.timelineEl.dom && this.timelineEl.clear) {
  //       this.timelineEl.clear()
  //     }

  //     this.trackplaybackControl._closeBtn.click()
  //   }

  //   this.props.onDismiss()

  //   this.setState({
  //     applied: false,
  //     showControls: false,
  //     custom: { speed: 13, play: false, tracks: true, points: true }
  //   })
  // }

  clickHandler = ev => {
    if (ev && ev.group) {
      let groups = [...this.props.groups]
      let selectGroups = { ...this.state.selectGroups }
      groups.map(g => {
        if (g.id === ev.group) {
          if (!g.className) {
            g.className = 'selected-group'
            selectGroups[ev.group] = true
          } else {
            selectGroups[ev.group] = false
            g.className = ''
          }
        }
        return null
      })
      this.setState({ selectGroups }, () => {
        this.timelineEl.setGroups(groups)
        this.playbacktrack?.on('tick', e => {
          if (e.target && e.target.tracks) {
            let b = []
            e.target.tracks.map(t => {
              let arr = t.getTrackPointsBeforeTime(e.time)

              if (arr && arr.length) {
                let a = arr.pop()
                let s = t.getStartTrackPoint()
                if (this.state.selectGroups[s.id]) {
                  b.push({ lat: a.lat, lng: a.lng })
                }
              }
              return null
            })
            if (b.length) {
              if (
                this.map &&
                this.map._layers &&
                this.map._layers.length !== 0
              ) {
                this.map.fitBounds(b)
              }
            }
          }
        })
      })
    }
  }

  onAddLayer = (e, id) => {
    this.setState({
      fitBounds: {
        ...this.state.fitBounds,
        [id]: e.target.getBounds()
      }
    })
  }

  updateVisible = () => {
    setTimeout(() => {
      let bounds = []
      this.props.geoFence.map(g => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id])
        }
        return null
      })
      if (bounds.length) {
        this.map.fitBounds(bounds)
      }
    }, 50)
  }

  checkZoom = options => {
    if(this.state.presistZoom) {
      this.setState({maxZoom: options.zoom, reset: true}, () => {
          this.map.setMaxZoom(this.state.maxZoom);
      })
    }
  }

  resetMapZoom = (zoom) => {
    this.setState({
      maxZoom: zoom || this.props.mapLayer.maxZoom,
      reset: false
    }, () => {
      this.map.setMaxZoom(this.state.maxZoom);
    })
  }

  presistZoom = (e) => {
    this.setState({
      presistZoom: e.target.checked,
    }, () => {
      if(this.state.presistZoom) {
        this.resetMapZoom(this.props.mapLayer.maxZoom);
      }
      else {
        this.resetMapZoom(16);
      }
    })
    
  }

  render () {
    const position = [this.state.lat, this.state.lng]

    const geofences = this.props.geoFence.map(obj => {
      if (obj.attributes.type === 'circle' && obj.visible === true) {
        return (
            <Circle
              onAdd={e => this.onAddLayer(e, obj.id)}
              id={obj.id}
              radius={obj.attributes.radius}
              center={obj.attributes.latlng}
              color={obj.attributes.color}
              key={obj?.id}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Circle>
        )
      } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
        return (
            <Polygon
              onAdd={e => this.onAddLayer(e, obj.id)}
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Polygon>
        )
      } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
        return (
            <Polyline
              onAdd={e => this.onAddLayer(e, obj.id)}
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Polyline>
        )
      }

      return ''
    })

    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const thisMap = [
      <Map
        boundsOptions={this.setBoundOptions}
        key={1}
        onZoomAnim={this.checkZoom}
        center={position}
        zoom={this.state.zoom}
        zoomControl={false}
        style={{ height: '100%' }}
        ref={this.mapRef}
        maxZoom={this.state.maxZoom}
        {...crs}
      >
        <TileLayer
          {...this.props.mapLayer}
          maxNativeZoom={this.state.maxZoom}
          maxZoom={this.state.maxZoom}
          minZoom={this.state.minZoom}
        />
        <ZoomControl position={'topright'} />
        {geofences}
        <div className='map-filters-wrapper'>
          <MapFilters
            disableBottomLeftFilters
            disablePOIFilters
            updateVisible={this.updateVisible}
            themecolors={this.props.themecolors}
            translate={this.props.translate}
            mapRef={this.map}
          />
        </div>
      </Map>
    ]

    return (
      <React.Fragment>
        {this.props.loading === true ? <Loader /> :<>

        {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
        {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleTerrain'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleSatellite'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)
          ? thisMap
          : null}

        {this.state.showControls && this.trackplaybackControl && (
          <div className='trackplaybackcontrol'>
            <Grid container>
              <Grid item className='show-tracks'>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='inherit'
                      id='showTracks'
                      onChange={e => this.showTracks(e)}
                      checked={this.state.custom.tracks}
                    />
                  }
                  label={this.props.translate('showTracks')}
                />
              </Grid>
              <Grid item className='show-points'>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='showPoints'
                      color='inherit'
                      onChange={e => this.showPoints(e)}
                      checked={this.state.custom.points}
                    />
                  }
                  label={this.props.translate('showPoints')}
                />
              </Grid>
              <Grid item className='show-points'>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='presistZoom'
                      color='inherit'
                      onChange={e => this.presistZoom(e)}
                      checked={this.state.presistZoom}
                    />
                  }
                  label={this.props.translate('presistZoom')}
                />
              </Grid>
              {this.state.reset === true ? <Grid item className='show-points'>
                <Button onClick={()=> this.resetMapZoom(this.props.mapLayer.maxZoom)}>Reset Map Zoom</Button>
              </Grid> : null}

              <Grid item className='track-control-right speed-controls'>
                <IconButton onClick={()=> this.setSpeed(false)}>
                  <NavigateBeforeIcon />
                </IconButton>
                <div className='speed-text'>
                  {this.props.translate('sensorsTranslation.speed')}{' '}
                  {'X' + this.state.custom.speed}
                </div>
                <IconButton onClick={()=> this.setSpeed(true)}>
                  <NavigateNextIcon />
                </IconButton>
              </Grid>
              <Grid item className='playback-controls'>
                <IconButton onClick={()=> this.restart()}>
                  <ReplayIcon />
                </IconButton>
                <IconButton onClick={()=> this.playToggle()}>
                  {!this.state.custom.play ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
        {this.props.items && (
          <h2>timeline</h2>
          // <Timeline
          //   key='timeline-1'
          //   // options={{
          //   //   height: '500px', // Set the desired height here
          //   // }}
          //   ref={this.timelineRef}
          //   options={this.state.options}
          //   items={this.props.items}
          //   groups={this.props.groups}
          //   clickHandler={this.clickHandler}
          //   timechangeHandler={this.onRangeChangeHandler}
          // />
        )}
        </>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer,
  devices2: state.devices2,
  devices: state.devices.data,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  geoFence: state.geoFence
})

export default connect(mapStateToProps)((TimemachineMap))