  import React, { Component } from "react";

  import { connect } from "react-redux";
  import axios from "axios";
  import { checkPrivileges } from "../../Helpers";
  import Layout from "./../../Layout";
  
  import Grid from "@mui/material/Grid";
  import instance from "../../axios";
  import Table from "../../Components/common/tableWithBackEndPagination";
  import Loader from "../../Layout/Loader";
  import CustomDialog from "../../Components/common/Dialog";
import { notifyError } from "../../Utils/CustomNotifcations";
  // import { CreateInvoice } from './CreateInvoice'
  const CancelToken = axios.CancelToken;
  let source = "";
  // const packageItem = {
  //   itemName:'',
  //   cost:'',
  //   quantity:''
  // }
  // const packageService = {
  //   serviceName:'',
  //   cost:'',
  //   quantity:''
  // }

  // const defaultServices = {
  //   serviceName:"",
  //   serviceTotalAmount:"",
  //   serviceVATAmount:"",

  // }
  // const packageDefaultForm={
  //   packageCode:'',
  //   packageName:'',
  //   packageTotalAmount:'',
  //   PackageDiscount:'',
  //   PackageVATAmount:'',
  //   PackageNetAmount:'',
  //   service:[packageService],
  //   items:[packageItem]
  // }

  // const defaultItems={
  //   itemName:"",
  //   itemQuantity:"",
  //   itemVATAmount:"",
  //   itemPrice:"",
  // }
  class Invoice extends Component {
    constructor(props) {
      super(props);
      this.state = {
        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        showStats: false,
        selectedAreaId: "",
        selectedvehicleId: "",
        to: "",
        from: "",
        loader: false,
        openCreateForm: false,
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
        showImportedInvoiceError: false,
        importedInvoiceData: {},
        // invoiceForm:{
        //   plateNumber:"",
        //   customer:{},
        //   Invoice:[],
        //   Services:[defaultServices],
        //   items:[defaultItems]
        // },
        // Package:packageDefaultForm,
      };
    }

    componentWillUnmount() {
      this.setState({
        assignMode: "",
        currentPage: 1,
        pagesize: 50,
        selectedvehicleId: "",
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
      });
    }

    componentWillMount() {
      if (
        this.props.logInUser &&
        this.props.logInUser.id &&
        this.state.initFetch === false
      ) {
        this.setState(
          {
            initFetch: true,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    }

    componentWillReceiveProps(n) {
      if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
        this.setState(
          {
            initFetch: true,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    }

    fetchMoreItems = (a, b, c) => {
      let pagination = true;
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize,
        pagination
      );
    };

    searchItems = (text) => {
      this.setState(
        {
          searchText: text,
        },
        () => {
          this.fetchData(this.props.logInUser, 1, this.state.pagesize);
        }
      );
    };
    // fetchInvoices = (text, allInvoices) => {
    //   const u = allInvoices&&allInvoices.filter(u =>
    //     (u.id + u.name).toLowerCase().includes(text.toLowerCase())
    //   )
    //   this.setState(
    //     {
    //       invoices:u
    //     }
    //   )
    // }

    fetchData = (logInUser, page, perPage, pagi, reset) => {
      const { contractType } = this.props.ServerSetting;
      source = CancelToken.source();
      let url = "";
      let searchText = this.state.searchText;
      if (searchText) {
        searchText = "&search=" + searchText;
      } else {
        searchText = "";
      }

      let items = this.state.itemPagination.items;
      if (reset) {
        items = [];
      }
      let invoice = contractType === 2 ? "leaseinvoices" : "invoices";
      // fetch(`/api/commands/get?userId=${logInUser.id}&all=true&page=${page}&limit=${perPage}${searchText}`)
      //http://localhost:8090/api/services/invoice/get?page=1&limit=20&search=&vehicleId=1&vehicleId=2&from=2020-06-14T00:00:00.000Z&to=2022-06-14T00:00:00.000Z
      url = this.state.itemSearch
        ? `/api/${invoice}/get?page=${page}&limit=${perPage}&invoicenumber=${this.state.itemSearch}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`
        : `/api/${invoice}/get?page=${page}&limit=${perPage}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`;

      instance({
        url: url,
        method: "GET",
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({
            loader: false,
            invoices: pagi ? items.concat(res.data) : res.data,
            isDataRecieved: true,
            itemPagination: {
              ...res,
              items: pagi ? items.concat(res.data) : res.data,
            },
            currentPage: res.hasNext ? res.page  : this.state.currentPage,
            // currentPage: res.hasNext ? res.page + 1 : res.page,
          });
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    };
    setPagination = (user) => {
      const { itemPagination } = this.state;
      if (itemPagination && itemPagination.items && itemPagination.items.length) {
        let nUser = { ...user };
        let lastPage = nUser.total / this.state.pagesize;
        let IsFloate = this.checkFloteNumber(lastPage);
        delete nUser.data;
        nUser.pageSize = this.state.pagesize;
        nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
        return nUser;
      }
      return {};
    };

    handleChangeRowsPerPage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "invoice") {
        this.setState(
          {
            pagesize: value,
            currentPage: 1,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    handleChangePage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "invoice") {
        this.setState(
          {
            currentPage: value,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    SearchItem = (searchValue, mode) => {
      if (source) {
        source.cancel();
      }
      this.setState(
        {
          itemSearch: searchValue,
          currentPage: 1,
        },
        () => {
          if (mode === "invoice") {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        }
      );
    };

    checkFloteNumber(n) {
      return Number(n) === n && n % 1 !== 0;
    }

    toggleFilterForm = () => {
      this.setState({
        showStats: !this.state.showStats,
      });
    };

    submitSideBarSearch = (data, type) => {
      this.setState(
        {
          loader: true,
          to: `&to=${data.to}`,
          from: `&from=${data.from}`,
          selectedAreaId: data.selectedAreaId,
          selectedvehicleId: data.selectedvehicleId,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    };

    downloadInvoice = (data, type) => {
      this.setState(
        {
          loader: true,
          to: `to=${data.to}`,
          from: `&from=${data.from}`,
          selectedAreaId: data.selectedAreaId,
          selectedvehicleId: data.selectedvehicleId,
        },
        () => {
          this.downloadInvoices(type);
        }
      );
    };

    downloadInvoices = (type) => {
      let accept;
      if (type === "downloadPdf") {
        accept = "application/pdf";
      } else {
        accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      let header;
      header = {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: accept,
        }),
        method: "GET",
      };
      fetch(
        `/api/invoices/export?${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`,
        { ...header }
      )
        .then((response1) => {
          if (response1.status === 200) {
            console.log("typee", type);
            if (type === "downloadPdf") {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "Invoices.pdf");
                  }
                );
              });
            } else {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "Invoices.xlsx");
                  }
                );
              });
            }
          } else {
            throw response1;
          }
        })

        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    };

    saveData = (blob, filename) => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          // this.setState({  : false })
        }, 0);
      }
      // this.setState({  : false })
    };

    //   createForm = ()=>{
    //       this.setState({
    //           openCreateForm:true
    //       })
    //   }

    //   closeCreateFrom = ()=>{
    //     this.setState({
    //       openCreateForm:false
    //     })

    //   }

    //   handleChange = (name,event,index,chk)=>{
    //     let rows = this.state.Package.service
    //     console.log('name ====', name)
    //     console.log('event ====', event)
    //     console.log('index ====', index)
    //     console.log('chk ====', chk)
    //     if(chk === 'service'){
    //       rows[index]['cost'] = event
    //       let val = rows
    //       this.setState({

    //         })

    //     }
    //     else if(chk==='customer'){
    //       this.setState({
    //         invoiceForm:{
    //           ...this.state.invoiceForm,
    //           customer:{
    //             ...this.state.invoiceForm.customer,
    //             [name]:event
    //           }
    //         }
    //       })
    //     }
    //     else{
    //       this.setState({
    //         invoiceForm:{
    //           ...this.state.invoiceForm,
    //           [name]:event
    //         }
    //       })
    //     }
    //   }

    //   handlePackChange = (name,event,chk) =>{
    //     // console.log('name ====', name)
    //     // console.log('event ====', event)
    //     // console.log('chk ====', chk)
    //   }

    //   addMore = (e,chk) => {
    //     // console.log('e ====', e)
    //     // console.log('chk ====', chk)
    //     if(chk === 'packageItem'){
    //       let rows = []
    //       rows = [...this.state.Package.items]
    //       // console.log('rows ===', rows)
    //       if (rows.length) {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(packageItem)
    //       } else {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(last)
    //       }
    //       this.setState({
    //         Package:{
    //           ...this.state.Package,
    //           items:rows
    //         }
    //       })
    //     }
    //     else if(chk === 'packageService'){
    //       let rows = []
    //       rows = [...this.state.Package.service]
    //       // console.log('rows ===', rows)
    //       if (rows.length) {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(packageService)
    //       } else {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(last)
    //       }
    //       this.setState({
    //         Package:{
    //           ...this.state.Package,
    //           service:rows
    //         }
    //         })
    //     }
    //     else if(chk === "invoiceService"){
    //       let rows = []
    //       rows = [...this.state.invoiceForm.Services]
    //       // console.log('rows ===', rows)
    //       if (rows.length) {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(defaultServices)
    //       } else {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(last)
    //       }
    //       this.setState({
    //         invoiceForm:{
    //           ...this.state.invoiceForm,
    //           Services:rows
    //         } }, ()=>{console.log('package 33 ===', this.state.package)})
    //     }
    //     else if(chk === "invoiceItems"){
    //       let rows = []
    //       rows = [...this.state.invoiceForm.items]
    //       // console.log('rows ===', rows)
    //       if (rows.length) {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(defaultItems)
    //       } else {
    //         let last = { ...rows[rows.length - 1] }
    //         rows.push(last)
    //       }
    //       this.setState({
    //         invoiceForm:{
    //           ...this.state.invoiceForm,
    //           items:rows
    //         } })
    //     }
    //     else{
    //       let rows = []
    //         rows = [...this.state.Package]

    //         if (rows.length) {
    //           let last = { ...rows[rows.length - 1] }
    //           rows.push(packageDefaultForm)
    //         } else {
    //           let last = { ...rows[rows.length - 1] }
    //           rows.push(last)
    //         }
    //         this.setState({ Package:rows })

    //     }
    // }

    //   deleteRow = (row, index, e, chk) => {
    //     // console.log('row ===', row)
    //     // console.log('index ===', index)
    //     // console.log('e ===', e)
    //     // console.log('chk===', chk)
    //     // console.log('this.state.Package ===', this.state.Package)
    //     if(chk === 'packageItem'){
    //       let rows = [this.state.Package.items]
    //       // console.log('rows ====', rows)
    //       rows[0].splice(index, 1)
    //       this.setState({Package:{
    //         ...this.state.Package,
    //         items:rows[0]
    //       },
    //         isVisible: (rows[0].itemName&&rows[0].cost&&rows[0].quantity)?false:true }, () => {
    //         this.state.Package.items.map((row, index) => {
    //         this.handleChart2('itemName', row['itemName'], index)
    //         this.handleChart2('cost', row['cost'], index)
    //         this.handleChart2('quantity', row['quantity'], index)
    //           return null
    //         })
    //       })
    //     }
    //     else if(chk === 'packageService'){
    //       let rows = [this.state.Package.service]
    //       // console.log('this.state.Package.service ====', this.state.Package.service)
    //       // console.log('rows ====', rows)
    //       // console.log('rows.splice(index, 1) ====', rows.splice(index, 1))
    //       rows[0].splice(index, 1)
    //       // console.log('rows[0] ===', rows[0])
    //       // console.log('delete row =====', rows[0])
    //       // console.log('check state =====', this.state.Package)
    //       this.setState({Package:{
    //         ...this.state.Package,
    //         service:rows[0]
    //       },
    //         isVisible: (rows[0].serviceName&&rows[0].cost&&rows[0].quantity)?false:true }, () => {
    //         this.state.Package.service.map((row, index) => {
    //         this.handleChart2PackageService('serviceName', row['serviceName'], index)
    //         this.handleChart2PackageService('cost', row['cost'], index)
    //         this.handleChart2PackageService('quantity', row['quantity'], index)
    //           return null
    //         })
    //       })
    //     }
    //     else if(chk === 'invoiceService'){
    //       let rows = [this.state.invoiceForm.Services]
    //       // console.log('this.state.Package.service ====', this.state.Package.service)
    //       // console.log('rows ====', rows)
    //       // console.log('rows.splice(index, 1) ====', rows.splice(index, 1))
    //       rows[0].splice(index, 1)
    //       // console.log('rows[0] ===', rows[0])
    //       // console.log('delete row =====', rows[0])
    //       // console.log('check state =====', this.state.Package)
    //       this.setState({Package:{
    //         ...this.state.invoiceForm,
    //         Services:rows[0]
    //       },
    //         isVisible: (rows[0].serviceName&&rows[0].serviceTotalAmount&&rows[0].serviceVATAmount)?false:true }, () => {
    //         // this.state.invoiceForm.Service.map((row, index) => {
    //         // this.handleChart2PackageService('serviceName', row['serviceName'], index)
    //         // this.handleChart2PackageService('serviceTotalAmount', row['serviceTotalAmount'], index)
    //         // this.handleChart2PackageService('serviceVATAmount', row['serviceVATAmount'], index)
    //         //   return null
    //         // })
    //       })
    //     }
    //     else if(chk === 'invoiceItems'){
    //       let rows = [this.state.invoiceForm.items]
    //       // console.log('this.state.Package.service ====', this.state.Package.service)
    //       // console.log('rows ====', rows)
    //       // console.log('rows.splice(index, 1) ====', rows.splice(index, 1))
    //       rows[0].splice(index, 1)
    //       // console.log('rows[0] ===', rows[0])
    //       // console.log('delete row =====', rows[0])
    //       // console.log('check state =====', this.state.Package)
    //       this.setState({Package:{
    //         ...this.state.invoiceForm,
    //         items:rows[0]
    //       },
    //         isVisible: (rows[0].itemName&&rows[0].itemQuantity&&rows[0].itemVATAmount&&rows[0].itemPrice)?false:true }, () => {
    //         // this.state.invoiceForm.Service.map((row, index) => {
    //         // this.handleChart2PackageService('serviceName', row['serviceName'], index)
    //         // this.handleChart2PackageService('serviceTotalAmount', row['serviceTotalAmount'], index)
    //         // this.handleChart2PackageService('serviceVATAmount', row['serviceVATAmount'], index)
    //         //   return null
    //         // })
    //       })
    //     }
    //     else{
    //       let rows = [...this.state.Package]
    //       rows.splice(index, 1)
    //       this.setState({ Package : rows,
    //         isVisible: (rows[0].packageCode&&rows[0].packageName&&rows[0].packageTotalAmount&&rows[0].PackageDiscount&&rows[0].PackageVATAmount&&rows[0].PackageNetAmount&&rows[0].Service)?false:true }, () => {
    //         this.state.Package.map((row, index) => {
    //         this.handleChart2('packageCode', row['packageCode'], index)
    //         this.handleChart2('packageName', row['packageName'], index)
    //         this.handleChart2('packageTotalAmount', row['packageTotalAmount'], index)
    //         this.handleChart2('PackageDiscount', row['PackageDiscount'], index)
    //         this.handleChart2('PackageVATAmount', row['PackageVATAmount'], index)
    //         this.handleChart2('PackageNetAmount', row['PackageNetAmount'], index)
    //         this.handleChart2('Service', row['Service'], index)
    //           return null
    //         })
    //       })

    //     }
    //   }

    //   handleChart2 = (name, value, index) => {
    //     let rows = [...this.state.Package.items]
    //     let keys = []
    //     rows[index][name] = value
    //     rows.map(i => {
    //       keys.push(i.x)
    //       return null
    //     })
    //     let sorted_arr = keys.slice().sort()
    //     let results = []
    //     for (let i = 0; i < sorted_arr.length - 1; i++) {
    //       if (sorted_arr[i + 1] === sorted_arr[i]) {
    //         results.push(sorted_arr[i])
    //       }
    //     }
    //     let a = []
    //     keys.map((item, index) => {
    //       results.map(w => {
    //         if (item === w) {
    //           a.push(index)
    //         }
    //         return null
    //       })
    //       return null
    //     })
    //     rows.map(sal => {
    //       sal.e = false
    //       return null
    //     })
    //     a.map(val => {
    //       rows[val] = { ...rows[val], e: true }
    //       return null
    //     })
    //     this.setState({ Package:{
    //       ...this.state.Package,
    //       items:rows}, error: a.length > 0 ? true : false })
    //   }

    //   handleChart2PackageService = (name, value, index) => {
    //     let rows = [...this.state.Package.service]
    //     let keys = []
    //     rows[index][name] = value
    //     rows.map(i => {
    //       keys.push(i.x)
    //       return null
    //     })
    //     let sorted_arr = keys.slice().sort()
    //     let results = []
    //     for (let i = 0; i < sorted_arr.length - 1; i++) {
    //       if (sorted_arr[i + 1] === sorted_arr[i]) {
    //         results.push(sorted_arr[i])
    //       }
    //     }
    //     let a = []
    //     keys.map((item, index) => {
    //       results.map(w => {
    //         if (item === w) {
    //           a.push(index)
    //         }
    //         return null
    //       })
    //       return null
    //     })
    //     rows.map(sal => {
    //       sal.e = false
    //       return null
    //     })
    //     a.map(val => {
    //       rows[val] = { ...rows[val], e: true }
    //       return null
    //     })
    //     this.setState({ Package:{
    //       ...this.state.Package,
    //       service:rows}, error: a.length > 0 ? true : false })
    //   }

    //   handleChangeInvoice = (name,value,index,chk)=>{
    //     let rows = [...this.state.invoiceForm.items]
    //     console.log('rows ====', rows)
    //     console.log('name ===', name)
    //     console.log('value ===', value)
    //     console.log('index ===', index)
    //     console.log('chk ===', chk)
    //     if(chk === "invoiceItems" ){
    //       console.log('rows index =====',rows[index] )
    //       let list  = rows[index]
    //       console.log('li =====', list)
    //       list[name] = value
    //       console.log('list[name] =====', list[name])
    //       console.log('list[name] =====', list[name])
    //       console.log('rows ====', rows)
    //       this.setState({
    //         invoiceForm:{
    //           ...this.state.invoiceForm,
    //           items:rows
    //         }
    //       },()=>{console.log('invoiceForm =====', this.state.invoiceForm)})
    //     }
    //     // if(chk === 'invoiceItems'){
    //     //   let rows = [...this.state.invoiceForm.items]
    //     //   console.log('rows[index].itemName ====', rows[index].itemName)
    //     //   let keys = []
    //     //   rows[index][name] = value
    //     //   rows.map(i => {
    //     //     console.log('i====', i)
    //     //     keys.push(i)
    //     //     return null
    //     //   })
    //     //   console.log('rows =====', rows)
    //     //   let sorted_arr = keys.slice().sort()
    //     //   let results = []
    //     //   for (let i = 0; i < sorted_arr.length - 1; i++) {
    //     //     if (sorted_arr[i + 1] === sorted_arr[i]) {
    //     //       results.push(sorted_arr[i])
    //     //     }
    //     //   }
    //     //   let a = []
    //     //   console.log('keys ====', keys)
    //     //   keys.map((item, index) => {
    //     //     results.map(w => {
    //     //       if (item === w) {
    //     //         a.push(index)
    //     //       }
    //     //       return null
    //     //     })
    //     //     return null
    //     //   })
    //     //   a.map(val => {
    //     //     rows[val] = { ...rows[val] }
    //     //     return null
    //     //   })

    //     //   this.setState({
    //     //     invoiceForm:{
    //     //       ...this.state.invoiceForm,
    //     //       items:rows
    //     //     }
    //     //   },()=>{console.log('invoice form ===', this.state.invoiceForm)})
    //     // }
    //     // rows[index]['cost'] = parseFloat(value*rows[index].expenseSubTypeId.unitPrice).toFixed(2)

    //   }

    downloadInvoicePdf = (item) => {
      let invoice =
        this.props.ServerSetting && this.props.ServerSetting.contractType === 1
          ? "invoices"
          : this.props.ServerSetting.contractType === 2
          ? "leaseinvoices"
          : "invoices";

      let Accept = "application/pdf";
      let api = `/api/${invoice}/export/${item[0].id}`;
      axios({
        method: "GET",
        url: api,
        headers: {
          Accept: Accept,
        },
        responseType: "blob",
      })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          a.setAttribute("download", `invoice.pdf`);
          document.body.appendChild(a);
          a.href = url;
          a.click();
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            notifyError("somethingWentWrong")
          );
        });
    };

    openImportTable = (item) => {
      this.setState({
        showImportedInvoiceError: true,
        importedInvoiceData: item,
      });
    };

    onCloseImportedModal = () => {
      this.setState({ showImportedInvoiceError: false });
    };

    render() {
      const endMessage =
        this.state.itemPagination && this.state.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {this.props.translate("notFound")}
          </p>
        );
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            modeEmpty={this.modeEmpty}
            toggleFilterForm={this.toggleFilterForm}
            submitSideBarSearch={this.submitSideBarSearch}
            downloadInvoice={this.downloadInvoice}
            {...this.state}
          >
            <div className="main-content-page">
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                }}
              >
                {
                  <Grid container style={{ height: "100%" }}>
                    <Grid item sm={12} xs={12}>
                      {this.state.isDataRecieved ? (
                        <Table
                          rows={
                            (this.state.itemPagination &&
                              this.state.itemPagination.items) ||
                            []
                          }
                          pagination={this.setPagination(
                            this.state.itemPagination && this.state.itemPagination
                          )}
                          handleChangeRowsPerPage={(n) =>
                            this.handleChangeRowsPerPage(n, "invoice")
                          }
                          handleChangePage={(n) =>
                            this.handleChangePage(n, "invoice")
                          }
                          SearchItem={(n) => this.SearchItem(n, "invoice")}
                          logInUser={this.props.logInUser}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          vehicles={this.props?.vehicles || []}
                          viewInvoice
                          invoiceLabel
                          searchable
                          invoiceModule
                          ServerSetting={this.props.ServerSetting}
                          invoiceRow
                          downloadInvoicePdf={this.downloadInvoicePdf}
                          // createButton={
                          //   checkPrivileges('invoiceCreate') && (
                          //     <div style={{ marginLeft: 10 }}>
                          //       <Button onClick={this.createForm}>
                          //         {this.props.translate('Create')}
                          //       </Button>
                          //     </div>
                          //   )
                          // }
                          // isDownload={
                          //   <ExportImportMenu
                          //     fileName="invoices"
                          //     checkPrivilege
                          //     isInvoices
                          //     data={[]}
                          //     themecolors={this.props.themecolors}
                          //     fetchData={this.fetchData}
                          //     openImportTable={this.openImportTable}
                          //   />
                          // }
                          rowDefinition={
                            this.props.ServerSetting.contractType !== 1
                              ? [
                                  {
                                    id: "invoiceNumber",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("invoiceNumber"),
                                  },
                                  {
                                    id: "invoiceDate",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("invoiceDate"),
                                  },
                                  {
                                    id: "saleTotalAmount",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("Amount"),
                                  },
                                  {
                                    id: "saleVATAmount",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("VAT Amount"),
                                  },
                                  {
                                    id: "saleNetAmount",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("Total Amount"),
                                  },
                                  {
                                    id: "viewInvoice",
                                    numeric: false,
                                    disablePadding: false,
                                    label: this.props.translate("invoice"),
                                    hide: false,
                                  },
                                ]
                              : [
                          {
                            id: "invoiceNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceNumber"),
                          },
                          // {
                          //   id: "invoiceRefNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("invoiceRefNum"),
                          // },
                          // {
                          //   id: "serviceId",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("bookingID"),
                          // },
                          // {
                          //   id: "serviceRefNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("customerRefNum"),
                          // },
                          // {
                          //   id: "plateNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("vehicleLicensePlate"),
                          // },
                          {
                            id: "serviceDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceDate"),
                          },
                          {
                            id: "saleTotalAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Amount"),
                          },
                          {
                            id: "saleVATAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("VAT Amount"),
                          },
                          {
                            id: "saleNetAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Total Amount"),
                          },
                          {
                          id: "valid",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("status"),
                          hide: checkPrivileges("invoiceInvalidView") ? false : true,
                          },
                          {
                            id: "viewInvoice",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoice"),
                            hide: false,
                          },
                        ]
                          }
                        />
                      ) : (
                        <Loader />
                      )}
                    </Grid>
                  </Grid>
                }
                {/* {this.state.openCreateForm ?(
                  <>
                    <CreateInvoice
                        invoiceForm={this.state.invoiceForm}
                        Package={this.state.Package}
                        themecolors={this.props.themecolors}
                        translate={this.props.translate}
                        logInUser={this.props.logInUser}
                        closeCreateFrom={this.closeCreateFrom}
                        handleChange={this.handleChange}
                        handlePackChange={this.handlePackChange}
                        addMore={this.addMore}
                        deleteRow={this.deleteRow}
                        handleChangeInvoice={this.handleChangeInvoice}
                      />
                  </>
                ) :
                null}  */}
              </div>
            </div>
            {this.state.showImportedInvoiceError && (
              <CustomDialog
                title="Imported Invoice"
                visable={true}
                onClose={this.onCloseImportedModal}
                bodyPadding={10}
                hideDragIcon
                fullWidth
                isVisableBtn
                noFullScreen
              >
                <div>
                  <table>
                    <tr
                      style={{
                        borderBottom: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td>
                        <strong>Invoice no</strong>
                      </td>
                      <td>
                        <strong>Error</strong>
                      </td>
                    </tr>
                    {Object.entries(this.state.importedInvoiceData).map(
                      (item, i) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "1px solid black",
                              paddingTop: 2,
                            }}
                          >
                            <td style={{ padding: 10 }}>
                              <strong>{item[0]}</strong>
                            </td>
                            <td style={{ padding: 10 }}>{item[1]}</td>
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              </CustomDialog>
            )}
          </Layout>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
    themecolors: state.themeColors,
    logInUser: state.logInUsers,
    ServerSetting: state.ServerSetting,
    vehicles: state.vehicles,
  });

  export default connect(mapStateToProps)(((Invoice)));
