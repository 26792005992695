import React from 'react'
import { connect } from 'react-redux'
import {
  setTrackId,
  updateDeviceVisible,
  toggleGroupDevices,
  setDeviceId,
  unsetDeviceId,
  fetchMoreDevices,
  resetGroupBy,
  updateGroupNames
  // applyGroupByFilter
} from './../../Actions/Devices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { updateEvents } from './../../Actions/Notifications'
import Icon from '@mui/material/Icon'
import UnitMenu from './../Menu/UnitMenu'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import './index.scss'
import { CheckIcon } from '../common/CheckIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from './../../assets/monitoring/info.svg'
import { ReactComponent as PlayIcon } from '../../assets/monitoring/play.svg'
import { ReactComponent as StopIcon } from '../../assets/monitoring/stop.svg'
import { ReactComponent as IgnitionIcon } from '../../assets/monitoring/idling.svg'
import { ReactComponent as TowingIcon } from '../../assets/monitoring/towing.svg'
import { ReactComponent as ParkingIcon } from '../../assets/monitoring/parking.svg'
import { ReactComponent as QuestionIcon } from '../../assets/monitoring/question.svg'
import { ReactComponent as PowerCutIcon } from '../../assets/monitoring/powerCut.svg'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { getComputedAttributes } from '../../Actions/ComputedAttributes'
import Scrollbar from 'react-scrollbars-custom'
import Loader from './../../Layout/Loader'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'

class Devices extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedId: ''
    }
  }
  componentWillUnmount () {
    this.setState(
      {
        setNull: true
      },
      () => {
        this.state = null
      }
    )
  }

  selectGroup = id => {
    if (this.state.selectedId === id) {
      // reset
      this.setState(
        {
          selectedId: ''
        },
        () => {
          this.props.dispatch(resetGroupBy())
        }
      )
    } else {
      this.setState(
        {
          selectedId: id
        },
        () => {
          // this.props.dispatch(applyGroupByFilter(id))
        }
      )
    }
  }

  groupCollapse = event => {
    event.preventDefault()
  }

  render () {
    if (this.state.setNull) {
      return null
    }
    if (this.props.switchView === 'units') {
      const data = this.props.devices.map(device => {
        return (
          <ListRow
            monitoringMap={this.props.monitoringMap}
            openDeviceSettings={this.props.openDeviceSettings}
            collapseSidebar={this.props.collapseSidebar}
            key={device.id}
            item={device}
            trackId={this.props.trackId}
            deviceId={this.props.deviceId}
            dispatch={this.props.dispatch}
            showBox={this.props.showBox}
            relatedData={this.props.deviceRelatedData[device.id]}
            themecolors={this.props.themecolors}
            translate={this.props.translate}
          />
        )
      })
      return (
        <div className='individual-view list-row-condest'>
          <ul className='list-view list-view-children'>{data}</ul>
        </div>
      )
    } else {
      return (
        <DrawGroups
          selectGroup={this.selectGroup}
          {...this.props}
          selectedId={this.state.selectedId}
          groupCollapse={this.groupCollapse}
        />
      )
    }
  }
}

class DrawGroups1 extends React.PureComponent {
  render () {
    return (
      <div className='group-view list-row-condest'>
        <ul className='list-view-group'>
          {this.props.groups.map((group, index) => (
            <DrawGroupRow key={index} group={group} {...this.props} />
          ))}
          <DrawGroupRow
            key='empty'
            group={{ id: 'nogroup', name: 'No Group' }}
            {...this.props}
          />
        </ul>
      </div>
    )
  }
}

export class DrawGroupRow extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      checked: true
    }
  }

  toggleGroupDevices = event => {
    this.setState(
      {
        checked: event.target.checked
      },
      () => {
        this.props.dispatch(
          toggleGroupDevices({
            id: this.props.group.id,
            checked: this.state.checked,
            ids: Object.keys(this.props.visibleGroups)
          })
        )
      }
    )
  }

  componentWillReceiveProps( n ) {
    if(n.isChecked !== this.props.isChecked) {
      this.setState({
        checked: n.isChecked
      })
    }
    if(this.props.visibleGroups && n.visibleGroups && n.visibleGroups[n.group.id] !== this.props.visibleGroups[n.group.id]) {
      this.setState({
        checked: n.visibleGroups[n.group.id]
      })
    }
  }

  render () {
    const emptyLinks = null
    const groups = this.props.groupWiseDevices[this.props.group.id]
    const checked = this.props.selectedId === this.props.group.id

    if (groups && groups.length) {
      return (
        <li key={this.props.group.id}>
          <div className='clearfix group-list-row list-row'>
            <label className='checkbox'>
              <input
                type='checkbox'
                checked={this.state.checked}
                onChange={e => this.toggleGroupDevices(e)}
              />
              <CheckIcon />
              <span className='unit-name'>{this.props.group.name}</span>
            </label>
            <div
              className='pull-right'
              onClick={e => this.props.selectGroup(this.props.group.id)}
            >
              <span className='action-item action-additional-menu'>
                <a
                  href={emptyLinks}
                  onClick={e =>
                    this.props.groupCollapse(e, this.props.group.id)
                  }
                  title='Additional Menu'
                >
                  {checked ? (
                    <KeyboardArrowUpIcon/>
                  ) : (
                    <KeyboardArrowDownIcon/>
                  )}
                </a>
              </span>
            </div>
          </div>
          <Collapse in={checked} timeout='auto' unmountOnExit>
            <ListRows
              child={this.props.devicesData.data}
              {...this.props}
            />
          </Collapse>
        </li>
      )
    } else {
      return (
        <li key={this.props.group.id}>
          <div className='clearfix group-list-row list-row'>
            <label className='checkbox'>
              <input
                type='checkbox'
                checked={this.state.checked}
                onChange={e => this.toggleGroupDevices(e)}
              />
              <CheckIcon />
              <span className='unit-name'>{this.props.group.name}</span>
            </label>
            <div
              className='pull-right'
              onClick={e => this.props.selectGroup(this.props.group.id)}
            >
              <span className='action-item action-additional-menu'>
                <a
                  href={emptyLinks}
                  onClick={e => this.props.groupCollapse(e)}
                  title='Additional Menu'
                >
                  {checked ? (
                    <KeyboardArrowUpIcon/>
                  ) : (
                    <KeyboardArrowDownIcon/>
                  )}
                </a>
              </span>
            </div>
          </div>

          <Collapse in={checked} timeout='auto' unmountOnExit>
            <ListRows child={[]} {...this.props} />
          </Collapse>
        </li>
      )
    }
  }
}

export class ListRows extends React.Component {
  fetchNext = () => {
    this.props.dispatch(fetchMoreDevices(false, 0, this.props.group.id))
  }
  render () {
    if (this.props.child.length) {
      return (
        <div style={{ height: 250 }}>
          <Scrollbar
            disableTracksWidthCompensation={true}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id={'scrollableDivDevices' + this.props.group.id}
                  />
                )
              }
            }}
          >
            <InfiniteScroll
              dataLength={this.props.devicesData.data.length}
              next={this.fetchNext}
              hasMore={this.props.devicesData.hasNext}
              loader={<Loader defaultStyle={true} />}
              endMessage={this.props.endMessage}
              scrollableTarget={'scrollableDivDevices' + this.props.group.id}
            >
              <ul className='list-view list-view-children'>
                {this.props.devicesData.data.map((device, i) => {
                  return (
                    <ListRow
                      monitoringMap={this.props.monitoringMap}
                      openDeviceSettings={this.props.openDeviceSettings}
                      collapseSidebar={this.props.collapseSidebar}
                      key={device.id}
                      item={device}
                      relatedData={this.props.deviceRelatedData[device.id]}
                      dispatch={this.props.dispatch}
                      showBox={this.props.showBox}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      isCheckedFn={this.props.isCheckedFn}
                      trackId={this.props.trackId}
                      deviceId={this.props.deviceId}
                    />
                  )
                })}
              </ul>
            </InfiniteScroll>
          </Scrollbar>
        </div>
      )
    } else {
      return (
        <p style={{ textAlign: 'center', color: 'rgb(204, 204, 204)' }}>
          empty
        </p>
      )
    }
  }
}

export const ListRowAlter1 = React.memo(({deviceRelatedData, ...others}) => others.item ? <ListRow1 {...others} relatedData={deviceRelatedData[others.item.id]} /> : null)

export class ListRow1 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleChange = event => {
    const { target } = event

    this.props.dispatch(
      updateDeviceVisible({ checked: target.checked, id: this.props.item.id })
    )
  }
  viewOnMap = () => {
    if (this.props.relatedData && this.props.relatedData.exists) {
      this.props.dispatch(
        updateDeviceVisible({ checked: true, id: this.props.item.id })
      )
      this.props.dispatch(setTrackId(this.props.item.id))
    }

    if (this.props.deviceId !== this.props.item.id) {
      this.props.dispatch(unsetDeviceId({ id: this.props.item.id }))
      document.body.classList.remove('shown-widgets')
    }

    this.props.collapseSidebar()
  }

  addComputedAttributes = id => {
    // fetch(`/api/attributes/computed?deviceId=${id}&all=true`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/attributes/computed`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params :{
        deviceId: id,
        all: true
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(Attribute => {
          this.props.dispatch(getComputedAttributes(Attribute))
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  viewDetails = () => {
    if (window.innerWidth <= 959) {
      this.props.collapseSidebar()
    }
    if (this.props.deviceId === this.props.item.id) {
      this.props.dispatch(unsetDeviceId({ id: this.props.item.id }))
      document.body.classList.remove('shown-widgets')
    } else {
      this.props.dispatch(setDeviceId({ id: this.props.item.id }))
      document.body.classList.add('shown-widgets')

      // this.addComputedAttributes(this.props.item.id)
      // fetch('/api/events/get?deviceId=' + this.props.item.id + '&limit=4')
      instance({
        url: `/api/events/get`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          deviceId: this.props.item.id ,
          limit: 4
        }
      })
      // .then(
      //   response => {
      //     if (response.ok) {
      //       response.json()
            .then(events => {
              this.props.dispatch(updateEvents(events.data))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      this.props.dispatch(updateGroupNames([]));
      // fetch('/api/itemgroups/names?itemId=' + this.props.item.id + '&itemType=Device')
      instance({
        url: `/api/itemgroups/names`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          itemId: this.props.item.id,
          itemType: 'Device'
        }
      })
      // .then(response => {
      //     if (response.ok) {
      //       response.json()
            .then(res => {
              this.props.dispatch(updateGroupNames(res.data))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })


    }
  }

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  openDeviceSettings = () => {
    this.props.openDeviceSettings(this.props.item.id)
  }

  onClose = () => {
    this.setState({ anchorEl: null })
  }

  /* shouldComponentUpdate (next, state) {
    console.log(!isEqual(next.data, this.props.item), 1)
    console.log(!isEqual(next.relatedData, this.props.relatedData), 2)
    console.log(
      !isEqual(next.trackId, this.props.trackId) &&
        (this.props.trackId === next.data.id || next.trackId === next.data.id),
      3
    )
    console.log(!isEqual(next.deviceId, this.props.deviceId), 4)
    console.log(!isEqual(state, this.state), 5)
    return (
      !isEqual(next.data, this.props.item) ||
      !isEqual(next.relatedData, this.props.relatedData) ||
      (!isEqual(next.trackId, this.props.trackId) &&
        (this.props.trackId === next.data.id ||
          next.trackId === next.data.id)) ||
      !isEqual(next.deviceId, this.props.deviceId) ||
      !isEqual(state, this.state)
    )
  } */

  render () {
    const emptyLinks = null
    const { statusText, statusClass, exists, icons } = this.props.relatedData || {}
      const icon = icons && icons.playicon && icons.playicon.icon ? icons.playicon.icon : "";
      let checkPowerCut = this.props.relatedData&&this.props.relatedData.attributes&&this.props.relatedData.attributes.devicePowerCut ? true :false
      let RelatedIcon = null;
      let PowerCut = PowerCutIcon;
      if(icon === 'play') { RelatedIcon = PlayIcon; }
      else if(icon === 'ignition') { RelatedIcon = IgnitionIcon; }
      else if(icon === 'towing') { RelatedIcon = TowingIcon; }
      else if(icon === 'parking') { RelatedIcon = ParkingIcon; }
      else if(icon === 'stop') { RelatedIcon = StopIcon; }
      else if(icon === 'unknown') { RelatedIcon = QuestionIcon; }
    return (
      <li>
        <div
          className={
            'clearfix list-row' +
            (this.props.item.id === this.props.trackId ? ' active ' : '') +
            ' ' +
            (!exists
              ? 'disabled-row'
              : statusClass === 'not-updated'
              ? 'not-updated'
              : '')
          }
        >
          <label className='checkbox'>
            <input
              type='checkbox'
              onChange={this.handleChange}
              checked={this.props.item.visible}
            />
            <CheckIcon className='theme-input-checkbox' />
          </label>
          <Tooltip
            title={this.props.item.name}
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <a
              onClick={this.viewOnMap}
              href={emptyLinks}
              className='unit-name'
              style={{ textDecoration: 'none' }}
            >
              <img
                src={
                  '/assets/category/default/' +
                  (this.props.item.category || 'default') +
                  '.svg'
                }
                // alt={this.props.item.name}
              />
              {this.props.item.name}
            </a>
          </Tooltip>
          <div className='pull-right list-action-bar'>
            <span
              className={
                'action-item action-view ' +
                (this.props.deviceId === this.props.item.id ? 'view' : '')
              }
            >
              <a
                href={emptyLinks}
                onClick={this.viewDetails}
                title={this.props.translate('viewDetails')}
                style={{ cursor: 'pointer' }}
              >
                <svg fill='currentColor' width={16} height={16}>
                  <use xlinkHref={`${InfoIcon}#icon`} />
                </svg>
              </a>
            </span>
            
            {exists ? <span className={'action-item action-view2 '}>
                <a
                  href={emptyLinks}
                  onClick={e => this.props.showBox(this.props.item)}
                  title={this.props.translate(icons.playicon.label)}
                >
                  <LocationSearchingIcon fill="currentColor" style={{width: 16, height: 16}} />
                </a>
              </span> : null}
            {icons && (
                <Tooltip
                  title={checkPowerCut ? "On" : ""}
                  classes={{
                    popper: 'menu-popper',
                    tooltip: 'menu-popper-tooltip'
                  }}
                >
                <span style={{marginTop:8, paddingRight:4}}>
                  <a
                    href={emptyLinks}
                    title={this.props.translate(icons.playicon.label)}
                  >
                    {checkPowerCut ? <PowerCut fill="#FF0000" width={16} height={16} />  : null }
                  </a></span></Tooltip>
              )}
            {icons &&(
              checkPowerCut ? <></> 
              : <span className={'action-item action-running '+icons.play}>
                  <a
                    href={emptyLinks}
                    title={this.props.translate(icons.playicon.label)}
                  >
                    {RelatedIcon && <RelatedIcon fill="currentColor" width={16} height={16} />}
                  </a>
                </span>
            )}
            <span className={'action-item action-connection ' + statusClass}>
              <a
                href={emptyLinks}
                title={this.props.translate('tracker.' + statusText)}
              >
                <i className='circle'></i>
              </a>
            </span>

            <span className='action-item action-ellipsis has-menu'>
              <a
                href={emptyLinks}
                onClick={this.openMenu}
                title={this.props.translate('monitoringPanelCustomizer')}
              >
                <MoreVertIcon style={{fontSize: 16}}/>
              </a>
              {this.state.anchorEl && (
                <UnitMenu
                  id={this.props.item.id}
                  onClose={this.onClose}
                  anchorEl={this.state.anchorEl}
                  menuClass='level-right'
                  removeDevice={this.onRemovedItem}
                  editDevice={this.editDevice}
                  relatedData={this.props.relatedData}
                  data={this.props.item}
                  openDeviceSettings={this.openDeviceSettings}
                  themecolors={this.props.themecolors}
                  translate={this.props.translate}
                  dispatch={this.props.dispatch}
                />
              )}
            </span>
          </div>
        </div>
      </li>
    )
  }
}

const mapStateToProps2 = state => ({
  deviceRelatedData: state.deviceRelatedData
})
const mapStateToProps = state => {
  let visibleGroupsArr = {};
  let visibleGroups = {};
  const ids = state.groups.map(g => g.id);
  state.devices3.map(d => {
    const gid = ids.includes(d.groupId) ? d.groupId : 'nogroup'; 
    visibleGroupsArr[gid] = visibleGroupsArr[gid] || [];
    visibleGroupsArr[gid].push(d.visible);
    return null
  })
  ids.push('nogroup');
  ids.map(e => {
    visibleGroups[e] = visibleGroupsArr[e] && visibleGroupsArr[e].includes(false) ? false : true
    return null
  })

  return {
    groups: state.groups,
    groupWiseDevices: state.groupWiseDevices,
    visibleGroups,
    devices3: state.devices3
  }
}
export const DrawGroups = connect(mapStateToProps)(DrawGroups1)
export const ListRow = ListRow1
export const ListRowAlter = connect(mapStateToProps2)(ListRowAlter1)
export const DevicesList = connect(mapStateToProps2)(Devices)
