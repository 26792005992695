import React, { Component } from 'react';
import CustomDialog from '../common/Dialog'
import Table from '../common/tableWithBackEndPagination'
import {unitsList} from './unitsPortList'
export default class DevicePortList extends Component {
  	render () {
		return (
            <CustomDialog
                title={this.props.translate('deviceModel')}
                themecolors={this.props.themecolors}
                visable={true}
                isVisableBtn
                noFullScreen
                onClose={this.props.onCloseModal}
                isButtonVisable={false}
                draggable={true}
                CancelText={this.props.translate('sharedCancel')}
                bodyPadding={0}>
                <Table insideDialog={true}
                    rows={this.props.unitList?.data || []}
                    rowsPerPage={this.props.page}
                    searchable={true}
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    pagination={this.props.setPagination(this.props?.unitList || [])}
                    handleChangeRowsPerPage={(n)=>this.props.handleChangeRowsPerPage(n)}
                    handleChangePage={(n)=>this.props.handleChangePage(this.props.page+1)}
                    SearchItem={(n)=>this.props.SearchItem(n)}
                    rowDefinition={[
                        { id: 'name', numeric: false, disablePadding: false, label: this.props.translate('device') },
                        { id: 'protocol',numeric: false, disablePadding: false, label: this.props.translate('protocol') },
                        { id: 'port',numeric: false, disablePadding: false, label: this.props.translate('port') },
                    ]} />
        </CustomDialog>
        ) 
	}
}
