import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "./../../Layout";
import { FormControlLabel, Grid } from "@mui/material";
import EmptyState from "../../Components/common/EmptyState";
import axios from "axios";
import "highcharts";
// import ReactHighcharts from "react-highcharts";
import pieChartDefault from "../../Components/common/Charts/pieChartDefault";
import Switch from "../../Components/common/Switch";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";

const CancelToken = axios.CancelToken;
let source;

class Costing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySwitch: false,
      data: [],
      piSeries: [],
      ownSeries: "",
      rentSeries: "",
      allOwnData: [],
      allRentData: [],
      calculate: false,
    };
  }

  componentDidMount() {
    // this.setState({ data });
  }

  componentWillUnmount() {
    this.setState({
      mode: "",
    });
  }

  submitData = (own, rent) => {
    this.setState(
      {
        allOwnData: own,
        allRentData: rent,
        displaySwitch: true,
      },
      () => {
        this.dataFormat(own, rent);
      }
    );
  };

  dataFormat = (own, rent) => {
    let ownData = [];
    let rentData = [];

    if (this.state.calculate) {
      own &&
        own.map((item) => {
          if (item.name !== "TCO per Day") {
            ownData.push(item);
          }
        });
      rent &&
        rent.map((item) => {
          if (item.name !== "TCO Per Day") {
            rentData.push(item);
          }
        });
    } else {
      own &&
        own.map((item) => {
          if (item.name !== "TCO per KM") {
            ownData.push(item);
          }
        });
      rent &&
        rent.map((item) => {
          if (item.name !== "TCO per KM") {
            rentData.push(item);
          }
        });
    }

    this.setState({
      ownSeries: ownData,
      rentSeries: rentData,
    });
  };
  handleCalculate = (e) => {
    this.setState({ calculate: e.target.checked }, () => {
      if (this.state.allOwnData.length && this.state.allRentData.length) {
        this.dataFormat(this.state.allOwnData, this.state.allRentData);
      }
    });
  };

  render() {
    let table = [
      {
        c: "#7cb5ead9",
        name: "Idling 256:13:49",
        y: 34.57,
        h: 34.57,
        v: 34.57,
      },
      {
        c: "#90ed7dd9",
        name: "Idling 256:13:49",
        y: 34.57,
        h: 45.57,
        v: 45.57,
      },
      {
        c: "#90ed7dd9",
        name: "Idling 256:13:49",
        y: 81,
        h: 55.57,
        v: 81.57,
      },
    ];
    let piseries = [];
    table.map((v) => {
      piseries.push({ name: v.h, y: v.v, color: v.c });
      return null;
    });

    let ownData = [
      {
        colorByPoint: true,
        data: this.state.ownSeries,
        // innerSize: '65%',for donut
      },
    ];
    let rentData = [
      {
        colorByPoint: true,
        data: this.state.rentSeries,
        // innerSize: '75%', for donut
      },
    ];
    let pieData = [
      {
        colorByPoint: true,
        data: this.state.rentSeries,
      },
    ];

    let ownChart = {
      // config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "240px",
        },
        title: {
          text: table.head,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true, // To allow selection of points
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.y:.1f}", // Shows the label and value
              style: {
                fontWeight: "bold",
                color: "currentColor",
              },
            },
          },
        },
        series: ownData,
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              this.y.toFixed(2) +
              "</strong>" +
              " <br>" +
              "<strong>" +
              parseFloat(this.percentage.toFixed(2)) +
              "%</strong>"
            );
          },
        },
        credits: {
          enabled: false, // Set to false to hide the credits label
        },
      // }),
      show: true,
    };

    let rentChart = {
      // config: pieChartDefault().initializeWithOptions({
        chart: {
          type: "pie",
          height: "240px",
          // width:"400px"
          
        },
        title: {
          text: table.head,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true, // To allow selection of points
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.y:.1f}", // Shows the label and value
              style: {
                fontWeight: "bold",
                color: "currentColor",
              },
            },
          },
        },
        series: rentData,
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              this.y.toFixed(2) +
              "</strong>" +
              " <br>" +
              "<strong>" +
              parseFloat(this.percentage.toFixed(2)) +
              "%</strong>"
            );
          },
        },
        credits: {
          enabled: false, // Set to false to hide the credits label
        },
      // }),
      show: true,
    };
    return (
      <div>
        <Layout
          classFromChildren="has-padding"
          {...this.state}
          {...this.props}
          pathname="costing"
          submitData={this.submitData}
        >
          <div className="main-content-page">
            {this.state.rentSeries && this.state.ownSeries ? (
              <>
                <Grid
                  item
                  md={12}
                  xs={12}
                  lg={12}
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    padding: "16px 16px 0 16px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {this.state.displaySwitch ? (
                    <FormControlLabel
                      style={{ marginLeft: "auto" }}
                      control={
                        <Switch
                          checked={this.state.calculate}
                          onChange={(e) => this.handleCalculate(e)}
                          name="calculate"
                        />
                      }
                      label={
                        this.state.calculate
                          ? this.props.translate("perKM")
                          : this.props.translate("perDay")
                      }
                      labelPlacement="start"
                      classes={{ label: "color-inherit" }}
                    />
                  ) : null}
                </Grid>

                <Grid
                container
                  // item
                  // md={12}
                  // xs={12}
                  // lg={12}
                  style={{
                    // background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                    padding: "0 16px 16px 16px",
                    // display: "flex",
                    // justifyContent: "space-around",
                    // background:"orange"
                  }}
                >
                   <Grid
                    item
                    md={6}
                    xs={12}
                    lg={6}
                    container
                    direction="column"
                    alignItems="center"  // Centers the content horizontally
                  >
                      <h4 className="page-title" style={{ marginBottom: 10 }}>
                        {this.props.translate("owningAVehicle")}
                      </h4>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ownChart}
                      />
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    lg={6}
                    container
                    direction="column"
                    alignItems="center"  // Centers the content horizontally
                  >
                    <h4
                      className="page-title"
                      style={{ marginBottom: 10 }}
                    >
                      {this.props.translate("rentingAVehicle")}
                    </h4>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={rentChart}
                    />
                  </Grid>


                  {this.state.mode === "" ? (
                    <EmptyState text={this.props.translate("Costing")} />
                  ) : null}
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  lg={12}
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    padding: 16,
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 20,
                  }}
                >
                  <LineChart
                    chartData={LineData}
                    rentData={this.state.rentSeries}
                    ownData={this.state.ownSeries}
                    cal={this.state.calculate}
                  />
                </Grid>
              </>
            ) : null}
          </div>
        </Layout>
      </div>
    );
  }
}

const LineChart = (props) => {
  let ownFuel =
    props.ownData && props.ownData.find((it) => it.name === "Fuel Cost");
  let ownTotalAcqusition =
    props.ownData && props.ownData.find((it) => it.name === "Total Acqusition");
  let ownMaintenanceCost =
    props.ownData && props.ownData.find((it) => it.name === "Maintenance Cost");
  let ownMonthlyOps =
    props.ownData && props.ownData.find((it) => it.name === "Monthly Ops");
  let ownRenewalCharges =
    props.ownData &&
    props.ownData.find((it) => it.name === "Annul Renewal charges");
  let ownBankInterest =
    props.ownData && props.ownData.find((it) => it.name === "Bank interest");
  let ownZakat =
    props.ownData && props.ownData.find((it) => it.name === "Zakat");
  let ownTCO = props.ownData && props.ownData.find((it) => it.name === "TCO");
  let ownTCOKM =
    props.ownData && props.ownData.find((it) => it.name === "TCO per KM");
  let ownTCODay =
    props.ownData && props.ownData.find((it) => it.name === "TCO per Day");

  let rentFuel =
    props.rentData && props.rentData.find((it) => it.name === "Fuel Cost");
  let rentTotalAcqusition =
    props.rentData &&
    props.rentData.find((it) => it.name === "Total Acqusition");
  let rentMaintenanceCost =
    props.rentData &&
    props.rentData.find((it) => it.name === "Maintenance Cost");
  let rentMonthlyOps =
    props.rentData && props.rentData.find((it) => it.name === "Monthly Ops");
  let rentTCO =
    props.rentData && props.rentData.find((it) => it.name === "TCO");
  let rentTCOKM =
    props.rentData && props.rentData.find((it) => it.name === "TCO per KM");
  let rentTCODay =
    props.rentData && props.rentData.find((it) => it.name === "TCO Per Day");

  let makingData = [];
  if (props.cal) {
    makingData = [
      {
        name: "Fuel",
        data: [ownFuel, rentFuel],
      },
      {
        name: "Total Acquisition",
        data: [ownTotalAcqusition, rentTotalAcqusition],
      },
      {
        name: "Maintenance Cost",
        data: [ownMaintenanceCost, rentMaintenanceCost],
      },
      {
        name: "Monthly Ops",
        data: [ownMonthlyOps, rentMonthlyOps],
      },
      {
        name: "Renewal Charges",
        data: [ownRenewalCharges, 1],
      },
      {
        name: "Bank Interest",
        data: [ownBankInterest, 1],
      },
      {
        name: "Zakat",
        data: [ownZakat, 1],
      },
      {
        name: "TCO",
        data: [ownTCO, rentTCO],
      },
      {
        name: "TCO per KM",
        data: [ownTCOKM, rentTCOKM],
      },
    ];
  } else {
    makingData = [
      {
        name: "Fuel",
        data: [ownFuel, rentFuel],
      },
      {
        name: "Total Acquisition",
        data: [ownTotalAcqusition, rentTotalAcqusition],
      },
      {
        name: "Maintenance Cost",
        data: [ownMaintenanceCost, rentMaintenanceCost],
      },
      {
        name: "Monthly Ops",
        data: [ownMonthlyOps, rentMonthlyOps],
      },
      {
        name: "Renewal Charges",
        data: [ownRenewalCharges, 1],
      },
      {
        name: "Bank Interest",
        data: [ownBankInterest, 1],
      },
      {
        name: "Zakat",
        data: [ownZakat, 1],
      },
      {
        name: "TCO",
        data: [ownTCO, rentTCO],
      },
      {
        name: "TCO Per Day",
        data: [ownTCODay, rentTCODay],
      },
    ];
  }

  const chartOptions = {
    chart: {
      type: "bar",
      backgroundColor: "transparent",
      height: 250,
    },
    title: {
      text: "Daily Vehicle Costs Comparison",
      style: {
        color: "white",
      },
    },
    legend: {
      margin: 15,
      itemDistance: 10,
      itemStyle: {
        color: "currentColor",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
      },
    },

    xAxis: {
      categories: ["Owing", "Renting"],
      labels: {
        style: {
          color: "currentColor",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cost",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    credits: {
      enabled: false, // Set to false to hide the credits label
    },
    series: makingData,
  };
  // <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  // <ReactHighcharts config={chartOptions} />;
  
};

const LineData = [
  {
    data: [2, 4],
    name: "Category 1",
    minPointLength: 10,
  },
  {
    data: [1, 2],
    name: "Category 2",
    minPointLength: 5,
  },
  {
    data: [37244, 2767],
    name: "Category 3",
    minPointLength: 5,
  },
  {
    data: [141, 0],
    name: "Category 4",
    minPointLength: 5,
  },
  {
    data: [50, 53],
    name: "Category 5",
    minPointLength: 5,
  },
];

const mapStateToProps = (state) => ({
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(((Costing)));
