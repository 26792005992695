export const logInUsers = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      localStorage.removeItem('userInfoFront')
      return {}

    case 'LOGIN_USER_INFO':
      localStorage.setItem('userInfoFront', JSON.stringify({ ...action.user }))
      let u = {
        ...action.user,
        attributes: action.user.attributes ? action.user.attributes : {}
      }
      return u

    default:
      return state
  }
}
