import React from "react";
import { connect } from "react-redux";
import Card from "../../Dashboard/components/Card/CardPremium";
import { MotionList } from "../../Dashboard";
import Scrollbar from 'react-scrollbars-custom'
const colors = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d'
]

const ComponentBase = React.memo(({themecolors, gadget, devices, handleItem})=> {
    
    let cat = {}

    devices.map(v => {
        let c = v.category || 'default'
        cat[c] = cat[c] || 0
        cat[c]++
        return null
    })

    let arr1 = { total: 0, data: [] }

    Object.keys(cat).map((e, i) => {
        arr1.total = devices.length
        arr1.data.push({
        value: cat[e],
        color: colors[i],
        name: e,
        key: e,
        image: <img src={`/assets/category/default/${e}.svg`} alt='' />
        })
        return null
    })

    return (<Card themecolors={themecolors}>
        <h3 className="cart-title">{gadget.name || 'Unit'}</h3>
        <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
                height: 38 * 9
            }}
            >
            <MotionList
            category
            data={arr1}
            showSelectedUnits={handleItem}
            />
        </Scrollbar>
    </Card>);
});

const mapStateToProps = state => ({
    themecolors: state.themeColors,
    devices: state.devices3
});

export const Component = connect(mapStateToProps)(ComponentBase)