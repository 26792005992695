import React, { Component } from "react";
import Button from "../../../Components/common/Button";
import SearchField from "../../../Components/common/SearchField";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import {
  checkPrivileges,
  errorHandler,
} from "../../../Helpers";
import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
import Style from "style-it";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import instance from "../../../axios";
import { removedTypes } from "../../../Actions/Services";
import ExportImportMenu from "../../../Components/Maps/ExportImportMenu";
import { notifySuccess } from "../../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../../HOC/withTranslation";
class Commands extends Component {
  constructor(props) {
    super(props);
    this.state = { searchRecently: "" };
  }

  search(e) {
    this.props.searchItems(e.target.value);
  }

  render() {
    const { services } = this.props;
    const commands1 = services.map((g) => (
      <ListRow
        key={g.id}
        item={g}
        editCommandForm={this.props.editCommandForm}
        modeEmpty={this.props.modeEmpty}
        onEditType={this.props.onEditType}
        serviceForm={this.props?.serviceForm || {}}
        checkData={this.props.checkData}
        // onClick={e=>this.props.editCommandForm(this.props.item)}
      />
    ));

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className="fms-sidebar sidebar-bg-layer">
          {checkPrivileges("expensetype") && (
            <div>
              <div
                className="section-head section-head-filter"
                style={{ paddingTop: 0 }}
              >
                <ul className="filter-row">
                  <li style={{ flex: 1 }} className="sidebar-icon-gap">
                    <SearchField
                      label={this.props.translate("searchServices")}
                      fullWidth
                      onChange={(e) => this.search(e)}
                    />
                  </li>
                  <li
                    style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}
                  >
                    {checkPrivileges("expensetypeCreate") && (
                      <Button
                        size="small"
                        onClick={(e) => this.props.openCreateFrom()}
                      >
                        {this.props.translate("sharedCreate")}
                      </Button>
                    )}
                  </li>
                  <li style={{ padding: 0, paddingTop: 8, paddingBottom: 4 }}>
                    <ExportImportMenu
                      fileName="expensetypes"
                      checkPrivilege="serviceCreate"
                      data={this.state?.expense || []}
                      themecolors={this.props.themecolors}
                      expensetypeId={this.state?.serviceForm?.id || ""}
                      fetchDataFunc={this.props.fetchData}
                      downloadSamplev2
                    />
                  </li>
                </ul>
              </div>
              <ul className="list-view with-padding-right geofence-sidebar-list">
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id="scrollableDiv"
                        />
                      );
                    },
                  }}
                >
                  {this.props.itemPagination &&
                  this.props.itemPagination.items ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      // loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget="scrollableDiv"
                      endMessage={this.props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    );
  }
}

class ListRow1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onDeleteConfirmation: false,
    };
  }

  onDelete = () => {
    if (this.props.item.id) {
      // fetch(`api/commands/${this.props.item.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `api/expensetypes/${this.props.item.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.checkData();
          this.props.dispatch(removedTypes({ id: this.props.item.id }));
          this.props.dispatch(
            notifySuccess("savedServiceIsDeleted")
          );
          // this.props.modeEmpty()
          this.setState({ item: {} });
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true,
    });
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  render() {
    return (
      <li
        className={
          "clearfix list-row " +
          (parseInt(this.props.serviceForm && this.props.serviceForm.id) ===
          parseInt(this.props.item.id)
            ? "active"
            : "")
        }
        key={this.props.item.id}
        // onClick={(e) => this.props.editCommandForm(this.props.item)}
       

      >
   <div  style={{display:"flex",
    // justifyContent:"space-between"
  }}
   >
        <label
          className="checkbox"
           onClick={(e) => this.props.editCommandForm(this.props.item)}
          
          style={{ flexGrow: 1}}
        >
          <span className="unit-name" style={{ fontSize: 12 }}>
            {this.props.item.name}
          </span>
        </label>
        <div>
        <div className="callout-actions">
          {checkPrivileges("expensetypeDelete") && (
            <span className="action" onClick={this.onRemovedItem}>
              <DeleteIcon className="material-icons" />
            </span>
          )}
        </div>
        <div className="callout-actions">
          {checkPrivileges("expensetypeUpdate") && (
            <span
              className="action"
              onClick={() => this.props.onEditType(this.props.item)}
            >
              <EditIcon className="material-icons" />
            </span>
          )}
        </div>
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onDelete}
            title={this.props.translate("areYouWantToDelete")}
            children={this.props.item.description}
          />
        )}
        </div>
        </div>
      </li>
    );
  }
}

const mapState = (state) => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors,
});

const mapStateToProps = connect(mapState);
export const ListRow = mapStateToProps(withTranslationWrapper(ListRow1));
export default mapStateToProps(withTranslationWrapper(Commands));
