export function garages(state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
      return [];
      case 'GET_GARAGES':
          let NewData = action.garages.data
          action.garages.data = NewData
          return action.garages
      case 'ADD_GARAGES':
        let S_data = state.data.filter(m => m.id !== action.garages.id)
        let item = action.garages
        return S_data.concat(item)
      case 'REMOVED_GARAGES':
        let alldata = state.data.filter(m => m.id !== action.garages.id)
        return alldata
      default:
        return state;
    }
  }

  export function selectedGarages (state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
        return []
      case 'GARAGE_USER_WISE_SELECTED_LIST':
        return action.garages
      case 'UPDATE_GARAGE_USER_WISE_SELECTED_LIST':
        let updatedata = state.map(m => {
          if (m.id !== action.garages.id) {
            return m
          } else {
            return action.garages
          }
        })
        return updatedata
  
      case 'ADD_GARAGE_USER_WISE_SELECTED_LIST':
        return state.concat(action.garages)
      case 'REMOVED_GARAGE_USER_WISE_SELECTED_LIST':
        let rData = state.filter(m => m.id !== action.garages.id)
        return rData
      default:
        return state
    }
  }


  
  
  
  