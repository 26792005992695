function parepareResponse (arr, old) {
  let gf = []

  arr.map(item => {
    let circle = []
    let polygon = []
    let polyline = []

    const d = old.find(e => e.id === item.id)

    const common = {
      ...item,
      visible: d && d.visible !== undefined ? d.visible : false,
      attributes: { ...item.attributes, latlng: [] }
    }

    polyline = item.area.split('LINESTRING ')

    if (polyline.length > 1) {
      let p = polyline[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
        .split(', ')
      let positions = []
      if (p.length) {
        p.map(pos => {
          positions.push(pos.split(' ').map(Number))
          return null
        })
      }

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polyline',
          latlng: positions
        }
      })
    }

    circle = item.area.split('CIRCLE')

    if (circle.length > 1) {
      let c = circle[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
      let a = c.split(', ')
      let b = a[0].split(' ')

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'circle',
          latlng: { lat: b[0], lng: b[1] },
          radius: parseFloat(a[1])
        }
      })
    }

    polygon = item.area.split('POLYGON')

    if (polygon.length > 1) {
      let poly = {
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polygon'
        }
      }

      let a = polygon[1].replace('((', '').replace('))', '')
      let b = a.split(', ')

      b.forEach(obj => {
        let c = obj.split(' ')
        poly.attributes.latlng.push([parseFloat(c[0]), parseFloat(c[1])])
      })

      gf.push(poly)
    }
    return item
  })
  return gf
}
export const geoFence = (state = [], action) => {
  const items = []

  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'UPDATE_GEOFANEC':
      state.map(geo => {
        if (action.geofence.id === geo.id) {
          items.push(action.geofence)
        } else {
          items.push(geo)
        }
        return ''
      })

      return items

    case 'DELETE_GEOFENCE':
      var array = []
      state.map((geofence, index) => {
        if (geofence.id !== action.geofence.id) {
          array.push(geofence)
        }
        return ''
      })

      return array

    case 'ADD_GEOFANEC':
      return state.concat(parepareResponse(action.geofence, state))

    case 'GET_GEOFANEC':
      return parepareResponse(action.geofence, state)

    case 'GEO_FENSE_VISIBLE':
      state.map(obj => {
        const o = { ...obj }
        if (o.id === action.payload.id) {
          o.visible = action.payload.visible
        }
        items.push(o)
        return obj
      })
      return items

    case 'TOGGLE_GEOFENCES':
      state.map(obj => {
        items.push({ ...obj, visible: action.payload })
        return obj
      })
      return items

    default:
      return state
  }
}
export const isGeofenceLoad = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_GEOFENCE':
    return action.loadGeofence
    default:
      return state
  }
}
