import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import Style from "style-it";

const mapStateToProps = (state, ownProps) => {
	return ({themecolors: ownProps.themecolors || state.themeColors})
};

const styles = theme => ({
	root: {
		borderColor: 'transparent',
		minWidth: 94,
	    [theme.breakpoints?.down('lg')]: {
			minWidth: 'inherit',
		},
	    [theme.breakpoints?.down('sm')]: {
	    	padding: "5px 7px"
	    }
	}
});

class CustomButton extends React.Component {
	render() {

		let p = {...this.props};

		delete p.dispatch;
		

		return <Style>
		{`
			.theme-button {
				background: ${this.props.themecolors.themeLightColor};
				color: ${this.props.themecolors.themeLightInverse};
				min-width: 50px;
				font-family: Inter, sans-serif;
				font-size: 12px;
				padding: 6px 12px;
				text-transform: none
			}
			.button-inactive {
				opacity: 0.6
			}
			.theme-button.active {
				opacity: 1
			}
			.theme-button:hover {
				background: ${this.props.themecolors.themeLightColor};
			}
			.theme-button.active {
				color: ${this.props.themecolors.themeLightInverse};
				background: ${this.props.themecolors.themeLightColor};
			}
			.theme-button.active:hover{
				color: ${this.props.themecolors.themeLightInverse};
				background: ${this.props.themecolors.themeLightColor};
			}
			.theme-green {
				background: ${this.props.themecolors.menuActiveBackground};
				color: ${this.props.themecolors.menuActiveColor};
			}
			
			.button-dark.active:hover,
			.button-dark.active,
			.button-dark:hover,
			.button-dark {
				background: ${this.props.themecolors.themeDarkColor};
				color: ${this.props.themecolors.themeInverse};
			}
			.theme-button.text-left {
				justify-content: start;
			}
			.button-white {
				background: #fff;
				color: #333;
				box-shadow: 0 0 3px rgba(0,0,0,0.2);
			}
			
			.button-grey {
				background: #f5f5f5;
				color: #333;
			}
			
			.button-grey:hover {
				background: #f5f5f5;
				color: #333;
			}
			
			.button-white:hover {
				background: #fafafa;
			}
			.theme-button.alter {
				background: #f0ad4e;
				color: #fff;
			}
			.theme-button:disabled {
				background: #ebebeb;
				color: #acacac;
			}

			.button-narrow {
				padding: 4px 10px;
			}

			.theme-green.active:hover,
			.theme-green.active,
			.theme-green:hover,
			.theme-green {
				background: ${this.props.themecolors.menuActiveBackground};
				color: ${this.props.themecolors.menuActiveColor};
			}
			
		`}
			{this.props.normal && <Button {...p} className={this.props.className+" theme-button"}  classes={null} size="small" />}
			{!this.props.normal && <Button {...p} className={this.props.className+" theme-button"} classes={{sizeSmall: 'button-small'}} size="small"/>}
			</Style>
	}
}


CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CustomButton));