import React from "react";
import { Trans as Translate} from 'react-i18next';
import { ReactComponent as TrailerIcon } from './../../../assets/dashboard/truck.svg'
import { ReactComponent as NotRegistered } from './../../../assets/dashboard/not-registered.svg'
import { ReactComponent as GPSNotSupport } from './../../../assets/dashboard/gps-no-support.svg'
import { ReactComponent as OnlineStatus } from './../../../assets/dashboard/online.svg'
import { ReactComponent as OfflineStatus } from './../../../assets/dashboard/offline.svg'
import { connect } from "react-redux";
import Card from "../../Dashboard/components/Card/CardPremium";
import Scrollbar from "react-scrollbars-custom";
const ComponentBase = React.memo(({themecolors, gadget, allNotifications, handleItem})=> {
  const { notRegistered, statusOnline:online, statusOffline:offline, gpsNotUpdated}  = allNotifications;
  const pieChart2 = {
      total: online.length + offline.length + notRegistered.length + gpsNotUpdated.length,
      online: online.length,
      offline: offline.length,
      notRegistered: notRegistered.length,
      gpsNotUpdated: gpsNotUpdated.length
  }

  return (<Card themecolors={themecolors}>
    <h3 className="cart-title">{gadget.name}</h3>
    <Scrollbar disableTracksWidthCompensation>
      <h3>
        <span>
          <TrailerIcon style={{
              verticalAlign: 'middle',
              marginRight: 10,
              width: 40,
              display: 'inline-block'
            }} />
          <span
            style={{
              fontSize: 30,
              verticalAlign: 'middle',
              marginRight: 10,
              display: 'inline-block'
            }}
          >
            {pieChart2.total}
          </span>
          <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle'
            }}
          >
            <Translate i18nKey="trackers" />
          </span>
        </span>
      </h3>
      <div className='driver-state-wrap'>
        <div className='driver-state-container'>
          <div className='driver-state2 online hover' onClick={e => handleItem && handleItem('statusOnline', 'device', "tracker.online")}>
            <OnlineStatus width={32} height={32} fill="currentColor" />
            <h3>{pieChart2.online}</h3>
            <p><Translate i18nKey="tracker.online" /></p>
          </div>
          <div className='driver-state2 offline hover' onClick={e => handleItem && handleItem('statusOffline', 'device', "tracker.offline")}>
            <OfflineStatus width={32} height={32} fill="currentColor" />
            <h3>{pieChart2.offline}</h3>
            <p><Translate i18nKey="tracker.offline" /></p>
          </div>
          <div className='driver-state2 not-register hover' onClick={e => handleItem && handleItem('notRegistered', 'device', "tracker.notRegisteredYet")}>
            <NotRegistered width={32} height={32} fill="currentColor" />
            <h3>{pieChart2.notRegistered}</h3>
            <p><Translate i18nKey="tracker.notRegisteredYet" /></p>
          </div>
          <div className='driver-state2 not-update hover'  onClick={e => handleItem && handleItem('gpsNotUpdated', 'device', "tracker.gpsNotUpdated")}>
            <GPSNotSupport width={32} height={32} fill="currentColor" />
            <h3>{pieChart2.gpsNotUpdated}</h3>
            <p><Translate i18nKey="tracker.gpsNotUpdated" /></p>
          </div>
        </div>
      </div>
    </Scrollbar>
  </Card>);

});

const mapStateToProps = state => ({
    themecolors: state.themeColors,
    allNotifications: state.allNotifications
})

export const Component = connect(mapStateToProps)(ComponentBase)
