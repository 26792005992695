
export const getComputedAttributes = attributes => ({
    type: 'GET_ATTRIBUTES',
    attributes
});

export const addComputedAttribute = attribute => ({
    type: 'ADD_ATTRIBUTE',
    attribute
});
export const removedComputedAttribute = attribute => ({
    type: 'REMOVED_ATTRIBUTE',
    attribute
});

export const getComputedAttributeDeviceWise = attributes => ({
    type: 'GET_ATTRIBUTE_DEVICE_WISE',
    attributes
});
export const addComputedAttributeDeviceWise = attribute => ({
    type: 'ADD_ATTRIBUTE_DEVICE_WISE',
    attribute
});
export const updateComputedAttributeDeviceWise = attribute => ({
    type: 'UPDATE_ATTRIBUTE_DEVICE_WISE',
    attribute
});

export const RemoveComputedAttributeDeviceWise = attribute => ({
    type: 'REMOVE_ATTRIBUTE_DEVICE_WISE',
    attribute
});

export const computedAttributeDeviceWiseSelectedList = attributes => ({
    type: 'ATTRIBUTES_DEVICE_WISE_SELECTED_LIST',
    attributes
});
export const addComputedAttributeDeviceWiseSelectedList = attributes => ({
    type: 'ADD_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST',
    attributes
});
export const updateComputedAttributeDeviceWiseSelectedList = attributes => ({
    type: 'UPDATE_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST',
    attributes
});
export const removedComputedAttributeDeviceWiseSelectedList = attributes => ({
    type: 'REMOVED_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST',
    attributes
});
