// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bordered-grid > .MuiGrid-item {
  border-width: 1px;
  border-style: dashed;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/Style.scss"],"names":[],"mappings":"AAIA;EACI,iBAAA;EACA,oBAAA;EACA,eAAA;AAHJ","sourcesContent":[".bordered-grid  {\n    // border-width: 0px;\n    // border-style: dashed;\n}\n.bordered-grid > .MuiGrid-item {\n    border-width: 1px;\n    border-style: dashed;\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
