import React, { memo } from 'react';
import { useDrag } from 'react-dnd';

export const Box = memo(function Box({ name, type, isDropped, themecolors }) {
    const [{ opacity }, drag] = useDrag({
        type: type, // Ensure this matches your defined type
        item: { name, type },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    });

    return (
        <div
            className="dnd-box"
            ref={drag}
            role="Box"
            style={{ opacity, color: themecolors.themeInverse, backgroundColor: themecolors.themeDarkColor }}
        >
            {isDropped ? <s>{name}</s> : name}
        </div>
    );
});
