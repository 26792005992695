import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import Grid from '@mui/material/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Style from 'style-it'
import { checkPrivileges } from '../../../Helpers'
import Scrollbar from 'react-scrollbars-custom'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'

export default class Timemachine extends Component {
  constructor (props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }
  createReport = type => {
    this.props.collapseSidebarWithButton()
    this.props.createReport(type)
  }
  goBack () {
    this.props.openForm()
  }

  render () {
    let viewScreen

    viewScreen = (
      <form style={{ padding: 10, paddingTop: 14 }}>
        <h2 className='alt-title sidebar-icon-gap'>
          {this.props.translate('timemachineForm')}
        </h2>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DateRangePicker
              fill
              {...this.props.timelineRanges}
              selectedDate={this.props.selectedDate}
              label={
                this.props.translate('reportFrom') +
                ' ' +
                this.props.translate('reportTo')
              }
              onEvent={this.props.showDates}
            />
          </Grid>
        </Grid>
        <DeviceSelector
          value={this.props.deviceId}
          onChange={this.props.selectedDevices}
          rows={10}
        />
        <Button
          disabled={!this.props.validatedForm}
          style={{ marginTop: 20 }}
          variant='contained'
          onClick={this.createReport}
        >
          {this.props.translate('submit')}
        </Button>
      </form>
    )

    if (viewScreen) {
      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <aside className='fms-sidebar sidebar-bg-layer'>
            {checkPrivileges('track') && <Scrollbar disableTracksWidthCompensation>{viewScreen}</Scrollbar>}
          </aside>
        </Style>
      )
    } else {
      return this.props.trackLoading ? <Loader /> : null
    }
  }
}
