import React, { Fragment, PureComponent } from 'react'
import Menu from './../../../Components/Menu/CommonMenu'
import MenuItem from '@mui/material/MenuItem'
import SortIcon from '@mui/icons-material/Sort'
class SortMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render () {
    const { anchorEl } = this.state
    const empty = null

    return (
      <div>
        <a
          href={empty}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <SortIcon style={{ marginTop: 8 }} />
        </a>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
        >
          <MenuItem
            component='label'
            className='menu-item'
            onClick={this.props.sortByName}
          >
            {this.props.translate('sortByName')}
          </MenuItem>
          <MenuItem
            component='label'
            className='menu-item'
            onClick={this.props.sortByDeviceType}
          >
            {this.props.translate('sortByDeviceStatus')}
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
export default SortMenu
