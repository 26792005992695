
export const getAreas = areas => ({
    type: 'GET_AREAS',
    areas
});
export const addAreas = areas => ({
    type: 'ADD_AREAS',
    areas
});
export const removedAreas = areas => ({
    type: 'REMOVED_AREAS',
    areas
});
export const areaUsersWiseSelectedList = areas => ({
    type: 'AREA_USER_WISE_SELECTED_LIST',
    areas
});
export const updateAreaUsersWiseSelectedList = areas => ({
    type: 'UPDATE_AREA_USER_WISE_SELECTED_LIST',
    areas
});
export const addAreaUsersWiseSelectedList = areas => ({
    type: 'ADD_TEMPLATE_USER_WISE_SELECTED_LIST',
    areas
});
export const removeAreaUsersWiseSelectedList = areas => ({
    type: 'REMOVED_TEMPLATE_USER_WISE_SELECTED_LIST',
    areas
});
