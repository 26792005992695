// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
  padding: 8px 8px 6px !important;
}
.menu-item:not(.selected_item):focus {
  background: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Maps/PositionMenu.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;AAAI;EACI,2BAAA;AAER","sourcesContent":[".menu-item {\n    padding: 8px 8px 6px !important;\n    &:not(.selected_item):focus {\n        background: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
