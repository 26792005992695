import { modifyTheme, updateTheme } from "../Theme";

export const ServerInfo = server => {
  return function (dispatch, getState) {
    const { themeColors } = getState();
    if(server && server.attributes && server.attributes.themeId) {
      if(server.attributes.themeId !== 'custom') {
        dispatch(updateTheme(server.attributes.themeId))
      } else {
        if(server.attributes.customTheme) {
          dispatch(modifyTheme({...themeColors, ...server.attributes.customTheme}))
        }
      }
    }
    dispatch(saveServerInfo(server))
  }
}

export const saveServerInfo = server => ({
  type: 'SERVER_INFO',
  server
})

