export const trailers = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'GET_TRAILERS':
      return action.trailers

    case 'ADD_TRAILER':
      return state.concat(action.trailer)

    case 'ADD_TRAILERS':
      return state.concat(action.trailers)

    case 'UPDATE_TRAILER':
      let updatedata = state.map(m => {
        if (m.id !== action.trailer.id) {
          return m
        } else {
          return action.trailer
        }
      })

      return updatedata

    case 'REMOVE_TRAILER':
      let _trailerList = state.filter(trailer => trailer.id !== action.trailer.id)

      return _trailerList
    case 'SORT_TRAILERS':
      var trailers1 = []
      if (action.trailers.sort === 'DESC') {
        trailers1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        trailers1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return trailers1

    default:
      return state
  }
}
export const isTrailerLoad = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_TRAILERS':
    return action.loadTrailer
    default:
      return state
  }
}
export const searchTrailer = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return ''
    case 'SEARCH_TRAILER':
      return action.payload
    default:
      return state
  }
}
