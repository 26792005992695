export function computedAttributes(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return [];
    case 'GET_ATTRIBUTES':
      let data = action.attributes
      data.map(item => {
        item.check = false
        return null
      })
      return data;

    case 'ADD_ATTRIBUTE':
      let tdata = state.filter(m => m.id !== action.attribute.id)
      let item = action.attribute
      item.check = item.check ? item.check : false
      return tdata.concat(item);
    case 'REMOVED_ATTRIBUTE':
      let alldata = state.filter(m => m.id !== action.attribute.id)
      return alldata
    default:
      return state;
  }
}

export function computedAttributesDeviceWise(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return [];
    case 'ADD_ATTRIBUTE_DEVICE_WISE':
      return state.concat(action.attribute);

    case 'UPDATE_ATTRIBUTE_DEVICE_WISE':
      let updatedata = state.filter(m => m.id !== action.attribute.id)
      return updatedata.concat(action.attribute);

    case 'REMOVE_ATTRIBUTE_DEVICE_WISE':
      let data = state.filter(m => m.id !== action.attribute.id)
      return data
    case 'GET_ATTRIBUTE_DEVICE_WISE':
      return action.attributes
    default:
      return state;
  }
}


export function selectedComputedAttributes(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return [];
    case 'ATTRIBUTES_DEVICE_WISE_SELECTED_LIST':
      return action.attributes
    case 'UPDATE_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST':

      let updatedata = state.map(m => {
        if (m.id !== action.attributes.id) {
          return m;
        }
        else {
          return action.attributes
        }
      })

      return updatedata
    case 'ADD_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST':
      action.attributes.check = false
      return state.concat(action.attributes)
    case 'REMOVED_ATTRIBUTES_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.attributes.id)
      return rData
    default:
      return state;
  }
}

