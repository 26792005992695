export function services(state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
      return [];
      case 'GET_TYPES':
          let NewData = action.types
          action.types = NewData
          return action.types
      case 'ADD_TYPES':
        let S_data = state.filter(m => m.id !== action.types.id)
        let item = action.types
        return S_data.concat(item)
      case 'REMOVED_TYPES':
        let alldata = state.filter(m => m.id !== action.types.id)
        return alldata 
      default:
        return state;
    }
  }

  export function selectedServices (state = [], action) {
    switch (action.type) {
      case 'RESET_APP':
        return []
      case 'SERVICE_USER_WISE_SELECTED_LIST':
        return action.services
      case 'UPDATE_SERVICE_USER_WISE_SELECTED_LIST':
        let updatedata = state.map(m => {
          if (m.id !== action.services.id) {
            return m
          } else {
            return action.services
          }
        })
        return updatedata
  
      case 'ADD_SERVICE_USER_WISE_SELECTED_LIST':
        return state.concat(action.services)
      case 'REMOVED_SERVICE_USER_WISE_SELECTED_LIST':
        let rData = state.filter(m => m.id !== action.services.id)
        return rData
      default:
        return state
    }
  }