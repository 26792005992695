import React, { Component } from 'react'

import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
import { Grid } from '@mui/material';
import Loader from '../../Layout/Loader';
import VehicleSelector from '../Devices/VehicleSelector'
import AreaSelector from './AreaSelector';
import Scrollbar from 'react-scrollbars-custom'
import MaintenanceSelectors from './MaintenanceSelectors';
import Checkbox from './Checkbox';
import SingleSelect from './SingleSelect';
import { notifyError } from '../../Utils/CustomNotifcations';

const statusTypes = [
  {
    key: 'GarageInitiate',
    name: 'GarageInitiate',
  },
  {
    key: 'WaitingForOpApproval',
    name: 'WaitingForOpApproval',
  },
  {
    key: 'WaitingForCustomerApproval',
    name: 'WaitingForCustomerApproval',
  },
  {
    key: 'OpReject',
    name: 'OpToGarage',
  },
  {
    key: 'CustomerReject',
    name: 'CustomerReject',
  },
  // {
  //   key: 'UpdateStatus',
  //   name: 'UpdateStatus',
  // },
  {
    key: 'required',
    name: 'customerApprove',
  },
  {
    key: 'expired',
    name: 'OverDue',
  },
  {
    key: 'completed',
    name: 'Completed',
  },

]

// let allMaintaince= ''
export default  class MaintenanceFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openMaintenanceModal:false,
      from: '',
      to: '',
      selectedDate: '',
      vehicleId:[],
      maintenanceId:[],
      areaId:[],
      loader:false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId:[],
      // selectedMaintenanceId:allMaintaince,
      selectedMaintenanceId:'',
      selectedAreaId:[],
      steps: [{id: 1, title: 'selectServiceTimeControl'},{id: 2, title: 'selectStatuses'}, {id: 3, title: 'selectVehicles'},{id:4, title:'selectAreaIds'}],
      // steps: [{id: 1, title: 'selectServiceTimeControl'}, {id: 2, title: 'selectVehicles'},{id:3, title:'selectAreaIds'}],
      activeStep: 0,
      reportType:0,
      isSubmitBtn:false,
      createdBy:this.props.logInUser.customerType === 1 ? true : false,
      selectedAccount:{}
    }
  }


  onCloseDueMaintenanceModel = () =>{
    this.setState({
      openMaintenanceModal:false,
      to:'',
      from:'',
      isSubmitBtn:false,
      selectedDate:'',
      from: '',
      to: '',
      vehicleId:[],
      maintenanceId:[],
      areaId:[],
      countChecked: 0,
      anchorEl: null,
      activeStep:0,
    })
  }

 
  showDates = (e, date) => {
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  handleChange =(name, e)=>{
  if(name === "contractNumber"){
    this.setState(
      {
        selectedAccount : e || {}
      })
  }
    else if(name === "createdBy"){
    this.setState(
      {
        createdBy : e.target.checked,
        selectedAccount:  e.target.checked ? {}: this.state.selectedAccount
      })
  }
    else if(e ==='area'){
          this.setState(
            {
              areaId: name
            })
            this.setState({areaId: name },()=>{  
              let filter = '';
                Object.entries(this.state.areaId).map(([key,value])=>{
                  if(value){
                    filter += '&areaId=' + value
                    this.setState({
                    })
                  }
                })
                this.setState({
                  selectedAreaId: filter
                })
            })
      }
      else if(e === 'maintenance'){
        this.setState(
          {
            maintenanceId: name
          })
          this.setState({maintenanceId: name },()=>{  
            let filter = '';
              Object.entries(this.state.maintenanceId).map(([key,value])=>{
                if(value){
                  if (value === "WaitingForCustomerApproval") {
                    filter += "&status=OpToCustomer&status=GarageToCustomer";
                  }else if (value === "WaitingForOpApproval") {
                    filter += "&status=GarageToOp&status=CustomerToOp";
                  }else if (value === "CustomerReject") {
                    filter += "&status=CustomerToGarage&status=CustomerToOp";
                  } else {
                    filter += "&status=" + value;
                  }
                  // filter += '&status=' + value
                }
              })

              this.setState({
                selectedMaintenanceId: filter
              })
          })
      }
      else{
          this.setState({vehicleId: name },()=>{  
            let filter = '';
              Object.entries(this.state.vehicleId).map(([key,value])=>{
                if(value){
                  filter += '&vehicleId=' + value
                  this.setState({
                  })
                }
              })
              this.setState({
                selectedvehicleId: filter
              })
          })
      }
  }

  goToNextStep = (step, type, index) => {
    if(step === 4){ 
        this.setState({
          openMaintenanceModal:false,
        }, ()=>{
          this.props.formSubmit(this.state, type)
          // this.onCloseDueMaintenanceModel()
      })
    }
    else{
      this.validatedCurrentStep(step, type, index);
    }
  }
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      this.getMoreUsers()
      // if(this.state.to && this.state.from && this.state.selectedMaintenanceId){
        if(this.state.to && this.state.from ){
          validated = true
          errorMsg= null
        }
        else {
          validated = false
          errorMsg= <span className="text-warning">{this.props.translate('Please select Date and Maintenance Status from above')}</span>
        }
    }
    else if(step === 2) {
          validated = true
    }
    else if(step === 3) {
      // this.setState({}, ()=>this.getMoreUsers())
          validated = true
    }

    if(validated === true) {
      this.setState({activeStep: index + 1}, ()=>{
        if(this.state.activeStep===3){
          this.setState({isSubmitBtn:true})
        }})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

    this.setState({steps})

  }


  
  replaceHtmlEntities = (text) => {
    let itemname = text.replace(/amp;/, ' ').replace(/amp;/g, '');
    const textarea = document.createElement('textarea');
    textarea.innerHTML = itemname;
    return textarea.value;
  }
  getMoreUsers = () =>{
    // /api/accounts/get?userId=1&contractType=1&all=true&page=1&limit=50&search=${inputValue}
    fetch(`api/accounts/get?userId=${this.props.logInUser.id}&all=true&limit=-1`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){
            let data = res.data
          // const transformedUsers = users.map((user) => ({
          //   name: user.entity.name,
          //   email: user.entity.email,
          //   e: user.entity.e,
          // }));
            let result = data.data.map(item => ({
              // ...item.entity,
              label: this.replaceHtmlEntities(item.name || item.contractName)+ "-"+ item.contractNumber,
              name: this.replaceHtmlEntities(item.name || item.contractName) + "-"+ item.contractNumber,
              value: item.id,
              id: item.id,
              contractNumber: item.contractNumber,
              userId: item.userId,
              // contractNumber
            }))
            if (result.length === 0) {
              this.setState({
                resultMessage: 'No option found',
                defaultOptions: ''
              })
            } else {
              this.setState({
                resultMessage: '',
                defaultAccountsList: result
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              // Notifications.error({
              //   message: this.props.translate(err[1]),
              //   autoDismiss: 5,
              //   location: this.props.location
              // })
              notifyError(err[1])
            )
          }
          else {
            throw response
          }
        })
     
    }).catch(e => {
      console.log('e =', e)
      //   this.props.dispatch(Notifications.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }



  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ this.props.timeError || false}
            selectedDate={this.state.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            onEvent={this.showDates}
          />
        </Grid>
        {/* <Grid item  xs={12}>
          <MaintenanceSelectors
            api='maintenance'
            fill
            hideIcons
            isClearable
            placeholder={this.props.translate('searchMaintenance')}
            onChange={this.handleChange}
            value={this.state?.maintenanceId||[]}
            // maintenanceTypes={this.props.tabBtn === "tab2" ? statusTypesEstimation : statusTypes}
            maintenanceTypes={statusTypes}
            allPreSelected={false}
            sidebar={this.props.sidebar?true:false}
          />    
        </Grid> */}
      </Grid>)
      case 2: 
      return (<Grid container >
          {this.props.logInUser.customerType=== 1 && (
            <Grid item xs={12} style={{margin:"10px 0px"}}>
                    <Checkbox
                        name="createdBy"
                        checked={this.state.createdBy}
                        onChange={(e) => this.handleChange("createdBy", e)}
                        color='primary'
                        label={this.props.translate("Created By Me")}
                      />
                </Grid>
                )}
          {this.props.logInUser.customerType=== 1 &&  this.state.createdBy === false &&  (
                <Grid item  xs={12} style={{backgroundColor:"white",
                 borderRadius:"8px", height:"46px", marginBottom:"5px"}}>
                    <SingleSelect
                      color="white"
                      array={this.state.defaultAccountsList || []}
                      selectName='contractNumber'
                      label={this.props.translate('selectAccount')}
                      value={this.state.selectedAccount&&this.state.selectedAccount.id
                        ? {
                            id: this.state.selectedAccount.contractNumber,
                            value: this.state.selectedAccount.contractNumber,
                            label: this.state.selectedAccount.name,
                            // uniqueId:expenseForm.contractNumber.uniqueId,
                          }
                        : ''
                      }
                      handleChange={this.handleChange}
                      canRemove={true}
                      canAssign={true}
                    />
              </Grid>)}
          <Grid item  xs={12}>
            <MaintenanceSelectors
              api='maintenance'
              fill
              hideIcons
              isClearable
              placeholder={this.props.translate('searchMaintenance')}
              onChange={this.handleChange}
              value={this.state?.maintenanceId||[]}
              // maintenanceTypes={this.props.tabBtn === "tab2" ? statusTypesEstimation : statusTypes}
              maintenanceTypes={statusTypes}
              allPreSelected={false}
              sidebar={this.props.sidebar?true:false}
            />    
          </Grid>
        </Grid>)
    case 3: 
    return (<Grid container className='form-group'>
      <Grid item  xs={12}>
          <VehicleSelector
            api='vehicle'
            fill
            hideIcons
            isClearable
            placeholder={this.props.translate('searchVehicle')}
            onChange={this.handleChange}
            value={this.state?.vehicleId||[]}
            userId={this.state.selectedAccount.userId}
          />
      </Grid>
    </Grid>)
    case 4: 
      return (<Grid container className='form-group'>
       <Grid item  xs={12}>
            <AreaSelector
                api='area'
                fill
                hideIcons
                isClearable
                placeholder={this.props.translate('searchArea')}
                onChange={this.handleChange}
                value={this.state?.areaId||[]}
              /></Grid>
    </Grid>)
    default:
        return null
    }
  }
  

  render () {
    const viewScreen = (
      
        <form style={{ padding: "10px 0" }}>
        {<Grid container className='form-group'>
          <Grid item  xs={this.props.gridSize?this.props.gridSize:12}>
            <Stepper 
                translate={this.props.translate}
                activeStep={this.state.activeStep} 
                reportType={this.state.reportType} 
                getStepContent={this.getStepContent} 
                steps={this.state.steps}
                handleNext={this.goToNextStep} 
                handleBack={this.goToBackStep} 
                isSubmitBtn={this.state.isSubmitBtn}  
                services
                />
          </Grid>
            
          </Grid>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {!this.props.loader?(
        <Scrollbar>
          {viewScreen}
        </Scrollbar>):<Loader/>
        }
      </div>
  )
  }
}