import React, { Component } from 'react'
;
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Button from '../../Components/common/Button'
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import instance from '../../axios'
import { getAreas,addAreas,removedAreas } from '../../Actions/Areas'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations';
import withTranslationWrapper from '../../HOC/withTranslation';

class Area extends Component {
  constructor (props) {
    super(props)
    this.state = {
      allAreas:[],
      areaForm: {
        name:'',
        city:'',
        postalCode:'',
        country:''
      },
      mode: '',
      onDeleteConfirmation:false,
      isVisableSubmitBtn:false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true, 
        searchText: ''
      },
      checkEmpty:false,
      intialFetch:true
    }
  }

  componentWillUnmount () {
    this.setState({
      areaForm: {},
      mode: '',
      allAreas:[],
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
        searchText: ''
      },
      intialFetch:true
    })
  }

  componentWillMount () {
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize)
  }

  componentWillReceiveProps (n) {

  }

  searchItems = text => {
    let pagination
    this.setState(
      {
        searchText: text,
      },
      () => {
        // if(text){
          this.fetchData(
            this.props.logInUser,
            1,
            50,
            pagination=true
          )
        // }
        // else{
        //   this.fetchData(
        //     this.props.logInUser,
        //     this.state.currentPage,
        //     this.state.pagesize)
        // }
      }
    )
  }

  fetchAreas = (text, allAreas) => {
    const u = allAreas&&allAreas.filter(u =>
      (u.id + u.name).toLowerCase().includes(text.toLowerCase())
    )
    this.setState(
      {
        allAreas:u
      }
    )
  }

  fetchData = (logInUser, page, perPage,pagination) => {
    // let searchText = this.state.searchText
    // if (searchText) {
    //   searchText = '&search=' + searchText
    // } else {
    //   searchText = ''
    // }

    let items = this.state.itemPagination&&this.state.itemPagination.items
     instance({
      url: `/api/areas/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        userId: logInUser?logInUser.id:null,
        all:true,
        page: page,
        limit: perPage,
        search: this.state.searchText || ''
      }
    })
        .then(res => {
            this.setState(
              {
                itemPagination: {
                  ...res,
                  items:items.concat(res.data)
                },
                currentPage: res.hasNext ? res.page + 1 : res.page,
                allAreas:res,
                checkEmpty:res.data && res.data.length ? false:true
              },()=>{
                if(res.data&&res.data[0] && this.state.intialFetch) this.editAreaForm(res.data[0])
              })              
          this.props.dispatch(getAreas(res))
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }


  openCreateFrom = () => {
      this.setState({
        areaForm: {
          name:'',
          city:'',
          postalCode:'',
          country:''
        },
        checkEmpty:false
      },()=>{
        this.setState({
          mode: 'create',
        })
      })
  }

  editAreaForm = item => {
    this.setState({ mode: '', areaForm: {} }, () => {
      this.setState({
        mode: 'update',
        areaForm:{...item},
        intialFetch:false
      })
    })
  }

  closeCreateFrom = () => {
    this.setState({
      mode: '',
      areaForm: ''
    },
    () => this.checkRequiredFields())
  }
 

  handleChange = (name, value) => {
      this.setState({
        areaForm: {
          ...this.state.areaForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields())
  }

  checkRequiredFields = () => {
      let { name} = this.state.areaForm
    if (name){
      this.setState({
        isVisableSubmitBtn: false
      })
    } else {
      this.setState({
        isVisableSubmitBtn: true
      })
    }
  }



  submitForm = () => {
    if(this.state.mode === 'create'){
        let obj ={
          ...this.state.areaForm,
          id:0,
          parentId:0
        }
        if(obj){
          instance({
            url: `/api/areas`,
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data:{
              ...obj
            }
          })
              .then(areas => {
                this.props.dispatch(addAreas(areas))
                this.props.dispatch(
                  // Notifications.success({
                  //   message: this.props.translate('areaCreated'),
                  //   autoDismiss: 10
                  // })
                notifySuccess("areaCreated")

                )
                this.fetchData(this.props.logInUser)
              })
              .catch(e=> console.log('eee====', e))
        }
    }
    else if (this.state.mode === 'update'){
        instance({
          url: `/api/areas/${this.state.areaForm.id}`,
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data:{
            ...this.state.areaForm
          }
        })
            .then(areas => {
              this.props.dispatch(addAreas(areas))
              this.props.dispatch(
                // Notifications.success({
                //   message: this.props.translate('areaUpdated'),
                //   autoDismiss: 10
                // })
                notifySuccess("areaUpdated")
              )
              this.fetchData(this.props.logInUser)
            })
            .catch(e=> console.log('eee====', e))
            
    }
    else {
      this.props.dispatch(
        // Notifications.error({
        //   message: this.props.translate('somethingWentWrong'),
        //   autoDismiss: 10
        // })
        notifyError("somethingWentWrong")
      )
    }

    this.setState({
      mode: '',
      areaForm: ''
    })
  }

  onDelete = (id) => {
    if (id) {
      instance({
        url: `api/areas/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.props.dispatch(removedAreas({ id:id }))
          this.props.dispatch(
            // Notifications.success({
            //   message: this.props.translate('areaIsDeleted'),
            //   autoDismiss: 10
            // })
            notifySuccess("areaIsDeleted")
          )
          this.fetchData(this.props.logInUser)
          this.closeCreateFrom()
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  onRemovedItem = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
 
  fetchMoreItems = (a, b, c) => {
    let pagination
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      pagination=true
    )
  }

  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {this.props.translate('end')}
        </p>
      )
      const pp = {
        ...this.props,
        areas: this.state.allAreas,
      }
      const myStyle={
        backgroundImage: "url(/assets/category/default/emptyfolder.svg)",
        height:'150px',
        width:'150px',
        marginLeft:"200px"
    }

      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            editAreaForm={this.editAreaForm}
            searchItems={this.searchItems}
            deleteItem={this.onDelete}
            fetchMoreItems={this.fetchMoreItems}
            {...this.state}
          >
              {!this.state.checkEmpty ? 
                <div className='main-content-page'>
                      <div
                      style={{
                        background: this.props.themecolors.backgroundColor,
                        color: this.props.themecolors.textColor,
                        borderRadius: 6,
                        paddingTop: 10
                      }}
                    >
                      {this.state.mode === 'create' ||
                      this.state.mode === 'update' ? (
                        <CreateAreaForm
                          submitForm={this.submitForm}
                          mode={this.state.mode}
                          closeCreateFrom={this.closeCreateFrom}
                          form={this.state.areaForm}
                          handleChange={this.handleChange}
                          isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                          translate={this.props.translate}
                          themeColors={this.props.themecolors}
                        />
                      ) : null}
                      {this.state.mode === '' && this.state.itemPagination.items.length ? (
                        <EmptyState
                          text={this.props.translate('noAreaSelected')}
                        />
                      ) : null}
                     </div>
                </div>:
                <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                    }}
                  > 
                    <div style={myStyle}></div>
                  </div>
                 
              }
          </Layout>
        </div>
      )
  }
}
 {/* <div style={{backgroundColor:"red"}}>
                   
                  </div> */}
const CreateAreaForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  themeColors,
  closeCreateFrom,
  mode,
  isVisableSubmitBtn
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('sharedArea')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='name'
            required
            margin='dense'
            label={translate('sharedName')}
            variant='outlined'
            fullWidth
            value={form.name}
            onChange={e => handleChange('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='postalCode'
            margin='dense'
            label={translate('postalCode')}
            variant='outlined'
            fullWidth
            value={form.postalCode}
            onChange={e => handleChange('postalCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='city'
            margin='dense'
            label={translate('city')}
            variant='outlined'
            fullWidth
            value={form.city}
            onChange={e => handleChange('city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='country'
            margin='dense'
            label={translate('country')}
            variant='outlined'
            fullWidth
            value={form.country}
            onChange={e => handleChange('country', e.target.value)}
          />
        </Grid>
      </Grid>

      <div style={{ display: 'flex', marginTop: 10 }}>
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  areas:state.areas
})

export default connect(mapStateToProps)(withTranslationWrapper(Area))

