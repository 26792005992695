import React, { Component } from 'react'
import { components } from 'react-select'
// import AsyncSelect from 'react-select/lib/Async'
import AsyncSelect from 'react-select/async';

import TextField from '@mui/material/TextField'
import SearchField from './SearchField'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { selectStyles } from '../../Helpers'


const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.selectProps.resultMessage}
    </components.NoOptionsMessage>
  )
}

const styles = theme => ({})

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  if (props.selectProps.fill) {
    return (
      <SearchField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: 'theme-input-select',
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  } else {
    return (
      <TextField
        fullWidth
        variant='outlined'
        InputProps={{
          inputComponent,
          classes: {
            root: 'theme-input-select',
            focused: 'theme-cssFocused',
            notchedOutline: 'theme-notchedOutline'
          },
          inputProps: {
            className: 'theme-input-select',
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  }
}

class AsyncMulti extends Component {
  state = { inputValue: '' }
  handleInputChange = (newValue, option) => {
    this.setState({ inputValue: newValue })
    this.props.handleChange(this.props.selectName, newValue, option)
    return newValue
  }
  render () {
    const { themecolors } = this.props

    return (
      <AsyncSelect
        {...this.props}
        styles={selectStyles(themecolors)}
        isMulti={this.props.isMulti !== undefined ? this.props.isMulti : true}
        resultMessage={this.props.resultMessage || 'Type here for search'}
        className='async-select'
        components={{ NoOptionsMessage, Control }}
        onChange={this.handleInputChange}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral0: themecolors.textColor,
            neutral5: themecolors.textColor,
            neutral10: themecolors.textColor,
            neutral20: themecolors.textColor,
            neutral30: themecolors.textColor,
            neutral40: themecolors.textColor,
            neutral50: themecolors.textColor,
            neutral60: themecolors.textColor,
            neutral70: themecolors.textColor,
            neutral80: themecolors.textColor,
            neutral90: themecolors.textColor,
            dangerLight: themecolors.themeDarkColor,
            danger: themecolors.textColor,
            primary50: themecolors.themeLightColor,
            primary25: themecolors.themeLightColor,
            primary: themecolors.themeLightColor
          }
        })}
        placeholder={this.props.placeholder || 'type ...'}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AsyncMulti)
)
