// Actions for POI 
export const updatePOI = POI => ({
    type: 'UPDATE_POI',
    POI
  })
  
  export const addPOI = POI => ({
    type: 'ADD_POI',
    POI
  })
  export const getPOI = POI => ({
    type: 'GET_POI',
    POI
  })
  
  export const deletePOI = POI => ({
    type: 'DELETE_POI',
    POI
  })
  
  export const POIVisible = obj => ({
    type: 'POI_VISIBLE',
    payload: obj
  });
  