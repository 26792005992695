
export const calendars = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return [];
    case 'ADD_CALENDARS':
      let cData=state.concat(action.calendars)
      return cData
    case 'GET_CALENDARS':
      return action.calendars
    case 'REMOVED_CALENDAR':
      let clndList = state.filter(clnd => clnd.id !== action.calendar.id)
      return clndList
    case 'UPDATE_CALENDAR':
      const clnd = state.filter(cal => cal.id !== action.calendar.id)
      let updateCal=clnd.concat(action.calendar)
      return updateCal
    case 'SORT_CALENDARS':
      var calendars1 = [];
      if (action.calendars.sort === 'DESC') {
        calendars1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB)
            return -1
          if (nameA > nameB)
            return 1
          return 0
        });
      } else {
        calendars1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB)
            return -1
          if (nameA > nameB)
            return 1
          return 0
        });

      }

      return calendars1


    default:
      return state
  }
}
export const searchcalendar = (state = "", action) => {
  switch (action.type) {

    case 'RESET_APP':
      return "";
    case 'SEARCH_CALENDAR':
      return action.payload;
    default:
      return state
  }
}