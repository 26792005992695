import _ from 'lodash';
import moment from 'moment'
import { getDateTimeFormat } from '../../../Helpers'
export default function(props) {
  const defaultConfig = {
    chart: {
      height: 325,
      backgroundColor: 'transparent',
      type: 'line',
      marginTop: 40,
      marginLeft: 70,
      marginRight: 30,
      zoomType: 'x'
    },
    title: {
      text: '',
      align: 'left',
      style: {
        color: '#2c2c2c',
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'OpenSans-Regular'
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(40, 40, 40, 0.7)',
      borderColor: 'none',
      borderRadius: 20,
      shadow: false,
      padding: 15,
      style: {
        color: '#f4f4f4',
        fontSize: '13px'
      },
      // Time: ${(this.x * 1000).toLocaleString('en-US')}`;
      formatter() {
        return `Fuel: ${(this.y.toFixed(2) * 1).toLocaleString('en')}</b>
        Time:  ${moment(this.x).format(getDateTimeFormat())}`;
      }
    },
    
    series: [{
     data:[[154, 11.8],[12,22],[33,44]],
        lineWidth: 0.5,
        name: 'Hourly data points'
    }],
    plotOptions: {
      series: {
        pointStart: 0,
        depth: 0,
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          formatter() {
            if (this.y === 0) {
              return null;
            }
            return this.y;
          }
        }
      }
    },

    yAxis: {
      title: {
        enabled: true,
        text: '',
    }
    },
    xAxis: {
      title: {
        enabled: true,
        text: '',
    }
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1000
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }
      ]
    }
  };
  return {
    getDefaultConfig() {
      return Object.assign({}, defaultConfig);
    },

    initializeWithOptions(options) {
      return _.merge(Object.assign({}, defaultConfig), options);
    }
  };
}
