import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import CTab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import { UsersModal } from './../../Components/Users/usersModal'
import { connect } from 'react-redux';

import {
  checkPrivileges,
  errorHandler,
  postionAttributesTypeComp,
  selectAll,
} from '../../Helpers'
import isEqual from 'react-fast-compare'
import ComputedAttributes from './Components/computedAttributes'
import parse from 'html-react-parser';
import { ReactComponent as BasicIcon } from './../../assets/units/basic.svg';
import { ReactComponent as AccessIcon } from './../../assets/units/access.svg';
import { ReactComponent as UnitIcon } from './../../assets/nav/units.svg';
import { ReactComponent as StaffIcon } from './../../assets/nav/employees-one.svg';
import { ReactComponent as BinderIcon } from './../../assets/nav/binders.svg';
import { ReactComponent as VehicleIcon } from './../../assets/nav/vehicle.svg';
import { ReactComponent as AreaIcon } from './../../assets/nav/area-icon.svg';
import { ReactComponent as LockIcon } from './../../assets/nav/lock.svg';
import { ReactComponent as WidgetIcon } from './../../assets/nav/widget.svg';
import { ReactComponent as TemplateIcon } from './../../assets/nav/template.svg';
import { ReactComponent as DashboardIcon } from './../../assets/nav/dashboard.svg';
import { ReactComponent as MaintenanceIcon } from './../../assets/nav/spanner.svg';
import { ReactComponent as GeofenceIcon } from './../../assets/nav/geofence.svg';
import { ReactComponent as ElogicIcon } from './../../assets/units/elogic.svg';
import { ReactComponent as CommandIcon } from './../../assets/units/commands.svg';
import { ReactComponent as ResourceIcon } from "./../../assets/nav/resources.svg";

/* ACTIONS */
import { addGeoFence, deleteGeofence, fetchDashboards } from '../../Actions/Devices'
import {
  addDriverDeviceWiseSelectedList,
  addTrailerDeviceWiseSelectedList,
  addDashboardDeviceWiseSelectedList,
  addWidgetDeviceWiseSelectedList,
  addVehicleDeviceWiseSelectedList,
  driverDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  dashboardDeviceWiseSelectedList,
  widgetDeviceWiseSelectedList,
  RemoveDriverDeviceWise,
  RemoveTrailerDeviceWise,
  // RemoveDashboardDeviceWise,
  updateDriverDeviceWiseSelectedList,
  updateTrailerDeviceWiseSelectedList,
  updateDashboardDeviceWiseSelectedList,
  updateWidgetDeviceWiseSelectedList,
  updateVehicleDeviceWiseSelectedList,
  addDriverDeviceWise,
  addTrailerDeviceWise,
  // addDashboardDeviceWise,
  addNotification,
  removeDriverDeviceWiseSelectedList,
  removeTrailerDeviceWiseSelectedList,
  removeDashboardDeviceWiseSelectedList,
  removeWidgetDeviceWiseSelectedList,
  removeVehicleDeviceWiseSelectedList,
  getUserUsereWise,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addGeofenceDeviceWiseSelectedList,
  removeGeofenceDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  geofenceDeviceWiseSelectedList,
  updateGeofenceDeviceWiseSelectedList,
  RemoveGeofenceDeviceWise,
  addGeofenceDeviceWise,
  updateGroupsDeviceWiseSelectedList,
  RemoveGroupsDeviceWise,
  addGroupsDeviceWise,
  removeGroupDeviceWiseSelectedList,
  groupDeviceWiseSelectedList,
  addDeviceUserWise,
  RemoveDeviceUserWise,
  templateUserWiseSelectedList,
  deviceUserWiseSelectedList,
  updateDeviceUserWiseSelectedList,
  removeDeviceUserWiseSelectedList,
  addDeviceUserWiseSelectedList,
  removeUserUsereWise,
  updateTemplateUserWiseSelectedList
} from '../../Actions/Notifications'
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList,
  getComputedAttributes
} from '../../Actions/ComputedAttributes'
import {
  addCommands,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList,
  getCommands
} from '../../Actions/Commands'
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList,
  getMaintenance
} from '../../Actions/Maintenance'
import { removeDevice, addDevices } from './../../Actions/Devices'
import { removeGroup } from './../../Actions/Groups'
// import { garageUsersWiseSelectedList, updategarageUsersWiseSelectedList,removedGarages, getGarages } from './../../Actions/Garages'
import { areaUsersWiseSelectedList, updateAreaUsersWiseSelectedList } from './../../Actions/Areas'
import { addDriver, removeDriver } from './../../Actions/Drivers'
import { addTrailers, removeTrailer } from './../../Actions/Trailer'
import { addvehicle, removedvehicle } from './../../Actions/Vehicles'
/* ACTIONS END */

// common sections
import Notification from './Components/notification'
import CommonTableSection from './Components/commonTableSection'
import TableMultiCheckBoxUsers from '../../Components/common/TableMultiCheckBoxUsers'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import SavedCommands from './Components/savedCommands'
import MaintenanceForm from './Components/maintenance'
import axios from 'axios'
import Button from '../../Components/common/Button'
import instance from '../../axios'
import Tooltip from '../../Components/common/Tooltip'
import Loader from '../../Layout/Loader'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'
import withTranslationWrapper from '../../HOC/withTranslation'
import TextField from '../../Components/common/TextField'
const styles = theme => ({
  scrollButtons: {
    color: '#333'
  },
  indicator: {
    backgroundColor: '#333'
  }
})

const VerticalTabs = withStyles(theme => ({
  indicator: {
    display: 'none'
  },
  root: {
    minHeight: 42
  },
  flexContainer: {
    gap: 5
  }
}))(Tabs)

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: 'inherit', ...other.style }}
    >
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// --> parking form

const unitParkingAttFormat = {
  'parking.minIdleTime': 0,
  'parking.maxIdleSpeed': 0
}

const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '',
  type: ''
}

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: ''
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: 'custom',
  textChannel: ''
}
const maintenanceDataFormat = {
  attributes: {},
  name: '',
  type: '',
  start: '',
  period: ''
}

const locations = [
  '#user',
  '#changePassword',
  '#dashboard',
  '#widget',
  '#device',
  '#group',
  '#driver',
  '#binder',
  '#template',
  '#vehicle',
  '#garage',
  '#area',
  '#users',
  '#geofence',
  '#notification',
  '#attribute',
  '#command',
  '#maintenance'
]
const CancelToken = axios.CancelToken
let source
class MainUnitModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewScreen: null,
      currentTab: 'user',
      currentId: 0,
      selecteditem: null,
      activeOperation: '',
      unitParkingAttributesform: { ...unitParkingAttFormat },
      notificationForm: { ...NotificationDataFormat },
      computedAttForm: { ...ComputedAttributesDataFormat },
      savedCommandsForm: { ...savedCommandsDataFormat },
      maintenanceForm: { ...maintenanceDataFormat },

      visableAttribute: false,
      visableNotification: false,
      visableSavedCommands: false,
      visableMaintenance: false,
      visableGeofence: false,

      visableListNotification: true,
      visableListGeofence: true,
      visableListAttribute: true,
      visableListSavedCommands: true,
      visableListMaintenance: true,

      notificationCallStatus: '',
      attributeCallStatus: '',
      commandsCallStatus: '',
      maintenanceCallStatus: '',
      rowsPerPage: 5,
      onDeleteConfirmationGarage: false,

      allvehicle: {},
      selectedVehicles: {},
      vehiclePageSize: 5,
      vehicleLoader: false,
      vehiclePage: 1,
      selectAllVehicles: false,
      unitsList: "",
      userFilesData: [],
      vehicleSearch:"",
      cancelTokenSource:""
// handleChangeVehicle
      
    }
    this.tabChange = this.tabChange.bind(this)
    this.logo_handelChanges = this.logo_handelChanges.bind(this)
    this.isButtonVisable = this.isButtonVisable.bind(this)
    this.parkingformSubmit = this.parkingformSubmit.bind(this)
    this.onCloseC_Att_Modal = this.onCloseC_Att_Modal.bind(this)

    // notifications

    // common

    //1st
    this.onListNotification = this.onListNotification.bind(this)
    this.onListComputedAttributes = this.onListComputedAttributes.bind(this)
    this.onListSavedCommands = this.onListSavedCommands.bind(this)
    this.onListMaintenance = this.onListMaintenance.bind(this)

    // 2nd
    this.onCreateNewNotification = this.onCreateNewNotification.bind(this)
    //this.onCreateNewGeofence = this.onCreateNewGeofence.bind(this)
    this.onCreateNewAttributes = this.onCreateNewAttributes.bind(this)
    this.onCreateNewSavedCommands = this.onCreateNewSavedCommands.bind(this)
    this.onCreateNewMaintenance = this.onCreateNewMaintenance.bind(this)

    // 3rd
    this.notification_Selection = this.notification_Selection.bind(this)
    this.geofence_Selection = this.geofence_Selection.bind(this)
    this.group_Selection = this.group_Selection.bind(this)
    this.unit_Selection = this.unit_Selection.bind(this)
    this.driver_Selection = this.driver_Selection.bind(this)
    this.computedAttributes_Selection = this.computedAttributes_Selection.bind(
      this
    )
    this.savedCommands_Selection = this.savedCommands_Selection.bind(this)
    this.maintenance_Selection = this.maintenance_Selection.bind(this)

    // 4th
    this.notificationHandle = this.notificationHandle.bind(this)
    this.attributeHandle = this.attributeHandle.bind(this)
    this.savedCommandsHandle = this.savedCommandsHandle.bind(this)
    this.maintenancesHandle = this.maintenancesHandle.bind(this)

    // 5th
    this.addNotification = this.addNotification.bind(this)
    this.addComputedAttributes = this.addComputedAttributes.bind(this)
    this.addSavedCommands = this.addSavedCommands.bind(this)
    this.addMaintenance = this.addMaintenance.bind(this)
    this.addUser = this.addUser.bind(this)

    // 6th
    this.editNotification = this.editNotification.bind(this)
    this.editComputedAttributes = this.editComputedAttributes.bind(this)
    this.editCommands = this.editCommands.bind(this)
    this.editMaintenance = this.editMaintenance.bind(this)
    this.addMaintenanceAttributes = this.addMaintenanceAttributes.bind(this)

    // garage
    // this.garage_Selection  = this.garage_Selection.bind(this)
    // area
    this.area_Selection = this.area_Selection.bind(this)
    // remove functions

    this.removeMaintenance = this.removeMaintenance.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.removeNotification = this.removeNotification.bind(this)
    this.removeGeofence = this.removeGeofence.bind(this)
    this.removeGroups = this.removeGroups.bind(this)
    this.removeDriver = this.removeDriver.bind(this)
    this.removeComputedAttributes = this.removeComputedAttributes.bind(this)
    this.removeCommands = this.removeCommands.bind(this)
    this.removeDevice = this.removeDevice.bind(this)
  }

  componentWillMount() {
    if (this.props.id) {
      this.setState({ viewScreen: 'edit' })

      if (this.props.hash) {
        let hash = locations.indexOf(this.props.hash)
        if (hash !== -1) {
          this.setState({ currentTab: this.props.hash.substring(1, this.props.hash.length) })
        }
      }

      if (
        this.props.id &&
        parseInt(this.state.currentId) !== parseInt(this.props.id) &&
        this.props.users.length
      ) {
        this.setState(
          {
            currentId: parseInt(this.props.id), userFilesData: []
          },
          () => {
            this.getCurrentUser(this.props.id)
            this.getUserFilesData(this.props.id)
            this.fetchAllVehicles(this.props.logInUser.id)

            this.props.users.map(user => {
              if (parseInt(user.id) === parseInt(this.state.currentId)) {
                this.setState({ selecteditem: user })
              }
              return null
            })
          }
        )
      }
    }

    if (this.props.addUserScreen) {
      this.setState({ viewScreen: 'add' })
    }

  }
  // change tab
  tabChange = (event, currentTab) => {
    this.setState({ rowsPerPage: 5, currentTab: currentTab }, () => {
      this.props.history.push('/users/' + this.props.id + '#' + currentTab)
    })
  }

  onCloseModal = () => {
    this.setState({
      activeOperation: ''
    })
  }
  removeUserFile = (mediaId) => {
    return new Promise((resolve, reject) => {
      fetch(` /api/uploads/${mediaId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          response.json().then((res) => {
            this.setState({ userFilesData: res.data }, () => {
              this.getUserFilesData(this.props.id);
              resolve();
            })
          });
        })
        .catch((e) => {
          this.props.dispatch(
            notifyError(
              "somthingWentWrongMessage"
            ))
          reject(e);
        });
    })
  }
  getUserFilesData = (selecteditem) => {
    fetch(`/api/uploads/get?entityId=${selecteditem}&entity=User`)
      .then((response) => {
        response.json().then((res) => {
          this.setState({ userFilesData: res.data })
        });
      })
      .catch((e) => {
        this.props.dispatch(
          notifyError(
            this.props.translate("somthingWentWrongMessage")
        ))
      });
  }
  addNewGroups = () => {
    this.setState({
      isaddGroupVisable: !this.state.isaddGroupVisable
    })
  }

  logo_handelChanges(item) {
    this.setState(
      {
        aOneform: {
          category: item.name
        }
      },
      () => {
        this.isButtonVisable('aFormOne')
      }
    )
  }

  // Common

  // 1st

  onListNotification() {
    this.setState({
      visableListNotification: true
    })
  }

  onListComputedAttributes() {
    this.setState({
      visableListAttribute: true
    })
  }

  onListSavedCommands() {
    this.setState({
      visableListSavedCommands: true
    })
  }
  onListMaintenance() {
    this.setState({
      visableListMaintenance: true
    })
  }

  // 2nd
  onCreateNewNotification() {
    this.props.history.push('/notificationCreate')
    this.setState({
      isAddVisableBtn: false,
      notificationCallStatus: 'POST',
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'notificationCreate'
    })
  }

  onCreateNewAttributes() {
    this.setState({
      isAddVisableBtn: false,
      attributeCallStatus: 'POST',
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'attributeCreate'
    })
  }

  onCreateNewSavedCommands() {
    this.setState({
      isAddVisableBtn: false,
      commandsCallStatus: 'POST',
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'commandCreate'
    })
  }

  onCreateNewMaintenance() {
    this.props.history.push('/maintenanceCreate')
    // this.setState({
    //   isAddVisableBtn: false,
    //   maintenanceCallStatus: 'POST',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...maintenanceDataFormat },
    //   addButtonStatus: 'sharedAdd',
    //   isHaveAccess: 'maintenanceCreate'
    // })
  }
  onCreateNewDashboard = () => {
    this.props.history.push('/dashboardBuilder')
  }

  // 3rd
  notification_Selection(checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectednotification]
    let obj = {
      userId: this.props.id,
      notificationId: item.id
    }
    if (value) {
      method = 'POST'
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveNotificationDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(
                'notificationUnAssignedSuccessfully'
              )
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item))
          this.props.dispatch(addNotificationDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('notificationAssignedSuccessfully')
            )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  geofence_Selection(checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedgeofence]
    let obj = {
      userId: this.props.id,
      geofenceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveGeofenceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('geofenceUnAssignedSuccessfully')
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(addGeofenceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('geofenceAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  group_Selection(checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedgroups]
    let obj = {
      userId: this.props.id,
      groupId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateGroupsDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveGroupsDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('groupUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateGroupsDeviceWiseSelectedList(item))
          this.props.dispatch(addGroupsDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('groupAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  unit_Selection(checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddevices]
    let obj = {
      userId: this.props.id,
      deviceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(RemoveDeviceUserWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('trackerIsUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(addDeviceUserWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('trackerIsAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  driver_Selection(checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddriver]
    let obj = {
      userId: this.props.id,
      driverId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveDriverDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('driverUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(addDriverDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('driverAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }
  trailer_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedtrailer]
    let obj = {
      userId: this.props.id,
      binderId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateTrailerDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveTrailerDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('trailerUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateTrailerDeviceWiseSelectedList(item))
          this.props.dispatch(addTrailerDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('trailerAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }
  // garage_Selection = (checked, item) => {
  //   let method = 'DELETE'
  //   let value = checked
  //   let allData = this.props.selectedGarages&&this.props.selectedGarages || []
  //   let obj = {
  //     userId: this.props.id,
  //     garageId: item.id
  //   }
  //   if (value) {
  //     method = 'POST'
  //   }
  //   instance({
  //     url: `/api/permissions`,
  //     method: method,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data:{
  //           ...obj
  //         }
  //   })
  //   .then(response => {
  //       if (method === 'DELETE') {
  //         item.check = false
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = false
  //           }
  //           return null
  //         })
  //         this.props.dispatch(updategarageUsersWiseSelectedList(item))
  //          this.props.dispatch(
  //           Notifications.success({
  //             message: this.props.translate('garageUnAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       } else {
  //         item.check = true
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = true
  //           }
  //           return null
  //         })
  //         this.props.dispatch(updategarageUsersWiseSelectedList(item))
  //           this.props.dispatch(
  //           Notifications.success({
  //             message: this.props.translate('garageAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     // }
  //   }).catch(error => {errorHandler(error, this.props.dispatch)})
  // }
  area_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.selectedAreas && this.props.selectedAreas || []
    let obj = {
      userId: this.props.id,
      areaId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateAreaUsersWiseSelectedList(item))
          this.props.dispatch(
            notifySuccess('areaUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateAreaUsersWiseSelectedList(item))
          this.props.dispatch(
            notifySuccess('areaAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  // service_Selection = (checked, item) => {
  //   let method = 'DELETE'
  //   let value = checked
  //   let allData = this.props.selectedServices&&this.props.selectedServices || []
  //   let obj = {
  //     userId: this.props.id,
  //     expenseTypeId: item.id
  //   }
  //   if (value) {
  //     method = 'POST'
  //   }
  //   instance({
  //     url: `/api/permissions`,
  //     method: method,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data:{
  //           ...obj
  //         }
  //   })
  //   .then(response => {
  //       if (method === 'DELETE') {
  //         item.check = false
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = false
  //           }
  //           return null
  //         })
  //         this.props.dispatch(updateServiceUsersWiseSelectedList(item))
  //          this.props.dispatch(
  //           Notifications.success({
  //             message: this.props.translate('serviceUnAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       } else {
  //         item.check = true
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = true
  //           }
  //           return null
  //         })
  //         this.props.dispatch(updateServiceUsersWiseSelectedList(item))
  //           this.props.dispatch(
  //           Notifications.success({
  //             message: this.props.translate('serviceAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     // }
  //   }).catch(error => {errorHandler(error, this.props.dispatch)})
  // }

  dashboard_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddashboard]
    let obj = {
      userId: this.props.id,
      dashboardId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDashboardDeviceWiseSelectedList(item))
          // this.props.dispatch(RemoveDashboardDeviceWise(item))
          this.props.dispatch(
            notifySuccess('dashboardUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDashboardDeviceWiseSelectedList(item))
          // this.props.dispatch(addDashboardDeviceWise(item))
          this.props.dispatch(
            notifySuccess('dashboardAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }
  widget_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedwidget]
    let obj = {
      userId: this.props.id,
      gadgetId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateWidgetDeviceWiseSelectedList(item))
          // this.props.dispatch(RemoveWidgetDeviceWise(item))
          this.props.dispatch(
            notifySuccess('widgetUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateWidgetDeviceWiseSelectedList(item))
          // this.props.dispatch(addWidgetDeviceWise(item))
          this.props.dispatch(
            notifySuccess('widgetAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  template_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedtemplates]
    let obj = {
      userId: this.props.id,
      rpTmplId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateTemplateUserWiseSelectedList(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('templateUnAssignedSuccessfully'),
        
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateTemplateUserWiseSelectedList(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('templateAssignedSuccessfully'),
        
          )
        }
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }
  // vehicle start
  vehicle_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = this.state.allvehicle && this.state.allvehicle.data
    let obj = {
      userId: parseInt(this.props.id),
      vehicleId: item.id
    }
    if (value) {
      method = 'POST'
    }
    this.setState({ vehicleLoader: true }, () => {
      instance({
        url: `/api/permissions`,
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(response => {
          // this.setState({
          //   vehicleLoader:false,
          //   // allvehicle:{
          //   //   ...this.state.allvehicle,
          //   //   data:allData
          //   // },
          // })
          // if (response.status === 204) {
          if (method === 'DELETE') {
            // item.check = false
            // allData.map(dt => {
            //   if (dt.id === item.id) {
            //     dt.check = false
            //   }
            //   // return null
            // })
            // this.setState({
            //   vehicleLoader:false,
            //   allvehicle:{
            //     ...this.state.allvehicle,
            //     data:allData
            //   },
            //   selectAllVehicles:false
            // })
            // this.props.dispatch(updateVehicleDeviceWiseSelectedList(item))
            // this.props.dispatch(RemoveVehicleDeviceWise(item))
            this.props.dispatch(
              notifySuccess(this.props.translate('vehicleUnAssignedSuccessfully')
              )
            )
          } else {

            // item.check = true
            // allData.map(dt => {
            //   if (dt.id === item.id) {
            //     dt.check = true
            //   }
            //   this.setState({
            //     vehicleLoader:false,
            //     allvehicle:{
            //       ...this.state.allvehicle,
            //       data:allData
            //     },
            //   },()=>  this.fetchAllVehicles(this.props.logInUser.id))
            // //   // return null
            // })
            // this.fetchAllVehicles(this.props.logInUser.id)
            // this.props.dispatch(updateVehicleDeviceWiseSelectedList(item))
            // this.props.dispatch(addVehicleDeviceWise(item))
            this.props.dispatch(
              notifySuccess(this.props.translate('vehicleAssignedSuccessfully'),
              )
            )
    }
          this.fetchAllVehicles(this.props.logInUser.id)
        // }
      
      }).catch(error => { errorHandler(error, this.props.dispatch) } )
    })
    // this.fetchAllVehicles(this.props.logInUser.id)
    }
// vehicle end
handleChangeRowsPerPageVehicle = value => {
  this.setState(
    {
      vehiclePageSize: value
    },
    () => this.fetchAllVehicles(this.props.logInUser.id)
  )
}
handleChangePageVehicle = value => {
  this.setState(
    {
      //tableData: [],
      vehiclePage: value
      //defaultItemsList: false
    },
    () => this.fetchAllVehicles(this.props.logInUser.id)
  )
}

checkFloteNumber(n) {
  return Number(n) === n && n % 1 !== 0
}
setPagination = user => {
  if (this.state.allvehicle) {
    let nUser = { ...user }
    let lastPage = nUser.total / this.state.vehiclePageSize
    let IsFloate = this.checkFloteNumber(lastPage)
    delete nUser.data
    nUser.pageSize = this.state.vehiclePageSize
    nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
    return nUser
  }
  return {}
}

// vehicleSearch
// handleChangeVehicle

handleChangeVehicle=(e)=>{

  if (this.state.cancelTokenSource) {
    this.state.cancelTokenSource.cancel("Operation canceled due to new search.");
  }

  const cancelTokenSource = axios.CancelToken.source(); 
  this.setState({
    vehicleSearch:e.target.value,
    cancelTokenSource, // Store the new token source in state

  },()=>{
    
    // console.log("en=====", this.state.vehicleSearch);
    this.fetchAllVehicles(this.props.logInUser.id)
  })
}
fetchAllVehicles = (id) => {
  let {cancelTokenSource } = this.state;
  if (!cancelTokenSource) {
    cancelTokenSource = axios.CancelToken.source();
    this.setState({ cancelTokenSource });
  }
  instance({
    method: 'GET',
    url: `/api/vehicles/get?all=true&userId=${id}&page=${this.state.vehiclePage}&limit=${this.state.vehiclePageSize}&search=${this.state.vehicleSearch}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    cancelToken: cancelTokenSource.token,
  }).then(response => {
    this.setState({
      allvehicle: response,
      unitsList: response.data || []

    }, () => { this.fetchSelectedVehicle() })

    // this.props.dispatch(getVehicles(response.data))
    // this.getCurrentUser(this.props.id)
  }).catch(error => {
    // errorHandler(error, this.props.dispatch)
  })
}

fetchSelectedVehicle = () => {
  // source = CancelToken.source()

  if (checkPrivileges('vehicle')) {
    this.setState({ vehicleLoader: true }, () => {

      instance({
        url: `/api/vehicles/linked`,
        method: 'GET',
        // cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: this.props.id
        }
      })
        .then(vehicle => {

          let len = vehicle && vehicle.length === this.state.allvehicle.total
          this.setState({
            selectedVehicles: vehicle,
            isVehicleRecived: true,
            selectAllVehicles: len
          })


          // let allData = [...this.state.allvehicle.data];

          // Iterate over allData and update the 'check' property if a match is found in 'vehicle'

          let allData = this.state?.unitsList?.map(item => {
            // let findItem = vehicle.find(it => it.entityId === item.id);
            // if (findItem) {
            //   item.check = findItem.directAccess;
            //   item.groupAccess = findItem.groupAccess;
            // }
            // return item;
            let matchingItems = vehicle.filter(it => it.entityId === item.id);
            if (matchingItems.length > 0) {
              // Consider all matching items in 'matchingItems'
              item.check = matchingItems.some(match => match.directAccess);
              item.groupAccess = matchingItems.some(match => match.groupAccess);
            }
            return item;
          });

          // Calculate 'selectAllVehicles' based on the updated 'allData'
          let len1 = allData.every(item => item.check);

          // let allData = this.state.allvehicle&&this.state.allvehicle.data

          // allData.map(item => {
          //   // item.check = item.parent = parseInt(this.props.id) === item.parentId
          //   // item.indeterminate = false
          //   // if (vehicle.length) {
          //       const findItem = vehicle.find(it=>it.entityId ===item.id )

          //       //  if(findItem){
          //             // item.indeterminate = findItem.directAccess === false
          //             item.check = findItem?.directAccess
          //             //  item.indeterminate = findItem.directAccess
          //              item.groupAccess = findItem?.groupAccess
          //             // findItem={}
          //       // }
          //       if(!findItem.directAccess){
          //         len =false
          //       }
          //       // else{
          //       //   return null
          //       // }
          //     // vehicle.map(main => {
          //     //   if (item.id && main.entityId && item.id === main.entityId) {
          //     //     item.indeterminate = main.directAccess === false
          //     //     item.check = main.directAccess
          //     //     item.groupAccess = main.groupAccess
          //     //   }
          //     //   return null
          //     // })
          //     // return null
          //   // }
          //  })
          let units = this.state.allvehicle || {}
          this.setState({
            vehicleLoader: false,

            allvehicle: { ...this.state.allvehicle, data: allData },
            selectAllVehicles: len,
            // vehicleLoader:false
          }, () => { })

          // this.props.dispatch(vehicleDeviceWiseSelectedList(allData))

        }).catch(error => {
        })
    })

  }
}

computedAttributes_Selection(checked, item) {
  let method = 'DELETE'
  let value = checked
  let allData = this.props.computedAttributes
  let obj = {
    userId: this.props.id,
    attributeId: item.id
  }
  if (value) {
    method = 'POST'
  }
  instance({
    url: `/api/permissions`,
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: {
      ...obj
    }
  })
    .then(response => {
      // if (response.status === 204) {
      if (method === 'DELETE') {
        item.check = false
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = false
          }
          return null
        })
        this.props.dispatch(
          updateComputedAttributeDeviceWiseSelectedList(item)
        )
        this.props.dispatch(RemoveComputedAttributeDeviceWise(item))
        //this.props.enqueueSnackbar(this.props.translate('computedAttributeUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate(
            'computedAttributeUnAssignedSuccessfully'
          )
          )
            )
} else {
  item.check = true
  allData.map(dt => {
    if (dt.id === item.id) {
      dt.check = true
    }
    return null
  })
  this.props.dispatch(
    updateComputedAttributeDeviceWiseSelectedList(item)
  )
  this.props.dispatch(addComputedAttributeDeviceWise(item))
  this.props.dispatch(
    notifySuccess(this.props.translate(
      'computedAttributeAssignedSuccessfully'
    )
    )
            )
} 
      })
      .catch (error => {
  errorHandler(error, this.props.dispatch, this.errorCallBack)

})
  }
errorCallBack = (data) => {
  this.setState({ visableListAttribute: false }, () => {
    this.setState({ visableListAttribute: true })
  })
}

savedCommands_Selection(checked, item) {
  let method = 'DELETE'
  let value = checked
  let allData = this.props.commands
  let obj = {
    userId: this.props.id,
    commandId: item.id
  }
  if (value) {
    method = 'POST'
  }
  // fetch(`/api/permissions/`, {
  //   method: method,
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     ...obj
  //   })
  // })
  instance({
    url: `/api/permissions`,
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: {
      ...obj
    }
  })
    .then(response => {
      // if (response.status === 204) {
      if (method === 'DELETE') {
        item.check = false
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = false
          }
          return null
        })
        this.props.dispatch(updateCommandsDeviceWiseSelectedList(item))
        this.props.dispatch(RemoveCommandsDeviceWise(item))
        //this.props.enqueueSnackbar(this.props.translate('savedCommandsUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess("savedCommandsUnAssignedSuccessfully")
        )
      } else {
        item.check = true
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = true
          }
          return null
        })
        this.props.dispatch(updateCommandsDeviceWiseSelectedList(item))
        this.props.dispatch(addCommandsDeviceWise(item))
        //this.props.enqueueSnackbar(this.props.translate('savedCommandsAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess( 'savedCommandsAssignedSuccessfully')
        )
      }
      // }
    }).catch(error => { errorHandler(error, this.props.dispatch) })
}

maintenance_Selection(checked, item) {
  let method = 'DELETE'
  let value = checked
  let allData = this.props.maintenance
  let obj = {
    userId: this.props.id,
    maintenanceId: item.id
  }
  if (value) {
    method = 'POST'
  }
  instance({
    url: `/api/permissions`,
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: {
      ...obj
    }
  })
    .then(response => {
      if (method === 'DELETE') {
        item.check = false
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = false
          }
          return null
        })
        this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
        this.props.dispatch(RemoveMaintenanceDeviceWise(item))
        this.props.dispatch(
          notifySuccess(
              'maintenanceUnAssignedSuccessfully'
          )
        )
      } else {
        item.check = true
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = true
          }
          return null
        })
        this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
        this.props.dispatch(addMaintenanceDeviceWise(item))
        this.props.dispatch(
       notifySuccess('maintenanceAssignedSuccessfully')
        )
      }
      // }
    }).catch(error => { errorHandler(error, this.props.dispatch) })
}

// 4th
notificationHandle(name, value) {
  this.setState(
    {
      notificationForm: {
        ...this.state.notificationForm,
        [name]: value.id
      }
    },
    () => this.checkRequiredFields('notification')
  )
}

attributeHandle(name, value) {
  let selectedValue = postionAttributesTypeComp
    .filter(m => m.key === value)
    .map(m => m.valueType)
  this.setState(
    {
      computedAttForm: {
        ...this.state.computedAttForm,
        [name]: value,
        type: selectedValue[0] ? selectedValue[0] : 'string'
      }
    },
    () => this.checkRequiredFields('computedAtt')
  )
}

savedCommandsHandle(name, value) {
  this.setState(
    {
      savedCommandsForm: {
        ...this.state.savedCommandsForm,
        type: value.id
      }
    },
    () => this.checkRequiredFields('Commands')
  )
}

maintenancesHandle(name, value) {
  this.setState(
    {
      maintenanceForm: {
        ...this.state.maintenanceForm,
        [name]: value.id
      }
    },
    () => this.checkRequiredFields('maintenance')
  )
}

// 5th
addNotification() {
  let obj = { ...this.state.notificationForm }
  if (
    (this.state.notificationCallStatus === 'PUT' ||
      this.state.notificationCallStatus === 'POST') &&
    obj.notificators &&
    obj.notificators.length
  ) {
    let notificatorsArr = []
    obj.notificators.map(v => {
      notificatorsArr.push(v.value)
      return v
    })
    let notificators = notificatorsArr.join(',')
    obj.notificators = notificators
  }
  let params = ''
  if (
    this.state.notificationCallStatus === 'PUT' ||
    this.state.notificationCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    // fetch(`/api/notifications/${params}`, {
    //   method: `${this.state.notificationCallStatus}`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/notifications/${params}`,
      method: `${this.state.notificationCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(notifications => {
        // if (response.ok) {
        if (this.state.notificationCallStatus === 'DELETE') {
          this.props.dispatch(removeNotificationDeviceWiseSelectedList(obj))
          this.props.dispatch(removedNotification(obj))
          //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate('notificationIsDeleted')
            )
            )
    this.setState({
      onDeleteConfirmationNotification: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableNotification: false
    })

    if (this.state.notificationCallStatus === 'PUT') {
      let noti = { ...notifications, check }
      this.props.dispatch(
        updateNotificationDeviceWiseSelectedList(noti)
      )
      this.props.dispatch(addNotification(noti))

      this.props.dispatch(
        notifySuccess('notificationIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.notification_Selection(true, notifications)
      let noti = { ...notifications, check: true }
      this.props.dispatch(addNotificationDeviceWiseSelectedList(noti))
      this.props.dispatch(addNotification(noti))
      this.props.dispatch(
        notifySuccess('notificationIsCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addGeofence() {
  let obj = this.state.geofenceForm
  let params = ''
  if (
    this.state.geofenceCallStatus === 'PUT' ||
    this.state.geofenceCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/geofences/${params}`,
      method: `${this.state.geofenceCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(geofences => {
        if (this.state.geofenceCallStatus === 'DELETE') {
          this.props.dispatch(removeGeofenceDeviceWiseSelectedList(obj))
          this.props.dispatch(deleteGeofence(obj))
          this.props.dispatch(
            notifySuccess('geofenceDeleted')
            )
    this.setState({
      onDeleteConfirmationGeofence: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableGeofence: false
    })

    if (this.state.geofenceCallStatus === 'PUT') {
      let noti = { ...geofences, check }
      this.props.dispatch(updateGeofenceDeviceWiseSelectedList(noti))
      this.props.dispatch(addGeoFence(noti))

      this.props.dispatch(
        notifySuccess('geofenceUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.geofence_Selection(true, geofences)
      let noti = { ...geofences, check: true }
      this.props.dispatch(addGeofenceDeviceWiseSelectedList(noti))
      this.props.dispatch(addGeoFence(noti))
      this.props.dispatch(
        notifySuccess('geofenceCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addGroup() {
  let obj = this.state.groupForm
  let params = ''
  if (
    this.state.groupCallStatus === 'PUT' ||
    this.state.groupCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/groups/${params}`,
      method: `${this.state.groupCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(groups => {
        if (this.state.groupCallStatus === 'DELETE') {
          this.props.dispatch(removeGroupDeviceWiseSelectedList(obj))
          this.props.dispatch(removeGroup(obj))
          this.props.dispatch(
            notifySuccess('resourceDeleted')
            )
    this.setState({
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationGroup: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableGeofence: false
    })

    if (this.state.groupCallStatus === 'PUT') {
      let noti = { ...groups, check }
      this.props.dispatch(updateGeofenceDeviceWiseSelectedList(noti))
      this.props.dispatch(addGeoFence(noti))
      this.props.dispatch(
        notifySuccess('resourceUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.group_Selection(true, groups)
      let noti = { ...groups, check: true }
      this.props.dispatch(addGeofenceDeviceWiseSelectedList(noti))
      this.props.dispatch(addGeoFence(noti))
      this.props.dispatch(
        notifySuccess('resourceAdded')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }

  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addDevice() {
  let obj = this.state.deviceForm
  let params = ''
  if (
    this.state.deviceCallStatus === 'PUT' ||
    this.state.deviceCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/devices/${params}`,
      method: `${this.state.deviceCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(devices => {
        if (this.state.deviceCallStatus === 'DELETE') {
          this.props.dispatch(removeDeviceUserWiseSelectedList(obj))
          this.props.dispatch(removeDevice(obj))
          this.props.dispatch(
            notifySuccess('deviceIsDeleted'),
        
          )
          this.setState({
            onDeleteConfirmationDevice: false,
            attributeChangesMessage: ''
          })
        } else {
          this.setState({
            visableDevice: false
          })

          if (this.state.deviceCallStatus === 'PUT') {
            let noti = { ...devices, check }
            this.props.dispatch(updateDeviceUserWiseSelectedList(noti))
            this.props.dispatch(addDevices(noti))
            this.props.dispatch(
              notifySuccess("deviceIsUpdated")
            )
            this.setState({
              attributeChangesMessage: ''
            })
          } else {
            this.device_Selection(true, devices)
            let noti = { ...devices, check: true }
            this.props.dispatch(addDeviceUserWiseSelectedList(noti))
            this.props.dispatch(addDevices(noti))
            this.props.dispatch(
              notifySuccess("deviceIsCreated")
            )
            this.setState({
              attributeChangesMessage: ''
            })
          }
        }
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)

      })
  }
}

addDriver() {
  let obj = this.state.driverForm
  let params = ''
  if (
    this.state.driverCallStatus === 'PUT' ||
    this.state.driverCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/drivers/${params}`,
      method: `${this.state.driverCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(drivers => {
        if (this.state.driverCallStatus === 'DELETE') {
          this.props.dispatch(removeDriverDeviceWiseSelectedList(obj))
          this.props.dispatch(removeDriver(obj))
          this.props.dispatch(
            notifySuccess(this.props.translate('driverIsDeleted')
            )
            )
    this.setState({
      onDeleteConfirmationDriver: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableDriver: false
    })

    if (this.state.driverCallStatus === 'PUT') {
      let noti = { ...drivers, check }
      this.props.dispatch(updateDriverDeviceWiseSelectedList(noti))
      this.props.dispatch(addDriver(noti))
      this.props.dispatch(
        notifySuccess('driverIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.driver_Selection(true, drivers)
      let noti = { ...drivers, check: true }
      this.props.dispatch(addDriverDeviceWiseSelectedList(noti))
      this.props.dispatch(addDriver(noti))
      this.props.dispatch(
        notifySuccess('driverIsCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }
addTrailer() {
  let obj = this.state.trailerForm
  let params = ''
  if (
    this.state.trailerCallStatus === 'PUT' ||
    this.state.trailerCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/binders/${params}`,
      method: `${this.state.trailerCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(trailers => {
        if (this.state.trailerCallStatus === 'DELETE') {
          this.props.dispatch(removeTrailerDeviceWiseSelectedList(obj))
          this.props.dispatch(removeTrailer(obj))
          this.props.dispatch(
            notifySuccess(this.props.translate('trailerIsDeleted')
            )
            )
    this.setState({
      onDeleteConfirmationTrailer: false,
      attributeChangesMessage: ''
    })
  } else {
    // response.json().then(trailers => {
    this.setState({
      visableTrailer: false
    })

    if (this.state.trailerCallStatus === 'PUT') {
      let noti = { ...trailers, check }
      this.props.dispatch(updateTrailerDeviceWiseSelectedList(noti))
      this.props.dispatch(addTrailers(noti))
      this.props.dispatch(
        notifySuccess('trailerIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.trailer_Selection(true, trailers)
      let noti = { ...trailers, check: true }
      this.props.dispatch(addTrailerDeviceWiseSelectedList(noti))
      this.props.dispatch(addTrailers(noti))
      this.props.dispatch(
        notifySuccess('trailerIsCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }

  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }


addDashboard() {
  let obj = this.state.dashboardForm
  let params = ''
  if (
    this.state.dashboardCallStatus === 'PUT' ||
    this.state.dashboardCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }

    instance({
      url: `/api/dashboards/${params}`,
      method: `${this.state.dashboardCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(dashboards => {
        if (this.state.dashboardCallStatus === 'DELETE') {
          this.props.dispatch(removeDashboardDeviceWiseSelectedList(obj))
          fetchDashboards(this.props.dispatch, this.props.logInUser)
          this.props.dispatch(
            notifySuccess('dashboardIsDeleted')
            )
    this.setState({
      onDeleteConfirmationDashboard: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableDashboard: false
    })

    if (this.state.dashboardCallStatus === 'PUT') {
      let noti = { ...dashboards, check }
      this.props.dispatch(updateDashboardDeviceWiseSelectedList(noti))
      this.props.dispatch(
        notifySuccess('dashboardIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.dashboard_Selection(true, dashboards)
      let noti = { ...dashboards, check: true }
      this.props.dispatch(addDashboardDeviceWiseSelectedList(noti))
      this.props.dispatch(
        notifySuccess('dashboardIsCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }
addWidget() {
  let obj = this.state.widgetForm
  let params = ''
  if (
    this.state.widgetCallStatus === 'PUT' ||
    this.state.widgetCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/gadgets/${params}`,
      method: `${this.state.widgetCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(widgets => {
        if (this.state.widgetCallStatus === 'DELETE') {
          this.props.dispatch(removeWidgetDeviceWiseSelectedList(obj))
          this.props.dispatch(
            notifySuccess(this.props.translate('widgetIsDeleted'),
            )
            )
    this.setState({
      onDeleteConfirmationWidget: false,
      attributeChangesMessage: ''
    })
  } else {
    this.setState({
      visableWidget: false
    })

    if (this.state.widgetCallStatus === 'PUT') {
      let noti = { ...widgets, check }
      this.props.dispatch(updateWidgetDeviceWiseSelectedList(noti))
      this.props.dispatch(
        notifySuccess('widgetIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.widget_Selection(true, widgets)
      let noti = { ...widgets, check: true }
      this.props.dispatch(addWidgetDeviceWiseSelectedList(noti))
      this.props.dispatch(
        notifySuccess('widgetIsCreated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }
// start vehicle
addVehicle() {
  let obj = this.state.vehicleForm
  let params = ''
  if (
    this.state.vehicleCallStatus === 'PUT' ||
    this.state.vehicleCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    let check
    if (obj.check !== undefined) {
      check = obj.check
      delete obj.check
    }
    if (obj.alarms) {
      obj.attributes = { ...obj.attributes, alarms: obj.alarms }
      delete obj.alarms
    }
    instance({
      url: `/api/vehicles/${params}`,
      method: `${this.state.vehicleCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(vehicles => {
        if (this.state.vehicleCallStatus === 'DELETE') {
          this.props.dispatch(removeVehicleDeviceWiseSelectedList(obj))
          this.props.dispatch(removedvehicle(obj))
          this.props.dispatch(
            notifySuccess('vehicleIsDeleted'),
        
          )
          this.setState({
            onDeleteConfirmationVehicle: false,
            attributeChangesMessage: ''
          })
        } else {
          this.setState({
            visableVehicle: false
          })

          if (this.state.vehicleCallStatus === 'PUT') {
            let noti = { ...vehicles, check }
            this.props.dispatch(updateVehicleDeviceWiseSelectedList(noti))
            this.props.dispatch(addvehicle(noti))
            this.props.dispatch(
              notifySuccess("vehicleIsUpdated")
            )
            this.setState({
              attributeChangesMessage: ''
            })
          } else {
            // this.vehicle_Selection(true, vehicles)
            let noti = { ...vehicles, check: true }
            this.props.dispatch(addVehicleDeviceWiseSelectedList(noti))
            this.props.dispatch(addvehicle(noti))
            this.props.dispatch(
              notifySuccess("vehicleIsCreated")
            )
            this.setState({
              attributeChangesMessage: ''
            })
          }
        }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }
}
// end vehicle
addComputedAttributes() {
  let obj = this.state.computedAttForm
  let params = ''
  if (
    this.state.attributeCallStatus === 'PUT' ||
    this.state.attributeCallStatus === 'DELETE'
  ) {
    params = obj.id
    delete obj.deviceId
  }
  if (obj) {
    if (obj.check !== undefined) {
      delete obj.check
    }
    // fetch(`/api/attributes/computed/${params}`, {
    //   method: `${this.state.attributeCallStatus}`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/attributes/computed/${params}`,
      method: `${this.state.attributeCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(Attribute => {
        // if (response.ok) {
        if (this.state.attributeCallStatus === 'DELETE') {
          this.props.dispatch(
            removedComputedAttributeDeviceWiseSelectedList(obj)
          )
          this.props.dispatch(removedComputedAttribute(obj))
          //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate('computedAttributeIsDeleted')
            )
            )
    this.setState({
      onDeleteConfirmationAttributes: false
    })
  } else {
    // response.json().then(Attribute => {
    this.setState({
      visableAttribute: false
    })
    if (this.state.attributeCallStatus === 'PUT') {
      this.props.dispatch(
        updateComputedAttributeDeviceWiseSelectedList(Attribute)
      )
      this.props.dispatch(addComputedAttribute(Attribute))
      //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('computedAttributeIsUpdated')
      )
    } else {
      this.computedAttributes_Selection(true, Attribute)
      this.props.dispatch(
        addComputedAttributeDeviceWiseSelectedList(Attribute)
      )
      this.props.dispatch(addComputedAttribute(Attribute))
      //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('computedAttributeIsCreated')
      )
      this.setState({
        visableListAttribute: false
      })
    }
    //     })
    //   }
    // }
    // else{
    //   throw response
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addSavedCommands() {
  let {
    attributes,
    id,
    description,
    type,
    textChannel
  } = this.state.savedCommandsForm
  textChannel = textChannel === 'Yes' ? true : false
  attributes = attributes || {}
  id = id || -1
  let obj = { attributes, id, description, type, textChannel }
  obj.deviceId = 0
  let params = ''
  if (
    this.state.commandsCallStatus === 'PUT' ||
    this.state.commandsCallStatus === 'DELETE'
  ) {
    params = obj.id
  }

  if (obj) {
    instance({
      url: `/api/commands/${params}`,
      method: `${this.state.commandsCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(commands => {
        if (this.state.commandsCallStatus === 'DELETE') {
          this.props.dispatch(removedCommandsDeviceWiseSelectedList(obj))
          this.props.dispatch(removedCommands(obj))
          //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess('savedCommandsIsDeleted')
            
            )
    this.setState({
      onDeleteConfirmationCommand: false
    })
  } else {
    // response.json().then(commands => {
    this.setState({
      visableSavedCommands: false
    })
    if (this.state.commandsCallStatus === 'PUT') {
      this.props.dispatch(
        updateCommandsDeviceWiseSelectedList(commands)
      )
      this.props.dispatch(addCommands(commands))
      //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('savedCommandsIsUpdated')
      )
    } else {
      this.savedCommands_Selection(true, commands)
      this.props.dispatch(addCommandsDeviceWiseSelectedList(commands))
      this.props.dispatch(addCommands(commands))
      //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('savedCommandsIsCreated')
      )

      this.setState({ visableListSavedCommands: false })
    }
    //     })
    //   }
    // }
    // else{
    //   throw response
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addMaintenance() {
  let obj = this.state.maintenanceForm

  if (this.state.maintenanceForm.type === 'totalDistance') {
    obj.start = parseFloat(this.state.maintenanceForm.start * 1000)
    obj.period = parseFloat(this.state.maintenanceForm.period * 1000)
  } else if (this.state.maintenanceForm.type === 'hours') {
    obj.start = parseFloat(this.state.maintenanceForm.start * (1000 * 3600))
  } else if (this.state.maintenanceForm.type === 'date') {
    obj.start = 1
  }

  let params = ''
  if (
    this.state.maintenanceCallStatus === 'PUT' ||
    this.state.maintenanceCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    if (obj.check !== undefined) {
      delete obj.check
    }
    // fetch(`/api/maintenance/${params}`, {
    //   method: `${this.state.maintenanceCallStatus}`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/maintenance/${params}`,
      method: `${this.state.maintenanceCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(Maintenance => {
        // if (response.ok) {
        if (this.state.maintenanceCallStatus === 'DELETE') {
          this.props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj))
          this.props.dispatch(removedMaintenance(obj))
          //this.props.enqueueSnackbar(this.props.translate('maintenanceIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate('maintenanceIsDeleted')
            )
            )

    this.setState({
      onDeleteConfirmationMaintenance: false,
      attributeChangesMessage: ''
    })
  } else {
    // response.json().then(Maintenance => {
    if (this.state.maintenanceCallStatus === 'PUT') {
      this.props.dispatch(
        updateMaintenanceDeviceWiseSelectedList(Maintenance)
      )
      this.props.dispatch(addMaintenance(Maintenance))
      //this.props.enqueueSnackbar(this.props.translate('maintenanceIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('maintenanceIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.maintenance_Selection(true, Maintenance)
      this.props.dispatch(
        addMaintenanceDeviceWiseSelectedList(Maintenance)
      )
      this.props.dispatch(addMaintenance(Maintenance))
      //this.props.enqueueSnackbar(this.props.translate('MaintenanceIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('MaintenanceIsCreated')
      )
      this.setState({
        attributeChangesMessage: '',
        visableListMaintenance: false
      })
    }

    this.setState({
      visableMaintenance: false,
      attributeChangesMessage: ''
    })
    //     })
    //   }
    // }
    // else{
    //   throw response
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

addUser() {
  let obj = this.state.userForm
  let params = ''
  if (
    this.state.userCallStatus === 'PUT' ||
    this.state.userCallStatus === 'DELETE'
  ) {
    params = obj.id
  }
  if (obj) {
    if (obj.check !== undefined) {
      delete obj.check
    }
    // fetch(`/api/users/${params}`, {
    //   method: `${this.state.userCallStatus}`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/users/${params}`,
      method: `${this.state.userCallStatus}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: {
        ...obj
      }
    })
      .then(User => {
        // if (response.ok) {
        if (this.state.userCallStatus === 'DELETE') {
          this.props.dispatch(removeUserUsereWise(obj))

          this.props.dispatch(
            notifySuccess(this.props.translate('userIsRemoved')
            )
            )
    this.setState({
      onDeleteConfirmationUser: false,
      attributeChangesMessage: ''
    })
  } else {
    // response.json().then(User => {
    if (this.state.maintenanceCallStatus === 'PUT') {
      this.props.dispatch(updateUserDeviceWiseSelectedList(User))
      this.props.dispatch(
        notifySuccess('UserIsUpdated')
      )
      this.setState({
        attributeChangesMessage: ''
      })
    } else {
      this.maintenance_Selection(true, User)

      this.props.dispatch(addUserDeviceWiseSelectedList(User))

      //this.props.enqueueSnackbar(this.props.translate('UserIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifySuccess('UserIsCreated')
      )
      this.setState({
        attributeChangesMessage: '',
        visableListUser: false
      })
    }

    this.setState({
      visableUser: false,
      attributeChangesMessage: ''
    })
    //     })
    //   }
    // }
    // else{
    //   throw response
  }
}).catch (error => { errorHandler(error, this.props.dispatch) })
    }
  }

// 6th
editNotification(item) {
  this.props.history.push(`/notificationUpdate/${item[0].id}`)
  let a =
    item && item[0] && item[0].notificators
      ? item[0].notificators.split(',')
      : []
  let notificators = a.length
    ? a.map(e => ({ label: this.props.translate(e), value: e }))
    : []
  this.setState({
    isAddVisableBtn: false,
    selecteItem: item[0],
    notificationCallStatus: 'PUT',
    visableNotification: true,
    notificationForm: { ...item[0], notificators },
    addButtonStatus: 'sharedUpdate',
    defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
    attributeChangesMessage: '',
    itemAttributes: item[0].attributes,
    isHaveAccess: 'notificationUpdate'
  })
}

editComputedAttributes(item) {
  this.setState({
    isAddVisableBtn: false,
    selecteItem: item[0],
    attributeCallStatus: 'PUT',
    visableAttribute: true,
    computedAttForm: {
      ...item[0],
      expression: parse(item[0].expression)[0]
    },
    addButtonStatus: 'sharedUpdate',
    isHaveAccess: 'attributeUpdate'
  })
}

editCommands(item) {
  this.setState({
    isAddVisableBtn: false,
    selecteItem: item[0],
    commandsCallStatus: 'PUT',
    visableSavedCommands: true,
    savedCommandsForm: { ...item[0] },
    addButtonStatus: 'sharedUpdate',
    isHaveAccess: 'commandUpdate'
  })
}

editDashboard = (item) => {
  this.props.history.push(`/dashboardBuilder/${item[0].id}`)
}

editMaintenance(item) {
  this.props.history.push(`/maintenanceUpdate/${item[0].id}`)
  // let obj = JSON.parse(JSON.stringify(item[0]))
  // if (obj.type === 'totalDistance') {
  //   obj.start = parseFloat(obj.start / 1000)
  //   obj.period = parseFloat(obj.period / 1000)
  // } else if (obj.type === 'hours') {
  //   obj.start = parseFloat(obj.start / (1000 * 3600))
  //   obj.period = parseFloat(obj.period / (1000 * 3600))
  // } else if (obj.type === 'date') {
  //   obj.start = 1
  // }

  // this.setState({
  //   isAddVisableBtn: false,
  //   selecteItem: item[0],
  //   maintenanceCallStatus: 'PUT',
  //   visableMaintenance: true,
  //   maintenanceForm: { ...obj },
  //   addButtonStatus: 'sharedUpdate',
  //   attributeChangesMessage: '',
  //   defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
  //   itemAttributes: item[0].attributes,
  //   isHaveAccess: 'maintenanceUpdate'
  // })
}

addMaintenanceAttributes() {
  this.setState({
    isAddAttributes: true,
    componentType: 'Maintenance',
    //defaultAttributes: JSON.parse(JSON.stringify(this.state.maintenanceForm.attributes)),
    itemAttributes: this.state.maintenanceForm.attributes
  })
}

notification_handleChange2 = (value, option) => {
  if (option && option.name === 'calendarId') {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [option.name]: value.value
        }
      },
      () => {
        this.checkRequiredFields('notification')
      }
    )
  } else if (typeof option === 'object' && Object.keys(option).length) {
    // for select
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [option.name]: value
        }
      },
      () => {
        this.checkRequiredFields('notification')
      }
    )
  } else if (value === 'type') {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [value]: option
        }
      },
      () => this.checkRequiredFields('notification')
    )
  } else {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          attributes: {
            ...this.state.notificationForm.attributes,
            [value]: option
          }
        }
      },
      () => this.checkRequiredFields('notification')
    )
  }
}

// direct arrow function
notification_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      notificationForm: {
        ...this.state.notificationForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('notification')
  )
}

geofence_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      geofenceForm: {
        ...this.state.geofenceForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('geofence')
  )
}

driver_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      driverForm: {
        ...this.state.driverForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('driver')
  )
}

trailer_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      trailerForm: {
        ...this.state.trailerForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('trailer')
  )
}

dashboard_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      dashboardForm: {
        ...this.state.dashboardForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('dashboard')
  )
}

widget_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      widgetForm: {
        ...this.state.widgetForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('widget')
  )
}

//start vehicle
vehicle_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      vehicleForm: {
        ...this.state.vehicleForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('vehicle')
  )
}
//end vehicle

group_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      groupForm: {
        ...this.state.groupForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('group')
  )
}

C_Att_handleChange = name => event => {
  const { target } = event
  this.setState(
    {
      computedAttForm: {
        ...this.state.computedAttForm,
        [name]: target.value
      }
    },
    () => this.checkRequiredFields('computedAtt')
  )
}

savedCommands_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  if (name === 'attributes') {
    let att = this.state.savedCommandsForm.attributes
    let newAtt = { [target.name]: target.value }
    att = { ...att, ...newAtt }
    value = att
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          [target.name]:
            target.type === 'checkbox' ? target.checked : target.value
        }
      },
      () => this.checkRequiredFields('Commands')
    )
  }
  this.setState(
    {
      savedCommandsForm: {
        ...this.state.savedCommandsForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('Commands')
  )
}

maintenance_handleChange = name => event => {
  const { target } = event
  let value = target.type === 'checkbox' ? target.checked : target.value
  this.setState(
    {
      maintenanceForm: {
        ...this.state.maintenanceForm,
        [name]: value
      }
    },
    () => this.checkRequiredFields('maintenance')
  )
}

maintenance_handleChange_attr = name => date => {
  const value =
    name === 'repeat' || name === 'status' ? date.target.checked : date
  this.setState(
    {
      maintenanceForm: {
        ...this.state.maintenanceForm,
        attributes: {
          ...this.state.maintenanceForm.attributes,
          [name]: value
        }
      }
    },
    () => this.checkRequiredFields('maintenance')
  )
}

// remove

removeNotification() {
  this.setState(
    {
      notificationCallStatus: 'DELETE'
    },
    () => this.addNotification()
  )
}

removeGeofence() {
  this.setState(
    {
      geofenceCallStatus: 'DELETE'
    },
    () => this.addGeofence()
  )
}

removeGroups() {
  this.setState(
    {
      groupCallStatus: 'DELETE'
    },
    () => this.addGroup()
  )
}

removeDriver() {
  this.setState(
    {
      driverCallStatus: 'DELETE'
    },
    () => this.addDriver()
  )
}
removeTrailer = () => {
  this.setState(
    {
      trailerCallStatus: 'DELETE'
    },
    () => this.addTrailer()
  )
}
// removeGarage = () => {
//   this.setState(
//     {
//       garageCallStatus: 'DELETE'
//     },
//     () => this.deleteGarages()
//   )
// }
removeDashboard = () => {
  this.setState(
    {
      dashboardCallStatus: 'DELETE'
    },
    () => this.addDashboard()
  )
}
removeWidget = () => {
  this.setState(
    {
      widgetCallStatus: 'DELETE'
    },
    () => this.addWidget()
  )
}
//start vehicle
removeVehicle = () => {
  this.setState(
    {
      vehicleCallStatus: 'DELETE'
    },
    () => this.addVehicle()
  )
}
//end vehicle

removeMaintenance() {
  this.setState(
    {
      maintenanceCallStatus: 'DELETE'
    },
    () => this.addMaintenance()
  )
}

removeUser() {
  this.setState(
    {
      userCallStatus: 'DELETE'
    },
    () => this.addUser()
  )
}

removeComputedAttributes() {
  this.setState(
    {
      attributeCallStatus: 'DELETE'
    },
    () => this.addComputedAttributes()
  )
}

onRemovedNotification = item => {
  this.setState({
    notificationForm: { ...item[0] },
    onDeleteConfirmationNotification: true
  })
}

onRemovedGeofence = item => {
  this.setState({
    geofenceForm: { ...item[0] },
    onDeleteConfirmationGeofence: true
  })
}

onRemovedGroup = item => {
  this.setState({
    groupForm: { ...item[0] },
    onDeleteConfirmationGroup: true
  })
}
onRemovedDriver = item => {
  this.setState({
    driverForm: { ...item[0] },
    onDeleteConfirmationDriver: true
  })
}
onRemovedTrailer = item => {
  this.setState({
    trailerForm: { ...item[0] },
    onDeleteConfirmationTrailer: true
  })
}
// onRemovedGarage = item => {
//   this.setState({
//     garageForm: { ...item[0] },
//     onDeleteConfirmationGarage: true
//   })
// }
onRemovedDashboard = item => {
  this.setState({
    dashboardForm: { ...item[0] },
    onDeleteConfirmationDashboard: true
  })
}
onRemovedWidget = item => {
  this.setState({
    widgetForm: { ...item[0] },
    onDeleteConfirmationWidget: true
  })
}

onRemovedTemplate = item => {
  this.setState({
    templateForm: { ...item[0] },
    onDeleteConfirmationTemplate: true
  })
}
//start vehicle
onRemovedVehicle = item => {
  this.setState({
    vehicleForm: { ...item[0] },
    onDeleteConfirmationVehicle: true
  })
}
// end vehicle
onRemovedAttribute = item => {
  this.setState({
    computedAttForm: { ...item[0] },
    onDeleteConfirmationAttributes: true
  })
}

onRemovedCommand = item => {
  this.setState({
    savedCommandsForm: { ...item[0] },
    onDeleteConfirmationCommand: true
  })
}

onRemovedMaintenance = item => {
  this.setState({
    maintenanceForm: { ...item[0] },
    onDeleteConfirmationMaintenance: true
  })
}

onRemovedUser = item => {
  this.setState({
    userForm: { ...item[0] },
    onDeleteConfirmationUser: true
  })
}

onRemovedDevice = item => {
  // e.preventDefault()
  this.setState({
    slectedUnits: item[0],
    onDeleteConfirmationUnits: true
  })
  return false
}

removeCommands() {
  this.setState(
    {
      commandsCallStatus: 'DELETE'
    },
    () => this.addSavedCommands()
  )
}

// deleteGarages = () => {
//   let id =  this.state.garageForm.id
//   if (id) {
//     instance({
//       url: `/api/garages/${id}`,
//       method: 'DELETE',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       }
//     })
//     .then(response => {
//         this.props.dispatch(removedGarages({ id:id }))
//         this.props.dispatch(
//           Notifications.success({
//             message: this.props.translate('GaragesIsDeleted'),
//             autoDismiss: 10
//           })
//         )
//         // this.fetchGarageNewLists()
//     }).catch(error => {errorHandler(error, this.props.dispatch)})
//   }
//   this.setState({
//      onDeleteConfirmationGarage: false,
//   })
// }

// fetchGarageNewLists = ()=>{
//   instance({
//     url: `/api/garages/get`,
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//   })
//   .then(res => {this.props.dispatch(getGarages(res))})
//   .catch(error => {
//     // errorHandler(error, this.props.dispatch)
//   })
// }
removeDevice() {
  let { slectedUnits } = this.state

  instance({
    url: `/api/devices/${slectedUnits.id}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      // if (response.status === 204) {
      this.props.dispatch(removeDeviceUserWiseSelectedList(slectedUnits))
      this.props.dispatch(removeDevice(slectedUnits))
      this.setState(
        {
          onDeleteConfirmationUnits: false
        },
        () => {
          this.props.dispatch(
            notifySuccess(this.props.translate('deviceIsDeleted')
            )
            )
    }
    )
  // }
}).catch (error => { errorHandler(error, this.props.dispatch) })
  }

onCancel = () => {
  this.setState({
    onDeleteConfirmationGeofence: false,
    onDeleteConfirmationNotification: false,
    onDeleteConfirmationAttributes: false,
    onDeleteConfirmationMaintenance: false,
    onDeleteConfirmationUser: false,
    onDeleteConfirmationCommand: false,
    onDeleteConfirmationUnits: false
  })
}

handleChangeRowsPerPage = rowsPerPage => {
  this.setState({ rowsPerPage })
}

// comman for all
onCloseC_Att_Modal() {
  this.setState({
    visableAttribute: false,
    visableNotification: false,
    visableGeofence: false,
    visableMaintenance: false,
    visableSavedCommands: false,
    attributeChangesMessage: '',
    defaultAttributes: {}
  })
}

// check for required field
checkRequiredFields(computedAtt) {
  // changed attribute work is in pendding
  const {
    defaultAttributes,
    selecteItem,
    maintenanceForm,
    computedAttForm,
    savedCommandsForm,
    notificationForm
  } = this.state
  if (computedAtt === 'computedAtt') {
    let changed_comAt = !isEqual(selecteItem, computedAttForm)
    let { attribute, expression } = this.state.computedAttForm
    if (
      attribute &&
      expression &&
      attribute.trim().length > 0 &&
      expression.trim().length > 0 &&
      changed_comAt
    ) {
      this.setState({
        isAddVisableBtn: true
      })
    } else {
      this.setState({
        isAddVisableBtn: false
      })
    }
  } else if (computedAtt === 'Commands') {
    let changed_comm = !isEqual(selecteItem, savedCommandsForm)
    let { type, description } = this.state.savedCommandsForm
    if (type && description && changed_comm) {
      this.setState({
        isAddVisableBtn: true
      })
    } else {
      this.setState({
        isAddVisableBtn: false
      })
    }
  } else if (computedAtt === 'notification') {
    // attChangedValue is for attributes changing value
    let attChangedValue = !isEqual(
      defaultAttributes,
      notificationForm.attributes
    )
    if (attChangedValue) {
      this.setState({
        attributeChangesMessage: 'Change in attributes is pending'
      })
    } else {
      this.setState({
        attributeChangesMessage: ''
      })
    }
    let changed_noti = !isEqual(selecteItem, notificationForm)
    let { type, notificators } = this.state.notificationForm
    if (type && notificators && changed_noti) {
      this.setState({
        isAddVisableBtn: true
      })
    } else {
      this.setState({
        isAddVisableBtn: false
      })
    }
  } else if (computedAtt === 'maintenance') {
    let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes)
    if (Attvalue) {
      this.setState({
        attributeChangesMessage: 'Change in attributes is pending'
      })
    } else {
      this.setState({
        attributeChangesMessage: ''
      })
    }
    let changed_main = !isEqual(selecteItem, maintenanceForm)
    let { type, start, period, attributes } = this.state.maintenanceForm
    if (type && period && (start || attributes.startDate) && changed_main) {
      this.setState({
        isAddVisableBtn: true
      })
    } else {
      this.setState({
        isAddVisableBtn: false
      })
    }
  }
}

isButtonVisable(target) {
  if (target === 'parkingAttForm') {
    const { attributes } = this.state.resFormData
    let att = Object.keys(attributes).reduce((array, key) => {
      return [...array, [key, attributes[key]]]
    }, [])
    let unitParkingAttributes = []
    att.map(item => {
      if (item[0] === 'parking.minIdleTime') {
        unitParkingAttributes.push({ 'parking.minIdleTime': item[1] / 60000 })
      }
      if (item[0] === 'parking.maxIdleSpeed') {
        unitParkingAttributes.push({
          'parking.maxIdleSpeed': Math.round(item[1] * 1.852)
        })
      }
      return null
    })
    let value = {
      'parking.maxIdleSpeed': 0,
      'parking.minIdleTime': 0
    }
    unitParkingAttributes.map(item => {
      value = { ...value, ...item }
      return null
    })
    this.setState({
      isVisableParkingAttBtn: !isEqual(
        value,
        this.state.unitParkingAttributesform
      )
    })
  }
  if (target === 'aFormOne') {
    const { category } = this.state.resFormData
    this.setState({
      isVisableASOneBtn: !isEqual(category, this.state.aOneform.category)
    })
  }
}

handleChangeForParkingAtt = name => event => {
  const { target } = event
  this.setState(preState => ({
    ...preState,
    unitParkingAttributesform: {
      ...preState.unitParkingAttributesform,
      [name]: parseFloat(target.value)
    }
  }))
  this.setState(
    {
      isVisableParkingAttBtn: this.state.isVisableParkingAttBtn
    },
    () => {
      this.isButtonVisable('parkingAttForm')
    }
  )
}

parkingformSubmit() {
  let { selecteditem } = this.state
  if (selecteditem) {
    let attributes = selecteditem.attributes
    attributes = {
      ...attributes,
      'parking.minIdleTime': (
        this.state.unitParkingAttributesform['parking.minIdleTime'] *
        60 *
        1000
      ).toString(),
      'parking.maxIdleSpeed': (
        this.state.unitParkingAttributesform['parking.maxIdleSpeed'] / 1.852
      ).toString()
    }
    selecteditem.attributes = attributes
    this.setState(
      {
        selecteditem
      },
      () => this.formSubmit()
    )
  }
}

getCurrentUser = id => {
  if (source) {
    source.cancel()
  }
  source = CancelToken.source()

  if (id) {
    // for dashboard
    if (checkPrivileges('dashboard')) {
      instance({
        url: `/api/dashboards`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id,
          all: true,
          limit: -1
        }
      })
        .then(dashboard => {
          this.setState({
            mulitDashboardsSelect: dashboard,
            isDashboardRecived: true
          })
          let allData = this.props.dashboards
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (dashboard && dashboard.length) {
              dashboard.map(main => {
                if (item.id && main.id && item.id === main.id) {
                  item.check = true
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(dashboardDeviceWiseSelectedList(allData))
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for widget
    if (this.props.widgets.length) {
      // fetch(`/api/dashboards/gadgets?userId=${id}&all=true&limit=-1`, {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/dashboards/gadgets`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id,
          all: true,
          limit: -1
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then(widget => {
          this.setState({
            mulitWidgetsSelect: widget.data,
            isWidgetRecived: true
          })
          let allData = this.props.widgets
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (widget.data && widget.data.length) {
              widget.data.map(main => {
                if (item.id && main.id && item.id === main.id) {
                  item.check = true
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(widgetDeviceWiseSelectedList(allData))
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for driver
    if (checkPrivileges('driver')) {
      instance({
        url: `/api/drivers/linked`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(driver => {
          this.setState({
            mulitDriversSelect: driver,
            isDriverRecived: true
          })

          let allData = this.props.drivers
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (driver && driver.length) {
              driver.map(main => {
                if (item.id && main.entityId && item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(driverDeviceWiseSelectedList(allData))
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for trailer
    if (checkPrivileges('binder')) {
      instance({
        url: `/api/binders/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(trailer => {
          // if(trailer.length) {
          this.setState({
            multiTrailersSelect: trailer,
            isTrailerRecived: true
          })

          let allData = this.props.trailers
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (trailer.length) {
              trailer.map(main => {
                if (item.id && main.entityId && item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(trailerDeviceWiseSelectedList(allData))
          // }
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for garage
    // if (checkPrivileges('garage')) {
    //   instance({
    //     url: `/api/garages/linked`,
    //     method: 'GET',
    //     cancelToken: source.token,
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     params:{
    //       userId: id
    //     }
    //   })
    // .then(garage => {
    //     this.setState({
    //       multigaragesSelect: garage,
    //       isgarageRecived: true
    //     })

    //     let allData = this.props.garages&&this.props.garages.data
    //     allData.map(item => {
    //       item.check = item.parent = parseInt(id) === item.parentId
    //       item.indeterminate = false
    //       if (garage.length) {
    //         garage.map(main => {
    //           if (item.id && main.entityId && item.id === main.entityId) {
    //             item.indeterminate = main.directAccess === false
    //             item.check = main.directAccess
    //             item.groupAccess = main.groupAccess
    //           }
    //           return null
    //         })
    //         return null
    //       }
    //     })
    //       this.props.dispatch(garageUsersWiseSelectedList(allData))
    //     }).catch(error => {
    //       // errorHandler(error, this.props.dispatch)
    //     })

    //   }

    // for area
    if (checkPrivileges('area')) {
      instance({
        url: `/api/areas/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(area => {
          this.setState({
            multiareasSelect: area,
            isareaRecived: true
          })
          let allData = this.props.areas && this.props.areas.data
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (area.length) {
              area.map(main => {
                if (item.id && main.entityId && item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(areaUsersWiseSelectedList(allData))
        }).catch(error => { })
    }


    // if (checkPrivileges('service')) {
    //   instance({
    //     url: `/api/expensetypes/linked`,
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     params:{
    //       userId: id,
    //       // userId: this.props.logInUsers.id
    //     }
    //   })
    // .then(service => {
    //     this.setState({
    //       multiserviceSelect: service,
    //       isserviceRecived: true
    //     })
    //     let allData = this.props.services&&this.props.services
    //     allData.map(item => {
    //       item.check = item.parent = parseInt(id) === item.parentId
    //       item.indeterminate = false
    //       if (service.length) {
    //         service.map(main => {
    //           if (item.id && main.entityId && item.id === main.entityId) {
    //             item.indeterminate = main.directAccess === false
    //             item.check = main.directAccess
    //             item.groupAccess = main.groupAccess
    //           }
    //           return null
    //         })
    //         return null
    //       }
    //     })
    //       this.props.dispatch(serviceUsersWiseSelectedList(allData))
    //     }).catch(error => {})
    //   }
    //service removed services are now contract based

    //for vehicles


    // for users
    if (checkPrivileges('user')) {
      instance({
        url: `/api/users/get`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id,
          all: true,
          limit: -1
        }
      })
        .then(users => {
          this.setState({
            mulitUsersSelect: users,
            isUsersRecived: true
          })
          this.props.dispatch(getUserUsereWise(users.data))
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for geofences
    if (checkPrivileges('geofence')) {
      instance({
        url: `/api/geofences/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(geofences => {
          this.setState({
            multiGeofencesSelect: geofences,
            isGroRecived: true
          })

          let allData = this.props.geofences
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (geofences.length) {
              geofences.map(main => {
                if (item.id && main.entityId && item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(geofenceDeviceWiseSelectedList(allData))
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    if (checkPrivileges('group')) {
      instance({
        url: `/api/groups`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(groups => {
          this.setState({
            multiGroupsSelect: groups,
            isGroRecived: true
          })

          let allData = this.props.groups

          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            if (groups.length) {
              groups.map(main => {
                if (item.id && main.id && item.id === main.id) {
                  item.check = true
                  main.check = true
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(groupDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    if (checkPrivileges('device')) {
      instance({
        url: `/api/devices/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id,
          all: true,
          limit: -1
        }
      })

        .then(res => {
          if (res) {
            this.setState({
              multiDevicesSelect: res,
              isGroRecived: true
            })

            let allData = this.props.devices2

            allData.map(item => {
              item.indeterminate = false
              item.check = false
              item.groupAccess = false
              item.check = item.parent = parseInt(id) === item.parentId
              if (res.length) {
                res.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = !main.directAccess
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            this.props.dispatch(deviceUserWiseSelectedList(allData))
          }
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    if (checkPrivileges('rptmpl')) {

      instance({
        url: `/api/report/templates`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id,
          all: true,
          limit: -1
        }
      })

        .then(res => {
          if (res) {
            this.setState({
              multiDevicesSelect: res,
              isGroRecived: true
            })

            const allData = this.props.templates.map(item => {
              const data = { ...item.rpTmpl }
              data.check = data.parent = parseInt(id) === item.rpTmpl.parentId
              if (res.length) {
                res.map(main => {
                  if (item.rpTmpl.id && main.rpTmpl.id && item.rpTmpl.id === main.rpTmpl.id) {
                    data.directAccess = main.rpTmpl.directAccess
                    data.indeterminate = main.rpTmpl.directAccess === false
                    data.check = main.rpTmpl.directAccess
                  }
                  return null
                })
              }
              return data
            })
            this.props.dispatch(templateUserWiseSelectedList(allData))
          }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for Notification
    if (checkPrivileges('notification')) {
      instance({
        url: `/api/notifications`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(notifications => {
          let allData = this.props.notification
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            if (notifications.length) {
              notifications.map(main => {
                if (item.id && main.id && item.id === main.id) {
                  item.check = true
                  main.check = true
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(getNotificationDeviceWise(notifications))
          this.props.dispatch(notificationDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for ComputedAttributes
    if (checkPrivileges('attribute')) {
      instance({
        url: `/api/attributes/computed/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(attributes => {
          let allData = this.props.computedAttributes
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (attributes && attributes.length) {
              attributes.map(main => {
                if (item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          this.props.dispatch(
            computedAttributeDeviceWiseSelectedList(allData)
          )
          this.setState({
            isComputedAttributesRecived: true
          })
          instance({
            method: 'GET',
            url: `/api/attributes/computed/get?userId=${this.props.logInUser.id}&category=elogic&all=true&page=1&limit=-1`,
          })
            .then(response => {
              // if(response && response.status === 200) {
              this.props.dispatch(getComputedAttributes(response.data))
              // }
            }).catch(error => {
              // errorHandler(error, this.props.dispatch)
            })
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for SavedCommands
    if (checkPrivileges('command')) {
      instance({
        url: `/api/commands/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(commands => {
          let allData = this.props.commands
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            item.indeterminate = false
            if (commands && commands.length) {
              commands.map(main => {
                if (item.id === main.entityId) {
                  item.indeterminate = main.directAccess === false
                  item.check = main.directAccess
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          instance({
            method: 'GET',
            url: `/api/commands/get?userId=${this.props.logInUser.id}&limit=${-1}&all=${true}`,
          })
            .then(response => {
              // if(response && response.status === 200) {
              this.props.dispatch(getCommands(response.data))
              // }
            }).catch(error => {
              // errorHandler(error, this.props.dispatch)
            })

          this.props.dispatch(commandsDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }

    // for Maintenance
    if (checkPrivileges('maintenance')) {
      instance({
        url: `/api/maintenance/linked`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(maintenance => {
          let allData = this.props.maintenance
          allData.map(item => {
            item.check = item.parent = parseInt(id) === item.parentId
            if (maintenance.length) {
              maintenance.map(main => {
                if (item.id === main.entityId) {
                  item.check = true
                  main.check = true
                  item.groupAccess = main.groupAccess
                }
                return null
              })
              return null
            }
          })
          instance({
            method: 'GET',
            url: `/api/maintenance/get?userId=${this.props.logInUser.id}&limit=${-1}&all=${true}`,
          })
            .then(response => {
              // if(response && response.status === 200) {
              this.props.dispatch(getMaintenance(response.data))
              // }
            }).catch(error => {
              // errorHandler(error, this.props.dispatch)
            })
          this.props.dispatch(getMaintenanceDeviceWise(maintenance))
          this.props.dispatch(maintenanceDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }
  }
  this.setState({
    gOneform: {},
    aOneform: {},
    resFormData: {},
    mulitDriversSelect: '',
    mulitDashboardsSelect: '',
    mulitWidgetsSelect: '',
    mulitTrailersSelect: '',
    mulitUsersSelect: '',
    multiGeofencesSelect: '',
    isDriverRecived: false,
    isDashboardRecived: false,
    isWidgetRecived: false,
    isTrailerRecived: false,
    // isgarageRecived: false,
    isareaRecived: false,
    isVehicleRecived: false,
    isGroRecived: false,
    unitParkingAttributesform: {},
    isHaveAccess: 'attributeCreate'
  })

  let user = ''
  user = this.props.users.filter(dev => {
    return parseInt(dev.id) === parseInt(id)
  })
  if (user[0]) {
    let { name, uniqueId, groupId, phone, attributes } = user[0]

    let { category } = user[0]
    let f_3 = { category }

    let att = []

    if (attributes) {
      att = Object.keys(attributes).reduce((array, key) => {
        return [...array, [key, attributes[key]]]
      }, [])
    }

    let devicePassword = ''
    let ptime = { 'parking.minIdleTime': 0 },
      pspeed = { 'parking.maxIdleSpeed': 0 }
    att.map(item => {
      // if (item[0] === 'devicePassword') {
      //   devicePassword = item[1]
      // }
      if (item[0] === 'parking.minIdleTime') {
        ptime['parking.minIdleTime'] = item[1] / 60000
      }
      if (item[0] === 'parking.maxIdleSpeed') {
        pspeed['parking.maxIdleSpeed'] = Math.round(item[1] * 1.852)
      }
      return null
    })

    let f_1 = { name, uniqueId, groupId, phone, devicePassword }

    this.setState({
      gOneform: { ...f_1 },
      aOneform: { ...f_3 },
      unitParkingAttributesform: {
        ...this.state.unitParkingAttributesform,
        ...pspeed,
        ...ptime
      },
      resFormData: { ...user[0] }
    })
  }

  return { user }
}

componentWillReceiveProps(n) {
  if (n.id) {
    this.setState({ viewScreen: 'edit' })

    if (n.hash !== this.props.hash) {
      let hash = locations.indexOf(n.hash)
      if (hash !== -1) {
        this.setState({ currentTab: n.hash.substring(1, n.hash.length) })
      }
    }

    if (
      n.id &&
      parseInt(this.state.currentId) !== parseInt(n.id) &&
      n.users.length
    ) {
      this.setState(
        {
          currentId: parseInt(n.id),
          userFilesData: [],
        },
        () => {
          this.getCurrentUser(n.id)
          this.getUserFilesData(n.id)
          this.fetchAllVehicles(n.logInUser.id)
          n.users.map(user => {
            if (parseInt(user.id) === parseInt(this.state.currentId)) {
              this.setState({ selecteditem: user })
            }
            return null
          })
        }
      )
    }
  }

  if (n.addUserScreen) {
    this.setState({ viewScreen: 'add' })
  }
}

selectDriver = item => {
  this.setState({
    driverObj: item[0],
    activeOperation: 'edit',
    openDriverModal: true
  })
}

selectAllVehicles = e => {
  this.setState({
    selectAllVehicles: e.target.checked,
    // vehicleLoader:true,
  })
}

handleErrorCallback = () => {
  this.setState({
    selectAllVehicles: false,
  });
};

handleLoaderCallback = (e) => {
  this.setState({
    vehicleLoader: false,
  });
};


selectAllHandler = (e) => {
  const { logInUser, dispatch, translate } = this.props;
  const { resFormData, allvehicle, selectAllVehicles } = this.state;

  // this.setState({ vehicleLoader: true });

  selectAll(
    e.target.checked,
    'userId',
    logInUser.id,
    resFormData.id,
    allvehicle?.data || [],
    logInUser.id,
    dispatch,
    'vehicle',
    translate,
    this.handleErrorCallback, // Pass handleErrorCallback as a callback
    this.handleLoaderCallback
  );

  this.selectAllVehicles(e);
};

render() {
  const { currentTab, selecteditem } = this.state
  const { classes } = this.props

  const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vartical'

  let selecteduser = [...this.props.selecteduser]

  selecteduser.map(u => {
    if (this.props.roles.length) {
      this.props.roles.map(r => {
        if (r.id === u.roleId) {
          u.roleName = r.name
        }
        return null
      })
    }
    return null
  })

  const selecteddevices = [...this.props.selecteddevices]

  selecteddevices.map(n => {
    // const dev = n.id
    n.parent = n.parentId === parseInt(this.props.id)
    return null
  })

  return (
    <Fragment>
      <h4 className='tab-title'>
        {this.state.resFormData ? this.state.resFormData.name : 'User'}
      </h4>
      <div className={classes.root + ' main-unit-modal-bg'}>
        {this.state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeDevice}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.slectedUnits.name}
          />
        )}
        {this.state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeNotification}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'notification.' + this.state.notificationForm.type
            )}
          />
        )}
        {this.state.onDeleteConfirmationGroup && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeGroups}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.groupForm.name}
          />
        )}
        {this.state.onDeleteConfirmationDriver && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeDriver}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.driverForm.name}
          />
        )}
        {this.state.onDeleteConfirmationTrailer && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeTrailer}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.trailerForm.name}
          />
        )}
        {/* {this.state.onDeleteConfirmationGarage && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeGarage}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.garageForm.name}
            />
          )} */}
        {this.state.onDeleteConfirmationDashboard && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeDashboard}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.dashboardForm.name}
          />
        )}
        {this.state.onDeleteConfirmationWidget && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeWidget}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.widgetForm.name}
          />
        )}
        {/* start vehicle */}
        {this.state.onDeleteConfirmationVehicle && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeVehicle}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.vehicleForm.name}
          />
        )}
        {/* end vehicle */}
        {this.state.onDeleteConfirmationGeofence && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeGeofence}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.geofenceForm.name}
          />
        )}
        {this.state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeComputedAttributes}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              this.state.computedAttForm.description
            )}
          />
        )}
        {this.state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeCommands}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'commands.' + this.state.savedCommandsForm.type
            )}
          />
        )}
        {this.state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeMaintenance}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'positiont.' + this.state.maintenanceForm.type
            )}
          />
        )}
        {this.state.onDeleteConfirmationUser && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeUser}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.userForm.name}
          />
        )}
        {this.state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeDevice}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.slectedUnits.name}
          />
        )}
        {this.state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeNotification}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'notification.' + this.state.notificationForm.type
            )}
          />
        )}
        {this.state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeComputedAttributes}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              this.state.computedAttForm.description
            )}
          />
        )}
        {this.state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeCommands}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'commands.' + this.state.savedCommandsForm.type
            )}
          />
        )}
        {this.state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeMaintenance}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.translate(
              'positiont.' + this.state.maintenanceForm.type
            )}
          />
        )}
        <VerticalTabs
          orientation={orientation}
          variant='scrollable'
          scrollButtons='auto'
          value={currentTab}
          onChange={this.tabChange}
          aria-label='Vertical tabs example'
          className='custom-tabs'
        >
          <CTab
            value='user'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <Tooltip title={this.props.translate('basic')}>
                <span className='menu_item_text'>
                  <BasicIcon fill="currentColor" className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{this.props.translate('basic')}</span>
                </span>
              </Tooltip>
            }
            {...a11yProps(0)}
          />
          <CTab
            value='changePassword'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <Tooltip title={this.props.translate('changePassword')}>
                <span className='menu_item_text'>
                  <LockIcon fill="currentColor" className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{this.props.translate('changePassword')}</span>
                </span>
              </Tooltip>
            }
            {...a11yProps(12)}
          />
          {checkPrivileges('dashboard') && (
            <CTab
              value='dashboard'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('dashboard')}>
                  <span className='menu_item_text'>
                    <DashboardIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('dashboard')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(12)}
            />
          )}
          {checkPrivileges('dashboard') && this.props.widgets.length && (
            <CTab
              value='widget'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('widget')}>
                  <span className='menu_item_text'>
                    <WidgetIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('widget')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(13)}
            />
          )}
          {checkPrivileges('device') && (
            <CTab
              value='device'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sensorUnits')}>
                  <span className='menu_item_text'>
                    <UnitIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sensorUnits')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(1)}
            />
          )}
          {checkPrivileges('group') && (
            <CTab
              value='group'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('resources')}>
                  <span className='menu_item_text'>
                    <ResourceIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('resources')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(2)}
            />
          )}
          {checkPrivileges('driver') && (
            <CTab
              value='driver'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedDrivers')}>
                  <span className='menu_item_text'>
                    <StaffIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedDrivers')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(3)}
            />
          )}

          {checkPrivileges('binder') && (
            <CTab
              value='binder'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedBinder')}>
                  <span className='menu_item_text'>
                    <BinderIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedBinder')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(9)}
            />
          )}

          {checkPrivileges('rptmpl') && (
            <CTab
              value='template'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('templates')}>
                  <span className='menu_item_text'>
                    <TemplateIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('templates')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(11)}
            />
          )}

          {checkPrivileges('vehicle') && (
            <CTab
              value='vehicle'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedVehicle')}>
                  <span className='menu_item_text'>
                    <VehicleIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedVehicle')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(10)}
            />
          )}
          {/* {checkPrivileges('garage') && (
              <CTab
                value='garage'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <Tooltip title={this.props.translate('sharedGarage')}>
                  <span className='menu_item_text'>
                    <GarageIcon fill="currentColor" className='tab_menu_icon'/>
                    <span className='menu_item_text_inner'>{this.props.translate('sharedGarage')}</span>
                  </span>
                  </Tooltip>
                }
                {...a11yProps(14)}
              />
            )} */}
          {checkPrivileges('area') && (
            <CTab
              value='area'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedArea')}>
                  <span className='menu_item_text'>
                    <AreaIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedArea')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(15)}
            />
          )}

          {/* {checkPrivileges('service') && (
              <CTab
                value='service'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    <ServiceIcon fill="currentColor" className='tab_menu_icon'/>
                    <span className='menu_item_text_inner'>{this.props.translate('service')}</span>
                  </span>
                }
                {...a11yProps('service')}
              />
            )} */}
          {/* service hide service are now contract based */}

          {checkPrivileges('user') && (
            <CTab
              value='users'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('access')}>
                  <span className='menu_item_text'>
                    <AccessIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('access')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(3)}
            />
          )}
          {checkPrivileges('geofence') && (
            <CTab
              value='geofence'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedGeofences')}>
                  <span className='menu_item_text'>
                    <GeofenceIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedGeofences')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(4)}
            />
          )}
          {/* temprary hide for release 3.2.1 */}
          {/* {checkPrivileges('notification') && (
              <CTab
                value='notification'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedNotifications')}{' '}
                    <ArrowRightIcon className='tab_menu_icon'/>{' '}
                  </span>
                }
                {...a11yProps(5)}
              />
            )} */}
          {checkPrivileges('attribute') && (
            <CTab
              value='attribute'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedComputedAttributes')}>
                  <span className='menu_item_text'>
                    <ElogicIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedComputedAttributes')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(6)}
            />
          )}
          {checkPrivileges('command') && (
            <CTab
              value='command'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedSavedCommand')}>
                  <span className='menu_item_text'>
                    <CommandIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedSavedCommand')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(7)}
            />
          )}
          {checkPrivileges('maintenance') && (
            <CTab
              value='maintenance'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <Tooltip title={this.props.translate('sharedMaintenance')}>
                  <span className='menu_item_text'>
                    <MaintenanceIcon fill="currentColor" className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{this.props.translate('sharedMaintenance')}</span>
                  </span>
                </Tooltip>
              }
              {...a11yProps(8)}
            />
          )}
        </VerticalTabs>
        {currentTab === 'user' && (
          <TabPanel
            value={currentTab}
            index={'user'}
            style={{ width: '100%' }}
          >
            {selecteditem && (
              <UsersModal
                {...this.props}
                viewScreen={this.state.viewScreen}
                onCloseModal={this.onCloseModal}
                activeOperation={'edit'}
                selecteditem={this.state.resFormData}
                getCurrentUser={this.getCurrentUser}
                fetchUserLimit={this.props.extra.fetchUserLimit}
                getUserFilesData={this.getUserFilesData}
                userFilesData={this.state.userFilesData}
                removeUserFile={this.removeUserFile}
              />
            )}
          </TabPanel>
        )}
        {currentTab === 'changePassword' && (
          <TabPanel
            value={currentTab}
            index={'changePassword'}
            style={{ width: '100%' }}
          >
            {selecteditem && (
              <UsersModal
                {...this.props}
                viewScreen={this.state.viewScreen}
                onCloseModal={this.onCloseModal}
                activeOperation={'changePassword'}
                selecteditem={this.state.resFormData}
                getCurrentUser={this.getCurrentUser}
                fetchUserLimit={this.props.extra.fetchUserLimit}
                getUserFilesData={this.getUserFilesData}
                userFilesData={this.state.userFilesData}
                removeUserFile={this.removeUserFile}
              />
            )}
          </TabPanel>
        )}
        {checkPrivileges('dashboard') && currentTab === 'dashboard' && (
          <TabPanel
            value={currentTab}
            index={'dashboard'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}
              component_Selection={this.dashboard_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selecteddashboard}
              showCheckbox={true}
              onDelete={this.onRemovedDashboard}
              label={this.props.translate('dashboard')}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              createButton={
                checkPrivileges('dashboardCreate') && (
                  <div style={{ marginLeft: 10 }}>
                    <Button onClick={() => this.onCreateNewDashboard()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  </div>
                )
              }
              onEdit={this.editDashboard}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'dashboardId',
                  this.props.id,
                  this.props.selecteddashboard,
                  this.props.dashboards,
                  this.props.dispatch,
                  'dashboard',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('dashboard')
                }
              ]}
              canDelete={checkPrivileges('dashboardDelete')}
              canUpdate={checkPrivileges('dashboardUpdate')}
              canRemove={checkPrivileges('userUnlinkDashboard')}
              canAssign={checkPrivileges('userLinkDashboard')}
            />
          </TabPanel>
        )}
        {checkPrivileges('dashboard') && this.props.widgets.length && currentTab === 'widget' ? (
          <TabPanel
            value={currentTab}
            index={'widget'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}
              component_Selection={this.widget_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selectedwidget}
              showCheckbox={true}
              onDelete={this.onRemovedWidget}
              label={this.props.translate('widget')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'gadgetId',
                  this.props.id,
                  this.props.selectedwidget,
                  this.props.widgets,
                  this.props.dispatch,
                  'widget',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('widget')
                }
              ]}
              canRemove={checkPrivileges('userUnlinkGadget')}
              canAssign={checkPrivileges('userLinkGadget')}
            />
          </TabPanel>
        ) : null}
        {checkPrivileges('device') && currentTab === 'device' && (
          <TabPanel
            value={currentTab}
            index={'device'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={this.unit_Selection}
              themecolors={this.props.themecolors}
              componentData={selecteddevices}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              translate={this.props.translate}

              showCheckbox
              showGroupAccess
              onDelete={this.onRemovedDevice}
              label={this.props.translate('sensorUnits')}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              canUpdate={false}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sensorUnits')
                }
              ]}
              canDelete={checkPrivileges('deviceDelete')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              canAssign={checkPrivileges('userLinkDevice')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'deviceId',
                  this.props.id,
                  this.props.selecteddevices,
                  this.props.devices2,
                  this.props.dispatch,
                  'device',
                  this.props.translate
                )
              }
            />
          </TabPanel>
        )}
        {checkPrivileges('group') && currentTab === 'group' && (
          <TabPanel
            value={currentTab}
            index={'group'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}

              component_Selection={this.group_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selectedgroups}
              showCheckbox
              onDelete={this.onRemovedGroup}
              label={this.props.translate('resources')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('resources')
                }
              ]}
              canDelete={checkPrivileges('groupDelete')}
              canRemove={checkPrivileges('userUnlinkGroup')}
              canAssign={checkPrivileges('userLinkGroup')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'groupId',
                  this.props.id,
                  this.props.selectedgroups,
                  this.props.groups,
                  this.props.dispatch,
                  'group',
                  this.props.translate
                )
              }
            />
          </TabPanel>
        )}
        {checkPrivileges('driver') && currentTab === 'driver' && (
          <TabPanel
            value={currentTab}
            index={'driver'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}

              component_Selection={this.driver_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selecteddriver}
              showCheckbox={true}
              showGroupAccess
              onDelete={this.onRemovedDriver}
              label={this.props.translate('sharedDrivers')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'driverId',
                  this.props.id,
                  this.props.selecteddriver,
                  this.props.drivers,
                  this.props.dispatch,
                  'driver',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedDrivers')
                }
              ]}
              canDelete={checkPrivileges('driverDelete')}
              canRemove={checkPrivileges('userUnlinkDriver')}
              canAssign={checkPrivileges('userLinkDriver')}
            />
          </TabPanel>
        )}
        {checkPrivileges('binder') && currentTab === 'binder' && (
          <TabPanel
            value={currentTab}
            index={'binder'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}

              component_Selection={this.trailer_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selectedtrailer}
              showCheckbox={true}
              showGroupAccess
              onDelete={this.onRemovedTrailer}
              label={this.props.translate('sharedBinder')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'binderId',
                  this.props.id,
                  this.props.selectedtrailer,
                  this.props.trailers,
                  this.props.dispatch,
                  'binder',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedBinder')
                }
              ]}
              canDelete={checkPrivileges('binderDelete')}
              canRemove={checkPrivileges('userUnlinkBinder')}
              canAssign={checkPrivileges('userLinkBinder')}
            />
          </TabPanel>
        )}

        {checkPrivileges('rptmpl') && currentTab === 'template' && (
          <TabPanel
            value={currentTab}
            index={'template'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}

              component_Selection={this.template_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selectedtemplates}
              showCheckbox={true}
              onDelete={this.onRemovedTemplate}
              label={this.props.translate('templates')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'rpTmplId',
                  this.props.id,
                  this.props.selectedtemplates,
                  this.props.templates,
                  this.props.dispatch,
                  'template',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('reportTemplate')
                }
              ]}
              canDelete={checkPrivileges('templateDelete')}
              canRemove={checkPrivileges('userUnlinkRpTmpl')}
              canAssign={checkPrivileges('userLinkRpTmpl')}
            />
          </TabPanel>
        )}

        {checkPrivileges('vehicle') && currentTab === 'vehicle' && (
          <TabPanel
            value={currentTab}
            index={'vehicle'}
            style={{ width: '100%' }}
          >
            <div style={{
               display:"flex",
               justifyContent:"space-between",
               alignItems:"center",
               padding : "2px 8px 6px 0px"
            }}>
              <h4
                className='v-tab-heading pull-left with-border'
                style={{ marginTop: 8, marginBottom: 4 }}
              >
                {this.props.translate('sharedVehicle')}
              </h4>
              <div >
                 <TextField 
                 value={this.state.vehicleSearch || ""} 
                 onChange={this.handleChangeVehicle}
                 placeholder= {this.props.translate("searchVehicles")}
                 />
            </div>
            </div>
            {!this.state.vehicleLoader ?
              <TableMultiCheckBoxUsers
                onClick={this.vehicle_Selection}
                rows={this.state.allvehicle && this.state.allvehicle.data || []}
                pagination={this.setPagination(this.state.allvehicle)}
                handleChangeRowsPerPage={this.handleChangeRowsPerPageVehicle}
                handleChangePage={this.handleChangePageVehicle}
                SearchItem={this.SearchItem}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                themecolors={this.props.themecolors}
                isRowIcons={false}
                searchable
                isCursoPointer
                filterDropdown
                setSerialNo
                showCheckbox={true}
                showGroupAccess
                onDelete={this.onRemovedVehicle}
                translate={this.props.translate}
                hasAccessOfDelete={checkPrivileges('userDelete')}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                userVehicles
                selectAll={this.selectAllHandler}
                // selectAll={e =>
                //      { selectAll(
                //         e.target.checked,
                //         'userId',
                //         this.props?.logInUser?.id,
                //         this.state.resFormData.id,
                //         this.state.allvehicle?.data || [],
                //         this.props?.logInUser?.id,
                //         this.props.dispatch,
                //         'vehicle',
                //         this.props.translate,
                //         () => {
                //           // Call fetchAllVehicles here
                //           this.handleErrorCallback();
                //         }
                //       ); 
                //       this.selectAllVehicles(e)
                //     }
                //     }
                selectAllVehicles={this.state.selectAllVehicles}
                handleErrorCallback={this.handleErrorCallback}
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedVehicle')
                  },
                  {
                    id: 'category',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('vehicleType')
                  },
                  {
                    id: 'vehicleLicensePlate',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('vehicleLicensePlate')
                  },
                  {
                    id: 'attributes.make',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('make')
                  },
                  {
                    id: 'vin',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('vin')
                  },
                ]}
              />
              : <Loader />}
          </TabPanel>
        )}
        {/* vahicles end */}
        {/* Garage start */}
        {/* {checkPrivileges('garage') && currentTab === 'garage' && (
              <TabPanel
                value={currentTab}
                index={'garage'}
                style={{ width: '100%' }}
              >

                <CommonTableSection
                  component_Selection={this.garage_Selection}
                  themecolors={this.props.themecolors}
                  componentData={this.props.garages?.data || []}
                  showCheckbox={true}
                  showGroupAccess
                  onDelete={this.onRemovedGarage}
                  label={this.props.translate('sharedGarage')}
                  canUpdate={false}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  parent={
                    selecteditem && selecteditem.id
                      ? selecteditem.id
                      : 0
                  }
                  selectAll={e =>
                    selectAll(
                      e,
                      'userId',
                      'garageId',
                      this.props.id,
                      this.props.selectedGarages,
                      this.props.garages?.data || [],
                      this.props.dispatch,
                      'garage',
                      this.props.translate
                    )
                  }
                  rowDefinition={[
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate('sharedGarage')
                    }
                  ]}
                  canDelete={checkPrivileges('garageDelete')}
                  canRemove={checkPrivileges('userUnlinkGarage')}
                  canAssign={checkPrivileges('userLinkGarage')}
                />
              </TabPanel>
            )} */}
        {/* Garage end */}
        {/* Area start */}
        {checkPrivileges('area') && currentTab === 'area' && (
          <TabPanel
            value={currentTab}
            index={'area'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
                translate={this.props.translate}

              component_Selection={this.area_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.areas?.data || []}
              showCheckbox={true}
              label={this.props.translate('sharedArea')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'areaId',
                  this.props.id,
                  this.props.selectedAreas,
                  this.props.selectedAreas || [],
                  this.props.dispatch,
                  'area',
                  this.props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedArea')
                }
              ]}
              // canDelete={checkPrivileges('areaDelete')}
              canRemove={checkPrivileges('userUnlinkArea')}
              canAssign={checkPrivileges('userLinkArea')}
            />
          </TabPanel>
        )}
        {/* Area end */}

        {/* Service hide because services are now contract based */}
        {/* {checkPrivileges('service') && currentTab === 'service' && (
              <TabPanel
              value={currentTab}
              index={'service'}
              style={{ width: '100%' }}
            >
              <CommonTableSection
                component_Selection={this.service_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.services || []}
                showCheckbox={true}
                label={this.props.translate('service')}
                canUpdate={false}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                selectAll={e =>
                  selectAll(
                    e,
                    'userId',
                    'expenseTypeId',
                    this.props.id,
                    this.props.selectedServices,
                    this.props.selectedServices || [],
                    this.props.dispatch,
                    'service',
                    this.props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('service')
                  }
                ]}
                // canDelete={checkPrivileges('areaDelete')}
                canRemove={checkPrivileges('userUnlinkExpenseType')}
                canAssign={checkPrivileges('userLinkExpenseType')}
              />
            </TabPanel>
            )} */}

        {checkPrivileges('user') && currentTab === 'users' && (
          <TabPanel
            value={currentTab}
            index={'users'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              //component_Selection={this.geofence_Selection}
              translate={this.props.translate}

              themecolors={this.props.themecolors}
              componentData={selecteduser}
              showCheckbox={false}
              onDelete={this.onRemovedUser}
              label={this.props.translate('access')}
              canUpdate={false}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                {
                  id: 'email',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('userEmail')
                },
                {
                  id: 'roleName',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('roleName')
                },
                {
                  id: 'parentName',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('parentName')
                }
              ]}
              canDelete={checkPrivileges('userDelete')}
            />
          </TabPanel>
        )}
        {checkPrivileges('geofence') && currentTab === 'geofence' && (
          <TabPanel
            value={currentTab}
            index={'geofence'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              translate={this.props.translate}
              component_Selection={this.geofence_Selection}
              themecolors={this.props.themecolors}
              componentData={this.props.selectedgeofence}
              showCheckbox={true}
              showGroupAccess
              onDelete={this.onRemovedGeofence}
              label={this.props.translate('sharedGeofences')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'geofenceId',
                  this.props.id,
                  this.props.selectedgeofence,
                  this.props.geofences,
                  this.props.dispatch,
                  'geofence',
                  this.props.translate
                )
              }
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedGeofences')
                }
              ]}
              canDelete={checkPrivileges('geofenceDelete')}
              canRemove={checkPrivileges('userUnlinkGeofence')}
              canAssign={checkPrivileges('userLinkGeofence')}
            />
          </TabPanel>
        )}
        {checkPrivileges('notification') && currentTab === 'notification' && (
          <TabPanel
            value={currentTab}
            index={'notification'}
            style={{ width: '100%' }}
          >
            <Notification
              onListNotification={this.onListNotification}
              onCloseC_Modal={this.onCloseC_Modal}
              onCreateNewNotification={this.onCreateNewNotification}
              notification_Selection={this.notification_Selection}
              onCloseC_Att_Modal={this.onCloseC_Att_Modal}
              addNotification={this.addNotification}
              notification_handleChange={this.notification_handleChange}
              notification_handleChange2={this.notification_handleChange2}
              notificationHandle={this.notificationHandle}
              calendars={this.props.calendars}
              notificationDeviceWise={this.props.notificationDeviceWise}
              themecolors={this.props.themecolors}
              notificationData={this.props.selectednotification}
              visableCreateNotification={this.state.visableNotification}
              notificationForm={this.state.notificationForm}
              notificationChannel={this.props.notificationChannel}
              notificationType={this.props.notificationType}
              translate={this.props.translate}
              onEdit={this.editNotification}
              onDelete={this.onRemovedNotification}
              addButtonStatus={this.state.addButtonStatus}
              addAttributes={this.addNotificationAttributes}
              isAddVisableBtn={this.state.isAddVisableBtn}
              isHaveAccess={this.state.isHaveAccess}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'notificationId',
                  this.props.id,
                  this.props.selectednotification,
                  this.props.notification,
                  this.props.dispatch,
                  'notification',
                  this.props.translate
                )
              }
              canRemove={checkPrivileges('userUnlinkNotification')}
              canAssign={checkPrivileges('userLinkNotification')}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              attributeChangesMessage={this.state.attributeChangesMessage}
            />
          </TabPanel>
        )}
        {checkPrivileges('attribute') && currentTab === 'attribute' && (
          <TabPanel
            value={currentTab}
            index='attribute'
            style={{ width: '100%' }}
          >
            <ComputedAttributes
              {...this.props}
              onListComputedAttributes={this.onListComputedAttributes}
              translate={this.props.translate}
              onCloseC_Att_Modal={this.onCloseC_Att_Modal}
              onCloseC_Modal={this.onCloseC_Modal}
              themecolors={this.props.themecolors}
              computedAttributesDeviceWise={
                this.props.computedAttributesDeviceWise
              }
              
              onCreateNewAttributes={this.onCreateNewAttributes}
              C_Att_handleChange={this.C_Att_handleChange}
              attributeHandle={this.attributeHandle}
              addComputedAttributes={this.addComputedAttributes}
              computedAttributes_handleChange={
                this.computedAttributes_handleChange
              }
              visableAttribute={this.state.visableAttribute}
              visableListAttribute={this.state.visableListAttribute}
              computedAttributesData={this.props.selectedComputedAttributes}
              computedAttForm={this.state.computedAttForm}
              computedAttributes_Selection={this.computedAttributes_Selection}
              onEdit={this.editComputedAttributes}
              onDelete={this.onRemovedAttribute}
              addButtonStatus={this.state.addButtonStatus}
              isAddVisableBtn={this.state.isAddVisableBtn}
              isHaveAccess={this.state.isHaveAccess}
              showGroupAccess
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'attributeId',
                  this.props.id,
                  this.props.selectedComputedAttributes,
                  this.props.computedAttributes,
                  this.props.dispatch,
                  'attribute',
                  this.props.translate
                )
              }
              canRemove={checkPrivileges('userUnlinkAttribute')}
              canAssign={checkPrivileges('userLinkAttribute')}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
            />
          </TabPanel>
        )}
        {checkPrivileges('command') && currentTab === 'command' && (
          <TabPanel
            value={currentTab}
            index='command'
            style={{ width: '100%' }}
          >
            <SavedCommands
              onListSavedCommands={this.onListSavedCommands}
              translate={this.props.translate}
              onCloseC_Att_Modal={this.onCloseC_Att_Modal}
              onCloseC_Modal={this.onCloseC_Modal}
              themecolors={this.props.themecolors}
              commandsDeviceWise={this.props.commandsDeviceWise}
              onCreateNewSavedCommands={this.onCreateNewSavedCommands}
              visableAttribute={this.state.visableSavedCommands}
              addSavedCommands={this.addSavedCommands}
              savedCommands_handleChange={this.savedCommands_handleChange}
              savedCommandsHandle={this.savedCommandsHandle}
              visableListSavedCommands={this.state.visableListSavedCommands}
              visableSavedCommands={this.state.visableSavedCommands}
              commandsData={this.props.selectedcommands}
              savedCommandsForm={this.state.savedCommandsForm}
              savedCommands_Selection={this.savedCommands_Selection}
              commandsTypes={this.props.commandsTypes}
              onEdit={this.editCommands}
              onDelete={this.onRemovedCommand}
              addButtonStatus={this.state.addButtonStatus}
              isAddVisableBtn={this.state.isAddVisableBtn}
              isHaveAccess={this.state.isHaveAccess}
              showGroupAccess
              canRemove={checkPrivileges('userUnlinkCommand')}
              canAssign={checkPrivileges('userLinkCommand')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'commandId',
                  this.props.id,
                  this.props.selectedcommands,
                  this.props.commands,
                  this.props.dispatch,
                  'command',
                  this.props.translate
                )
              }
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
            />
          </TabPanel>
        )}
        {checkPrivileges('maintenance') && currentTab === 'maintenance' && (
          <TabPanel
            value={currentTab}
            index='maintenance'
            style={{ width: '100%', minWidth: 0 }}
          >
            <MaintenanceForm
              onListMaintenance={this.onListMaintenance}
              onCloseC_Modal={this.onCloseC_Modal}
              onCreateNewMaintenance={this.onCreateNewMaintenance}
              maintenance_Selection={this.maintenance_Selection}
              onCloseC_Att_Modal={this.onCloseC_Att_Modal}
              addMaintenance={this.addMaintenance}
              maintenance_handleChange={this.maintenance_handleChange}
              maintenance_handleChange_attr={
                this.maintenance_handleChange_attr
              }
              
              maintenancesHandle={this.maintenancesHandle}
              maintenanceDeviceWise={this.props.maintenanceDeviceWise}
              themecolors={this.props.themecolors}
              visableListMaintenance={this.state.visableListMaintenance}
              maintenanceData={this.props.selectedMaintenance}
              visableCreateMaintenance={this.state.visableMaintenance}
              maintenanceForm={this.state.maintenanceForm}
              translate={this.props.translate}
              onEdit={this.editMaintenance}
              onDelete={this.onRemovedMaintenance}
              addButtonStatus={this.state.addButtonStatus}
              addAttributes={this.addMaintenanceAttributes}
              isAddVisableBtn={this.state.isAddVisableBtn}
              isHaveAccess={this.state.isHaveAccess}
              showGroupAccess
              canRemove={checkPrivileges('userUnlinkMaintenance')}
              canAssign={checkPrivileges('userLinkMaintenance')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'maintenanceId',
                  this.props.id,
                  this.props.selectedMaintenance,
                  this.props.maintenance,
                  this.props.dispatch,
                  'maintenance',
                  this.props.translate
                )
              }
              attributeChangesMessage={this.state.attributeChangesMessage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              isAllowed
            />
          </TabPanel>
        )}
      </div>
    </Fragment>
  )
}
}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => ({
  logInUser: state.logInUsers,
  dashboards: state.dashboards?.data,
  widgets: state.widgets?.data,
  devices: state.devices.data,
  devices2: state.devices3,
  groups: state.groups,
  drivers: state.drivers,
  vehicles: state.vehicles,
  trailers: state.trailers,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selectedtrailer: state.selectedtrailer,
  selecteddashboard: state.selecteddashboard,
  selectedwidget: state.selectedwidget,
  selectedvehicle: state.selectedvehicle,
  selectedgroups: state.selectedgroups,
  selecteduser: state.selecteduseruser,
  selecteddevices: state.selecteddevices,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  selectedtemplates: state.selectedtemplates,
  // selectedGarages:state.selectedGarages,
  calendars: state.calendars,
  templates: state.templates,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
  // garages: state.garages,
  selectedAreas: state.selectedAreas,
  areas: state.areas,
  selectedServices: state.selectedServices,
  services: state.services
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(
  ((withStyles(styles)(withTranslationWrapper(MainUnitModal))))
)
