export const deviceAttributesTypes = [
  /* {
    key: 'web.reportColor',
    name: 'attributeWebReportColor',
    valueType: 'color',
    htmlTag: 'color'
}, */
  {
    key: 'devicePassword',
    name: 'attributeDevicePassword',
    valueType: 'string',
    htmlTag: 'input'
  },
  /* {
    key: 'processing.copyAttributes',
    name: 'attributeProcessingCopyAttributes',
    valueType: 'string',
    htmlTag: 'input'
}, */ {
    key: 'decoder.timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
  },
  {
    key: 'speedLimit',
    name: 'attributeSpeedLimit',
    valueType: 'number',
    dataType: 'speed',
    htmlTag: 'input'
  },
  {
    key: 'report.ignoreOdometer',
    name: 'attributeReportIgnoreOdometer',
    valueType: 'boolean',
    htmlTag: 'input'
  }
]

export const simpleAttributesTypes = [
  {
    key: 'simpleAttributes',
    valueType: 'text',
    htmlTag: 'input'
  }
]

export const userAttributesTypes = [
  {
    key: 'mail.smtp.host',
    name: 'attributeMailSmtpHost',
    valueType: 'string',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.port',
    name: 'attributeMailSmtpPort',
    valueType: 'number',
    allowDecimals: false,
    minValue: 1,
    maxValue: 65535,
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.starttls.enable',
    name: 'attributeMailSmtpStarttlsEnable',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.starttls.required',
    name: 'attributeMailSmtpStarttlsRequired',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.enable',
    name: 'attributeMailSmtpSslEnable',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.trust',
    name: 'attributeMailSmtpSslTrust',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.ssl.protocols',
    name: 'attributeMailSmtpSslProtocols',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.from',
    name: 'attributeMailSmtpFrom',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.auth',
    name: 'attributeMailSmtpAuth',
    valueType: 'boolean',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.username',
    name: 'attributeMailSmtpUsername',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'mail.smtp.password',
    name: 'attributeMailSmtpPassword',
    valueType: 'text',
    htmlTag: 'input'
  },
  {
    key: 'web.liveRouteLength',
    name: 'attributeWebLiveRouteLength',
    valueType: 'number',
    allowDecimals: false,
    htmlTag: 'input'
  },
  {
    key: 'web.selectZoom',
    name: 'attributeWebSelectZoom',
    valueType: 'number',
    allowDecimals: false,
    // minValue: 'Style.mapDefaultZoom',
    // maxValue: 'Style.mapMaxZoom',
    htmlTag: 'input'
  },
  {
    key: 'web.maxZoom',
    name: 'attributeWebMaxZoom',
    valueType: 'number',
    allowDecimals: false,
    //  minValue: 'Style.mapDefaultZoom',
    //   maxValue: 'Style.mapMaxZoom',
    htmlTag: 'input'
  },
  {
    key: 'distanceUnit',
    name: 'settingsDistanceUnit',
    valueType: 'string',
    dataType: 'distanceUnit',
    htmlTag: 'select'
  },
  {
    key: 'speedUnit',
    name: 'settingsSpeedUnit',
    valueType: 'string',
    dataType: 'speedUnit',
    htmlTag: 'select'
  },
  {
    key: 'volumeUnit',
    name: 'settingsVolumeUnit',
    valueType: 'string',
    dataType: 'volumeUnit',
    htmlTag: 'select'
  },
  {
    key: 'timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
  },
  {
    key: 'chassisNumber',
    name: 'chassisNumber',
    valueType: 'string'
  },
  {
    key: 'vin',
    name: 'vin',
    valueType: 'string'
  },
  {
    key: 'vehicleLicensePlate',
    name: 'vehicleLicensePlate',
    valueType: 'number'
  },
  {
    key: 'permittedSpeed',
    name: 'permittedSpeed',
    valueType: 'number'
  },
  {
    key: 'tag_1',
    name: 'tag_1',
    valueType: 'string'
  },
  {
    key: 'tag_2',
    name: 'tag_2',
    valueType: 'string'
  },
  {
    key: 'tag_3',
    name: 'tag_3',
    valueType: 'string'
  },
  {
    key: 'tag_4',
    name: 'tag_4',
    valueType: 'string'
  },
  {
    key: 'tag_5',
    name: 'tag_5',
    valueType: 'string'
  },
  {
    key: 'make',
    name: 'make',
    valueType: 'string'
  },
  {
    key: 'color',
    name: 'attributeColor',
    valueType: 'string'
  },
  {
    key: 'year',
    name: 'year',
    valueType: 'string'
  },
  {
    key: 'fuel_type',
    name: 'fuel_type',
    valueType: 'string'
  },
  {
    key: 'capacity',
    name: 'capacity',
    valueType: 'string'
  }
]

export const groupAttributesTypes = [
  {
    key: 'processing.copyAttributes',
    name: 'attributeProcessingCopyAttributes',
    valueType: 'string',
    htmlTag: 'input'
  },
  {
    key: 'decoder.timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
  },
  {
    key: 'speedLimit',
    name: 'attributeSpeedLimit',
    valueType: 'number',
    dataType: 'speed',
    htmlTag: 'input'
  },
  {
    key: 'report.ignoreOdometer',
    name: 'attributeReportIgnoreOdometer',
    valueType: 'boolean',
    htmlTag: 'input'
  }
]

export const speedUnit = [
  {
    key: 'kn',
    name: 'sharedKn',
    factor: 1
  },
  {
    key: 'kmh',
    name: 'sharedKmh',
    factor: 1.852
  },
  {
    key: 'mph',
    name: 'sharedMph',
    factor: 1.15078
  }
]

export const distanceUnit = [
  {
    key: 'km',
    name: 'sharedKm',
    factor: 0.001
  },
  {
    key: 'mi',
    name: 'sharedMi',
    factor: 0.000621371
  },
  {
    key: 'nmi',
    name: 'sharedNmi',
    factor: 0.000539957
  }
]
export const volumeUnit = [
  {
    key: 'ltr',
    name: 'sharedLiterAbbreviation',
    fullName: 'sharedLiter',
    factor: 1
  },
  {
    key: 'impGal',
    name: 'sharedGallonAbbreviation',
    fullName: 'sharedImpGallon',
    factor: 4.546
  },
  {
    key: 'usGal',
    name: 'sharedGallonAbbreviation',
    fullName: 'sharedUsGallon',
    factor: 3.785
  }
]

export const discreteSensors = [
  'ignition',
  'panicButton',
  'externalPower',
  'enginStatus',
  'carAlarm',
  'doors',
  'custom'
]
export const measurementSensors = [
  'fuel',
  'temperature',
  'rpm',
  'averagFuelConsumption',
  'fuelConsumption',
  'boardPower',
  'speed',
  'flowMeter'
]
