import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '@mui/material/Icon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
;
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Style from 'style-it'
library.add(faSatelliteDish)

class Jobs extends Component {
  render () {
    let data = ''
    if (this.props.jobs.length) {
      data = this.props.jobs.map(job => (
        <ListRow
          key={job.id}
          data={job}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
        />
      ))
    }
    return (
      <Style>
        {`
      			.material-icons {vertical-align: middle; color: inherit}
			`}
        <div>
          {data && (
            <div className='individual-view'>
              <ul className='list-view with-padding-right'>{data}</ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

export class ListRows extends Component {
  render () {
    if (this.props.children.length) {
      return (
        <ul className='list-view'>
          {this.props.children.map(data => (
            <ListRow key={data.id} data={data} {...this.props} />
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }
}
export class ListRow extends Component {
  render () {
    return (
      <li>
        <Link
          onClick={this.handleClick}
          className={
            'clearfix list-row ' +
            (parseInt(this.props.match.params.id) ===
            parseInt(this.props.data.id)
              ? 'active'
              : '')
          }
          to={'/schedules/' + this.props.data.id}
        >
          <label
            className='checkbox'
            //onClick={() => this.props.selecteItem(this.props.data)}
          >
            <span
              className='unit-name'
              style={{ minWidth: 220, maxWidth: 220, marginLeft: 0 }}
            >
              <span
                style={{
                  marginRight: '5px',
                  // display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <AccountCircleIcon/>
              </span>{' '}
              {this.props.data.description}
            </span>
          </label>
          <div className='pull-right'>
            {checkPrivileges('jobDelete') && (
              <span
                className='action-item hovered'
                title={this.props.translate('delete')}
                onClick={() => this.props.removedItem(this.props.data)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}
            {checkPrivileges('jobUpdate') && (
              <span
                className='action-item hovered'
                title={this.props.translate('edit')}
                onClick={() => this.props.editItem(this.props.data)}
              >
                <EditIcon style={{fontSize: 16}} />{' '}
              </span>
            )}
          </div>
        </Link>
      </li>
    )
  }
}

const mapState = state => {
  var job = state.jobs.filter(
    item =>
      item.description.toLowerCase().indexOf(state.searchJob) !== -1 ||
      item.id.indexOf(state.searchJob) !== -1
  )
  return {
    jobs: job
  }
}

const mapStateToProps = connect(mapState)
export const JobsShortList = mapStateToProps((Jobs))
