export const updateLayer = action => ({
  type: 'UPDATE_LAYER',
  payload: action
})

export const getLayer = action => ({
  type: 'GET_LAYER',
  payload: action
})

export const toggleTrafficLayer = () => ({
  type: 'UPDATE_TRAFFIC'
})