import React, { Fragment, PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import MenuItem from './../common/MenuItem'
import { ReactComponent as AnalaticsIcon } from './../../assets/nav/analaticss.svg'
import { Trans as Translate} from 'react-i18next';
import { connect } from 'react-redux'
import { ListSubheader, Tooltip } from '@mui/material'
import { NavLink } from 'react-router-dom'
import Loader from '../../Layout/Loader'
import { checkPrivileges } from '../../Helpers'
class DashboardMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
    this.activateOption = this.activateOption.bind(this)
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  activateOption (id) {
    if(id === 'new') {
      this.props.history.push('/dashboardBuilder');
    } else {
      this.props.history.push('/premium_dashboard/'+id);
    }
  }

  render () {
    const { anchorEl } = this.state
    const { dashboards,isFetching } = this.props
    const empty = null
    return (
      <Fragment>
        <Tooltip
          key={'dashboards'}
          title={<Translate i18nKey="analytics"/>}
          placement='right'
          classes={{
            popper: 'menu-popper',
            tooltip: 'menu-popper-tooltip'
          }}
        >
          <a
            className='navbar-menu-item'
            href={null}
            aria-owns={anchorEl ? 'simple-menu-dashboard' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
            
            <AnalaticsIcon fill='currentColor' width={20} height={20} />
            <span className='nav-link-text'>{<Translate i18nKey="analytics"/>}</span>
          </a>
      </Tooltip>
        <Menu
          id='simple-menu-dashboard'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
        >
          {checkPrivileges('dashboardCreate')&&(<MenuItem onClick={() => this.activateOption('new')}><Translate i18nKey="Create"/></MenuItem>)}
          {dashboards.length?<ListSubheader><Translate i18nKey="availableDashboards"/></ListSubheader>:null}
          {!isFetching ? 
          (dashboards.length?dashboards.map((option, index) => (
            <MenuItem onClick={() => this.activateOption(option.id)}key={index}>{option.name}</MenuItem>
          )):null) : 
          <Loader defaultStyle />}
        </Menu>
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  dashboards: state.dashboards?.data ||[],
  isFetching: state.dashboards?.isFetching !== false
})

export default connect(mapStateToProps)((DashboardMenu))
