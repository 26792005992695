
export const jobs = (state = [], action) => {
  switch (action.type) {

    case 'RESET_APP':
    return [];

	case 'GET_JOBS':

		return action.jobs;

	case 'ADD_JOB':

		return state.concat(action.job)

	case 'UPDATE_JOB':

  let updatedata = state.map(m => {
      if(m.id !== action.job.id) {
        return m;
      }
      else {
        return action.job
      }
    })
    
  return updatedata

	case 'REMOVE_JOB':

		let _jobList=state.filter(job => job.id !== action.job.id)

	return _jobList;
	case 'SORT_JOBS':
    var jobs1 = [];
      if(action.jobs.sort === 'DESC') {
        jobs1 = state.slice().sort(function(b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB)
            return -1
          if (nameA > nameB)
            return 1
          return 0
        });
      } else {
        jobs1 = state.slice().sort(function(a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB)
            return -1
          if (nameA > nameB)
            return 1
          return 0
        });

      }

      return jobs1


    default:
      return state
  }
}
export const searchJob = (state = "", action) => {
  switch (action.type) {

    case 'RESET_APP':
      return "";
    case 'SEARCH_Job':
        return action.payload;
    default:
      return state
  }
}