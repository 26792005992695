import Paper from '@mui/material/Paper';
import React, { Component } from "react";

export default class EmptyState extends Component {
	render() {
		return (
			<Paper style={{padding: this.props.padding || 20, minHeight: this.props.minHeight || 250, textAlign: 'center', display: 'flex', alignItems: 'center', borderRadius: 0, boxShadow: 'none', background: 'none' }}>
              {this.props.children || <h2 style={{width: '100%', color: 'inherit', fontWeight: 'normal'}}>{this.props.text}</h2>}
			</Paper>
		)
	}
}