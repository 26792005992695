import React, { Component } from 'react'
import Icon from '@mui/material/Icon'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar'
import { NavLink } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import Style from 'style-it'

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      usesId: '',
      userProcess: true
    }
  }

  render () {
    //if (this.state.userProcess && this.props.logInUser !== '' && this.props.logInUser.id !== this.state.usesId) {
    //this.fatchUsers(this.props.logInUser.id)
    //}

    let data = ''
    if (this.props.users.length) {
      data = this.props.users.map(user => (
        <ListRow
          key={user.id}
          data={user}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
        />
      ))
    }

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div>
          {data && (
            <div className='individual-view'>
              <ul className='list-view'>{data}</ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

export class ListRow extends Component {
  checkUserType = Utype => {
    let type = ''
    if (Utype === -1) {
      type = 'O'
    } else if (Utype === 1) {
      type = 'U'
    } else if (Utype === 2) {
      type = 'A'
    } else if (Utype === 3) {
      type = 'AA'
    } else if (Utype === 4) {
      type = 'SP'
    } else if (Utype === 5) {
      type = 'R'
    } else if (Utype === 6) {
      type = 'D'
    } else {
      type = 'U'
    }
    return type
  }
  render () {
    const empty = null
    return (
      <li>
        <a
          href={empty}
          className={
            'clearfix list-row ' +
            (this.props.selectedItemId === this.props.data.id ? 'active' : '')
          }
        >
          <label
            className='checkbox'
            onClick={() => this.props.selecteItem(this.props.data)}
          >
            <span
              className='unit-name'
              style={{
                lineHeight: '24px'
              }}
            >
              <span
                style={{
                  marginRight: '10px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <Avatar
                  className={
                    'avatar-' + this.checkUserType(this.props.data.userType)
                  }
                  style={{ width: 25, height: 25, fontSize: 12 }}
                >
                  {this.checkUserType(this.props.data.userType)}
                </Avatar>
              </span>{' '}
              {this.props.data.name} {this.props.data.uniqueId}
            </span>
          </label>
          {this.props.logInUser.administrator && (
            <div className='pull-right'>
              {checkPrivileges('userDelete') && (
                <span
                  className='action-item hovered '
                  title={this.props.translate('delete')}
                  onClick={() => this.props.removedItem(this.props.data)}
                >
                  <DeleteIcon style={{fontSize: 16}}/>
                </span>
              )}
              <span
                className='action-item hovered'
                title={this.props.translate('edit')}
                onClick={() => this.props.editItem(this.props.data)}
              >
                {' '}
                <EditIcon style={{fontSize: 16}}/>
              </span>
            </div>
          )}
        </a>
      </li>
    )
  }
}

class Users2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      usesId: '',
      userProcess: false
    }
  }

  render () {
    
    let data = ''
    if (this.props.users.length) {
      data = this.props.users.map(user => {
        
        return (
          <ListRow2
            key={user.id}
            data={user}
            {...this.props}
            removedItem={this.props.removedItem}
            editItem={this.props.editItem}
          />
        )
      })
    }

    return (
      <div>
        {data && (
          <div className='individual-view'>
            <ul className='list-view'>{data}</ul>
          </div>
        )}
      </div>
    )
  }
}

export class ListRow2 extends Component {
  checkUserType = Utype => {
    let type = ''
    if (Utype === -1) {
      type = 'O'
    } else if (Utype === 1) {
      type = 'U'
    } else if (Utype === 2) {
      type = 'A'
    } else if (Utype === 3) {
      type = 'AA'
    } else if (Utype === 4) {
      type = 'SP'
    } else if (Utype === 5) {
      type = 'R'
    } else if (Utype === 6) {
      type = 'D'
    } else {
      type = 'U'
    }
    return type
  }
  render () {
    return (
      <li>
        <NavLink
          activeClassName='active'
          to={'/users/' + this.props.data.id}
          className={'clearfix list-row'}
        >
          <label className='checkbox'>
            <span
              className='unit-name'
            >
              <span
                style={{
                  marginRight: '10px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <Avatar
                  className={
                    'avatar-' + this.checkUserType(this.props.data.userType)
                  }
                  style={{ width: 18, height: 18, fontSize: 10 }}
                >
                  {this.checkUserType(this.props.data.userType)}
                </Avatar>
              </span>{' '}
              {this.props.data.name} {this.props.data.uniqueId}
            </span>
          </label>
          {this.props.logInUser.administrator && checkPrivileges('userDelete') && (
            <div className='pull-right'>
              <span
                className='action-item '
                title={this.props.translate('delete')}
                onClick={() => this.props.removedItem(this.props.data)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            </div>
          )}
        </NavLink>
      </li>
    )
  }
}

export const UsersShortList = Users
export const UsersShortList2 = Users2
