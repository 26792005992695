import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
//import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '../../../Components/common/TextField'
import Tooltip from '../../../Components/common/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { checkPrivileges } from '../../../Helpers'
import './ecoTable.scss'

function DrawCell ({
  item,
  value,
  unit,
  translate,
  label,
  handleChange,
  index,
  themecolors
}) {
  return (
    <TableCell
      className='res-td'
      padding={'none'}
      style={{ color: 'inherit', borderColor: themecolors.themeLightColor }}
    >
      <TextField
        fullWidth
        id={item}
        label={translate(label)}
        type='number'
        value={value || ''}
        onChange={e => handleChange(item, index, e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              classes={{ root: 'text-default' }}
              disableTypography={true}
            >
              {translate(
                (item === 'speedMedium' ||
                  item === 'speedExtreme' ||
                  item === 'idleMedium' ||
                  item === 'idleExtreme') &&
                  unit === 'g'
                  ? item === 'speedMedium' || item === 'speedExtreme'
                    ? 'kn'
                    : 'sec'
                  : unit
              )}
            </InputAdornment>
          )
        }}
      />
    </TableCell>
  )
}

export const EcoDrivingTable = props => {
  let { row } = props
  let arr = [
    { unit: 'g', label: 'min' },
    { unit: 'g', label: 'max' },
    { unit: 'points', label: 'penalty' },
    { unit: 'sec', label: 'threshold' }
  ]
  return (
    <>
      <Table className='res-table'>
        <TableBody>
          {row &&
            Object.keys(row).map(
              item =>
                item !== 'preset' && (
                  <>
                    <TableRow key={item}>
                      <TableCell
                        className='res-th'
                        padding={'none'}
                        style={{
                          color: 'inherit',
                          borderColor: props.themecolors.themeLightColor
                        }}
                      >
                        {props.translate(item)}
                      </TableCell>

                      {arr.map((obj, i) =>
                        obj.label !== 'threshold' ||
                        (obj.label === 'threshold' &&
                          item !== 'idleMedium' &&
                          item !== 'idleExtreme') ? (
                          <DrawCell
                            item={item}
                            themecolors={props.themecolors}
                            {...obj}
                            translate={props.translate}
                            value={row[item][i] || ''}
                            handleChange={props.handleChange}
                            index={i}
                          />
                        ) : (
                          <TableCell
                            className='res-td-empty'
                            style={{
                              color: 'inherit',
                              borderColor: props.themecolors.themeLightColor
                            }}
                          />
                        )
                      )}

                      <TableCell
                        className='res-td-action'
                        padding={'none'}
                        style={{
                          color: 'inherit',
                          borderColor: props.themecolors.themeLightColor
                        }}
                      >
                        {checkPrivileges('settingUpdate') && (
                          <Tooltip title='Update'>
                            <IconButton
                              aria-label='Update'
                              onClick={() => props.onUpdate()}
                              color='inherit'
                            >
                              <SaveIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {checkPrivileges('settingDelete') && (
                          <Tooltip title='Delete'>
                            <IconButton
                              aria-label='Delete'
                              color='inherit'
                              onClick={() => props.onDelete(item)}
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )
            )}
        </TableBody>
      </Table>
    </>
  )
}
