import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as LoaderIcon } from './../assets/tracks-loader.svg'
class Loader extends Component {
  render () {
    const style = {
      pageLoader: {
        maxHeight: '100%',
        height: '100vh',
        minHeight: 300,
        background: this.props.themecolors.backgroundColor,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      },
      defaultPageLoader: {
        color: 'inherit'
      }
    }
    return (
      <div
        className='page-loader'
        style={
          this.props.defaultStyle ? style.defaultPageLoader : style.pageLoader
        }
      >
        <p style={{ textAlign: 'center', color: this.props.themecolors.textColor }}>
          <LoaderIcon
            style={{width: this.props.imgSize || 50, height: this.props.imgSize || 50}}
          />
        </p>
      </div>
    )
  }
}

const mapState = state => ({
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Loader)
