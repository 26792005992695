
// get jobs
export const getJobs = jobs => ({
  type: 'GET_JOBS',
 jobs
})
export const addJob = job => ({
  type: 'ADD_JOB',
  job
})
export const updateJob = job => ({
  type: 'UPDATE_JOB',
  job
})
export const removeJob = job => ({
  type: 'REMOVE_JOB',
  job
})

export const sortJobs = jobs => ({
  type: 'SORT_JOBS',
  jobs
});


export const searchJob = job => ({
  type: 'SEARCH_JOB',
  payload: job.job
});
