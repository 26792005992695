// get Drivers
export const getDrivers = drivers => ({
  type: 'GET_DRIVERS',
  drivers
})
export const addDrivers = drivers => ({
  type: 'ADD_DRIVERS',
  drivers
})
export const addDriver = driver => ({
  type: 'ADD_DRIVER',
  driver
})
export const updateDriver = driver => ({
  type: 'UPDATE_DRIVER',
  driver
})
export const removeDriver = driver => ({
  type: 'REMOVE_DRIVER',
  driver
})

export const sortDrivers = drivers => ({
  type: 'SORT_DRIVERS',
  drivers
});


export const searchDriver = driver => ({
  type: 'SEARCH_DRIVER',
  payload: driver.driver
});

export const isDriverLoad = (loadDriver) => ({
  type: 'LOAD_DRIVERS',
  loadDriver
});
