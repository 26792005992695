
export const getMaintenance = maintenance => ({
    type: 'GET_MAINTENANCE',
    maintenance
});
export const addMaintenance = maintenance => ({
    type: 'ADD_MAINTENANCE',
    maintenance
});
export const removedMaintenance = maintenance => ({
    type: 'REMOVED_MAINTENANCE',
    maintenance
});
export const addMaintenanceDeviceWise = maintenance => ({
    type: 'ADD_MAINTENANCE_DEVICE_WISE',
    maintenance
});
export const RemoveMaintenanceDeviceWise = maintenance => ({
    type: 'REMOVE_MAINTENANCE_DEVICE_WISE',
    maintenance
});


export const getMaintenanceDeviceWise = maintenance => ({
    type: 'GET_MAINTENANCE_DEVICE_WISE',
    maintenance
});

export const maintenanceDeviceWiseSelectedList = maintenance => ({
    type: 'MAINTENANCE_DEVICE_WISE_SELECTED_LIST',
    maintenance
});
export const addMaintenanceDeviceWiseSelectedList = maintenance => ({
    type: 'ADD_MAINTENANCE_DEVICE_WISE_SELECTED_LIST',
    maintenance
});
export const updateMaintenanceDeviceWiseSelectedList = maintenance => ({
    type: 'UPDATE_MAINTENANCE_DEVICE_WISE_SELECTED_LIST',
    maintenance
});
export const removedMaintenanceDeviceWiseSelectedList = maintenance => ({
    type: 'REMOVED_MAINTENANCE_DEVICE_WISE_SELECTED_LIST',
    maintenance
});
