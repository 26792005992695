export const vehicles = (state = [], action) => {
  let data = [];
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_VEHICLES':
      return action.vehicles || state
    case 'ADD_VEHICLES':
      return state.concat(action.vehicle)
    case 'REMOVED_VEHICLE':
      return state.filter(clnd => clnd.id !== action.vehicle.id)
    case 'UPDATE_VEHICLE':
        data = state
        .filter(cal => cal.id !== action.vehicle.id)
        .concat(action.vehicle)
      return data
    default:
      return state
  }
}
export const searchvehicle = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return ''
    case 'SEARCH_VEHICLE':
      return action.payload
    default:
      return state
  }
}
