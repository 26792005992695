import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { SnackbarProvider } from 'notistack'
import combineReducers from './Reducers'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import SocketController from './SocketController'
import { Slide, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import thunk from 'redux-thunk'

export const store = createStore(combineReducers, compose(applyMiddleware(...[thunk])));
const rootElement = document.getElementById('root')
const theme = createTheme({
  spacing: 1, // Define spacing unit
});
ReactDOM.render(
  <Provider store={store}>
    {/* <LocalizeProvider> */}
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        preventDuplicate
      >
        <BrowserRouter>
        <ThemeProvider theme={theme}>

          <SocketController />
          <App />
        </ThemeProvider>
          <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme="colored"
        />
        </BrowserRouter>
      </SnackbarProvider>

    {/* </LocalizeProvider> */}
  </Provider>,
  rootElement
)

registerServiceWorker()
