export function commands(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
    return [];
    case 'GET_COMMANDS':
      let data = action.commands
      if (data.length) {
        data.map((item, index) => {
          data[index].textChannel = item.textChannel ? 'Yes' : 'No'
          item.check = false
          return null
        })
      }
      return data;
    case 'ADD_COMMANDS':
      let S_data = state.filter(m => m.id !== action.commands.id)
      let item = action.commands
      item.check = item.check ? item.check : false
      item.textChannel = item.textChannel ? 'Yes' : 'No'
      return S_data.concat(item)
    case 'REMOVED_COMMANDS':
      let alldata = state.filter(m => m.id !== action.commands.id)
      return alldata
    default:
      return state;
  }
}


export function commandsDeviceWise(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
    return [];
    case 'ADD_COMMANDS_DEVICE_WISE':
      return state.concat(action.commands);

    case 'REMOVE_COMMANDS_DEVICE_WISE':
      let data = state.filter(m => m.id !== action.commands.id)
      return data
    case 'GET_COMMANDS_DEVICE_WISE':
      return action.commands
    default:
      return state;
  }
}


export function selectedcommands(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
    return [];
    case 'COMMANDS_DEVICE_WISE_SELECTED_LIST':
      return action.commands
    case 'UPDATE_COMMANDS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if(m.id !== action.commands.id) {
          return m;
        }
        else {
          return action.commands
        }
      })
      return updatedata;
    case 'ADD_COMMANDS_DEVICE_WISE_SELECTED_LIST':
    let data= state.filter(m => m.id !== action.commands.id)
      action.commands.check = false
      return data.concat(action.commands) 
    case 'REMOVED_COMMANDS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.commands.id)
      return rData
    default:
      return state;
  }
}
export function commandsTypes(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
    return [];
    case 'GET_COMMANDS_TYPES':
      let data = action.commands
      // data.map(item => {
      //   let addtype = 'command'
      //   let nameCapitalized = item.type.charAt(0).toUpperCase() + item.type.slice(1)
      //   item.type = addtype.concat(nameCapitalized)
      // })
      return data;
    default:
      return state;
  }
}



