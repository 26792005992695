import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import { connect } from 'react-redux'
import Style from 'style-it'
const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}

const styles = theme => ({})

class CustomTextField extends React.PureComponent {
  render () {
    const { classes } = this.props

    const p = { ...this.props }

    delete p.dispatch

    return (
      <Style>
        {`
        .theme-label {
          font-size: 12px;
          margin-top:-4px;
          color: ${this.props.themecolors.textColor}
        }
          
        
        .theme-label-shrink {
          margin-top: 0
        }
        .theme-label-focused {
          margin-top: 0;
        }
        .theme-textarea {
          font-size: 14px;
          padding: 0
        }
        .theme-label-error {
          color: ${this.props.themecolors.error}
        }
        .theme-notchedOutline {
          border-color:  ${this.props.themecolors.themeLightColor}4D !important;
        }
        .theme-input-error .theme-notchedOutline {
          border-color:  ${this.props.themecolors.error} !important;
        }
        .theme-cssFocused .theme-notchedOutline {
          border-color:  ${this.props.themecolors.themeLightColor} !important;
        }
        .theme-input-select {
          border-color: ${this.props.themecolors.themeLightColor};
          color: ${this.props.themecolors.textColor};
          padding: 0;
          display: flex;
          height: auto
        }
        .theme-input {
          border-color: ${this.props.themecolors.themeLightColor}4D;
          color: ${this.props.themecolors.textColor};
          font-size: 12px;
          padding: 5px 14px;
          min-height: 26px;
          line-height: 26px;
        }
        .menu-list-icon {
          color: ${this.props.themecolors.textColor};
        }
        .custom-paper {
          background-color: ${this.props.themecolors.themeLightColor};
        }
      `}
        <div>
          <TextField
            margin='dense'
            {...p}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...this.props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: this.props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline'
              }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            InputLabelProps={{
              ...this.props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink'
              }
            }}
          />
        </div>
      </Style>
    )
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField))
