import React, { Component } from "react";
import TextField from "./../../Components/common/TextFieldLg";
import Button from "./../../Components/common/Button";
import Loader from "./../../Layout/Loader";
import { logInUserInfo, getRoles } from "./../../Actions/Users";
import { ServerInfo } from "./../../Actions/serverSetting";
import { getLayer } from "./../../Actions/Maps";
import { Trans as Translate} from 'react-i18next';
import { fetchDevicesList, fetchDevices } from "./../../Actions/Devices";
import {
  displayErrorMessages,
  checkedDevelopmentType,
  errorHandler,
  checkPrivileges,
} from "../../Helpers/index";
import Checkbox from "../../Components/common/Checkbox";
import SocketController from "../../SocketController";
import { connect } from "react-redux";
import Style from "style-it";

import { NavLink } from "react-router-dom";
import InfoIcon from "./../../assets/monitoring/info.svg";
import { themes } from "../../Reducers/Theme";
import instance from "../../axios";
import {notifySuccess } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";

const mapState = (state) => {
  return {
    themecolors: themes[2],
    devices: state.devices.data,
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
  };
};

class LoginForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, loginErrorMessage: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state !== ""
    ) {
      this.setState({
        failed: true,
        loginErrorMessage: this.props.location.state,
      });
    }
    this.setState({
      email: this.getCookie("userName"),
      password: this.getCookie("pass"),
    });
  }
  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleLogin(event) {
    
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({
      failed: false,
      loading: true,
      loginErrorMessage: "",
    });
    let fetchUrl = checkedDevelopmentType();
    // fetch('/api/session', {
    //   method: 'POST',
    //   body: new URLSearchParams(
    //     `email=${email}&password=${password}&app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05${fetchUrl}`
    //   )
    // })
    instance({
      url: `/api/session`,
      method: "POST",
      data: new URLSearchParams({
        email: email,
        password: password,
        app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
        // host: fetchUrl,
      }),
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((userInfoFront) => {
        // fetch call for Api  privileges
        let lang = userInfoFront.attributes.lang;
        this.props.setActiveLanguage(lang || "en");
        // notifyError("attributeDevicePassword")

        /* fetch(
              `/api/devices/list?userId=${userInfoFront.id}&all=true&limit=-1`
            ).then(response => {
              if (response.ok) {
                response.json().then(devices => {
                  this.props.dispatch(getDevices2(devices.data))
                })
              }
            })
            fetch(
              `/api/devices/get?userId=${userInfoFront.id}&all=true&page=1&limit=-1`
            ).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  this.props.dispatch(getDevices(res.data))
                  this.props.dispatch(getDevices3(res.data))
                })
              }
            }) */

        // fetch(`/api/privileges?roleId=${userInfoFront.roleId}`, {
        //   method: 'Get'
        // })
        instance({
          url: `/api/privileges`,
          method: "GET",
          params: {
            roleId: userInfoFront.roleId,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((privileges) => {
            let privilegeKeys = [];
            privileges.map((item) => {
              privilegeKeys.push(item.key);
              return null;
            });
            localStorage.setItem(
              "frontPrivileges",
              JSON.stringify({ ...privilegeKeys })
            );
            if (privileges.length) {
              this.props.dispatch(logInUserInfo(userInfoFront));
              checkPrivileges("device") &&
                this.props.dispatch(fetchDevicesList(userInfoFront));
              checkPrivileges("device") &&
                this.props.dispatch(fetchDevices(userInfoFront));

              if (userInfoFront.userType === -1) {
                // fetch(`/api/roles?all=true`)
                instance({
                  url: `/api/roles`,
                  method: "GET",
                  params: {
                    all: true,
                  },
                })
                  // .then(response => {
                  //   if (response.ok) {
                  //     response.json()
                  .then((role) => {
                    this.props.dispatch(getRoles(role));
                    //   })
                    // }
                    // else{
                    //   throw response
                    // }
                  })
                  .catch((error) => {
                    // errorHandler(error, this.props.dispatch)
                  });
              } else if (
                userInfoFront.userType !== 1 &&
                checkPrivileges("role")
              ) {
                // fetch(`/api/roles?userId=${userInfoFront.id}`)
                instance({
                  url: `/api/roles`,
                  method: "GET",
                  params: {
                    userId: userInfoFront.id,
                  },
                })
                  // .then(response => {
                  //     if (response.ok) {
                  //       response.json()
                  .then((role) => {
                    this.props.dispatch(getRoles(role));
                    //   })
                    // }
                    // else{
                    //   throw response
                    // }
                  })
                  .catch((error) => {
                    // errorHandler(error, this.props.dispatch)
                  });
              }

              // fetch(`/api/accounts?accountId=${userInfoFront.accountId}`)
              instance({
                url: `/api/accounts`,
                method: "GET",
                params: {
                  accountId: userInfoFront.accountId,
                },
              })
                // .then(response => {
                //   if (response.ok) {
                //     response.json()
                .then((server) => {
                  if (server[0]) {
                    //implatation of whitelabeling to replace data
                    this.props.dispatch(ServerInfo(server[0]));
                    this.props.dispatch(
                      getLayer({
                        id:
                          userInfoFront.map ||
                          (server && server[0].map) ||
                          "osm",
                      })
                    );
                    // console.log("this.props.location.state=====", this.props.location.state);
                    if(userInfoFront.customerType === 1 || userInfoFront.customerType === 2){
                      this.props.history.push("/maintenance");
                    }else{
                    if (this.props.location && this.props.location.state) {
                      this.props.history.push(this.props.location.state);
                    } else {
                      this.props.history.push("/maintenance");
                    }
                  }

                  } else {
                    this.someThingWendWrong("Account Data Not Found");
                  }
                })
                //   } else {
                //     throw response
                //   }
                // })
                .catch((error) => {
                  // errorHandler(error, this.props.dispatch, this.errorCallBack)
                  this.setState({
                    failed: true,
                    password: "",
                    loading: false,
                    loginErrorMessage: displayErrorMessages(error.message),
                  });
                });
            } else {
              this.setState({
                failed: true,
                password: "",
                loading: false,
                loginErrorMessage: "Lack Of Privileges",
              });
            }
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch, this.errorCallBack)
            this.setState({
              failed: true,
              password: "",
              loading: false,
              loginErrorMessage: displayErrorMessages(error.message),
            });
          });
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  }
  // errorMessage = e => {
  // if (e && e.text) {
  //   e.text().then(err => {
  //     console.log("login failed ==", err)
  //     this.setState({
  //       failed: true,
  //       password: '',
  //       loading: false,
  //       loginErrorMessage: displayErrorMessages(err)
  //     })
  //   })
  // }
  // }
  errorCallBack = (data) => {
    this.setState({
      failed: true,
      password: "",
      loading: false,
      loginErrorMessage: displayErrorMessages(data.message),
    });
  };
  handleClick = (e) => {
    let { email, password } = this.state;
    if (e.target.checked && email && password) {
      this.setCookie("userName", email);
      this.setCookie("pass", password);
    } else {
      this.setCookie("userName", "");
      this.setCookie("pass", "");
    }
  };
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  setCookie(name, value, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      name + "= " + encodeURIComponent(value) + ";" + expires + ";path=/login;";
  }
  someThingWendWrong = (mgs) => {
    this.setState({
      failed: true,
      password: "",
      loading: false,
      loginErrorMessage: this.props.translate(mgs),
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="loginLoaderWrapper">
          <Loader />
        </div>
      );
    } else {
      let e = this.props.translate("userEmail") + " *";
      return (
        <form
          className="loginForm"
          onSubmit={this.handleLogin}
          autoComplete="off"
        >
          {/* <div className='login-logo-wrapper'>
            <img
              className='login-logo'
              src={this.props.whiteLabling.logo}
              alt='logo'
            />
          </div> */}
          <h1
            className="alt-title text-center"
            style={{ marginBottom: 25, marginTop: 25, fontSize: 38 }}
          >
            <Translate i18nKey="loginTitle" />
          </h1>
          {this.state.failed && (
            <div
              className="alert alert-danger"
              style={{
                color: this.props.themecolors["error"],
              }}
            >
              <svg fill="currentColor" width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {this.props.translate(this.state.loginErrorMessage)}
            </div>
          )}
          <div className="form-group">
            <TextField
              themecolors={this.props.themecolors}
              id="email"
              variant="outlined"
              label={e}
              type="text"
              value={this.state.email || ""}
              onChange={this.handleChange}
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ marginBottom: 7 }}>
            <TextField
              themecolors={this.props.themecolors}
              id="password"
              variant="outlined"
              label={this.props.translate("userPassword") + " *"}
              type="password"
              value={this.state.password || ""}
              onChange={this.handleChange}
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              gap: 12,
            }}
            className="form-group"
          >
            <div
              style={{
                color: this.props.themecolors.textColor,
              }}
            >
              <Checkbox
                disableRipple
                onChange={(event) => this.handleClick(event)}
                label={this.props.translate("rememberme")}
              />
            </div>
          </div>
          <p style={{ textAlign: "center", marginBottom: 10 }}>
            <Button
              themecolors={this.props.themecolors}
              type="submit"
              className="btn-submit theme-green"
            >
              {this.props.translate("loginLogin")}
            </Button>
          </p>
          <p style={{ textAlign: "center", fontSize: 12 }}>
            <NavLink
              to={"/login"}
              onClick={(e) => this.props.switchView("forgot")}
            >
              {this.props.translate("forgotPassword")}{" "}
              <strong className="text-theme">
                {this.props.translate("recoverNow")}
              </strong>
            </NavLink>
          </p>

          {this.props.logInUser && this.props.logInUser.id ? (
            <SocketController />
          ) : null}
        </form>
      );
    }
  }
}

const mapStateToProps = connect(mapState);

export const LoginForm = mapStateToProps(withTranslationWrapper(LoginForm1));

class ForgotForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", errorMessage: "", loading: false };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    this.setState(
      {
        loading: true,
        errorMessage: "",
      },
      () => {
        // fetch('/api/password/forget', {
        //   headers: new Headers({
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     Accept: 'application/json'
        //   }),
        //   method: 'POST',
        //   body: new URLSearchParams(`email=${email}`)
        // })
        instance({
          url: `/api/password/forget`,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          data: `email= ${email}`,
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((res) => {
            // if (res.status === 'success') {
            this.setState({
              loading: false,
              errorMessage: this.props.translate("emailSentSuccessfully"),
            });

            this.props.dispatch(
              notifySuccess("emailSentSuccessfully")
            );
            // } else {
            //   this.setState({
            //     loading: false,
            //     errorMessage: this.props.translate('invalidEmailAddress')
            //   })
            // }
            //   })
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch, this.errorCallBack);
          });
      }
    );
  };
  errorCallBack = (data) => {
    this.setState({
      loading: false,
      errorMessage: this.props.translate("invalidEmailAddress"),
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  render() {
    let { loading, errorMessage } = this.state;
    if (loading) {
      return (
        <div className="loginLoaderWrapper">
          <Loader />
        </div>
      );
    } else {
      return (
        <form
          className="loginForm"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Style>
            {`
            a {
              color: ${this.props.themecolors.textColor}
            }
            .loginWrapperInner {
              background: ${this.props.themecolors.backgroundColor}
            }
            .login-footer {
              border-top: 1px solid ${this.props.themecolors.themeLightColor};
            }
            `}
          </Style>
          {/* <div className='login-logo-wrapper'>
            <img
              className='login-logo'
              src={this.props.whiteLabling.logo}
              alt='logo'
            />
          </div> */}
          <h1
            className="alt-title text-center"
            style={{ marginBottom: 25, marginTop: 25, fontSize: 38 }}
          >
            <Translate i18nKey="forgotPassword" />
          </h1>
          <p
            style={{
              marginTop: 0,
              marginBottom: 30,
              fontSize: 12,
              color: this.props.themecolors.textColor,
            }}
          >
            {this.props.translate("resetPasswordMessage")}
          </p>
          {errorMessage && (
            <div
              className="alert alert-danger"
              style={{
                color:
                  errorMessage === "Email sent successfully"
                    ? this.props.themecolors["success"]
                    : this.props.themecolors["error"],
                background: this.props.themecolors.textColor,
              }}
            >
              <svg fill="currentColor" width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {errorMessage}
            </div>
          )}
          <TextField
            themecolors={this.props.themecolors}
            id="email"
            label={this.props.translate("userEmail") + " *"}
            type="text"
            value={this.state.email || ""}
            onChange={this.handleChange}
            margin="dense"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p style={{ textAlign: "center", marginTop: 0, marginBottom: 10 }}>
            <Button
              themecolors={this.props.themecolors}
              type="submit"
              className="btn-submit theme-green"
            >
              {this.props.translate("submit")}
            </Button>
          </p>
          <p style={{ textAlign: "center", fontSize: 12 }}>
            <NavLink
              to={"/login"}
              onClick={(e) => this.props.switchView("login")}
            >
              I already have an account{" "}
              <strong className="text-theme">
                {" "}
                {this.props.translate("loginTitle")}
              </strong>
            </NavLink>
          </p>

          {/* <div className='login-footer'>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('about')}
            </a>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('termsAndConditions')}
            </a>
            <a
              href={
                this.props.whiteLabling &&
                this.props.whiteLabling.privacyPolicyLink
              }
            >
              {this.props.translate('privacy')}
            </a>
          </div> */}
        </form>
      );
    }
  }
}

export const ForgotForm = mapStateToProps((ForgotForm1));
