import React, { Component } from 'react'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
;
import isEqual from 'react-fast-compare'
import { checkPrivileges} from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'
import { ReactComponent as CityIcon } from '../../../assets/garages/city.svg'
import { ReactComponent as GarageIcon } from '../../../assets/garages/Garage.svg'
import { ReactComponent as EnterIcon } from '../../../assets/garages/enter.svg'
import { IconButton, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import withTranslationWrapper from '../../../HOC/withTranslation';
class Garage extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
  }

  search (e, check,name) {
    this.props.searchItems(e.target.value, check,name)
  }

  render () {
    const { garages,deleteItem} = this.props
    // console.log("garageForm====",this.props.setGarageForm);
    const commands1 = this.props.itemPagination?.items?.map((g) => (
      <ListRow
        key={g.id}
        item={g}
        deleteItem={deleteItem}
        editGarageForm={this.props.editGarageForm}
        onOpenMap={this.props.onOpenMap}
        setGarageForm={this.props.setGarageForm}
        onClick={this.props.selectSingleGarage}
        showSingleGarage={this.props.showSingleGarage}
      />
    ));
    

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('garage') && (
            <div>
              <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
              <ul className='filter-row' style={{ justifyContent:"end"}}>
              <li style={{ paddingTop: 8, paddingBottom: 4}}>
                    {(checkPrivileges('garageCreate')) && (
                      <Button
                        size='small'
                        onClick={e => this.props.openCreateFrom()}
                      >
                        {this.props.translate('sharedCreate')}
                      </Button>
                    )}
                  </li>
                  </ul>
                <div>
                <div style={{ width: "90%", marginLeft:"30px" }}>
                    <SearchField
                      label={this.props.translate("searchGarage")}
                      fullWidth
                      onChange={(e) => this.search(e, true, "searchText")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="search" style={{ paddingLeft: 100 }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>


                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
                        <CityIcon/>
                        <div  style={{width:"90%"}}>
                          <SearchField
                            label={this.props.translate('Search Garage City')}
                            fullWidth
                            onChange={e => this.search(e, true,"cityText")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="search">
                                    <EnterIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                      </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                      <GarageIcon/>
                      <div  style={{width:"90%"}} >
                        <SearchField
                          label={this.props.translate('Search Garage Type')}
                          fullWidth
                          onChange={e => this.search(e, true,"typeText")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="search">
                                  <EnterIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                    </div>
                    </div>
                </div>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {this.props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={this.props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

class ListRow1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      onDeleteConfirmation: false
    }
  }

  onDelete = () => {
      this.props.deleteItem(this.props.item.id)
    // if (this.props.item.id) {
    //   instance({
    //     url: `api/garages/${this.props.item.id}`,
    //     method: 'DELETE',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //   .then(response => {
    //       this.props.dispatch(removedGarages({ id: this.props.item.id }))
    //       this.props.dispatch(
    //         Notifications.success({
    //           message: this.props.translate('GaragesIsDeleted'),
    //           autoDismiss: 10
    //         })
    //       )
    //       // this.props.modeEmpty()
    //       this.setState({ item: {} })
    //       this.props.fetchGarages()
    //       this.props.closeForm()
    //   }).catch(error => {errorHandler(error, this.props.dispatch)})
    // }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true
    })
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  render () {
    return (
      <li 
      // className={'list-row clearfix'}
        className={
        'clearfix list-row ' +
          (parseInt(this.props.setGarageForm&&this.props.setGarageForm.id) ===
          parseInt(this.props.item.id) && this.props?.showSingleGarage
          ? 'active'
          : '')
        } 
       key={this.props.item.id}>
        <label
          className='checkbox'
          onClick={e =>{this.props.onOpenMap(this.props.item)}}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {this.props.item.name}
          </span>
        </label>
        <div className='callout-actions'>
          {(checkPrivileges('garageUpdate')) && (
            <span className='action' onClick={e=>{this.props.editGarageForm(this.props.item)}}>
              <EditIcon className='material-icons'/>
            </span>
          )}
          {(checkPrivileges('garageDelete')) && (
            <span className='action' onClick={this.onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onDelete}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.item.description}
          />
        )}
      </li>
    )
  }
}

const mapState = state => ({
  garages: state.garages,
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withTranslationWrapper(ListRow1))
export default mapStateToProps(withTranslationWrapper(Garage))
