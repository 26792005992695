import React from 'react'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '../../../Components/common/Button'
import Tooltip from '../../../Components/common/Tooltip'

export default class IconForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      category: {
        name: this.props.selectedDevice.category || 'default',
        id: this.props.selectedDevice.category || 'default'
      },
      images: [],
      applied: false,
      currentId: 0
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (category) {
    this.setState({ category })
    this.props.logo_handelChanges(category)
  }

  onSubmit () {
    this.props.advenceSectionOneformSubmit({
      category: this.state.category.name
    })
  }

  componentWillMount () {
    if (
      this.props.selectedDevice.id &&
      this.props.selectedDevice.id !== this.state.currentId
    ) {
      this.setState({ currentId: this.props.selectedDevice.id }, () => {
        this.setState({
          category: {
            name: this.props.selectedDevice.category,
            id: this.props.selectedDevice.category
          }
        })
      })
    }
  }

  componentWillReceiveProps (n) {
    if (n.selectedDevice.id && n.selectedDevice.id !== this.state.currentId) {
      this.setState({ currentId: n.selectedDevice.id }, () => {
        this.setState({
          category: {
            name: n.selectedDevice.category,
            id: n.selectedDevice.category
          }
        })
      })
    }
  }

  render () {
    const { images } = this.props

    return (
      <div>
        <div
          style={{ textAlign: 'right', padding: '10px 0' }}
          className='clearfix'
        >
           {/* <div style={{float: 'right'}}>{this.props.isVisablBtn && (
              <Button
                disabled={!this.props.isVisableASOneBtn}
                onClick={this.onSubmit}
                size='small'
              >
                {this.props.translate('sharedUpdate')}
              </Button>
            )}</div> */}
          <h4 className='v-tab-heading pull-left with-border'>
            {this.props.translate('icon')}
          </h4>
        </div>
        <div>
          <Grid container spacing={1}>
            {images.map((item, i) => {
              return (
                <Tooltip key={i} title={this.props.translate(item.name)}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    item
                    onClick={e => this.onChange(item)}
                  >
                    <Paper
                      style={{
                        textAlign: 'center',
                        alignItems: 'center'
                      }}
                      className={
                        item.name === (this.state.category.name || 'default')
                          ? 'unit-image-active'
                          : 'unit-image'
                      }
                    >
                      <Avatar
                        alt=''
                        src={'/assets/category/default/' + item.name + '.svg'}
                        style={{
                          padding: 10,
                          margin: 'auto',
                          height: 80,
                          width: 80
                        }}
                      />
                    </Paper>
                  </Grid>
                </Tooltip>
              )
            })}
            <Grid
              style={{ cursor: 'pointer', textAlign: 'left', marginTop: 10 }}
              item
              xs={12}
            >
              {this.props.isVisablBtn && (
                <Button
                  disabled={!this.props.isVisableASOneBtn}
                  onClick={this.onSubmit}
                  size='small'
                >
                  {this.props.translate('sharedUpdate')}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
