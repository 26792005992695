import L from 'leaflet';
import { createLayerComponent } from '@react-leaflet/core';
import 'leaflet-rotatedmarker';

// Save these original methods before they are overwritten
const proto_initIcon = L.Marker.prototype._initIcon;
const proto_setPos = L.Marker.prototype._setPos;

L.Marker.addInitHook(function () {
  this.options.animationTime = this.options.animationTime || 0;

  this.on('drag', function (e) {
    e.target._applyAnimationTime();
  });
});

L.Marker.include({
  _initIcon: function () {
    proto_initIcon.call(this);
  },

  _setPos: function (pos) {
    proto_setPos.call(this, pos);
    this._applyAnimationTime();
  },

  _applyAnimationTime: function () {
    if (this.options.animationTime) {
      this._icon.style[L.DomUtil.TRANSITION] =
        'transform ' + this.options.animationTime + 's linear';
    }
  },

  setAnimationTime: function (animationTime) {
    this.options.animationTime = animationTime;
    this.update();
    return this;
  }
});

const createRotatedMarker = (props, context) => {
  const { position, rotationAngle, rotationOrigin, animationTime, ...options } = props;
  const marker = new L.Marker(position, {
    ...options,
    rotationAngle,
    rotationOrigin,
    animationTime
  });

  return { instance: marker, context: { ...context, overlayContainer: marker } };
};

const updateRotatedMarker = (marker, props, prevProps) => {
  if (props.position !== prevProps.position) {
    marker.setLatLng(props.position);
  }
  if (props.rotationAngle !== prevProps.rotationAngle) {
    marker.setRotationAngle(props.rotationAngle);
  }
  if (props.rotationOrigin !== prevProps.rotationOrigin) {
    marker.setRotationOrigin(props.rotationOrigin);
  }
  if (props.animationTime !== prevProps.animationTime) {
    marker.setAnimationTime(props.animationTime);
  }
};

const RotatedMarker = createLayerComponent(createRotatedMarker, updateRotatedMarker);

export default RotatedMarker;