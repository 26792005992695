
export const getCommands = commands => ({
    type: 'GET_COMMANDS',
    commands
});
export const addCommands = commands => ({
    type: 'ADD_COMMANDS',
    commands
});
export const removedCommands = commands => ({
    type: 'REMOVED_COMMANDS',
    commands
});
export const getCommandsTypes = commands => ({
    type: 'GET_COMMANDS_TYPES',
    commands
});


export const getCommandsDeviceWise = commands => ({
    type: 'GET_COMMANDS_DEVICE_WISE',
    commands
});
export const addCommandsDeviceWise = commands => ({
    type: 'ADD_COMMANDS_DEVICE_WISE',
    commands
});
export const RemoveCommandsDeviceWise = commands => ({
    type: 'REMOVE_COMMANDS_DEVICE_WISE',
    commands
});
export const commandsDeviceWiseSelectedList = commands => ({
    type: 'COMMANDS_DEVICE_WISE_SELECTED_LIST',
    commands
});
export const addCommandsDeviceWiseSelectedList = commands => ({
    type: 'ADD_COMMANDS_DEVICE_WISE_SELECTED_LIST',
    commands
});
export const updateCommandsDeviceWiseSelectedList = commands => ({
    type: 'UPDATE_COMMANDS_DEVICE_WISE_SELECTED_LIST',
    commands
});
export const removedCommandsDeviceWiseSelectedList = commands => ({
    type: 'REMOVED_COMMANDS_DEVICE_WISE_SELECTED_LIST',
    commands
});