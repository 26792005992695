import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../../Layout";
import { getVehicles, removedvehicle } from "../../Actions/Vehicles";
import Grid from "@mui/material/Grid";
import { VehicleModal } from "../../Components/Vehicles/vehicleModal";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { Api, checkPrivileges, errorHandler } from "../../Helpers/index";
import CustomMarker from "../../Components/Maps/CustomMarker";
import { MapTooltip } from "../../Components/Maps/MapTooltip";
import axios from "axios";
import moment from "moment";
import {
  MapContainer as Map,
  TileLayer,
  Tooltip,
  ZoomControl,
  LayersControl,
} from "react-leaflet";

import { MapTileLayers } from "../../Reducers/Maps";
import L from "leaflet";
import Style from "style-it";
import { setTrackId } from "../../Actions/Devices";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import instance from "../../axios";
import Table1 from "../../Components/common/TableWithColors";
import Dialog from "../../Components/common/Dialog";
import Button from "../../Components/common/Button";
import EnhancedTable from "./TableServices";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./../../images", false, /\.(png|jpe?g|svg)$/)
);
const CancelToken = axios.CancelToken;
let source;
class vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      selecteItemUrl: "",
      activeOperation: "",
      vehicleAddress: "",
      vehicleLat: "",
      vehicleLon: "",
      selecteditemId: props.match.params.id,
      trackersApiResponce: false,
      trackerData: "",
      assignVehicle: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      resourceList: false,
      allVehicleLocation: {},
      showWaslShortList: false,
      sequenceNumber: "",
      fetchModelId: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      areaList: [],
      selectedUser: "",
      showImportedVehicleSampleError: false,
      allVehiclesValid: false,
      isImportBtnDisabled: true,
      isVehicleStatus: false,

      validVehicles: [],
      inValidVehicles: [],
      uploadedVehicles: [],
      vehicleStatusButton: "all",
      vehicleUplaodPageSize: 10,
    };
    source = CancelToken.source();
  }

  componentWillReceiveProps(n) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude,
      });
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude,
      });
    }
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            n.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
    }
    if (!n.match.params.id && this.props.match.params.id) {
      this.setState({ selecteditemId: this.props.match.params.id });
    }

    if (this.props.trackId !== n.trackId) {
      this.setState({ allVehicleLocation: {}, animCount: 0 }, () => {
        this.calculate(n);
      });
    } else {
      this.calculate(n);
    }
    if (
      n.match.params.id &&
      n.vehicles.length &&
      n.match.url !== this.state.selecteItemUrl
    ) {
      if (source) {
        source.cancel();
      }
      this.selectedItem(n.match.params.id);
    }
  }

  checkZoom = () => {
    if (this.state.applied === true) {
      this.setState({ tracking: false, applied: false, animCount: 0 }, () => {
        this.props.dispatch(setTrackId(0));
      });
      this.setState({ allTrailerLocation: {} }, () => {
        this.calculate(this.props);
      });
    }
  };

  calculate = (n) => {
    if (n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids = n.vehicles.map((d) => {
        list[d.deviceId] = d;
        return d.deviceId;
      });
      const vehicles = {};
      Object.values(n.deviceRelatedData).map((d) => {
        if (ids.includes(d.id)) {
          vehicles[d.id] = d;
          vehicles[d.id].vehicle = list[d.id];
        }
        return null;
      });
      this.setState({ allVehicleLocation: vehicles });
    }

    if (
      this.state.showItemDetail &&
      n.vehicles &&
      n.vehicles.length &&
      n.deviceRelatedData &&
      Object.values(n.deviceRelatedData).length &&
      n.match &&
      n.match.params &&
      n.match.params.id &&
      this.props.renderMode === "view"
    ) {
      const vehicleLocation = Object.values(n.deviceRelatedData).find(
        ({ id, vehicle }) =>
          vehicle && parseInt(vehicle.id) === parseInt(n.match.params.id)
      );
      if (
        vehicleLocation &&
        vehicleLocation.latitude &&
        vehicleLocation.longitude
      ) {
        this.setState({ vehicleLocation: vehicleLocation || null }, () => {
          if (vehicleLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [vehicleLocation.latitude, vehicleLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(n.mapLayer.maxZoom);
              this.setState({ applied: true, animCount: 1 });
            }, 200);
          }
        });
      }
    }
  };

  componentDidUpdate() {
    if (
      this.state.assignVehicle === false &&
      this.props.vehicles &&
      this.props.devices &&
      this.props.devices.length > 0 &&
      this.state.itemPagination &&
      this.state.itemPagination.total > 0
    ) {
      this.setState({ assignVehicle: true }, () => {
        const selecteditem = this.props.vehicles.find(
          (v) => "" + v.id === "" + this.state.selecteditemId
        );
        if (selecteditem && this.props.renderMode === "edit") {
          this.editItem(selecteditem);
        }
      });
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.latitude &&
      this.props.logInUser.longitude &&
      this.state.assigned === false
    ) {
      this.setState({
        assigned: true,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
  }

  componentWillMount() {
    if (this.props.ServerSetting && this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude,
      });
    }
    if (this.props.logInUser && this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
    if (this.props.match.params.id) {
      this.setState({ selecteditemId: this.props.match.params.id });
    }
    if (
      this.props.match.params.id &&
      this.props.vehicles.length &&
      this.props.match.url !== this.state.selecteItemUrl
    ) {
      if (source) {
        source.cancel();
      }
      this.selectedItem(this.props.match.params.id);
    }

    let trailerUniqueId = 0;
    if (
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length
    ) {
      const list = {};
      const ids = this.props.vehicles.map((d) => {
        list[d.deviceId] = d;
        if (d.id === parseInt(this.props.match.params.id)) {
          trailerUniqueId = d.id;
        }
        return d.deviceId;
      });
      const vehicles = {};
      Object.values(this.props.deviceRelatedData).map((d) => {
        if (ids.includes(d.id)) {
          vehicles[d.id] = d;
          vehicles[d.id].vehicle = list[d.id];
        }
        return null;
      });
      this.setState({ allVehicleLocation: vehicles });
    }

    if (
      this.state.showItemDetail &&
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.renderMode === "view"
    ) {
      const vehicleLocation = Object.values(this.props.deviceRelatedData).find(
        ({ id }) =>
          trailerUniqueId && parseInt(id) === parseInt(trailerUniqueId)
      );
      if (
        vehicleLocation &&
        vehicleLocation.latitude &&
        vehicleLocation.longitude
      ) {
        this.setState({ vehicleLocation: vehicleLocation || null }, () => {
          if (vehicleLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [vehicleLocation.latitude, vehicleLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(this.props.mapLayer.maxZoom);
              this.setState({ applied: true });
            }, 100);
          }
        });
      }
    }
    if (checkPrivileges("area")) {
      this.getAreaList();
    }
  }
  onCloseImportedVehicleModal = () => {
    this.setState({
      showImportedVehicleSampleError: false,
    });
  };
  getAreaList = () => {
    instance({
      url: `/api/areas`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        let List = res.map((item) => {
          return {
            key: item.id,
            name: item.name,
            uniqueId: item.id,
            valueType: "string",
          };
        });
        this.setState({ areaList: List });
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    this.setState({
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      activeOperation: "",
      vehicleAddress: "",
      vehicleLat: "",
      vehicleLon: "",
      selecteditemId: "",
      trackersApiResponce: false,
      multiTrackers: "",
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      animCount: 0,
      allVehicleLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  searchItems = (text) => {
    if (source) {
      source.cancel();
    }
    this.setState(
      {
        searchText: text,
      },
      () => {
        source = CancelToken.source();
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true);
      }
    );
  };
  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser.id,
      this.state.currentPage,
      this.state.pagesize,
      a
    );
  };

  removeDuplicates = (n, o) => {
    let items = [];
    o.map((j) => {
      let found = n.data.find((e) => e.id === j.id);
      if (found) {
        items.push(found);
      }
      return null;
    });

    if (!items.length) {
      this.setState(
        {
          itemPagination: {
            ...n,
            items: o.concat(n.data),
          },
          currentPage: n.hasNext ? n.page + 1 : n.page,
        },
        () => {
          this.props.dispatch(getVehicles(o.concat(n.data)));
        }
      );
    }
  };

  fetchData = (userId, page, perPage, reset) => {
    const query = "all=true&userId=" + userId;
    let searchText = this.state.searchText;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }
    let items = this.state.itemPagination.items;
    if (reset) {
      this.setState(
        {
          itemPagination: { ...this.state.itemPagination, items: [] },
        },
        () => {
          items = [];
        }
      );
    }

    instance({
      method: "GET",
      url: `/api/vehicles/get?${query}&page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 200) {
        // if (response.data.status === 'success') {

        this.setState({ fetchModelId: false }, () =>
          this.removeDuplicates(response, items)
        );
        // } else {
        //something went wrong
        // }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  addItem = () => {
    this.setState(
      {
        isVisable: true,
        showItemDetail: false,
        selecteditem: "",
        trackerData: "",
        activeOperation: "add",
      },
      () => this.props.history.push("/vehicles")
    );
  };
  showResources = (type) => {
    this.setState({
      resourceList: type,
    });
  };
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "addResource",
      selecteditem: "",
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "editResource",
      selecteditem: item,
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  };

  removedItem = (item) => {
    // fetch(`/api/vehicles/${item.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/vehicles/${item.id}`,
      method: "DELETE",
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((data) => {
        // if (data.status) {
        this.props.dispatch(removedvehicle(item));
        this.onCloseModal();
        this.setState({
          selectedVehicle: "",
        });
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true);
        this.props.dispatch(
          notifySuccess(this.props.translate("vehicleIsDeleted"))
        );
        // } else {
        //   this.props.dispatch(
        //     Notifications.success({
        //       message: data.message,
        //       autoDismiss: 10
        //     })
        //   )
        // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  getSelectedDevice = (trailerUniqueId) => {
    if (trailerUniqueId) {
      const device = this.props.devices.find((d) => d.id === trailerUniqueId);
      if (device) {
        this.setState({
          trackerData: {
            value: device.id,
            label: device.name,
            uniqueId: device.uniqueId,
          },
        });
      } else {
        this.setState({
          trackerData: "",
        });
      }

      if (
        this.map &&
        this.props.deviceRelatedData &&
        Object.values(this.props.deviceRelatedData).length
      ) {
        const vehicleLocation = Object.values(
          this.props.deviceRelatedData
        ).find(({ id }) => id === trailerUniqueId);
        this.setState({
          vehicleLocation:
            vehicleLocation && vehicleLocation.exists ? vehicleLocation : null,
          animCount: 0,
        });
        if (vehicleLocation && vehicleLocation.exists) {
          this.props.dispatch(setTrackId(vehicleLocation.id));
        } else {
          this.props.dispatch(setTrackId(0));
          this.map.setZoom(3);
        }
      }
    }
    this.setState({
      trackersApiResponce: true,
    });
  };
  getFuelEfficiency = async (type) => {
    // console.log("type====", type);
    let result = await axios.get(`/api/vehicles/fuelefficiency?type=${type}`);
    const [key] = Object.keys(result.data);
    const value = result.data[key];
    // console.log("er=====", value);
    this.setState({
      form: {
        ...this.state.form,
        fuelEfficiency: value || 0,
      },
    });
    return value;
  };
  editItem = async (item) => {
    // const x = await this.fetchSelectedUser(item.parentId)
    let fuelEfficiency;
    // if(!this.state.fetchModelId && item.modelId){
    if (item.modelId) {
      // console.log("x====",x);

      const z = await this.fetchSelectedModel(item.modelId);

      if (z) {
        this.setState({
          selecteditem: {
            ...item,
            modelId: { id: z.id, label: z.modelName },
            make: { id: z.makeName, key: z.makeName, label: z.makeName },
            // parentId:x&&x.id?{id:x.id,key:x.id, label:x.name || x.label,value:x.id}:""
          },
          fetchModelId: true,
        });
      }
    } else {
      this.setState({
        selecteditem: item,
        fetchModelId: false,
      });
    }
    if (item.deviceId) {
      this.setState(
        {
          trackersApiResponce: false,
        },
        () => this.getSelectedDevice(item.deviceId)
      );
    } else {
      this.setState({
        trackersApiResponce: true,
      });
    }
    if (item.category) {
      fuelEfficiency = await this.getFuelEfficiency(item.category);
    }
    this.setState({
      isVisable: true,
      showItemDetail: false,
      selecteditem: this.state.fetchModelId ? this.state.selecteditem : item,
      activeOperation: "edit",
      trackerData: "",
      fuelEfficiency: item.fuelEfficiency
        ? item.fuelEfficiency
        : fuelEfficiency || "",
      // selectedUser:x
    });
    this.props.history.push("/vehicles/" + item.id);
  };

  async fetchSelectedUser(id) {
    return await instance({
      method: "GET",
      url: `/api/users/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }
  async fetchSelectedModel(id) {
    return await instance({
      method: "GET",
      url: `/api/models?modelId=${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response[0];
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  showWaslDetails = (item) => {
    this.setState(
      {
        showItemDetail: false,
        isVisable: false,
        onDeleteConfirmation: false,
        showWaslShortList: false,
        vehicleHookData: "",
      },
      () => {
        this.getWaslDetails(item);
      }
    );
  };

  getWaslDetails = (item) => {
    if (item.attributes.sequenceNumber) {
      axios
        .post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: "getVehicle",
          account_id: this.props.logInUser && this.props.logInUser.accountId,
          data: {
            sequenceNumber: `${
              item.attributes && item.attributes.sequenceNumber
            }`,
            activity: "DEFAULT",
          },
        })
        .then((res) => {
          if (res && res.data && res.data.success) {
            this.setState({
              vehicleHookData: res.data,
              sequenceNumber: item.attributes && item.attributes.sequenceNumber,
              showWaslShortList: true,
            });
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.data &&
            err.response.data.data.errorMsg
          ) {
            this.props.dispatch(notifyError(err.response.data.data.errorMsg));
          }
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.data &&
            err.response.data.data.resultCode
          ) {
            this.props.dispatch(notifyError("Vehicle Not Found"));
          }
        });
    } else {
      this.props.dispatch(notifyError("Sequence Number doesnot exists"));
    }
  };
  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: "",
      activeOperation: "",
      onDeleteConfirmation: false,
      showWaslShortList: false,
    });
    this.props.history.push("/vehicles");
  };
  selectedItem = async (peramId) => {
    let data;
    this.setState({
      selecteItemUrl: this.props.match.url,
    });
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();
    try {
      const response = await instance({
        method: "GET",
        url: `/api/vehicles/get?vehicleId=${peramId}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // if (response.status === 'success') {
      const data = response && response && response.data;
      const dd = (await this.props.devices.length) > 0;
      data.map((item) => {
        this.setState(
          {
            selecteditem: item,
            selecteditemId: item.id,
          },
          () => {
            if (this.props.renderMode === "view") {
              this.selecteItem(item);
            } else if (this.props.renderMode === "edit") {
              this.editItem(item);
            }
          }
        );
      });
      // }
    } catch (error) {
      //  errorHandler(error,this.props.dispatch)
    }
  };
  selecteItem = (item) => {
    this.props.dispatch(setTrackId(0));
    this.setState({ allVehicleLocation: {} }, () => {
      this.getSelectedDevice(item.deviceId);
      this.calculate(this.props);
      this.setState(
        {
          showItemDetail: true,
          isVisable: false,
          activeOperation: "details",
          trackersApiResponce: false,
          trackerData: "",
          multiTrackers: "",
          vehicleLocation: null,
          animCount: 0,
          tracking: true,
          applied: false,
          onDeleteConfirmation: false,
        },
        () => this.getSelectedDevice(item.deviceId)
      );
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  mapReference = (el) => {
    if (el) {
      this.map = el.leafletElement;
    }
  };

  onRemovedItem = (item) => {
    this.setState({
      selecteditem: item,
      onDeleteConfirmation: true,
    });
  };

  openImportTable = (items, user) => {
    let validVehicles = [];
    let invalidVehicles = [];
    let newArray1;
    let userId = user ? user.id : this.props.logInUser.id;
    let status = false;

    items.forEach((item) => {
      if (
        item.attributes.invalidList &&
        item.attributes.invalidList.length > 0
      ) {
        // If invalidList is not empty, mark the vehicle as invalid
        invalidVehicles.push(item);
      } else {
        // If invalidList is empty, mark the vehicle as valid
        validVehicles.push(item);
      }
    });

    if (user === "vehicleStatus") {
      newArray1 = items.filter((item) => !item.attributes.invalid);
      this.setState({ isVehicleStatus: true });
    } else {
      newArray1 = items.map((item) => {
        const {
          attributes: { invalidList, ...restAttributes },
          modelName,
          makeName,
          ...restItem
        } = item;
        // Create a new object without the invalidList, modelName, makeName property
        const newObj = {
          ...restItem,
          attributes: restAttributes,
        };
        return newObj;
      });
      this.setState({ isVehicleStatus: false });
    }

    //  if(status === false) {
    this.setState({ isImportBtnDisabled: false });
    //  } else {
    //    this.setState({ isImportBtnDisabled: true })
    //  }
    // this.setState({
    //   allVehiclesValid:status,
    //   showImportedVehicleSampleError: true,
    //   importedInvoiceData: items,
    //   selectedUserId:userId,
    //   selectedVehicleList:newArray1
    // });
    this.setState({
      showImportedVehicleSampleError: true,

      allVehiclesValid: status,
      showImportedInvoiceError: true,
      importedInvoiceData: items,
      uploadedVehicles: items,
      selectedUserId: userId,
      validVehicles: validVehicles,
      inValidVehicles: invalidVehicles,
      selectedVehicleList: newArray1,
    });
  };
  SubmitImport = (e) => {
    const objFor =
      this.state.selectedVehicleList && this.state.selectedVehicleList;
    const url = this.state.isVehicleStatus
      ? `/api/vehicles/vehiclewrappersstatus`
      : `/api/vehicles/vehiclewrappers?userId=${this.state.selectedUserId}`;
    instance({
      url: url,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: objFor,
    })
      .then((res) => {
        this.props.dispatch(
          notifySuccess(
            "Total " +
              res.total +
              "   " +
              "  Added " +
              "   " +
              res.added +
              // "   " +
              // "  Updated " +
              // "   " +
              // res.updated
              // +
              "    " +
              " Skipped " +
              "   " +
              res.skipped
          )
        );
        // this.onCloseColorModel();
        this.setState(
          {
            showImportedVehicleSampleError: false,
            vehicleStatusButton: "all",
          },
          () => {
            this.onCloseImportedVehicleModal();
          }
        );
      })
      .catch((err) => {
        const splitString = err.message.split(": ");
        let result;
        if (splitString.length >= 2) {
          result = splitString[1]; // Take the second part after splitting
        }
        this.props.dispatch(notifyError(result));
      });
  };
  handleChangeTabs = (value) => {
    this.setState(
      {
        vehicleStatusButton: value,
        vehicleUplaodPageSize: 10,
      },
      () => {
        this.setState({
          uploadedVehicles:
            value === "valid"
              ? this.state.validVehicles
              : value === "inValid"
              ? this.state.inValidVehicles
              : value === "all"
              ? this.state.importedInvoiceData
              : [],
        });
      }
    );
  };
  render() {
    let crs = {};

    if (["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 };
    }

    const body = [
      <>
        <ZoomControl position={"bottomright"} />
      </>,
    ];

    const position = [this.state.lat, this.state.lng];

    const thisMap = [
      <Map
        key={1}
        ref={this.mapReference}
        onZoomAnim={this.checkZoom}
        zoomControl={false}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        boundsOptions={this.setBoundOptions}
        style={{ height: this.props.height, width: this.props.width }}
        center={position}
        zoom={this.state.zoom}
        minZoom={this.state.minZoom}
        maxZoom={this.props.mapLayer.maxZoom}
        maxNativeZoom={this.props.mapLayer.maxZoom}
        {...crs}
      >
        {this.state.pointer}
        {body}

        {this.state.allVehicleLocation &&
        Object.keys(this.state.allVehicleLocation).length
          ? Object.values(this.state.allVehicleLocation).map((row) =>
              row && row.exists ? (
                <CustomMarker
                  key={row.id}
                  position={{
                    lat: row.latitude,
                    lng: row.longitude,
                    updated: moment(row.serverTime),
                  }}
                  rotationAngle={0}
                  rotationOrigin="center"
                  animationTime={
                    this.state.animCount > 0 &&
                    (this.state.applied === true) &
                      (this.props.trackId === row.id)
                      ? row.animationTime
                      : 0
                  }
                  icon={L.divIcon({
                    iconUrl:
                      "/assets/category/default/" +
                      (row.category || "default") +
                      "top.svg",
                    iconSize: [50, 50],
                    iconAnchor: [25, 25],
                    tooltipAnchor: [0, -20],
                    className: "custom-marker",
                    html: `<img
                  style="transform: rotate(${row.course}deg)"
                    src=
                      '/assets/category/default/${
                        row.category || "default"
                      }top.svg'
                    
                    alt=''
                  />`,
                  })}
                  iconSize={[50, 50]}
                >
                  <Tooltip direction={"top"}>
                    <MapTooltip
                      themecolors={this.props.themecolors}
                      position={row}
                      device={row}
                      trailer={row.trailer}
                      trailer2={this.state.selecteditem}
                      logInUser={this.props.logInUser}
                      translate={this.props.translate}
                    />
                  </Tooltip>
                </CustomMarker>
              ) : null
            )
          : null}
        <LayersControl position="bottomright">
          {MapTileLayers.map((layer) => (
            <LayersControl.BaseLayer
              id={layer.id}
              checked={layer.id === this.props.mapLayer.id}
              key={layer.id}
              name={layer.name}
            >
              <TileLayer {...layer} minZoom={this.state.minZoom} />
            </LayersControl.BaseLayer>
          ))}
        </LayersControl>
      </Map>,
    ];

    if (checkPrivileges("vehicle")) {
      return (
        <>
          <Layout
            {...this.props}
            addItem={this.addItem}
            editItem={this.editItem}
            showWaslDetails={this.showWaslDetails}
            removedItem={this.onRemovedItem}
            selecteItem={this.selectedItem}
            fetchMoreItems={this.fetchMoreItems}
            itemPagination={{ ...this.state.itemPagination }}
            searchItems={this.searchItems}
            allVehicleLocation={this.state.allVehicleLocation}
            showResources={this.showResources}
            onEditResource={this.onEditResource}
            onLinkResource={this.onLinkResource}
            addResource={this.addResource}
            resourceList={this.state.resourceList}
            openImportTable={this.openImportTable}
          >
            <Style>{`
              .leaflet-control-layers-toggle {
                  background: url('/assets/images/maps/layers.png') no-repeat center;
                } 
            `}</Style>
            {!this.state.isVisable ? (
              <div>
                {["osm", ""].includes(this.props.mapLayer.id) ? thisMap : null}
                {["carto"].includes(this.props.mapLayer.id) ? thisMap : null}
                {["gccStreet"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleTerrain"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleSatellite"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleHybrid"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleRoad"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["baidu"].includes(this.props.mapLayer.id) ? thisMap : null}
                {["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
              </div>
            ) : null}

            {(this.state.showItemDetail || this.state.showWaslShortList) && (
              <>
                <VehicleModal
                  onCloseModal={this.onCloseModal}
                  devicesIcons={images}
                  currentLocation={this.state.vehicleLocation}
                  devices={this.props.devices}
                  resourceList={this.state.resourceList}
                  getSelectedDevice={this.getSelectedDevice}
                  {...this.state}
                  fetchData={this.fetchData}
                  translate={this.props.translate}
                  getFuelEfficiency={this.getFuelEfficiency}
                />
              </>
            )}
            <ResourceModal
              changeResource={this.props.changeResource}
              selectedResourse={this.state.selecteditem}
              activeOperation={this.state.activeOperation}
              onCloseResource={this.onCloseResource}
              itemPagination={this.props.vehicles}
              assignItem={this.props.assignItem}
              unassignItem={this.props.unassignItem}
              fetchNestedItems={this.props.fetchNestedItems}
              nestedResources={this.props.nestedResources}
              translate={this.props.translate}
              editResource={this.state.editResource}
              linkResource={this.state.linkResource}
              themecolors={this.props.themecolors}
              itemType="Vehicle"
              title="sharedVehicle"
            />

            {!this.state.showItemDetail ? (
              <div className="main-content-page">
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                  }}
                >
                  {this.state.isVisable &&
                    ["add", "edit"].includes(this.state.activeOperation) && (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <VehicleModal
                            resourceList={this.state.resourceList}
                            onCloseModal={this.onCloseModal}
                            getSelectedDevice={this.getSelectedDevice}
                            devices={this.props.devices}
                            {...this.state}
                            getFuelEfficiency={this.getFuelEfficiency}
                            translate={this.props.translate}
                            fetchData={this.fetchData}
                          />
                        </Grid>
                      </Grid>
                    )}
                </div>
              </div>
            ) : null}

            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={() => this.removedItem(this.state.selecteditem)}
                title={this.props.translate("areYouWantToDelete")}
                children={this.state.selecteditem.label}
              />
            )}
            {this.state.showImportedVehicleSampleError && (
              <Dialog
                title={this.props.translate("vehicle")}
                onClose={this.onCloseImportedVehicleModal}
                bodyPadding={10}
                fullWidth
                isVisableBtn
                noFullScreen
                maxWidth="lg"
                headerActions={
                  <Button
                    // disabled={this.state.isImportBtnDisabled}
                    onClick={this.SubmitImport}
                  >
                    {this.props.translate("submit")}
                  </Button>
                }
              >
                {/* this.state?.allVehiclesValid ? */}
                {this.state.isVehicleStatus ? (
                  <EnhancedTable
                    rows={this.state.uploadedVehicles || []}
                    isCursoPointer
                    rowDefinition={[
                      {
                        id: "vehicleLicensePlate",
                        label: this.props.translate("vehicleLicensePlate"),
                      },
                      {
                        id: "vehicleStatus",
                        label: this.props.translate("status"),
                      },
                    ]}
                  />
                ) : (
                  <>
                    <div>
                      <Button
                        variant="contained"
                        disabled={false}
                        onClick={() => this.handleChangeTabs("all")}
                        style={{
                          // width: "50%",
                          // borderTopRightRadius: " 15px",
                          // borderTopLeftRadius: "15px",
                          marginLeft: "5px",
                          backgroundColor:
                            this.state.vehicleStatusButton === "all"
                              ? "#1E72B8"
                              : "#ffff",
                          color:
                            this.state.vehicleStatusButton === "all"
                              ? "#ffff"
                              : "#1E72B8",
                        }}
                      >
                        {" "}
                        {this.props.translate("all")}
                      </Button>
                      <Button
                        variant="contained"
                        disabled={false}
                        onClick={() => this.handleChangeTabs("valid")}
                        style={{
                          // width: "50%",
                          // borderTopRightRadius: " 15px",
                          // borderTopLeftRadius: "15px",
                          marginLeft: "5px",
                          backgroundColor:
                            this.state.vehicleStatusButton === "valid"
                              ? "#1E72B8"
                              : "#ffff",
                          color:
                            this.state.vehicleStatusButton === "valid"
                              ? "#ffff"
                              : "#1E72B8",
                        }}
                      >
                        {" "}
                        {this.props.translate("valid")}
                      </Button>
                      <Button
                        variant="contained"
                        disabled={false}
                        onClick={() => this.handleChangeTabs("inValid")}
                        style={{
                          // width: "50%",
                          // borderTopRightRadius: " 15px",
                          // borderTopLeftRadius: "15px",
                          marginLeft: "5px",
                          backgroundColor:
                            this.state.vehicleStatusButton === "inValid"
                              ? "#1E72B8"
                              : "#ffff",
                          color:
                            this.state.vehicleStatusButton === "inValid"
                              ? "#ffff"
                              : "#1E72B8",
                        }}
                      >
                        {" "}
                        {this.props.translate("inValid")}
                      </Button>
                    </div>
                    {this.state.uploadedVehicles.length > 0 ? (
                      <Table1
                        rows={this.state.uploadedVehicles || []}
                        rowsPerPage={10}
                        // isEditable={true}
                        themecolors={this.props.themecolors}
                        // filterDropdown
                        // setSerialNo
                        // setSerialNo
                        isCursoPointer
                        rowDefinition={
                          this.props.ServerSetting.contractType === 2
                            ? [
                                {
                                  id: "label",
                                  label: this.props.translate("label"),
                                },
                                {
                                  id: "category",
                                  label: this.props.translate("vehicleType"),
                                },
                                {
                                  id: "vehicleLicensePlate",
                                  label: this.props.translate(
                                    "vehicleLicensePlate"
                                  ),
                                },

                                {
                                  id: "make",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("make"),
                                },
                                {
                                  id: "model",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("model"),
                                },
                                {
                                  id: "category",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("category"),
                                },
                                {
                                  id: "capacity",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("capacity"),
                                },

                                {
                                  id: "vin",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("vin"),
                                },
                                {
                                  id: "garage",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("garage"),
                                },
                                {
                                  id: "deliveryDate",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("deliveryDate"),
                                },
                                {
                                  id: "leaseStartDate",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("leaseStartDate"),
                                },
                                {
                                  id: "leaseEndDate",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("leaseEndDate"),
                                },
                              ]
                            : [
                                {
                                  id: "label",
                                  label: this.props.translate("label"),
                                },
                                {
                                  id: "vehicleLicensePlate",
                                  label: this.props.translate(
                                    "vehicleLicensePlate"
                                  ),
                                },

                                {
                                  id: "make",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("make"),
                                },
                                {
                                  id: "model",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("model"),
                                },
                                {
                                  id: "category",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("category"),
                                },
                                {
                                  id: "capacity",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("capacity"),
                                },
                                {
                                  id: "vin",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("vin"),
                                },
                                {
                                  id: "garage",
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate("garage"),
                                },
                              ]
                        }
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.props.translate("nodataFound")}
                      </div>
                    )}
                  </>
                )}
                {/* "All Vehicles are Valid"} */}
              </Dialog>
            )}
          </Layout>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    vehicles: state.vehicles,
    positions: state.positions,
    devices: state.devices3,
    themecolors: state.themeColors,
    deviceRelatedData: state.deviceRelatedData,
    trackId: state.trackId,
    mapLayer: state.mapLayer,
  };
};
export default connect(mapStateToProps)(
  withResources(withTranslationWrapper(vehicle, "Vehicle"))
);
