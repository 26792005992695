/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import NoSsr from '@mui/material/NoSsr'
import TextField from './TextField'
import SearchField from './SearchField'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import MenuItem from './MenuItem'
// import { emphasize } from '@mui/material/styles/colorManipulator'
import { alpha } from '@mui/material/styles';
import Style from 'style-it'
import { connect } from 'react-redux'
import { selectStyles } from '../../Helpers'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex'
    //padding: '8px 14px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1)
  },
  chipFocused: {
    backgroundColor: alpha(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(1) * 2
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  if (props.selectProps.fill) {
    return (
      <SearchField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  } else {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  }
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function SingleValue (props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue (props) {
  return null
}

function Menu (props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      style={{ zIndex: 2 }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer
}

class IntegrationReactSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      single: null,
      multi:
        (this.props.multi &&
          this.props.multi.map(o => {
            return { value: o.id, label: o.name || o.label }
          })) ||
        null
    }
  }

  /* componentWillReceiveProps(nextProps){
    if(!isEqual(this.props.multi, nextProps.multi)) {
      this.setState({
        multi: (this.props.multi && this.props.multi.map((o) => { return {value: o.id, label: o.name}})) || null,
      });
    }
  } */

  handleChange = name => value => {
    if (value.length && this.props.canAssign) {
      this.setState(
        {
          [name]: value
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.multi)
          }
          if (this.props.returnSelected) {
            this.props.returnSelected(value, 'POST')
          }
        }
      )
    } else {
      if (this.props.canRemove) {
        this.setState(
          {
            [name]: value
          },
          () => {
            if (this.props.onChange) {
              this.props.onChange(this.state.multi)
            }
            if (this.props.returnSelected) {
              this.props.returnSelected(value, 'POST')
            }
          }
        )
      }
    }
  }

  handleDelete (data) {
    let newChips = []
    if (this.state.multi.length && this.props.canRemove) {
      this.state.multi.map(chip => {
        if (data.value !== chip.value) {
          newChips.push(chip)
        } else {
          return null
        }
        return null
      })

      //this.props.removeProps.onClick();

      this.setState({ multi: newChips }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.multi)
        }
        if (this.props.returnSelected) {
          this.props.returnSelected([data], 'DELETE')
        }
      })
    }
  }

  render () {
    const { classes, theme, array, themecolors } = this.props

    const suggestions = array.map(suggestion => {
      if (this.state.multi && this.state.multi.length) {
        if (
          !this.state.multi.filter(s => s.value === suggestion.value).length
        ) {
          return {
            value: suggestion.id,
            label: suggestion.name || suggestion.label
          }
        } else {
          return {}
        }
      } else {
        return {
          value: suggestion.id,
          label: suggestion.name || suggestion.label
        }
      }
    })
    let chips

    if (this.state.multi && this.state.multi.length) {
      chips = this.state.multi.map(chip => (
        <Chip
          className='chip-tags'
          key={chip.value}
          label={chip.label}
          variant='outlined'
          onDelete={e => this.handleDelete(chip, e)}
          style={{ marginRight: 5, marginBottom: 5 }}
        />
      ))
    }

    return (
      <div className={classes.root}>
        <Style>
          {`
        .custom-select .chip-tags {
          background: #fafafa;
          border-radius: 4px;
          border-color: transparent;
          color: #000;
        }
        .chip-wrapper {
          padding: 10px 0;
        }
        .chip-wrapper-fill {
          padding: 10px;
          background: ${this.props.themecolors.themeLightColor};
        }
      `}
          <div className='custom-select'>
            <NoSsr>
              <Select
                {...this.props}
                classes={classes}
                styles={selectStyles(themecolors)}
                textFieldProps={{
                  label: this.props.label || 'Select multiple',
                  InputLabelProps: {
                    shrink: true
                  }
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral0: themecolors.themeDarkColor,
                    neutral5: themecolors.textColor,
                    neutral10: themecolors.textColor,
                    neutral20: themecolors.textColor,
                    neutral30: themecolors.textColor,
                    neutral40: themecolors.textColor,
                    neutral50: themecolors.textColor,
                    neutral60: themecolors.textColor,
                    neutral70: themecolors.textColor,
                    neutral80: themecolors.textColor,
                    neutral90: themecolors.textColor,
                    dangerLight: themecolors.themeDarkColor,
                    danger: themecolors.textColor,
                    primary50: themecolors.themeLightColor,
                    primary25: themecolors.themeLightColor,
                    primary: themecolors.themeLightColor
                  }
                })}
                options={suggestions}
                // components={components}
                value={this.state.multi}
                onChange={this.handleChange('multi')}
                placeholder=''
              />
            </NoSsr>
          </div>
        </Style>
      </div>
    )
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(IntegrationReactSelect)
)
