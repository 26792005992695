export const drivers = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'GET_DRIVERS':
      return action.drivers

    case 'ADD_DRIVER':
      return state.concat(action.driver)

    case 'ADD_DRIVERS':
      return state.concat(action.drivers)

    case 'UPDATE_DRIVER':
      let updatedata = state.map(m => {
        if (m.id !== action.driver.id) {
          return m
        } else {
          return action.driver
        }
      })

      return updatedata

    case 'REMOVE_DRIVER':
      let _driverList = state.filter(driver => driver.id !== action.driver.id)

      return _driverList
    case 'SORT_DRIVERS':
      var drivers1 = []
      if (action.drivers.sort === 'DESC') {
        drivers1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        drivers1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return drivers1

    default:
      return state
  }
}
export const searchDriver = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return ''
    case 'SEARCH_DRIVER':
      return action.payload
    default:
      return state
  }
}

export const isDriverLoad = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_DRIVERS':
    return action.loadDriver
    default:
      return state
  }
}
