import React, { Component } from 'react'
import { connect } from 'react-redux'
;

import Layout from '../../Layout'
import { ShowReports } from '../../Components/Trash/'
import { logInUserInfo } from '../../Actions/Users'
import { checkPrivileges, errorHandler, getCookie, isOwnerOrDealer } from '../../Helpers'
import instance from '../../axios'

class SystemLogs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      userFetch: false
    }
  }

  render () {
      if (this.props.logInUser ) {
        return (
          <Layout
          noSidebar
            {...this.props}
          >
              <div
                style={{
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
                borderRadius: 6,
                paddingTop: 50,
                paddingLeft: 10,
                paddingRight: 10
                }}
                >
                    <ShowReports {...this.props} />
                </div>
          </Layout>
        )
      } else {
        // fetch('/api/session', {
        //   headers: { Accept: 'application/json; odata=verbose' }
        // })
        instance({
          url: `/api/session`,
          method: 'GET',
          headers: { Accept: 'application/json; odata=verbose' }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(userInfo => {
              this.props.setActiveLanguage(userInfo.attributes.lang || 'en')
              this.props.dispatch(logInUserInfo(userInfo))
          //   })
          // } else {
          //   this.props.history.push('/logout')
          //     throw response
          //   }
          }).catch(error => {
            // errorHandler(error,this.props.dispatch)
            this.props.history.push('/logout')
          })
        return null
      }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})

export default connect(mapStateToProps)((SystemLogs))
