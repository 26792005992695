
export const getGarages = garages => ({
    type: 'GET_GARAGES',
    garages
});
export const addGarages = garages => ({
    type: 'ADD_GARAGES',
    garages
});
export const removedGarages = garages => ({
    type: 'REMOVED_GARAGES',
    garages
});
export const garageUsersWiseSelectedList = garages => ({
    type: 'GARAGE_USER_WISE_SELECTED_LIST',
    garages
});
export const updategarageUsersWiseSelectedList = garages => ({
    type: 'UPDATE_GARAGE_USER_WISE_SELECTED_LIST',
    garages
});
export const addgarageUsersWiseSelectedList = garages => ({
    type: 'ADD_TEMPLATE_USER_WISE_SELECTED_LIST',
    garages
});
export const removegarageUsersWiseSelectedList = garages => ({
    type: 'REMOVED_TEMPLATE_USER_WISE_SELECTED_LIST',
    garages
});
