import React from "react";
import _ from "lodash";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'highcharts'
import { toast } from 'react-toastify';
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from '@mui/icons-material/Settings'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteIcon from '@mui/icons-material/Delete'
import { Responsive, WidthProvider } from "react-grid-layout";
import './../DashboardBuilder/style.scss'
import Layout from "../../Layout";
import { connect } from "react-redux";
import axios from "axios";
import { Component as Component1 } from './Components/Component1'
import { Component as Component2 } from './Components/Component2'
import { Component as Component3 } from './Components/Component3'
import { Component as Component4 } from './Components/Component4'
import { Component as Component5 } from './Components/Component5'
import { Component as Component6 } from './Components/Component6'
import { Component as Component7 } from './Components/Component7'
import { Trans as Translate} from 'react-i18next';
import DeviceSelector from "../../Components/Devices/DeviceSelector";
import { applyDevicesCatFilter, applyDevicesFilter, fetchDashboards } from "../../Actions/Devices";
import DriverSelector from "../../Components/Devices/DriverSelector";
import { Tooltip } from "@mui/material";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { checkPrivileges } from "../../Helpers";
import { NavLink } from "react-router-dom";
import { notifySuccess } from "../../Utils/CustomNotifcations";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class PremiumDashboard extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 60,
    onLayoutChange: function() {},
    onDropDragOver: function() {},
    cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 },
  };

  state = {
    onDeleteConfirmation: false,
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: { lg: generateLayout()},
    widgetArray: [],
    item: {i: 0, w: 3, h: 3},
    currentDashboard: {
      dashboard: {}
    },
    loading: false,
    action: null,
    type: null,
    title: null,
    show: false
  };

  componentWillUnmount () {
    this.setState({
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: generateLayout()},
      widgetArray: [],
      item: {i: 0, w: 3, h: 3},
      currentDashboard: {
        dashboard: {}
      },
      loading: false,
      action: null,
      type: null,
      title: null,
      show: false
    })
  }

  setItem = (i, w, h, mw, mh) => {
    this.setState({item: {i, w, h, minW: w, minH: h, maxH: mh || 12, maxW: mw || 12}});
  }

  fetchDashboard = id => {
    this.setState({loading: true}, () => {
      axios.get(`/api/dashboards/${id}`).then(({data}) => {
        if(data && data.data) {
          this.setState({widgetArray: []}, () => {
            this.setState({loading: false, currentDashboard: data.data, widgetArray: data.data.dashboardGadgets || []})
          })
        }
        else {
          this.setState({loading: false});
          // Notifications.success({
          //   message: <Translate i18nKey='somthingWentWrongMessage1' />,
          //   autoDismiss: 10
          // });
          notifySuccess("somthingWentWrongMessage1")

          this.props.history.push('/dashboard');
        }
      }).catch(err => {
        this.setState({loading: false});
      })
    })
  }

  setSelectedItem = (action, type, title) => {
    if(type !== 'driver') {
      this.showSelectedUnits(action, type)
    }
    console.log(action, type, title);
    this.setState({action, type, title, show: true})
  }

  showSelectedUnits = (data, type) => {
    if (type === 'device') {
      let newList = [].concat(data)

      this.props.dispatch(applyDevicesCatFilter([]))
      this.props.dispatch(applyDevicesFilter(newList))
    }

    if (type === 'geofence') {
      let newList = ['geoIds']
      this.props.dispatch(applyDevicesCatFilter([]))
      this.props.dispatch(applyDevicesFilter(newList, data))
    }

    if (type === 'category') {
      let newList = [].concat(data)
      this.props.dispatch(applyDevicesFilter([]))
      this.props.dispatch(applyDevicesCatFilter(newList))
    }
  }
  
  reset = () => {
    this.setState({
      action: null,
      type: null,
      title: null,
      show: false
    })
  }

  getSnapshotBeforeUpdate(props) {
    if(props.match.params.id !== this.props.match.params.id && this.props.match.params.id) {
      this.fetchDashboard(this.props.currentId)
    } 
    return null
  }

  componentDidUpdate() {
    
  }

  componentDidMount() {
    this.fetchDashboard(this.props.currentId)
    this.setState({ mounted: true });
  }
  deleteItem = key => {
    const tempArray = this.state.widgetArray.slice();
    const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
    tempArray.splice(index, 1);
    this.setState({widgetArray: tempArray});
  }
  drawDOM = (gadget) => {
    switch(gadget.gadgetId) {
      case 1: 
        return <Component1 gadget={gadget} />
      case 2: 
        return <Component2 gadget={gadget} />
      case 3:
        return <Component3 handleItem={this.setSelectedItem} gadget={gadget} />
      case 4:
        return <Component4 handleItem={this.setSelectedItem} gadget={gadget} />
      case 5: 
        return <Component5 handleItem={this.setSelectedItem} gadget={gadget} />
      case 6: 
        return <Component6 handleItem={this.setSelectedItem} gadget={gadget} />
      case 7:
        return <Component7 handleItem={this.setSelectedItem} gadget={gadget} />
      default: 
        return null;
    }
  }
  generateDOM = () => {
    return _.map(this.state.widgetArray, (l, i) => {
      return l && l.attributes.w !== undefined ? (
        <div className={` column-${l.attributes?.w} row-${l.attributes?.h} widget-${l.gadgetId}`} key={i}
        data-grid={{
          x: l?.attributes?.x,
          y: l?.attributes?.y,
          w: l?.attributes?.w,
          h: l?.attributes?.h,
          i: l?.attributes?.i,
          static: true
        }}
        >
          {this.drawDOM(l)}
      </div>
      ) : null;
    });
  }

  onBreakpointChange = breakpoint => {
    this.setState({
      currentBreakpoint: breakpoint
    });
  };

  onLayoutChange = (layout, layouts) => {

    const tempArray = this.state.widgetArray;
      this.props.onLayoutChange(layout, layouts);
      layout.map((position) => {
        if(tempArray && tempArray[Number(position.i)]) {
            tempArray[Number(position.i)].x = position.x;
            tempArray[Number(position.i)].y = position.y;
            tempArray[Number(position.i)].width = position.w;
            tempArray[Number(position.i)].height = position.h;
        }
      });
      this.setState({widgetArray: tempArray});
  };

  
  onDrop = (layout, layoutItem, _event) => {
    const tempArray = this.state.widgetArray;
    tempArray.push({...layoutItem, id: _event.dataTransfer.getData("text")});
    this.setState({widgetArray: tempArray});
  };

  openDeviceSelector = (key, name) => {
    this.resetSelectors()
    if (this.state.deviceSelector === false) {
      this.showSelectedUnits(key, 'device')
    }
    this.setState({
      deviceSelector: !this.state.deviceSelector,
      deviceSelectorKey: name
    })
  }

  resetSelectors = () => {
    this.setState({
      motionSelector: false,
      geoSelector: false,
      deviceSelector: false,
      categorySelector: false,
      driverSelector: false
    })
  }

  askForDelete = () => {
    this.setState({
      onDeleteConfirmation: true
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  
  onDeleteDashboard = (id) => {
    // make delete call here
    axios.delete(`/api/dashboards/${this.props.currentId}`).then( response => {
      if(response.status === 200) {
        this.props.dispatch(
          // Notifications.success({
          //   message: <Translate i18nKey='dashboardIsDeleted' />,
          //   autoDismiss: 10
          // })
          notifySuccess("dashboardIsDeleted")
        )
        fetchDashboards(this.props.dispatch, this.props.logInUsers);
        this.props.history.push('/dashboard');
      }
      else {
        alert('You cannot delete this dashboard');
      }
    }).catch(err => {
      alert('You cannot delete this dashboard');
    })
  }

  render() {

    const { currentDashboard } = this.state;
    const { themecolors } = this.props;

    const View = this.state.show ? <div className="overlayform">
      <h4><span><Translate i18nKey={this.state.title} /></span> <span><CloseIcon style={{cursor: 'pointer'}} onClick={this.reset} /></span></h4>
      <div className="form-body">
        {this.state.type == 'category' || this.state.type == 'geofence' || this.state.type == 'device' ? <DeviceSelector readOnly rows={16} /> : this.state.type === 'driver' ? <DriverSelector
          readOnly
          applyFilter
          rows={16}
          displayList={this.state.action}
        /> : null}
      </div>
    </div> : null;

    return (
      <Layout {...this.props} noSidebar classFromChildren="dashboard-page view-mode" afterView={View}>
      {this.state.onDeleteConfirmation && (
        <ConfirmDialoag
          onCancel={this.onCancel}
          onOk={this.onDeleteDashboard}
          title={<Translate i18nKey='areYouWantToDelete' />}
        />
      )}
      <div className="dashboard-layout-container" style={{color: themecolors.textColor}}>
        <div className={(this.state.layouts[this.state.currentBreakpoint] && this.state.layouts[this.state.currentBreakpoint].length ? '' : 'empty')+' grid-container'}>
          <h3 className="page-heading">
            <span>{currentDashboard.dashboard?.name}</span> 
            {checkPrivileges('dashboardUpdate')&&(<Tooltip title={<Translate i18nKey="settingsTitle" />}><NavLink to={`/dashboardBuilder/${currentDashboard?.dashboard?.id}`}> <SettingsIcon/></NavLink></Tooltip>)}
            {checkPrivileges('dashboardDelete')&&(<Tooltip title={<Translate i18nKey="delete" />}><a href={null} onClick={this.askForDelete}><DeleteIcon/></a></Tooltip>)}
            {checkPrivileges('dashboardCreate')&&(<Tooltip title={<Translate i18nKey="Create" />}><NavLink to={`/dashboardBuilder`}><AddBoxIcon/></NavLink></Tooltip>)}
          </h3>
          <ResponsiveReactGridLayout
            {...this.props}
            layouts={this.state.layouts}
            measureBeforeMount={false}
            useCSSTransforms={this.state.mounted}
            compactType={this.state.compactType}
            preventCollision={!this.state.compactType}
          >
            {this.generateDOM()}
          </ResponsiveReactGridLayout>
          </div>
      </div>
      </Layout>
    );
  }
}




const mapStateToProps = (state, props) => {
  return {
    currentId: props.match.params.id,
    logInUsers: state.logInUsers,
    themecolors: state.themeColors
  }
}

export default connect(mapStateToProps)(PremiumDashboard)




function generateLayout() {
  return [];
}
