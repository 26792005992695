import React, { Fragment, PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import { updateNotificationSetting } from './../../Actions/Notifications'
import { logInUserInfo } from './../../Actions/Users'
;
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import MenuItem from './../common/MenuItem'
import { connect } from 'react-redux'
import { MapPagination } from './MapPagination'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import withTranslationWrapper from '../../HOC/withTranslation';
let notificationSettings = []
class PaginationMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }


  render () {
    const { anchorEl } = this.state
    const empty = null

    return (
      <Fragment>
        <a
          href={empty}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          {/* <span>{this.props.translate('scale')}</span> */}
          <LinearScaleIcon style={{ fontSize: 24 }} />
        </a>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
        >
          <MenuItem onClick={e => this.props.addShape('polygon')}>
            {this.props.translate('areaCalculator')}
          </MenuItem>
          <MenuItem onClick={e => this.props.addShape('polyline')}>
            {this.props.translate('measureDistance')}
          </MenuItem>
        </Menu>
      </Fragment>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  notificationSettings: state.notificationSettings,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withTranslationWrapper(PaginationMenu))
