import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/styles'
// import MuiExpansionPanel from '@mui/material/Accordion'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
// import MuiExpansionPanelSummary from '@mui/material/AccordionSummary'
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux'
 
const mapStateToProps = (state) => {
  return {
    themecolors: state.themeColors
  }
}

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },

  heading: {
    fontSize: 12,
    color: '#333',
    fontWeight: 700
  },
  content: {
    padding: 3,
   },

  summeryContent: {
    alignItems: 'center'
  }
})

const ExpansionPanelStyle = () => ({
  root: {
     '&$expanded': {
       height: '100%'
    }
  },
  expanded: {}
})

const ExpansionPanel = styled(ExpansionPanelStyle)(props => (
  <Accordion {...props} />
))

ExpansionPanel.muiName = 'ExpansionPanel'

const ExpansionPanelSummaryStyle = () => ({
  root: {
    background: this.props.themecolors.backgroundColor,
    //borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 32,

    '&$expanded': {
      minHeight: 32
    }
  },
  expandIcon: {
    color: '#969696',
    padding: 7,
    '&:hover': {
      color: '#000'
    }
  },
  content: {
    margin: '4px 0',
    '&$expanded': {
      margin: '4px 0'
    }
  },
  expanded: {}
})

const ExpansionPanelSummary = styled(ExpansionPanelSummaryStyle)(props => (
  <AccordionSummary {...props} />
))

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary'

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: this.props.open
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render () {
    const classes = styles
    // const { themecolors } = this.props
    const { expanded } = this.state

    return (
      <div >
          <div >
            <Accordion
              classes={{ }}
              expanded={expanded === true}
              onChange={this.handleChange(true)}
              style={{ maxWidth: this.props.maxWidth, background:this.props.themecolors.backgroundColor}}
            >
              <AccordionSummary
                // className='ExpansionPanelSummary-header'
                // classes={{
                //   content: classes.summeryContent,
                //   root: 'ExpansionPanelSummary-paper'
                // }}
                style={{backgroundColor:"#0A83A1",minHeight:40,maxHeight:40}}
                expandIcon={
                  <ExpandMoreIcon 
                  // className={'ExpansionPanelSummary-heading'}
                   style={{color:"white"}} />
                }
              >
                <Typography 
                // className={'ExpansionPanelSummary-heading'}
                >
                  {this.props.title || 'Default Title'}
                </Typography>
                {this.props.headerActions}
              </AccordionSummary>
              <AccordionDetails
                className={classes.content}
                style={{
                  padding: this.props.bodyPadding,
                  boxSizing: 'border-box',
                  maxHeight: this.props.maxHeight || 'inherit',
                  overflow: this.props.maxHeight ? 'auto' : 'visible',
                }}
              >
                {this.props.children}
              </AccordionDetails>
            </Accordion>
          </div>
       </div>
    )
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired
}

 
const Pannel = styled(connect(mapStateToProps)(ControlledExpansionPanels))(styles)
export default Pannel