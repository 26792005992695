import React, { forwardRef } from 'react'
import MenuItem from '@mui/material/MenuItem'

function CustomMenuItem (props, ref) {
  return (
    <MenuItem
      {...props}
      classes={{ root: 'list-menu-item', selected: 'selected_item' }}
      className={props.className}
    />
  )
}

export default forwardRef(CustomMenuItem)
