import React from "react"
// import Check from "../../assets/nav/check.svg"
import UncheckIcon from './../../assets/nav/uncheck-box.svg'
import Check from './../../assets/nav/check-box.svg'
export const CheckIcon = (props) => {
    return <span className={props.className + " v-checkbox-wrap"}>
    <span className="on-active  v-checkbox">
    <svg fill='currentColor' width={16} height={16}>
      <use xlinkHref={`${Check}#icon`} />
    </svg>
    </span>
    <span className="on-inactive  v-checkbox">
    <svg fill='currentColor' width={16} height={16}>
      <use xlinkHref={`${UncheckIcon}#icon`} />
    </svg>
    </span>
  </span>
}