import React, { Component, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Trans as Translate } from "react-i18next";

import { connect, useDispatch } from "react-redux";
import {
  checkPrivileges,
  errorHandler,
  selectAll,
  setAttributeFormat,
  getDateTimeFormat,
} from "../../Helpers";
import Layout from "./../../Layout";
import Button from "../../Components/common/Button";
import Dialog from "../../Components/common/Dialog";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { ReactComponent as CheckIcon } from "../../assets/dashboard/check.svg";
import { ReactComponent as DeleteIcon } from "../../assets/dashboard/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/dashboard/edit.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/dashboard/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/dashboard/arrow-up.svg";
import { ReactComponent as PlusIcon } from "../../assets/dashboard/plus.svg";
import { ReactComponent as CustomerIcon } from "../../assets/services/customer-icon-01.svg";
import { ReactComponent as IssueInvoiceIcon } from "../../assets/services/issue-invoice.svg";
import { ReactComponent as GarageIcon } from "../../assets/services/garage-icon-01-01.svg";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import LinkIcon from "@mui/icons-material/Link";

import EmptyState from "../../Components/common/EmptyState";
import {
  getMaintenance,
  addMaintenance,
  removedMaintenance,
} from "../../Actions/Maintenance";
import ItemsSelector from "../Units/Components/itemsSelector";
import { ReactComponent as MaintenanceIcon } from "./../../assets/nav/spanner.svg";
import {
  removedUserDeviceWiseSelectedList,
  userDeviceWiseSelectedList,
} from "../../Actions/Notifications";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { removeUser } from "../../Actions/Users";
import moment from "moment";
import { removeDevice } from "../../Actions/Devices";
import CustomDialog from "../../Components/common/Dialog";
import { CreateMaintenanceForm } from "./CreateMaintenanceForm";
import { MaintenanceComp } from "./MaintenanceComp";
import { CreateExpense } from "./CreateExpense";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Tooltip, Grid, FormControlLabel } from "@mui/material";
import { SingleItem } from "../../Hooks/useSingleItem";
import { store } from "../../index";
import { Provider } from "react-redux";
import instance from "../../axios";
import { addDays } from "date-fns";
import Table from "../../Components/common/TableWithColors";
import { useState } from "react";
import { useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "../../Components/common/MenuItem";
import Menu from "../../Components/Menu/CommonMenu";
import Checkbox from "../../Components/common/Checkbox";
import TextField from "./../../Components/common/TextField";
import InfiniteScroll from "react-infinite-scroll-component";
import Scrollbar from "react-scrollbars-custom";
import Loader from "../../Layout/Loader";
import Skeleton from "@mui/material/Skeleton";

import MailIcon from "@mui/icons-material/Mail";
import { withStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import ExportImportMenu from "../../Components/Maps/ExportImportMenu";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { toast } from "react-toastify";
import { notifySuccess, notifyError } from "../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../HOC/withTranslation";
import DateRangePicker from "../../Components/common/DateRangePicker";
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const CancelToken = axios.CancelToken;
let source;
let tempFile = [];
const servicesAttributes = [];
let driversList = [];
let garageList = [];
let vehiclesList = [];
let subTypes = [];

let expenseType = [];
let allExpenseTypes = [];
let areaList = [];
const statusTypes = [
  {
    key: "completed",
    name: "completed",
  },
  {
    key: "required",
    name: "required",
  },
];
const statusTypes1 = [
  {
    key: "required",
    name: "required",
  },
];

const date = new Date();
const defaultExpenseForm = {
  name: "",
  description: "",
  contactNumber: "",
  contractNumber: "",
  odometer: "",
  workOrderNumber: "",
  serviceTime: new Date(),
  expireTime: addDays(date, 7),
  quantity: "",
  cost: "",
  address: "",
  coordinates: "",
  service: "Service",
  type: "",
  driver: "Driver",
  vehicles: "",
  device: "",
  // status: "required",
  status: "",
  files: "",
  engineHours: "",
  selectedDateTime: "",
  currentEngineHours: 0,
  currentOdometer: 0,
  showStats: false,
  vehicleId: [],
  selectedvehicleId: [],
  areaId: [],
  selectedareaId: [],
  fromDate: "",
  toDate: "",
  loadingSidebar: false,
  isValidVehicle: false,
  workOrderHistoryID: "",
  attributes: {
    updateMaintenance: false,
    mileage: 0,
    hours: 0,
    date: 0,
    status: true,
    mailTo: "",
    email: [],
    notificators: "mail,whatsapp",
  },
  createdBy: true,
};

// contractType
const DataFormat = (
  data,
  totalCost,
  totalQuantity,
  serviceData,
  account,
  customerType
) => {
  return {
    service: {
      id: data && data.id ? data.id : 0,
      contractNumber: data?.contractNumber?.contractNumber
        ? data.contractNumber.contractNumber
        : data.contractNumber
        ? data.contractNumber
        : "",
      updatedBy: data && data.updatedBy ? data.updatedBy : "",
      status:
        data && data.status
          ? data.status
          : customerType === 1
          ? "GarageInitiate"
          : customerType === 2
          ? "OpToCustomer"
          : "required",
      //  data.contractNumber && data.contractNumber.contractNumber ?  "GarageInitiate"  : "required",
      name: data && data.name ? data.name : "",
      description: data && data.description ? data.description : null,
      workOrderNumber: data && data.workOrderNumber ? data.workOrderNumber : "",
      contactNumber: data && data.contactNumber ? data.contactNumber : 0,
      // odometer: data && data.odometer ? parseFloat(data.odometer) * 1000 : 0,
      odometer: data && data.odometer ? parseFloat(data.odometer) : 0,
      // typeId: data && data.type && data.type.id ? data.type.id : '3',
      quantity: totalQuantity ? totalQuantity : "",
      cost: totalCost ? totalCost : "",
      address: data && data.address ? data.address : null,
      coordinate: data && data.coordinates ? data.coordinates : "30.00,50.00",
      vehicleId:
        data && data.vehicles && data.vehicles.id
          ? parseInt(data.vehicles.id)
          : "",
      garageId: data && data.garage && data.garage.id ? data.garage.id : null,

      vehicleLicensePlate:
        data && data.vehicles && data.vehicles.vehicleLicensePlate
          ? data.vehicles.vehicleLicensePlate
          : data.vehicles?.vehicleLP && data.vehicles?.vehicleLP
          ? data.vehicles.vehicleLP
          : data?.vehicleLicensePlate
          ? data?.vehicleLicensePlate
          : "",
      garageNumber:
        data && data.garage && data.garage.garageNumber
          ? data.garage.garageNumber
          : data.garageNumber
          ? data.garageNumber
          : null,

      // deviceId: data && data.device && data.device.id ? parseInt(data.device.id) : '',
      deviceId: (data && data.deviceId) || "",
      driverUniqueId:
        data && data.driver && data.driver.uniqueId
          ? data.driver.uniqueId
          : null,
      maintenanceId: data && data.maintenanceId ? data.maintenanceId : "",
      files: data.files,
      createdTime: data && data.createdTime ? data.createdTime : "",
      serviceTime: data && data.serviceTime ? data.serviceTime : "",
      expireTime: data && data.expireTime ? data.expireTime : "",
      userId: account && account.userId ? account.userId : 1,
      engineHours:
        data && data.engineHours
          ? parseInt(data.engineHours) * (1000 * 3600)
          : 0,
      tag1: data && data.tag1 ? data.tag1 : "",
      tag2: data && data.tag2 ? data.tag2 : "",
      tag3: data && data.tag3 ? data.tag3 : "",
      refNum: data && data.refNum ? data.refNum : "",
      refdate: data && data.refdate ? data.refdate : "",
      areaId:
        data && data.areaId && data.areaId.id
          ? data.areaId.id
          : data.areaId && data.areaId.key
          ? data.areaId.key
          : "",
      attributes: data && data.attributes ? { ...data.attributes } : {},
      invoiceType: account && account.contractType ? account.contractType : "",
      workOrderHistoryID: "",
    },
    serviceExpenseCollection: [...serviceData],
  };
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenanceForm: {},
      maintenanceHistForm: {},
      historyId: "",
      totalCount: 0,
      mode: "due",
      tab: "due",
      isVisible: true,
      // histFormVisible: false,
      onDeleteConfirm: false,
      onStatusAccept: false,
      onRejectAccept: false,
      onDeleteExpense: false,
      analyticData: "",
      anchorEl: null,
      countChecked: 0,
      statusFilter: {},
      // filterStatusType: "",
      filterType: "due",
      imageFile: "",
      enableImagePopup: false,
      selectMaintenanceId: "",
      itemSearch: "",
      isDataRecieved: false,
      loader: false,
      model: false,
      assignMode: "",
      currentMaintenance: "",
      entityId: 0,
      image64Type: [],
      initFetch: false,
      currentPage: 1,
      currentPageHist: 1,
      pagesize: 20,
      pagesizeHist: 20,
      searchVehicleId: {
        id: "",
        label: this.props.translate("searchVehicles"),
      },
      searchAreaId: { id: "", label: this.props.translate("searchAreas") },
      selectedVehicleId: "",
      selectedAreaId: "",
      selectedDate: "",
      statusMode: false,
      completeMode: false,
      showDueDialog: false,
      showDialogBtn: false,
      vehicleDueMaintenance: [],
      historyEdit: false,
      disabledSidebarBtn: true,
      importedData: [],
      importedReceivedData: [],
      showColorTable: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      },
      itemsPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
      itemsPaginationEstimate: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
      deleteForm: {},
      expenseForm: {
        ...defaultExpenseForm,
        garageNumber: this.props?.ServerSetting?.garageNumber
          ? this.props?.ServerSetting?.garageNumber
          : "",
      },
      serviceExpenseCollection: [
        {
          quantity: "1",
          cost: "1",
          expenseSubTypeId: "",
          expenseTypeId: "",
          selectedSubTypes: [],
        },
      ],
      resultMessage: "No Items",
      showDueMaintenancePopUp: false,
      sideBarStatus: "",
      zerovat: false,
      issueinvoice: false,
      rejectionMessage: "",
      statusValue: "",
      sendtogarage: false,
      // tabBtn:this.props.logInUser.userType === 4 ||
      //        this.props.logInUser.userType === 3 ?
      //        "tab2" :"tab1",
      // tabBtn:"tab2",
      statusVal: "",
      statusMessage: "",
      sendToGarage: "",
      garageVerticals: [],
      // createdBy:(this.props.logInUser.customerType === 1 || this.props.logInUser.customerType === 2) ? true : false,
      initSearch: false,
      createdBy: "",
      selectedAccount: {},
      clearFilters:false,
      fromDate: moment().subtract(30, 'days').startOf('day').toISOString(),
    toDate: moment().endOf('day').toISOString(),
    selectedDateTime: `${moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm')} - ${moment().startOf('day').format('YYYY-MM-DD HH:mm')}`,
      filterStatusType:
        this.props.logInUser.userType === 1 ||
        this.props.logInUser.userType === 2 ||
        this.props.logInUser.userType === 3
          ? "&status=OpToCustomer&status=GarageToCustomer"
          : "",
      // filterStatusType:'',
    };
  }

  replaceHtmlEntities = (text) => {
    let itemname = text.replace(/amp;/, " ").replace(/amp;/g, "");
    const textarea = document.createElement("textarea");
    textarea.innerHTML = itemname;
    return textarea.value;
  };

  getMoreUsers = () => {
    // /api/accounts/get?userId=1&contractType=1&all=true&page=1&limit=50&search=${inputValue}
    fetch(
      `api/accounts/get?userId=${this.props.logInUser.id}&all=true&limit=-1`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
            let data = res.data;
            let result = data.data.map((item) => ({
              // ...item.entity,
              label:
                this.replaceHtmlEntities(item.name || item.contractName) +
                "-" +
                item.contractNumber,
              name:
                this.replaceHtmlEntities(item.name || item.contractName) +
                "-" +
                item.contractNumber,
              value: item.id,
              id: item.id,
              contractNumber: item.contractNumber,
              userId: item.userId,
              pacContractId: item.pacContractId,
              // contractNumber
            }));

            if (result.length === 0) {
              this.setState({
                resultMessage: "No option found",
                defaultOptions: "",
              });
            } else {
              this.setState({
                resultMessage: "",
                defaultAccountsList: result,
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            this.props.dispatch(toast.error(this.props.translate(err[1])));
          } else {
            throw response;
          }
        });
      })
      .catch((e) => {
        console.log("e =", e);
        //   this.props.dispatch(Notifications.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };

  componentDidMount() {
    this.setState({
      createdBy:
        this.props.logInUser.customerType === 1 ||
        this.props.logInUser.customerType === 2
          ? true
          : false,
      filterStatusType:
        this.props.logInUser.userType === 1 ||
        this.props.logInUser.userType === 2 ||
        this.props.logInUser.userType === 3
          ? "&status=OpToCustomer&status=GarageToCustomer"
          : "",
    });
  }

  componentWillUnmount() {
    this.setState({
      maintenanceForm: {},
      mode: "",
      model: false,
      assignMode: "",
      currentMaintenance: "",
      entityId: 0,
      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      },
    });
  }

  componentWillMount() {
    if (this.props.location.pathname === "/maintenanceCreate") {
      this.setState({ mode: "create" });
    }
    if (this.props.location.pathname.indexOf("/maintenanceUpdate") !== -1) {
      this.openEditMaintenanceForm();
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
          createdBy:
            this.props.logInUser.customerType === 1 ||
            this.props.logInUser.customerType === 2
              ? true
              : false,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPage,
            this.state.pagesize
          );
        }
      );
      this.getGarageList();
    }

    this.maintenanceAnalytic();
    this.getExpenseTypes();
    if (checkPrivileges("area")) this.getAreaList();
  }

  getAreaList = () => {
    instance({
      url: `/api/areas/get?&page=1&limit=10&search=`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        areaList = res.data.map((item) => {
          return {
            key: item.id,
            name: item.name,
            label: item.name,
            uniqueId: item.id,
            valueType: "string",
          };
        });
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  getGarageList = () => {
    if (this.props.logInUser && this.props.logInUser.id) {
      instance({
        url: `/api/garages/get?userId=${this.props.logInUser.id}&page=1&limit=50&all=true&search=`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          garageList = res.data.map((item) => {
            return {
              id: item.id,
              key: item.id,
              name: item.name + " - " + item.garageNumber,
              label: item.name + " - " + item.garageNumber,
              uniqueId: item.id,
              valueType: "string",
              garageNumber: item.garageNumber,
            };
          });
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
          this.getGarageList();
        }
      );
    }
  }

  openEditMaintenanceForm = () => {
    let { maintenance } = this.props;
    maintenance.map((item) => {
      if (parseInt(this.props.match.params.id) === item.id) {
        this.editMaintenanceForm([item]);
      }
    });
  };

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    );
  };

  fetchMoreItemsHist = (a, b, c) => {
    this.fetchMaintenanceHistory(
      this.state.currentPageHist,
      this.state.pagesizeHist
    );
  };

  fetchData = (logInUser, page, perPage, reset) => {
    source = CancelToken.source();
    let items = this.state.itemPagination && this.state.itemPagination.items;
    if (reset) {
      items = [];
    }
    instance({
      method: "GET",
      url: `/api/maintenance/get?userId=${
        logInUser.id
      }&page=${page}&limit=${perPage}&all=${true}&search=${
        this.state.itemSearch
      }`,
      cancelToken: source.token,
    })
      .then((response) => {
        // if(response && response.status === 200) {
        this.setState(
          {
            itemPagination: {
              ...response,
              items: items.concat(response && response.data),
            },
            currentPage: response.hasNext ? response.page + 1 : response.page,
            isDataRecieved: true,
          },
          () => {
            this.props.dispatch(
              getMaintenance(items.concat(response && response.data))
            );
          }
        );
        if (this.state.itemSearch === "") {
          this.setState({
            savedData: {
              ...response,
              items: items.concat(response && response.data),
            },
          });
        }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  fetchMaintenanceHistory = (page, perPage) => {
    let searchAreaid =
      this.state.searchAreaId && this.state.searchAreaId.id
        ? `&areaId=${this.state.searchAreaId.id}`
        : "";
    let searchVehicleid =
      this.state.searchVehicleId && this.state.searchVehicleId.id
        ? `&vehicleId=${this.state.searchVehicleId.id}`
        : "";

    let url;

    let filterStatus = this.state.filterStatusType
      ? this.state.filterStatusType
      : "&status=GarageInitiate&status=OpReject&status=customerReject&status=WaitingForOpApproval&status=WaitingForCustomerApproval";

    // if(this.state.tabBtn === "tab1"){
    //  url =
    //   (this.state.vehicleId && this.state.vehicleId.length) ||
    //   (this.state.areaId && this.state.areaId.length)
    //     ? `/api/services/${
    //         this.state.filterType
    //       }?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${
    //         this.state.selectedVehicleId
    //       }${this.state.selectedAreaId}${this.state.filterStatusType}&from=${
    //         this.state?.fromDate || ""
    //       }&to=${this.state?.toDate || ""}`
    //     : (this.state.searchVehicleId && this.state.searchVehicleId.id) ||
    //       (this.state.searchAreaId && this.state.searchAreaId.id)
    //     ? `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${searchVehicleid}${searchAreaid}${this.state.filterStatusType} `
    //     : `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.filterStatusType} `;
    //     }
    //     else if(this.state.tabBtn === "tab2"){
    url =
      (this.state.vehicleId && this.state.vehicleId.length) ||
      (this.state.areaId && this.state.areaId.length)
        ? `/api/services/get?page=${page}&limit=${perPage}&search=${
            this.state.itemSearch
          }${this.state.selectedVehicleId}${this.state.selectedAreaId}${
            this.state.filterStatusType
          }&from=${this.state?.fromDate || ""}&to=${
            this.state?.toDate || ""
          }${filterStatus}`
        : (this.state.searchVehicleId && this.state.searchVehicleId.id) ||
          (this.state.searchAreaId && this.state.searchAreaId.id)
        ? `/api/services/get?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${searchVehicleid}${searchAreaid}${this.state.filterStatusType}${filterStatus}`
        : `/api/services/get?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.filterStatusType}${filterStatus}`;
    // }

    source = CancelToken.source();
    let items = this.state.itemsPagination.items;
    instance({
      method: "GET",
      url: url,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({
          itemsPagination: {
            ...response,
            items: items.concat(response && response.data),
          },
          currentPageHist: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true,
          loadingSidebar: false,
        });
        //   }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  fetchMaintenanceHistory2 = (page, perPage) => {
    let url;
    let estimationStatus =
      this.props.logInUser.customerType === 1
        ? "GarageInitiate"
        : this.props.logInUser.customerType === 2
        ? "WaitingForOpApproval"
        : this.props.logInUser.customerType === 3
        ? "WaitingForCustomerApproval"
        : "";

    // if(this.state.tabBtn === "tab1"){
    // url  =  (this.state.vehicleId && this.state.vehicleId.length) ||
    //   (this.state.areaId && this.state.areaId.length)
    //     ? `/api/services/${
    //         this.state.filterType
    //       }?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${
    //         this.state.selectedVehicleId
    //       }${this.state.selectedAreaId}${this.state.filterStatusType}&from=${
    //         this.state?.fromDate || ""
    //       }&to=${this.state?.toDate || ""}`
    //     : `/api/services/${
    //         this.state.filterType
    //       }?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${
    //         this.state.filterStatusType
    //       }&from=${this.state?.fromDate || ""}&to=${this.state?.toDate || ""}`;
    //     }
    //     else if(this.state.tabBtn === "tab2"){

    let user =
      (this.props.logInUser.customerType === 1 ||
        this.props.logInUser.customerType === 2) &&
      this.state.createdBy
        ? "me"
        : "all";

    url =
      (this.state.vehicleId && this.state.vehicleId.length) ||
      (this.state.areaId && this.state.areaId.length)
        ? `/api/services/get?createdby=${user}&page=${page}&limit=${perPage}&search=${
            this.state.itemSearch
          }${this.state.selectedVehicleId}${this.state.selectedAreaId}${
            this.state.filterStatusType
          }&from=${this.state?.fromDate || ""}&to=${this.state?.toDate || ""}`
        : `/api/services/get?createdby=${user}&page=${page}&limit=${perPage}&search=${
            this.state.itemSearch
          }${this.state.filterStatusType}&from=${
            this.state?.fromDate || ""
          }&to=${this.state?.toDate || ""}`;

    // }
    url = this.state.selectedAccount.contractNumber
      ? url + `&contractNumber=${this.state.selectedAccount.contractNumber}`
      : url;
    source = CancelToken.source();
    let items = this.state.itemsPagination.items;
    instance({
      method: "GET",
      url: url,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({
          itemsPagination: {
            ...response,
            items: items.concat(response && response.data),
          },
          currentPageHist: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true,
          loadingSidebar: false,
        });
        //   }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  assignModal = (assignMode, currentMaintenance) => {
    this.props.dispatch(userDeviceWiseSelectedList([]));
    this.setState(
      {
        assignMode,
        currentMaintenance,
        selectEntity: "",
        model: true,
      },
      () => {
        if (checkPrivileges("user") && assignMode === "user") {
          instance({
            url: `/api/users/access`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              maintenanceId: currentMaintenance.id,
            },
          })
            .then((users) => {
              let allData = this.props.users;
              if (users.length) {
                allData.map((item) => {
                  item.check = false;
                  users.map((main) => {
                    if (item.id && main.userId && item.id === main.userId) {
                      // for parent
                      item.check = true;
                    }
                    return null;
                  });
                  return null;
                });
              } else {
                allData.map((n) => (n.check = false));
              }

              this.props.dispatch(userDeviceWiseSelectedList(allData));
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
            });
        }
        if (checkPrivileges("device") && assignMode === "unit") {
          instance({
            url: `/api/devices/access`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              maintenanceId: currentMaintenance.id,
            },
          })
            .then((devices) => {
              let allData = this.props.devices3;
              if (devices.length) {
                allData.map((item) => {
                  item.check = false;
                  devices.map((main) => {
                    if (item.id && main.deviceId && item.id === main.deviceId) {
                      // for parent
                      item.check = true;
                    }
                    return null;
                  });
                  return null;
                });
              } else {
                allData.map((n) => (n.check = false));
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData));
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
            });
        }
      }
    );
  };

  selectEntity = (e) => {
    this.setState({
      selectEntity: e,
    });
  };

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === "unit") {
      let obj = {
        deviceId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id,
      };
      this.assignMaintenance(obj);
    } else if (this.state.assignMode && this.state.assignMode === "user") {
      let obj = {
        userId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id,
      };
      this.assignMaintenance(obj);
    }
  };

  assignMaintenance = (obj) => {
    instance({
      url: `/api/permissions/`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        this.props.dispatch(notifySuccess("maintenanceAssignedSuccessfully"));
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: "",
      commandTitle: "",
      currentMaintenance: "",
    });
  };

  openCreateFrom = () => {
    if (!vehiclesList.length && this.props.vehicles.length)
      this.getVehiclesList();
    this.setState({ mode: "", maintenanceForm: {} }, () => {
      this.setState({
        mode: "create",
        maintenanceForm: {
          attributes: {
            mileage: 0,
            hours: 0,
            date: 0,
            status: true,
            mailTo: "",
            email: [],
            userInputRequired: true,
          },
        },
        selectedUnit: null,
      });
    });
  };

  getExpenseTypes = (contractNumber) => {
    let userId = contractNumber
      ? contractNumber
      : this.props.ServerSettings && this.props.ServerSettings.contractNumber;
    instance({
      method: "GET",
      url: userId
        ? `/api/expensetypes?contractNumber=${userId}`
        : `/api/expensetypes`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response &&
          response.map((type) => {
            servicesAttributes.push({
              key: type.id,
              id: type.id,
              type: type.type,
              name: type.subType,
              subType: type.subType,
            });
          });

        let expenseTypes = response?.map((item) => {
          return {
            key: item.id,
            id: item.id,
            name: item.name,
            description: item.description,
            attributes: item.attributes,
            parentId: item.parentId,
            valueType: "string",
            expenseType: item.expenseType,
          };
        });
        expenseType = expenseTypes;
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  createExpense = () => {
    this.getData("vehicles", "id", 0, "multi", (data) => {
      vehiclesList = data.map((item) => {
        // console.log("item======", item);
        // Check if make and model are present
        const make = item.attributes.make || "";
        const model = item.attributes.model || "";
        const arabicPlate = item.vehicleLisencePlateArabic || "";

        const label = `${item.vehicleLicensePlate}  ${make} ${model}  ${arabicPlate}`;

        return {
          id: item.id,
          label: label,
          // label: label.trim().endsWith("-") ? item.vehicleLicensePlate : label,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: "string",
        };
      });
    });
    this.getData("drivers", "id", 0, "multi", (data) => {
      driversList = data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          uniqueId: item.uniqueId,
          valueType: "string",
        };
      });
    });
    this.setState(
      {
        mode: "createExpense",
        image64Type: [],
        loader: false,
        selectedVehicleId: "",
        selectedAreaId: "",
        selectedDate: "",
        expenseForm: {
          ...defaultExpenseForm,
          garageNumber: this.props.ServerSetting.garageNumber
            ? this.props.ServerSetting.garageNumber
            : "",
        },
        serviceExpenseCollection: [
          {
            quantity: "1",
            cost: "1",
            expenseSubTypeId: "",
            expenseTypeId: "",
            selectedSubTypes: [],
          },
        ],
      },
      () => {
        if (
          this.props.logInUser.customerType === 1 ||
          this.props.logInUser.customerType === 2
        ) {
          this.getMoreUsers();
        }
      }
    );
  };

  closeExpense = () => {
    this.setState({
      mode: "showHist",
      historyEdit: false,
    });
  };

  getSubTypes = (id) => {
    return new Promise((resolve, reject) => {
      let res = [];
      instance({
        method: "GET",
        url: `/api/expensetypes/subtype?expenseTypeId=${id}&search=`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response &&
            response.map((item) => {
              res.push({
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId,
              });
            });
          subTypes = res;
          this.setState({ subTypes2: res }, () => resolve(res));
          // }
        })
        .catch((error) => {
          resolve(this.state.subTypes2);
          // errorHandler(error,this.props.dispatch)
        });
    });
  };

  addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  getVehicles = (id) => {
    let { itemSearch } = this.state;

    let api = `api/vehicles/get?userId=${id}&all=true&page=${1}&limit=${50}&search=${itemSearch}`;
    source = axios.CancelToken.source();
    // axios
    //   .get(api, {
    //     cancelToken: source.token,
    //   })

    instance({
      method: "GET",
      url: api,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        let response =
          res?.data &&
          res?.data.map((item) => {
            const make = item.attributes.make || "";
            const model = item.attributes.model || "";

            const arabicPlate = item.vehicleLisencePlateArabic || ""
    
            const label = `${item.vehicleLicensePlate}  ${make} ${model}  ${arabicPlate}`;

            return {
              id: item.id,
              label: label.trim().endsWith("-")
                ? item.vehicleLicensePlate
                : label,
              value: item.id,
              vehicleLP: item.vehicleLicensePlate,
              valueType: "string",
            };
          });

        this.setState({
          selectedVehilcesList: response || [],
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        // }
      });
  };

  getExpense = async (id) => {
    let getVehicle;
    if (this.state.expenseForm.vehicleId) {
      getVehicle = await this.fetchSelectedVehicle(
        this.state.expenseForm.vehicleId
      );
    }

    let allExpenseTypes = expenseType;
    // expenseType=[]
    let serverTimeZoneName = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    instance({
      method: "GET",
      url: `/api/services/expenses/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const updatedResponse = response?.map((item) => ({
          ...item,
          expenseTypeId: {
            key: item.expenseTypeId,
            id: item.expenseTypeId,
            name: item.typeLabel,
          },
          expenseSubTypeId: {
            key: item.expenseSubTypeId,
            id: item.expenseSubTypeId,
            name: item.subTypeLabel,
            label: item.subTypeLabel,
            subTypeLabel: item.subTypeLabel,
            // unitPrice: item.cost/item.quantity
            unitPrice: item.unitPrice,
          },
        }));

        var tempData = [];
        this.getData(
          "devices3",
          "id",
          this.state.expenseForm.deviceId,
          "single",
          (data) => {
            let device = data;
            let vehicles = this.props.vehicles.find(
              (item) => item.id === this.state.expenseForm.vehicleId
            );
            let driver = this.props.drivers.find(
              (item) => item.uniqueId === this.state.expenseForm.driverUniqueId
            );
            let garage = garageList.find(
              (item) => item.key === this.state.expenseForm.garageId
            );
            let areaId =
              areaList &&
              areaList.find(
                (item) => item.key === this.state.expenseForm.areaId
              );

            let selectedStatus;
            if (checkPrivileges("serviceMarkAsComplete")) {
              selectedStatus = statusTypes.find(
                (item) => item.name === this.state.expenseForm.status
              );
            } else {
              selectedStatus = statusTypes1.find(
                (item) => item.name === this.state.expenseForm.status
              );
            }

            this.setState(
              {
                serviceExpenseCollection: updatedResponse || [],
                expenseForm: {
                  ...this.state.expenseForm,
                  serviceTime: moment(this.state.expenseForm.serviceTime)
                    // .tz(serverTimeZoneName)
                    .format("YYYY-MM-DD"),
                  // type: 1,
                  device,
                  vehicles: getVehicle,
                  // status: this.state.expenseForm.creationMode === "garage" ? this.state.expenseForm.status : selectedStatus && selectedStatus.name,
                  status: this.state.expenseForm.status,
                  // garage: garage,
                  areaId: areaId,
                  driver: driver,
                  // engineHours: this.state.expenseForm.engineHours > 0 ?(this.state.expenseForm.engineHours/(1000*3600)).toFixed(2)+' h': '0',
                  // odometer: this.state.expenseForm.odometer>0? setAttributeFormat('mileage', (this.state.expenseForm.odometer)) : '0'
                },
                image64Type: [],
                // serviceExpenseCollection: tempData,
                mode: "updateHist",
                loader: false,
                itemSearch: "",
              }
              // ,()=>this.getMoreUsers()
            );
          }
        );
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  // async fetchSelectedUser  (id) {
  //   return await instance({
  //      method: 'GET',
  //      url: `/api/accounts/${id}`,
  //      headers: {
  //        Accept: 'application/json',
  //        'Content-Type': 'application/json'
  //      }
  //    })
  //      .then(response => {
  //       let contractNumber = response || {}

  //       this.setState(
  //         {
  //           expenseForm: {
  //             ...this.state.expenseForm,
  //             contractNumber:contractNumber
  //           }
  //         })
  //       //  return response
  //      })
  //      .catch(error => {
  //        errorHandler(error, this.props.dispatch)
  //      })
  //  }

  fetchSelectedUser(id) {
    return instance({
      method: "GET",
      url: `/api/accounts/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }
  editHistoryFrom = (id, requestEdit) => {
    Object.values(id).map(async (e) => {
      let eng = e && e.engineHours ? e.engineHours / (1000 * 3600) : 0;
      // let dodometer =
      //   e && e.odometer ? setAttributeFormat("mileage", e.odometer) : 0;
      // let status1 =   this.state.tabBtn === 'tab2' ? e?.status : requestEdit ? "required" : "completed";
      let status1 = e?.status;
      let garage, contractNumber;
      if (e.garageNumber) {
        garage = await this.fetchSelectedGarage(e.garageNumber);
      }
      if (e.contractNumber) {
        contractNumber = await this.fetchSelectedUser(e.contractNumber);
      }
      this.setState(
        {
          // mode: '',
          expenseForm: {
            ...e,
            status: status1,
            garage: garage,
            engineHours: eng,
            // odometer: dodometer,
            contractNumber: contractNumber,
          },
          isVisible: true,
          loader: true,
          historyEdit: requestEdit ? false : true,
          historyId: e && e.id,
          selectedVehicleId: e && e.vehicleId,
          showStats: false,
          isValidVehicle: e.vehicleLicensePlate ? true : false,
          // isValidVehicle:true,
        },
        () => {
          if (e && e.id) this.getExpense(e.id);
          if (e.contractNumber) this.getExpenseTypes(e.contractNumber);
          if (e && e.vehicleId)
            this.getVehiclesData(e && e.vehicleId, "updateHist", false);
        }
      );
    });
    this.getData("vehicles", "id", 0, "multi", (data) => {
      vehiclesList = data.map((item) => {
        return {
          id: item.id,
          label: item.label,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: "string",
        };
      });
    });
    this.getData("drivers", "id", 0, "multi", (data) => {
      driversList = data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          uniqueId: item.uniqueId,
          valueType: "string",
        };
      });
    });
  };

  fetchSelectedGarage(id) {
    return instance({
      method: "GET",
      url: `/api/garages/garagenumber?garagenumber=${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  getData = (collection, field, value, item, cb) => {
    const HideMe = document.getElementById("HideMe");
    ReactDOM.render(
      <Provider store={store}>
        <SingleItem
          collection={collection}
          field={field}
          value={value}
          item={item}
        >
          {(id) => {
            cb(id);
            return null;
          }}
        </SingleItem>
      </Provider>,
      HideMe
    );
  };

  searchsubTypeList = async (inputValue, row, index) => {
    let array = this.state.serviceExpenseCollection;
    const expenseTypeId = array[index].expenseTypeId.id;
    let selectedVehicle = this.state.expenseForm?.vehicles?.id || "";

    if (!inputValue) {
      return [];
    }
    let res = [];
    if (source) {
      source.cancel("Operation canceled due to new request=======.");
    }
    source = axios.CancelToken.source();

    return await instance({
      method: "GET",
      cancelToken: source.token,
      url: `/api/expensetypes/subtype/get?expenseTypeId=${expenseTypeId}&vehicleId=${selectedVehicle}&subTypeNum=&page=1&limit=10&search=${inputValue}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data) {
          return response.data.map((item) => {
            return {
              key: item.id,
              id: item.id,
              // name: `${item.label} - ${item.subTypeNum}`,
              label: `${item.label} - ${item.subTypeNum}`,
              // showLabel: `${item.label} - ${item.subTypeNum}`,
              expenseTypeId: item.expenseTypeId,
              name: item.label,
              // label: item.label,
              parentId: item.parentId,
              unitPrice: item.unitPrice,
              itemCode: item.subTypeNum,
              expenseType: item.expenseType,
            };
          });
        }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  handleChangeServices = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection];
    if (name === "expenseTypeId") {
      // this.getSubTypes(value&&value.id)
      // rows[index].selectedSubTypes = [];
      rows[index].expenseSubTypeId = {};

      let selectedVehicle = this.state.expenseForm?.vehicles?.id || "";
      let res = [];
      instance({
        method: "GET",
        url: `/api/expensetypes/subtype/get?expenseTypeId=${
          value && value.id
        }&vehicleId=${selectedVehicle}&page=1&limit=10&search=`,
        // url: `api/expensetypes/subtype?expenseTypeId=${value && value.id}&vehicleId=${selectedVehicle}&search=`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response &&
          response.data.map((item) => {
            res.push({
              key: item.id,
              id: item.id,
              expenseTypeId: item.expenseTypeId,
              // label: item.label,
              parentId: item.parentId,
              unitPrice: item.unitPrice,
              itemCode: item.subTypeNum,
              expenseType: item.expenseType,
              label: `${item.label} - ${item.subTypeNum}`,
              name: item.label,
              // showLabel: `${item.label} - ${item.subTypeNum}`,
            });
          });
        rows[index].selectedSubTypes = res;
        rows[index].expenseTypeId = value;
        this.setState({ serviceExpenseCollection: rows }, () => {
          this.checkRequiredFields3();
        });
      });
    } else {
      if (name === "expenseSubTypeId") {
        rows[index]["cost"] = value.unitPrice !== 0 ? value.unitPrice : 0;
      }
      if (name === "quantity") {
        if (value > 0) {
          rows[index]["cost"] = parseFloat(
            value * rows[index].expenseSubTypeId.unitPrice
          ).toFixed(2);
          rows[index][name] = parseFloat(value);
        }
      }
      if (name === "unitPrice") {
        if (value >= 0) {
          rows[index].expenseSubTypeId.unitPrice = parseFloat(value);
          rows[index]["cost"] = parseFloat(
            value * rows[index].quantity
          ).toFixed(2);
          rows[index][name] = parseFloat(value);
        }
      }

      let keys = [];
      if (name !== "unitPrice" || name !== "quantity") {
        rows[index][name] = value;
      }
      rows.map((i) => {
        keys.push(i.x);
        return null;
      });
      let sorted_arr = keys.slice().sort();
      let results = [];
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
          results.push(sorted_arr[i]);
        }
      }
      let a = [];
      keys.map((item, index) => {
        results.map((w) => {
          if (item === w) {
            a.push(index);
          }
          return null;
        });
        return null;
      });
      a.map((val) => {
        rows[val] = { ...rows[val] };
        return null;
      });
      this.setState(
        { serviceExpenseCollection: rows, error: a.length > 0 ? true : false },
        () => {
          this.checkRequiredFields3();
        }
      );
    }
  };

  checkRequiredFields3 = () => {
    const {
      name,
      serviceTime,
      vehicles,
      refNum,
      garage,
      vehicleLicensePlate,
      garageNumber,
      odometer,
    } = this.state.expenseForm;

    // Check if all items in the serviceExpenseCollection array have a valid quantity and expenseSubTypeId
    const allExpensesValid = this.state.serviceExpenseCollection.every(
      (item) => {
        const { expenseSubTypeId, quantity } = item;
        return (
          Object.keys(expenseSubTypeId).length > 0 &&
          parseInt(quantity) >= 1 &&
          quantity
        );
      }
    );

    const isVehiclesValid = vehicles && Object.keys(vehicles).length > 0;

    if (this.state.serviceExpenseCollection.length > 0) {
      if (
        name &&
        odometer &&
        serviceTime &&
        (isVehiclesValid || vehicleLicensePlate) &&
        (garage || garageNumber) &&
        allExpensesValid &&
        refNum
        //&& status
      ) {
        this.setState({
          isVisible: false,
        });
      } else {
        this.setState({
          isVisible: true,
        });
      }
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };

  getVehiclesData = (id, check, date) => {
    let url = date
      ? `/api/vehicles/${id}/accumulator?date=${date}`
      : `/api/vehicles/${id}/accumulator`;
    if (id) {
      instance({
        method: "GET",
        url: url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (check === "updateHist") {
            this.setState({
              expenseForm: {
                ...this.state.expenseForm,
                currentEngineHours: response.hours
                  ? (response.hours / (1000 * 3600)).toFixed(2) + " h"
                  : 0,
                currentOdometer: response.totalDistance
                  ? setAttributeFormat("mileage", response.totalDistance)
                  : 0,
              },
              // mode: check
            });
          } else {
            this.setState(
              {
                expenseForm: {
                  ...this.state.expenseForm,
                  engineHours: response.hours
                    ? (response.hours / (1000 * 3600)).toFixed(2) + " h"
                    : 0,
                  // odometer: response.totalDistance
                  //   ? setAttributeFormat("mileage", response.totalDistance)
                  //   : 0,
                  odometer: response.totalDistance ? response.totalDistance : 0,
                },
              },
              () => this.checkRequiredFields3()
            );
          }
          // }
          // }
        })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
        });
      // .catch(e => {
      //     this.props.dispatch(
      //       Notifications.error({
      //         message: this.props.translate('invalidVehicle'),
      //         autoDismiss: 10
      //       })
      //     )
      // })
    }
  };

  deleteRow = (row, index, e) => {
    let rows = [...this.state.serviceExpenseCollection];
    rows.splice(index, 1);
    this.setState(
      {
        serviceExpenseCollection: rows,
        isVisible:
          rows[0].expenseSubTypeId && rows[0].cost && rows[0].quantity
            ? false
            : true,
      },
      () => {
        this.state.serviceExpenseCollection.map((row, index) => {
          this.handleChart2("quantity", row["quantity"], index);
          this.handleChart2("cost", row["cost"], index);
          this.handleChart2("expenseSubTypeId", row["expenseSubTypeId"], index);
          this.handleChart2("selectedSubTypes", row["selectedSubTypes"], index);
          return null;
        });
      }
    );
  };

  handleChart2 = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection];
    let keys = [];
    rows[index][name] = value;
    rows.map((i) => {
      keys.push(i.x);
      return null;
    });
    let sorted_arr = keys.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    let a = [];
    keys.map((item, index) => {
      results.map((w) => {
        if (item === w) {
          a.push(index);
        }
        return null;
      });
      return null;
    });
    rows.map((sal) => {
      sal.e = false;
      return null;
    });
    a.map((val) => {
      rows[val] = { ...rows[val], e: true };
      return null;
    });
    this.setState({
      serviceExpenseCollection: rows,
      error: a.length > 0 ? true : false,
    });
  };

  handleChangeVAT = (name, e) => {
    this.setState(
      {
        [name]: e,
      },
      () => {
        this.checkRequiredFields3();
      }
    );
  };

  submitExpense = () => {
    let expenseSubTypeId = 0,
      totalQuantity = 0,
      totalCost = 0,
      expenseType = 0,
      quantity = 0;
    let newFile1 = [];
    const orderNo = 1 + Math.random() * (1000 - 1);

    const newFile = (
      this.state.serviceExpenseCollection && this.state.serviceExpenseCollection
    ).map((item) => {
      if (item.quantity && item.expenseSubTypeId.id) {
        totalCost = totalCost + parseFloat(item.cost);
        totalQuantity = totalQuantity + parseInt(item.quantity);
        expenseSubTypeId = item.expenseSubTypeId.id || "";
        expenseType = item.expenseTypeId.id || "";
        quantity = parseInt(item.quantity);
        delete item.type;
        delete item.e;
        delete item.selectedSubTypes;
        delete item.allExpenseTypes;
        // delete item.unitPrice
        const unitPrice = parseFloat(item.expenseSubTypeId.unitPrice) || 0;
        return {
          ...item,
          serviceId: 0,
          // cost:unitPrice,
          cost: parseFloat(item.cost),
          unitPrice: unitPrice,
          expenseSubTypeId: expenseSubTypeId,
          expenseTypeId: expenseType,
          subTypeLabel: item.expenseSubTypeId.name,
          subTypeNum: item.expenseSubTypeId.itemCode,
          // typeLabel: item.expenseTypeId.label,
          typeLabel:
            item.expenseTypeId.label === "packages" ||
            item.expenseTypeId.name === "packages" ||
            item.expenseTypeId.label === "services" ||
            item.expenseTypeId.name === "services" ||
            item.expenseTypeId.label === "items" ||
            item.expenseTypeId.name === "items"
              ? "contract"
              : "spot",
          expenseType:
            item.expenseTypeId.label === "packages" ||
            item.expenseTypeId.name === "packages" ||
            item.expenseTypeId.label === "services" ||
            item.expenseTypeId.name === "services" ||
            item.expenseTypeId.label === "items" ||
            item.expenseTypeId.name === "items"
              ? item.expenseTypeId.label || item.expenseTypeId.name
              : item.expenseSubTypeId.expenseType,
        };
      }
    });
    newFile.map((item) => {
      if (item) {
        newFile1.push(item);
      }
    });
    this.setState({ serviceExpenseCollection: newFile1 }, () => {
      const obj = DataFormat(
        this.state.expenseForm,
        totalCost,
        totalQuantity,
        this.state.serviceExpenseCollection,
        this.props.ServerSetting,
        this.props.logInUser.customerType
      );
      let url =
        this.props.logInUser.customerType === 1 ||
        this.props.logInUser.customerType === 2
          ? `/api/services/estimate`
          : `/api/services/?zerovat=${this.state.zerovat}&issueinvoice=${this.state.issueinvoice}`;

      instance({
        url: url,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          this.props.dispatch(notifySuccess("requestCreated"));
          this.setState(
            {
              mode: this.state.dueActive
                ? "history"
                : this.state.tab === "showMaintenance"
                ? "showMaintenance"
                : "due",
              completeMode: false,
              zerovat: false,
              issueinvoice: false,
              isValidVehicle: true,
              itemSearch: "",
              currentPageHist: 1,
              expenseForm: { ...defaultExpenseForm },
              serviceExpenseCollection: [
                { quantity: "", cost: "", expenseSubTypeId: "" },
              ],
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
              this.fetchData(
                this.props.logInUser,
                this.state.currentPage,
                this.state.pagesize
              );
            }
          );
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    });
    this.closeAllDueMaintenance();
  };

  ud_expenseSubmit = (chk) => {
    let expenseSubTypeId = 0,
      totalQuantity = 0,
      totalCost = 0,
      expenseSubTypeLabel = "",
      expenseTypeLabel = "",
      expenseTypeId = 0;
    let newFile1 = [];
    const newFile = (
      this.state.serviceExpenseCollection && this.state.serviceExpenseCollection
    ).map((item) => {
      // if (item.quantity && item.expenseSubTypeId && item.expenseSubTypeId.id) {
      totalCost = totalCost + parseFloat(item?.cost);
      totalQuantity = totalQuantity + parseInt(item?.quantity);
      expenseSubTypeId = item?.expenseSubTypeId?.id || "";
      expenseTypeId = item?.expenseTypeId?.id || "";
      expenseSubTypeLabel = item?.expenseSubTypeId?.label || "";
      expenseTypeLabel = item?.expenseTypeId?.name || "";

      const unitPrice = parseFloat(item?.expenseSubTypeId?.unitPrice) || 0;

      delete item.selectedSubTypes;
      delete item.allExpenseTypes;
      delete item.type;
      delete item.e;

      return {
        ...item,
        // cost:unitPrice,
        cost: parseFloat(item.cost),
        unitPrice: unitPrice,
        serviceId: this.state.expenseForm.id,
        expenseSubTypeId: expenseSubTypeId,
        expenseTypeId: expenseTypeId,
        expenseSubTypeLabel: expenseSubTypeLabel,
        expenseTypeLabel: expenseTypeLabel,
        subTypeLabel: item.subTypeLabel
          ? item.subTypeLabel
          : item.expenseSubTypeId.label,
        subTypeNum: item.subTypeNum
          ? item.subTypeNum
          : item.expenseSubTypeId.itemCode,
        // typeLabel:item.typeLabel ? item.typeLabel : item.expenseTypeId.label,
        typeLabel: item.typeLabel
          ? item.typeLabel
          : item.expenseTypeId.label === "packages" ||
            item.expenseTypeId.label === "services" ||
            item.expenseTypeId.label === "items"
          ? "contract"
          : "spot",
        // expenseType:item.expenseTypeId.name || item.expenseSubTypeId.label
        // expenseType: item.expenseType ? item.expenseType :  item.expenseSubTypeId.expenseType
        expenseType: item.expenseType
          ? item.expenseType
          : item.expenseTypeId.label === "packages" ||
            item.expenseTypeId.name === "packages" ||
            item.expenseTypeId.label === "services" ||
            item.expenseTypeId.name === "services" ||
            item.expenseTypeId.label === "items" ||
            item.expenseTypeId.name === "items"
          ? item.expenseTypeId.label || item.expenseTypeId.name
          : item.expenseSubTypeId.expenseType,
      };
      // }
    });
    newFile.map((item) => {
      if (item) {
        newFile1.push(item);
      }
    });

    this.setState({ serviceExpenseCollection: newFile1 }, () => {
      const obj = DataFormat(
        this.state.expenseForm,
        totalCost,
        totalQuantity,
        this.state.serviceExpenseCollection,
        this.props.ServerSetting,
        this.props.logInUser.customerType
      );
      let url = !chk
        ? this.state.expenseForm.creationMode === "garage"
          ? `/api/services/estimate/${parseInt(this.state.expenseForm.id)}`
          : `/api/services/${parseInt(this.state.expenseForm.id)}?zerovat=${
              this.state.zerovat
            }&issueinvoice=${this.state.issueinvoice}`
        : `/api/services/retry`;
      instance({
        // url: !chk
        //   ? `/api/services/${parseInt(this.state.expenseForm.id)}?zerovat=${this.state.zerovat}&issueinvoice=${this.state.issueinvoice}`
        //   : `/api/services/retry`,
        url: url,
        method: !chk ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((res) => {
          this.props.dispatch(notifySuccess("requestUpdated"));
          this.setState(
            {
              // mode: this.state.dueActive ? 'due':'history',
              mode: this.state.tab,
              zerovat: false,
              issueinvoice: false,
              isValidVehicle: true,
              itemSearch: "",
              loader: false,
              currentPageHist: 1,
              expenseForm: { ...defaultExpenseForm },
              serviceExpenseCollection: [
                {
                  quantity: "",
                  cost: "",
                  expenseSubTypeId: "",
                  expenseTypeId: "",
                },
              ],
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
            }
          );
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          this.setState({
            mode: this.state.dueActive ? "due" : "history",
            itemSearch: "",
            loader: false,
            expenseForm: { ...defaultExpenseForm },
            serviceExpenseCollection: [
              {
                quantity: "",
                cost: "",
                expenseSubTypeId: "",
                expenseTypeId: "",
              },
            ],
          });
        });
    });
    this.getExpenseTypes();
    this.closeAllDueMaintenance();
  };

  onRetry = (item) => {
    this.ud_expenseSubmit(true);
  };

  closeAllDueMaintenance = () => {
    this.setState({
      showDueDialog: false,
      showDialogBtn: false,
      vehicleDueMaintenance: [],
    });
  };

  addMore = () => {
    let rows = [];
    rows = [...this.state.serviceExpenseCollection];

    if (rows.length) {
      let last = { ...rows[rows.length - 1] };
      rows.push({
        expenseSubTypeId: "",
        quantity: "1",
        cost: "1",
        expenseTypeId: "",
        selectedSubTypes: [],
      });
    } else {
      let last = { ...rows[rows.length - 1] };
      rows.push(last);
    }
    this.setState({ serviceExpenseCollection: rows });
  };

  imagePopup = (files) => {
    this.setState({
      maintenanceHistForm: {
        ...this.state.maintenanceHistForm,
        files,
      },
      enableImagePopup: true,
    });
  };

  deleteImage = (e, image, type) => {
    // let image = this.state.maintenanceHistForm.files && this.state.maintenanceHistForm.files.split(',')
    const s = image.filter((item, index) => index !== e);
    if (type === "url") {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            files: s.toString(),
          },
          expenseForm: {
            ...this.state.expenseForm,
            files: s.toString(),
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (type === "base64") {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            files: s.toString(),
          },
          image64Type: s,
        },
        () => {
          this.checkRequiredFields3();
          tempFile = s;
        }
      );
    }

    //  tempFile = s
  };

  onCloseModal = () => {
    this.setState({
      enableImagePopup: false,
    });
  };

  handleClickFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseFilter = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeFilter = (name, event) => {
    let filter = "";
    if (name === "createdBy") {
      this.setState(
        {
          createdBy: event.target.checked,
        },
        () => {
          if (this.state.showStats) this.checkRequiredFieldsSidebar();
        }
      );
    } else {
      this.setState(
        {
          statusFilter: {
            ...this.state.statusFilter,
            [name]: event.target.checked,
          },
          countChecked: 0,
          currentPageHist: 1,
        },
        () => {
          Object.entries(this.state.statusFilter).forEach(([key, value]) => {
            if (value) {
              if (key === "OpToCustomer") {
                filter += "&status=OpToCustomer&status=GarageToCustomer";
              } else if (key === "GarageToOp") {
                filter += "&status=GarageToOp&status=CustomerToOp";
              } else if (key === "CustomerToOp") {
                filter += "&status=CustomerToGarage&status=CustomerToOp";
              } else {
                filter += "&status=" + key;
              }
              this.setState((prevState) => ({
                countChecked: prevState.countChecked + 1,
              }));
            }
          });
          this.setState(
            {
              filterStatusType: filter,
            },
            () => {
              if (this.state.showStats) this.checkRequiredFieldsSidebar();
              // else this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)
            }
          );
        }
      );
    }
  };

  switchMode = (mode, filterType) => {
    if (filterType === "due") {
      this.setState(
        {
          mode: filterType,
          itemSearch: "",
          tab: "due",
          filterType,
          isDataRecieved: false,
          dueActive: false,
          currentPageHist: 1,
          itemPagination: this.state.savedData
            ? this.state.savedData
            : this.state.itemPagination,
          searchVehicleId: {
            id: "",
            label: this.props.translate("searchVehicles"),
          },
          searchAreaId: { id: "", label: this.props.translate("searchAreas") },
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else if (filterType === "history") {
      this.setState(
        {
          mode: filterType,
          itemSearch: "",
          filterType,
          tab: "history",
          isDataRecieved: false,
          dueActive: true,
          currentPageHist: 1,
          itemPagination: this.state.savedData
            ? this.state.savedData
            : this.state.itemPagination,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else {
      this.getVehiclesList();
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize,
        true
      );
      this.setState({
        itemSearch: "",
        tab: "showMaintenance",
        mode: filterType,
        dueActive: false,
      });
    }
  };

  getVehiclesList = () => {
    vehiclesList = this.props.vehicles.map((item) => {
      return {
        id: item.id,
        label: item.label,
        value: item.id,
        vehicleLP: item.vehicleLicensePlate,
        valueType: "string",
      };
    });
  };

  fetchSelectedVehicle = async (id) => {
    let z = await instance({
      method: "GET",
      url: `/api/vehicles/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log("err===", err);
      });
    return z;
  };

  editMaintenanceForm = (maintenanceForm) => {
    this.getSubTypes(
      maintenanceForm[0].attributes &&
        maintenanceForm[0].attributes.serviceTypeId
    ).then((subTypes) => {
      let selectedServiceExpenseId = expenseType.find(
        (item) => item.id === maintenanceForm[0].attributes.serviceTypeId
      );
      let selectedVehicle = this.props.vehicles.find(
        (item) => item.id === maintenanceForm[0].vehicleId
      );
      let selectedUnit = { id: 0, label: "", value: 0 };
      if (subTypes && subTypes.length) {
        Object.values(maintenanceForm).map((maintenanceForm1) => {
          this.setState({ mode: "", maintenanceForm1: {} }, () => {
            let obj = JSON.parse(JSON.stringify(maintenanceForm1));
            if (obj.attributes && obj.attributes.byMileage) {
              obj.attributes.mileage = parseFloat(
                (obj.attributes.mileage / 1000).toFixed(3)
              );
              obj.attributes.mileageInterval = parseFloat(
                (obj.attributes.mileageInterval / 1000).toFixed(3)
              );
              obj.attributes.reminderBeforeMileage = parseFloat(
                (obj.attributes.reminderBeforeMileage / 1000).toFixed(3)
              );
            }
            if (obj.attributes && obj.attributes.byHours) {
              obj.attributes.hours = parseFloat(
                (obj.attributes.hours / (1000 * 3600)).toFixed(3)
              );
              obj.attributes.hoursInterval = parseFloat(
                (obj.attributes.hoursInterval / (1000 * 3600)).toFixed(3)
              );
              obj.attributes.reminderBeforeHours = parseFloat(
                obj.attributes.reminderBeforeHours / (1000 * 3600).toFixed(3)
              );
            }
            if (obj.attributes && obj.attributes.byDays) {
              obj.attributes.date = obj.attributes.date;
              obj.attributes.daysInterval =
                parseFloat(obj.attributes && obj.attributes.daysInterval) ||
                parseFloat(1);
              obj.attributes.reminderBeforeDays =
                parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1);
            }
            if (obj.attributes.serviceExpenseTypeId) {
              let serviceSubType = subTypes.find(
                (el) =>
                  el.id === maintenanceForm[0].attributes.serviceExpenseTypeId
              );
              obj.attributes.serviceTypeId = selectedServiceExpenseId;
              obj.attributes.serviceExpenseTypeId = serviceSubType;
            }
            selectedUnit.id = obj.deviceId;
            selectedUnit.label = obj.deviceName;
            selectedUnit.value = obj.deviceId;

            delete obj.progress;
            this.setState({
              mode: "update",
              itemSearch: "",
              selectedUnit,
              maintenanceForm: {
                ...obj,
                vehicleId: selectedVehicle,
              },
            });
          });
        });
      }
    });
  };

  setPaginationHistory = (user) => {
    const { itemsPagination } = this.state;
    if (
      itemsPagination &&
      itemsPagination.data &&
      itemsPagination.data.length
    ) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pagesizeHist;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pagesizeHist = this.state.pagesizeHist;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  setPagination = (user) => {
    const { itemPagination } = this.state;
    if (itemPagination && itemPagination.items && itemPagination.items.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pagesize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pagesize = this.state.pagesize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  setDuePagination = (user) => {
    const { vehicleDueMaintenance } = this.state;
    if (
      vehicleDueMaintenance &&
      vehicleDueMaintenance.data &&
      vehicleDueMaintenance.data.length
    ) {
      let nUser = { ...user };
      let lastPage = nUser.total / user.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      // delete nUser.data
      nUser.pageSize = user.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangeRowsPerPage = (value, mode) => {
    if (!this.state.showStats) {
      if (source) {
        source.cancel();
      }
      if (mode === "maintenanceHist") {
        this.setState(
          {
            pagesizeHist: value,
            currentPageHist: 1,
          },
          () => {
            this.fetchMaintenanceHistory(
              this.state.currentPageHist,
              this.state.pagesizeHist
            );
          }
        );
      } else if (mode === "maintenance") {
        this.setState(
          {
            pagesize: value,
            currentPage: 1,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize,
              true
            );
          }
        );
      } else if (mode === "dueMaintenance") {
        this.fetchDueMaintenance(
          this.state.selectedVehicleId,
          this.state.vehicleDueMaintenance.page,
          value
        );
      }
    } else {
      if (
        this.state.filterStatusType &&
        this.state.toDate &&
        this.state.fromDate
      ) {
        this.setState(
          {
            pagesizeHist: value,
            currentPageHist: 1,
          },
          () => {
            this.fetchMaintenanceHistory(
              this.state.currentPageHist,
              this.state.pagesizeHist
            );
          }
        );
      }
    }
  };

  handleChangePage = (value, mode) => {
    if (source) {
      source.cancel();
    }
    if (mode === "maintenanceHist") {
      this.setState(
        {
          currentPageHist: value,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else if (mode === "maintenance") {
      this.setState(
        {
          currentPage: value,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
        }
      );
    } else if (mode === "dueMaintenance") {
      this.fetchDueMaintenance(
        this.state.selectedVehicleId,
        value,
        this.state.vehicleDueMaintenance.pageSize
      );
    }
  };

  SearchItem = (searchValue) => {
    this.setState({ itemSearch: searchValue });
  };

  SearchVehicle = (name, value) => {
    this.setState({ searchVehicleId: value });
  };

  SearchArea = (name, value) => {
    this.setState({ searchAreaId: value });
  };

  closeCreateFrom = (mode) => {
    this.getExpenseTypes();
    this.setState(
      {
        mode,
        itemSearch: "",
        // itemPagination:this.state&&this.state.savedData,
        maintenanceForm: "",
        isVisible: true,
        loader: false,
        completeMode: false,
        // histFormVisible: false,
        historyEdit: false,
        vehicleTracker: "",
        expenseForm: { ...defaultExpenseForm },
        serviceExpenseCollection: [
          {
            quantity: "",
            cost: "",
            expenseSubTypeId: "",
            expenseTypeId: "",
            selectedSubTypes: [],
          },
        ],
      },
      () => {
        tempFile = [];
      }
    );
    this.closeAllDueMaintenance();
  };

  onDeleteMaintenance = () => {
    if (this.state.itemDeleteId) {
      instance({
        url: `/api/maintenance/${this.state.itemDeleteId}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(
            removedMaintenance({ id: this.state.itemDeleteId })
          );
          this.props.dispatch(notifySuccess("maintenanceIsDeleted"));
          this.setState({
            onDeleteConfirm: false,
            currentPage: 1,
          });
          this.closeCreateFrom("showMaintenance");
          this.maintenanceAnalytic();
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onDeleteExpenseItem = () => {
    if (this.state.itemDeleteExpenseId) {
      instance({
        url: `/api/services/${this.state.itemDeleteExpenseId}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(notifySuccess("expenseIsDeleted"));
          this.setState(
            {
              onDeleteConfirm: false,
              currentPage: 1,
              currentPageHist: 1,
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
            }
          );

          this.onCancelExpense();
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onRemovedMaintenance = (item) => {
    this.setState({
      onDeleteConfirm: true,
      itemDeleteId: item[0].id,
      itemDeleteName: item[0].name,
    });
  };

  onStatusChange = (item) => {
    this.setState({
      onStatusChange: true,
      selectedStatusChange: item,
      itemStatusChange: item.name,
    });
  };

  onMarkComplete = (item) => {
    const typeExpense = expenseType.find((obj) => {
      return (
        parseInt(obj.key) ===
        parseInt(item.attributes && item.attributes.serviceTypeId)
      );
    });
    const searchedVehcile = this.props.vehicles.find(
      (it) => it.id === item.vehicleId
    );
    let type = "";
    if (item && item.attributes && item.attributes.serviceTypeId) {
      let res = [];
      instance({
        method: "GET",
        url: `/api/expensetypes/subtype?expenseTypeId=${
          item && item.attributes && item.attributes.serviceTypeId
        }&search=`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response &&
            response.map((item) => {
              res.push({
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId,
              });
            });
          subTypes = res;
          type = (subTypes && subTypes).find(
            (i) =>
              i.key ===
              parseInt(item.attributes && item.attributes.serviceExpenseTypeId)
          );
          this.setState({
            serviceExpenseCollection: [
              {
                attributes: {},
                cost: item.cost,
                expenseTypeId: typeExpense,
                expenseSubTypeId: type,
                id: item.id,
                quantity: 1,
                serviceId: item.id,
                allExpenseTypes: expenseType,
                selectedSubTypes: subTypes,
              },
            ],
          });
        })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
        });
    }
    this.getData("vehicles", "id", 0, "multi", (data) => {
      vehiclesList = data.map((item) => {
        return {
          id: item.id,
          label: item.label,
          deviceId: item.deviceId,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: "string",
        };
      });
    });
    this.getData("drivers", "id", 0, "multi", (data) => {
      driversList = data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          uniqueId: item.uniqueId,
          valueType: "string",
        };
      });
    });
    this.setState(
      {
        mode: "createExpense",
        filterType: "history",
        completeMode: true,
        expenseForm: {
          maintenanceId: item.id,
          attributes: {
            updateMaintenance: true,
          },
          type: typeExpense,
          name: item.name,
          cost: item.cost,
          vehicles: searchedVehcile,
          address: "",
          serviceTime: moment().format("YYYY-MM-DD"),
          status: "completed",
          vehicleId: "",
        },
      },
      () => {
        this.getVehiclesData(item.vehicleId, "createExpense", false);
      }
    );
  };

  onChangeStatus = () => {
    let data = this.state.selectedStatusChange;
    if (data) {
      data.attributes.status = !data.attributes.status;
      this.setState(
        {
          maintenanceForm: data,
          statusMode: true,
        },
        () => {
          this.submitForm();
        }
      );
    }
  };

  onSetMarkComplete = (item) => {
    this.setState({
      selectedMarkComplete: { ...item, status: "completed" },
      onMarkComplete: true,
    });
  };

  onCompleteMark = () => {
    let data = this.state.selectedMarkComplete;
    if (data) {
      this.setState(
        {
          maintenanceForm: data,
          statusMode: true,
        },
        () => {
          this.submitForm("complete");
        }
      );
    }
  };

  onRemovedExpense = (item) => {
    this.setState({
      onDeleteExpense: true,
      itemDeleteExpenseId: item[0].id,
      itemDeleteExpense: item[0].name,
    });
  };

  onCancelMaintenance = () => {
    this.setState({
      onDeleteConfirm: false,
    });
  };

  onCancelStatus = () => {
    this.setState({
      onStatusChange: false,
    });
  };

  onCancelMark = () => {
    this.setState({
      onMarkComplete: false,
    });
  };

  onCancelExpense = () => {
    this.setState({
      onDeleteExpense: false,
    });
  };

  handleChangeExpense = (name, value) => {
    if (name === "garage") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            garageId: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    }
    if (name === "driver" || name === "contractNumber") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            vehicles: {},
            [name]: value,
          },
        },
        () => {
          if (name === "contractNumber") {
            this.getVehicles(value.userId);
            this.getExpenseTypes(value.contractNumber);
          }
          this.checkRequiredFields3();
        }
      );
    } else if (value && value.name === "devices") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            device: name,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "vehicles") {
      let findArea = this.props.vehicles.find((item) => item.id === value?.id);
      let resArea = areaList.find((item) => item.key === findArea?.areaId);

      this.setState(
        {
          selectedVehicleId: value.id,
          expenseForm: {
            ...this.state.expenseForm,
            vehicles: value,
            areaId: resArea || {},
          },
          serviceExpenseCollection: [
            {
              quantity: "1",
              cost: "1",
              expenseSubTypeId: "",
              expenseTypeId: "",
              selectedSubTypes: [],
            },
          ],
          isValidVehicle: true,
        },
        () => {
          this.checkRequiredFields3();
          this.getVehiclesData(
            this.state.selectedVehicleId,
            this.state.mode,
            this.state.selectedDate
          );
          this.showDueMaintenance(this.state.selectedVehicleId);
          this.getDriverByVehicleId(this.state.selectedVehicleId);
        }
      );
    } else if (name === "type") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "service") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value.name,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "status" || name === "workOrderNumber") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value.target.value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "files") {
      tempFile.push(value);
      this.setState(
        {
          image64Type: tempFile,
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "serviceTime" || name === "expireTime") {
      // Check if the value is a valid Date object or convert it if possible
      let selectedDate = value instanceof Date ? value : value?.toDate?.();

      if (selectedDate && !isNaN(selectedDate)) {
        if (name === "serviceTime") {
          let exDate = this.addDays(selectedDate, 7);
          let expireTimeCal = new Date(exDate.setUTCHours(0, 0, 0, 0));
          let today = new Date();
          let calTodayTime = new Date(today.setUTCHours(0, 0, 0, 0));
          let calTime = new Date(selectedDate.setUTCHours(0, 0, 0, 0));

          // Check if the dates are valid before using toISOString()
          if (!isNaN(calTime) && !isNaN(calTodayTime)) {
            let time = calTime.toISOString();
            let todayTime = calTodayTime.toISOString();
            let expireTimeConv = expireTimeCal.toISOString();
            let settime = todayTime < time;
            let passDate = settime ? todayTime : time;

            this.setState(
              {
                selectedDate: time,
                expenseForm: {
                  ...this.state.expenseForm,
                  expireTime: expireTimeConv,
                  [name]: selectedDate,
                },
              },
              () => {
                this.checkRequiredFields3();
                this.getVehiclesData(
                  this.state.selectedVehicleId,
                  this.state.mode,
                  passDate
                );
              }
            );
          }
        } else if (name === "expireTime") {
          let calTime = new Date(selectedDate.setUTCHours(0, 0, 0, 0));

          // Check if the date is valid before using toISOString()
          if (!isNaN(calTime)) {
            let time = calTime.toISOString();
            this.setState(
              {
                expenseForm: {
                  ...this.state.expenseForm,
                  [name]: time,
                },
              },
              () => {
                this.checkRequiredFields3();
              }
            );
          }
        }
      } else {
        // Handle the case where the value is null or invalid (e.g., user presses backspace)
        this.setState(
          {
            expenseForm: {
              ...this.state.expenseForm,
              [name]: null,
            },
          },
          () => {
            this.checkRequiredFields3();
          }
        );
      }
    } else if (name === "refdate") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "updateMaintenance") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            attributes: {
              ...this.state.expenseForm.attributes,
              [name]: value,
            },
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "sendAlert") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            attributes: {
              ...this.state.expenseForm.attributes,
              [name]: value,
            },
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "areaId") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            areaId: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    }
  };
  uploadFile = async (mode) => {
    tempFile = [];
    let { image64Type } = this.state;
    this.setState({ loader: true });
    if (image64Type && image64Type.length) {
      // image64Type.map((item,i) =>
      for (let i = 0; i < image64Type.length; i++) {
        var today = Math.round(new Date().getTime() / 1000);
        const myExe = image64Type[i].split(";base64", 1);
        const exe = myExe[0].replace("data:", "");
        let obj = {
          name: today,
          contentType: exe,
          data: `${image64Type[i].split("base64")[1].substring(1)}`,
        };

        const response = await instance({
          url: `/api/uploads`,
          method: "POST",
          data: { ...obj },
        });
        try {
          const publicUrl = response;
          this.setState(
            {
              expenseForm: {
                ...this.state.expenseForm,
                files: this.state.expenseForm.files
                  ? publicUrl.publicUrl + "," + this.state.expenseForm.files
                  : publicUrl.publicUrl,
              },
            },
            () => {
              if (i === image64Type.length - 1) {
                if (this.state.mode === "updateHist") {
                  this.ud_expenseSubmit();
                } else {
                  this.submitExpense();
                }
              }
            }
          );
        } catch (error) {
          errorHandler(error, this.props.dispatch);
        }
      }
    } else {
      if (this.state.mode === "updateHist") {
        this.ud_expenseSubmit();
      } else {
        this.submitExpense();
      }
    }
  };

  uploadImage = (event, mode) => {
    let totalImages = [];
    totalImages =
      this.state.expenseForm.files && this.state.expenseForm.files.split(",");
    totalImages && totalImages.length
      ? (totalImages = totalImages.length)
      : (totalImages = 0);

    let file = event.target.files;
    let imgData = "";
    if (totalImages + this.state.image64Type.length + file.length <= 5) {
      if (file && file[0]) {
        if (file[0].size / 1000000 <= 5) {
          Object.values(file).map((item) => {
            let reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = (e) => {
              imgData = e.target.result;

              const myExe = imgData.split(";base64", 1);
              const exe = myExe[0].replace("data:", "");
              if (
                exe ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                exe ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                exe === "text/plain" ||
                exe === "image/png" ||
                exe === "image/jpeg" ||
                exe === "application/pdf"
              ) {
                let obj = {
                  name: `${item.name}`,
                  contentType: `${item.type}`,
                  // oldUrl: `${this.state.form.driverImg}`,
                  data: `${imgData.split("base64")[1].substring(1)}`,
                };
                this.setState(
                  {
                    imageFile: obj,
                  },
                  () => {
                    this.handleChangeExpense("files", imgData);
                  }
                );
              } else {
                this.props.dispatch(notifyError("onlyAcceptImage"));
              }
            };
          });
        } else {
          this.props.dispatch(notifyError("fileSize5MB"));
        }
      }
    } else {
      this.props.dispatch(notifyError("fileLimit"));
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleChangeHist = (name, value) => {
    let v = value;
    if (name === "type") {
      v = value.id;
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            status: v,
          },
        },
        () => this.checkRequiredFields2()
      );
    } else {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            [name]: v,
          },
        },
        () => {
          this.checkRequiredFields2();
        }
      );
    }
  };

  onChangedDevice = (name, item) => {
    if (item) {
      this.getData("positions", "vehicleId", item.id, "single", (data) => {
        const mileage =
          data && data.attributes && data.attributes.totalDistance
            ? (data.attributes.totalDistance / 1000).toFixed(2)
            : null;
        const hours =
          data && data.attributes && data.attributes.hours
            ? (data.attributes.hours / (3600 * 1000)).toFixed(2)
            : null;
        this.setState(
          {
            vehicleTracker: item,
            vehicleId: item,
            maintenanceForm: {
              ...this.state.maintenanceForm,
              deviceId: 0,
              vehicleId: item,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                mileage,
                hours,
                byMileage: mileage ? true : false,
                byHours: hours ? true : false,
                byDays: true,
              },
            },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      });
      return null;
    }
  };

  checkRequiredFields2 = () => {
    const { maintenanceHistForm } = this.state;
    if (
      maintenanceHistForm.cost + "".trim() &&
      maintenanceHistForm.status &&
      maintenanceHistForm.cDate
    ) {
      this.setState({
        isVisible: false,
      });
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };

  checkRequiredFields = () => {
    let { name } = this.state.maintenanceForm;
    let {
      date,
      mileage,
      hours,
      byDays,
      byHours,
      byMileage,
      serviceExpenseTypeId,
      serviceTypeId,
      emails,
      mailTo,
      notificators,
      avergeMileage,
      avergeDailyMileage,
    } = this.state.maintenanceForm && this.state.maintenanceForm.attributes;
    //  if ( name && (mileageInterval || hoursInterval || daysInterval) && (mileage || hours || date)) {
    if (
      name &&
      serviceExpenseTypeId &&
      serviceTypeId &&
      ((byMileage && mileage) ||
        (byHours && hours) ||
        (byDays && date) ||
        (avergeDailyMileage && avergeMileage))
    ) {
      if (
        notificators.includes("mail,whatsapp") &&
        mailTo === "custom" &&
        !emails
      ) {
        this.setState({
          isVisible: true,
        });
      } else {
        this.setState({
          isVisible: false,
        });
      }
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };

  handleChangeAttributes = (name, value, checked) => {
    if (value) {
      if (name === "byMileage" || name === "byHours" || name === "byDays") {
        let maintenanceForm = JSON.parse(
          JSON.stringify(this.state.maintenanceForm)
        );
        maintenanceForm.attributes[name] = checked;
        this.setState({ maintenanceForm });
      } else if (name === "serviceTypeId") {
        this.getSubTypes(value && value.id);
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                serviceTypeId: value,
                expenseName: value.name,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (name === "serviceExpenseTypeId") {
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              cost: value?.unitPrice || "",
              attributes: {
                ...this.state.maintenanceForm.attributes,
                serviceExpenseTypeId: value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                [name]: value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (this.state.maintenanceForm && this.state.maintenanceForm.attributes) {
        let attributes = { ...this.state.maintenanceForm.attributes };
        delete attributes[name];
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  submitForm = (checkTrue) => {
    let obj = JSON.parse(JSON.stringify(this.state.maintenanceForm));
    if (obj.vehicleId) {
      obj.vehicleId = this.state.statusMode ? obj.vehicleId : obj.vehicleId.id;
      obj.deviceId = this.state.statusMode ? obj.deviceId : 0;
    }
    if (obj.attributes.byMileage && !this.state.statusMode) {
      obj.attributes.mileage = parseFloat(obj.attributes.mileage * 1000);
      obj.attributes.mileageInterval = parseFloat(
        obj.attributes.mileageInterval * 1000
      );
      obj.attributes.reminderBeforeMileage = parseFloat(
        obj.attributes.reminderBeforeMileage * 1000
      );
    }
    if (obj.attributes.byHours && !this.state.statusMode) {
      obj.attributes.hours = parseFloat(obj.attributes.hours * (1000 * 3600));
      obj.attributes.hoursInterval = parseFloat(
        obj.attributes.hoursInterval * (1000 * 3600)
      );
      obj.attributes.reminderBeforeHours = parseFloat(
        obj.attributes.reminderBeforeHours * (1000 * 3600)
      );
    }
    if (obj.attributes.byDays && !this.state.statusMode) {
      obj.attributes.date = obj.attributes.date;
      obj.attributes.daysInterval =
        parseFloat(obj.attributes.daysInterval) || parseFloat(1);
      obj.attributes.reminderBeforeDays =
        parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1);
    }
    if (obj.attributes.serviceExpenseTypeId) {
      obj.attributes.serviceExpenseTypeId = this.state.statusMode
        ? obj?.attributes?.serviceExpenseTypeId
        : obj?.attributes?.serviceExpenseTypeId?.id || "";
      obj.attributes.serviceTypeId = this.state.statusMode
        ? obj?.attributes?.serviceTypeId
        : obj?.attributes?.serviceTypeId?.id || "";
    }

    obj = {
      ...obj,
      type: "",
      contractNumber: this.props.logInUser.contractNumber
        ? this.props.logInUser.contractNumber
        : this.props.ServerSettings.contractNumber
        ? this.props.ServerSettings.contractNumber
        : "",
    };
    obj.cost = parseFloat(obj.cost);
    delete obj.check;
    delete obj.parent;
    if (!obj.attributes.repeat) {
      delete obj.attributes.mileageInterval;
      delete obj.attributes.hoursInterval;
      delete obj.attributes.daysInterval;
    }
    if (!obj.attributes.byMileage) {
      delete obj.attributes.mileage;
      delete obj.attributes.mileageInterval;
      delete obj.attributes.reminderBeforeMileage;
      obj.attributes.reminderByMileage = false;
    }
    if (!obj.attributes.byHours) {
      delete obj.attributes.hours;
      delete obj.attributes.hoursInterval;
      delete obj.attributes.reminderBeforeHours;
      obj.attributes.reminderByHours = false;
    }
    if (!obj.attributes.byDays) {
      delete obj.attributes.date;
      delete obj.attributes.daysInterval;
      delete obj.attributes.reminderBeforeDays;
      obj.attributes.reminderByDays = false;
    }

    let params = "";
    if (this.state.mode === "update" || this.state.statusMode) {
      params = obj.id;
    }

    this.setState({
      isVisible: true,
    });
    if (obj) {
      let url =
        checkTrue === "complete"
          ? `/api/maintenance/${params}/complete`
          : `/api/maintenance/${params}`;
      instance({
        url: `${url}`,
        method: `${
          this.state.statusMode || this.state.mode === "update" ? "PUT" : "POST"
        }`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((maintenance) => {
          // if (response.ok) {
          if (checkTrue === "complete") {
            this.props.dispatch(notifySuccess("maintenanceIsMarkedComplate"));
            this.setState({
              onMarkComplete: false,
            });
          } else {
            // response.json().then(maintenance => {
            this.props.dispatch(addMaintenance(maintenance));
            if (this.state.mode === "update" || this.state.statusMode) {
              this.props.dispatch(notifySuccess("maintenanceIsUpdated"));
            } else {
              this.props.dispatch(notifySuccess("MaintenanceIsCreated"));
            }
            this.setState({
              selectMaintenanceId: maintenance && maintenance.id,
              itemSearch: "",
              onStatusChange: false,
              onMarkComplete: false,
              statusMode: false,
            });
            this.closeCreateFrom("showMaintenance");
            this.maintenanceAnalytic();
            this.fetchData(this.props.logInUser, 1, this.state.pagesize, true);
          }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          this.setState(
            {
              itemSearch: "",
              onStatusChange: false,
              onMarkComplete: false,
              statusMode: false,
            },
            () => {
              this.fetchData(
                this.props.logInUser,
                1,
                this.state.pagesize,
                true
              );
            }
          );
        });
    }
  };

  maintenanceSelection = () => {
    let obj = {
      deviceId: this.state.vehicleId,
      maintenanceId: this.state.selectMaintenanceId,
    };

    instance({
      url: `/api/permissions/`,
      method: `POST`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        this.props.dispatch(notifySuccess("maintenanceAssignedSuccessfully"));
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  submitHistoryForm = (e) => {
    let obj = this.state.maintenanceHistForm;
    let cDate = "";
    let items = this.state.itemsPagination.items.map((row) => {
      if (this.state.historyId === row.id) {
        row.attributes.cost = parseFloat(obj.cost);
        row.attributes.status = obj.status;
        row.attributes.cValue = obj.cValue;
        row.attributes.cDate = obj.cDate;
        row.attributes.updateMaintenance = obj.updateMaintenance;
        row.files = obj.files === "undefined" ? "" : obj.files;
      }
      cDate = moment(obj.cDate).toISOString();
      return row;
    });
    this.setState({ isVisible: true });
    instance({
      method: "GET",
      url: `/api/maintenance/history/${this.state.historyId}?cost=${obj.cost}&status=${obj.status}&cValue=${obj.cValue}&cDate=${cDate}&files=${obj.files}&updateMaintenance=${obj.updateMaintenance}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        this.props.dispatch(notifySuccess("maintenancehistoryupdated"));
        this.setState(
          {
            itemsPagination: {
              ...this.state.itemsPagination,
              items,
            },
          },
          () => {
            this.closeCreateFrom("history");
            this.maintenanceAnalytic();
          }
        );
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  maintenanceAnalytic = () => {
    instance({
      url: `/api/services/analytic`,
      method: `GET`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        this.setState({
          analyticData: data,
        });
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  item_Selection = (checked, item) => {
    let selectedUnit = item;
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selecteduser];
    let obj = {};
    if (this.state.assignMode === "user") {
      obj.userId = item.id;
    }
    if (this.state.assignMode === "unit") {
      obj.deviceId = item.id;
    }
    /* if(this.state.assignMode === 'group') {
      obj.groupId = item.id
    } */
    obj.maintenanceId = parseInt(this.state.currentMaintenance.id);
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = false;
            }
            return null;
          });

          this.props.dispatch(
            notifySuccess(
              this.state.assignMode === "user"
                ? "userUnAssignedSuccessfully"
                : this.state.assignMode === "unit"
                ? "trackerIsUnAssignedSuccessfully"
                : "groupUnAssignedSuccessfully"
            )
          );
          if (item) {
            selectedUnit.label = item.name;
            selectedUnit.value = item.value;
            this.setState(
              {
                selectedUnit,
                vehicleTracker: {},
              },
              () => {
                this.onChangedDevice(this.state.vehicleTracker);
              }
            );
          }
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = true;
            }
            return null;
          });

          this.props.dispatch(
            notifySuccess(
              this.state.assignMode === "user"
                ? "userAssignedSuccessfully"
                : this.state.assignMode === "unit"
                ? "trackerIsAssignedSuccessfully"
                : "groupAssignedSuccessfully"
            )
          );
          if (item) {
            selectedUnit.label = item.name;
            selectedUnit.value = item.value;
            this.setState(
              {
                selectedUnit,
                vehicleTracker: {},
              },
              () => {
                this.onChangedDevice(this.state.selectedUnit);
              }
            );
          }
        }

        this.props.dispatch(userDeviceWiseSelectedList(allData));

        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  onRemovedItem = (item) => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true,
    });
  };

  onRemovedExpenseItem = (item) => {
    this.setState({
      onDeleteExpenseConfirm: true,
    });
  };

  removeItem = () => {
    if (this.state.assignMode === "unit") {
      this.removeFromDatabase();
    } else if (this.state.assignMode === "user") {
      this.removeFromDatabase();
    }
    /* else if (this.state.assignMode === 'group') {

      this.removeFromDatabase();
    } */
  };

  removeFromDatabase = () => {
    let obj = this.state.deleteForm;
    let params = obj.id;
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      let call;
      if (this.state.assignMode === "user") {
        call = `/api/users/${params}`;
      } else if (this.state.assignMode === "unit") {
        call = `/api/devices/${params}`;
      }

      if (call) {
        instance({
          url: `${call}`,
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // if (response.ok) {
            this.props.dispatch(removedUserDeviceWiseSelectedList(obj));
            let translationKey;
            if (this.state.assignMode === "user") {
              translationKey = `userIsRemoved`;
              this.props.dispatch(removeUser(obj));
            } else if (this.state.assignMode === "unit") {
              translationKey = `trackersIsDeleted`;
              this.props.dispatch(removeDevice(obj.id));
            }

            this.props.dispatch(notifySuccess(translationKey));
            this.setState({
              onDeleteConfirmation: false,
            });
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
          });
      }
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      onDeleteExpenseConfirm: false,
      onStatusAccept: false,
      onRejectAccept: false,
    });
  };

  saveToParent = (obj) => {
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: { ...this.state.maintenanceForm.attributes, ...obj },
        },
      },
      () => this.checkRequiredFields()
    );
  };

  saveToExpense = (obj) => {
    this.setState(
      {
        expenseForm: {
          ...this.state.expenseForm,
          attributes: { ...this.state.expenseForm.attributes, ...obj },
        },
      },
      () => {
        this.checkRequiredFields3();
      }
    );
  };

  fetchDueMaintenance = (id, page, pageSize) => {
    let status = "required";
    source = CancelToken.source();
    instance({
      method: "GET",
      url: `/api/services/due?page=${page}&limit=${pageSize}&search=&vehicleId=${id}&status=${status}`,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({
          showDialogBtn: true,
          vehicleDueMaintenance: response,
        });
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  getDriverByVehicleId = (id) => {
    instance({
      method: "GET",
      url: `/api/drivers/vehicleid/${id}`,
      cancelToken: source.token,
    })
      .then((response) => {
        let driver = {
          key: response.id,
          id: response.id,
          label: response.name,
          name: response.name,
          uniqueId: response.uniqueId,
          valueType: "string",
        };
        if (driver) {
          this.setState(
            {
              expenseForm: {
                ...this.state.expenseForm,
                driver: driver,
              },
            },
            () => {
              this.checkRequiredFields3();
            }
          );
        }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  showDueMaintenance = (id) => {
    this.fetchDueMaintenance(id, 1, 10);
  };

  showDueMaintenanceDialogFunc = () => {
    this.setState({ showDueDialog: true });
  };

  onCloseDueMaintenanceModel = () => {
    this.setState({ showDueDialog: false });
  };

  toggleFilterForm = () => {
    this.setState(
      {
        showStats: !this.state.showStats,
        statusFilter: [],
        countChecked: 0,
        itemsPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          hasNext: true,
        },
        vehicleId: [],
        areaId: [],
        toDate: "",
        fromDate: "",
        filterStatusType: "",
        mode: "due",
      },
      () => {
        if (!this.state.showStats) {
          this.fetchMaintenanceHistory(1, 20);
        }
      }
    );
  };

   
  showDates = (e, date) => {
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      fromDate: formatedDate.from,
      toDate: formatedDate.to,
      selectedDateTime:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };
  // showDates = (e, date) => {
  //   let timezone = "Asia/Dubai";
  //   if (
  //     this.props.ServerSettings &&
  //     this.props.ServerSettings.attributes &&
  //     this.props.ServerSettings.attributes.timezone
  //   ) {
  //     timezone = this.props.ServerSettings.attributes.timezone;
  //   }
  //   if (
  //     this.props.logInUser &&
  //     this.props.logInUser.attributes &&
  //     this.props.logInUser.attributes.timezone
  //   ) {
  //     timezone = this.props.logInUser.attributes.timezone;
  //   }
  //   moment.tz.setDefault(timezone);

  //   var formatedDate = {
  //     from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
  //     to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
  //   };

  //   let timeFormat = getDateTimeFormat();

  //   this.setState({
  //     fromDate: formatedDate.from,
  //     toDate: formatedDate.to,
  //     selectedDateTime:
  //       moment(date.startDate).format(timeFormat) +
  //       " - " +
  //       moment(date.endDate).format(timeFormat),
  //     ...formatedDate,
  //   });
  // };

  handleChangeSidebar = (name, e) => {
    if (name === "sideBarStatus") {
      this.setState({ sideBarStatus: e.target.value }, () => {
        // let filter = this.state?.filterStatusType || ''
        let filter = "";
        if (this.state.sideBarStatus) {
          filter += "&status=" + this.state.sideBarStatus;
        }
        this.setState(
          {
            filterStatusType: filter,
          },
          () => {
            this.fetchMaintenanceHistory(1, this.state.pagesizeHist);
          }
        );
      });
    } else if (e === "area") {
      this.setState({ areaId: name }, () => {
        let filter = "";
        Object.entries(this.state.areaId).map(([key, value]) => {
          if (value) {
            filter += "&areaId=" + value;
            this.setState({
              // countChecked: ++this.state.countChecked
            });
          }
        });
        this.setState(
          {
            selectedAreaId: filter,
          },
          () => {
            // this.fetchMaintenanceHistory(1, this.state.pagesizeHist)
          }
        );
      });
    } else if (e === "maintenance") {
      this.setState({
        maintenanceId: name,
      });
      this.setState({ maintenanceId: name }, () => {
        let filter = "";
        Object.entries(this.state.maintenanceId).map(([key, value]) => {
          if (value) {
            filter += "&status=" + value;
            this.setState({});
          }
        });
        this.setState(
          {
            selectedMaintenanceId: filter,
          },
          () => {
            // this.checkRequiredFields()
          }
        );
      });
    } else {
      this.setState({ vehicleId: name }, () => {
        let filter = "";
        Object.entries(this.state.vehicleId).map(([key, value]) => {
          if (value) {
            filter += "&vehicleId=" + value;
            this.setState({
              // countChecked: ++this.state.countChecked
            });
          }
        });
        this.setState(
          {
            selectedVehicleId: filter,
          },
          () => {
            // this.fetchMaintenanceHistory(1, this.state.pagesizeHist)
          }
        );
      });
    }
  };
  checkRequiredFieldsSidebar = () => {
    let { toDate, fromDate } = this.state;
    if (toDate && fromDate) this.setState({ disabledSidebarBtn: false });
    else this.setState({ disabledSidebarBtn: true });
  };

  submitSideBarSearch = (data, type) => {
    this.setState(
      {
        loadingSidebar: true,
        areaId: data.areaId,
        vehicleId: data.vehicleId,
        selectedAreaId: data.selectedAreaId,
        selectedVehicleId: data.selectedvehicleId,
        filterStatusType: data.selectedMaintenanceId,
        toDate: data.to,
        fromDate: data.from,
        showStats: true,
        createdBy: data.createdBy,
        selectedAccount: data.selectedAccount || {},
        mode: "due",
      },
      () => {
        if (type === "downloadExcel") {
          this.downloadServices(type);
        } else {
          this.fetchMaintenanceHistory2(1, this.state.pagesizeHist);
        }
      }
    );
  };

  downloadServices = (type) => {
    let accept;
    if (type === "downloadPdf") {
      accept = "application/pdf";
    } else {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let header;
    header = {
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: accept,
      }),
      method: "GET",
    };
    // api = `/api/services/export?createdby=${user}&contractNumber=${selectedAccount}&sample=false&from=${from}&to=${to}${selectedVehicleId}${filterStatusType}`;
    let user =
      (this.props.logInUser.customerType === 1 ||
        this.props.logInUser.customerType === 2) &&
      this.state.createdBy
        ? "me"
        : "all";
    // &from=${from}&to=${to}${selectedVehicleId}${filterStatusType}
    let url = `/api/services/export?createdby=${user}&sample=false&from=${
      this.state?.fromDate || ""
    }&to=${this.state?.toDate || ""}${this.state.selectedVehicleId}${
      this.state.filterStatusType
    }`;
    url = this.state.selectedAccount.contractNumber
      ? url + `&contractNumber=${this.state.selectedAccount.contractNumber}`
      : url;

    fetch(url, { ...header })
      .then((response1) => {
        if (response1.status === 200) {
          if (type === "downloadPdf") {
            response1.blob().then((response) => {
              this.setState({ loadingSidebar: false }, () => {
                this.saveData(response, "Services.pdf");
              });
            });
          } else {
            response1.blob().then((response) => {
              this.setState(
                {
                  loadingSidebar: false,
                },
                () => {
                  this.saveData(response, "Services.xlsx");
                }
              );
            });
          }
        } else {
          throw response1;
        }
      })

      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // this.setState({  : false })
      }, 0);
    }
    // this.setState({  : false })
  };
  importFunc = (res) => {
    let arr = [];
    let data =
      res &&
      res.map((item) => {
        return {
          ...item.service,
          expenseCollection: [item.serviceExpenseCollection],
        };
      });

    this.setState({
      importedData: data,
      importedReceivedData: res,
      showColorTable: true,
    });
  };

  onCloseColorModel = () => {
    this.setState({ showColorTable: false });
  };

  SubmitImport = (e) => {
    e.preventDefault();

    const objFor = this.state.importedReceivedData.map((item) => {
      // Filter out invalid serviceExpenseCollection items
      const validExpenseCollection = item.serviceExpenseCollection.filter(
        (expense) => !expense.attributes?.invalid
      );

      // Remove invalid attribute from service attributes
      delete item.service.attributes?.invalid;

      // Remove invalid attribute from remaining serviceExpenseCollection items
      validExpenseCollection.forEach((expense) => {
        delete expense.attributes?.invalid;
      });

      return {
        ...item,
        serviceExpenseCollection: validExpenseCollection,
      };
    });

    // Uncomment the following lines to make the API request
    instance({
      url: `/api/services/servicewrappers`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: objFor,
    })
      .then((res) => {
        this.props.dispatch(notifySuccess("successfullyImported"));
        this.onCloseColorModel();
        this.setState({}, () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        });
      })
      .catch((err) => {
        this.props.dispatch(notifyError("err"));
      });
  };

  handleChange = (name, value) => {
    if (name === "rejectionMessage") {
      this.setState({
        [name]: value,
      });
    } else if (name === "sendToGarage") {
      this.setState({
        [name]: value,
      });
    } else if (name === "garageVerticals") {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      if (name === "vehicleId") {
        if (value && value.id) {
          instance({
            method: "GET",
            url: `/api/vehicles/${value.id}/accumulator`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              const mileage =
                response && response.totalDistance
                  ? (response.totalDistance / 1000).toFixed(2)
                  : null;
              const hours =
                response && response.hours
                  ? (response.hours / (3600 * 1000)).toFixed(2)
                  : null;
              this.setState(
                {
                  vehicleTracker: value,
                  vehicleId: value,
                  maintenanceForm: {
                    ...this.state.maintenanceForm,
                    deviceId: 0,
                    vehicleId: value,
                    attributes: {
                      ...this.state.maintenanceForm.attributes,
                      mileage,
                      hours,
                      byMileage: mileage ? true : false,
                      byHours: hours ? true : false,
                      byDays: true,
                      avergeMileage: 100,
                    },
                  },
                },
                () => {
                  this.checkRequiredFields();
                }
              );
            })
            .catch((err) => {
              console.log("err ===", err);
            });
        }
      } else {
        let v = value;
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              [name]: v,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  // handleStatusBtns = (value) =>{
  //   if(value === 'tab1' || value === 'tab2'){
  //     let  currentTab  = value  === 'tab1'?"request" :  value === 'tab2' ? "estimation" : null
  //     this.setState({ tabBtn:value }, () => {
  //       this.props.history.push('/maintenance#' + currentTab)
  //     })
  //   }else{
  //     this.setState({statusValue:value})
  //   }
  // }

  handleStates = (value) => {
    this.setState({
      initSearch: false,
      zerovat: false,
      issueinvoice: false,
      // clearFilters: false,

    });
  };

  clearFilter = () => {
    this.setState({
      fromDate: '',
      toDate: '',
      selectedDateTime:'',
      // itemSearch:"",
      // filterStatusType: "",
    });
    
  };

  submitSearch = () => {
    this.setState({
      initSearch: true,
    });
  };

  downloadWorkOrder = (item) => {
    let pdfTitle =
      item.creationMode === "garage" ? "Estimation.pdf" : "WorkOrder.pdf";

    if (this.state.mode === "history") {
      this.downloadInvoicePdf(item);
    } else {
      const header = {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/pdf",
        }),
        method: "GET",
      };

      axios({
        method: "GET",
        url: `/api/services/workorder/export?token=${item.token}`,
        headers: {
          Accept: "application/pdf",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.data.size) {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            a.setAttribute("download", pdfTitle);
            document.body.appendChild(a);
            a.href = url;
            a.click();
          } else {
            this.props.dispatch(notifyError("somethingWentWrong"));
          }
        })
        .catch((error) => {
          this.props.dispatch(notifyError("somethingWentWrong"));
        });
    }
  };

  downloadInvoicePdf = (item) => {
    let invoice =
      this.props.ServerSetting && this.props.ServerSetting.contractType === 1
        ? "deliverynotes"
        : this.props.ServerSetting.contractType === 2
        ? "leaseinvoices"
        : "invoices";

    let fileName =
      this.props.ServerSetting && this.props.ServerSetting.contractType === 1
        ? "deliveryNote.pdf"
        : this.props.ServerSetting.contractType === 2
        ? "invoice.pdf"
        : "invoice.pdf";

    axios({
      method: "GET",
      url: `/api/${invoice}/export/${item.invoiceId}`,
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    })
      .then((response) => {
        if (response.data.size) {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          a.setAttribute("download", `${fileName}`);
          document.body.appendChild(a);
          a.href = url;
          a.click();
        } else {
          this.props.dispatch(notifyError("somethingWentWrong"));
        }
      })
      .catch((error) => {
        console.log("er =", error);
        this.props.dispatch(notifyError("somethingWentWrong"));
      });
  };

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );
    let image =
      this.state.maintenanceHistForm.files &&
      this.state.maintenanceHistForm.files.split(",");
    if (checkPrivileges("maintenance")) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            createExpense={this.createExpense}
            editHistoryFrom={this.editHistoryFrom}
            closeCreateFrom={this.closeCreateFrom}
            classFromChildren="maintenance-page"
            editMaintenanceForm={this.editMaintenanceForm}
            fetchMoreItems={this.fetchMoreItems}
            fetchMoreItemsHist={this.fetchMoreItemsHist}
            searchItems={this.searchItems}
            toggleFilterForm={this.toggleFilterForm}
            showDates={this.showDates}
            handleChangeSidebar={this.handleChangeSidebar}
            handleChangeFilter={this.handleChangeFilter}
            handleClickFilter={this.handleClickFilter}
            handleCloseFilter={this.handleCloseFilter}
            submitSideBarSearch={this.submitSideBarSearch}
            // handleStatusBtns={this.handleStatusBtns}
            {...this.state}
          >
            {/* <Button> Test</Button> */}
            <div className="main-content-page">
              <div id="HideMe" />
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeItem}
                  title={this.props.translate("areYouWantToDelete")}
                  children={
                    this.state.deleteForm.name ||
                    this.state.deleteForm.description
                  }
                />
              )}

              {this.state.onDeleteConfirm && (
                <ConfirmDialoag
                  onCancel={this.onCancelMaintenance}
                  onOk={this.onDeleteMaintenance}
                  title={this.props.translate("areYouWantToDelete")}
                  children={this.state.itemDeleteName}
                />
              )}

              {this.state.onStatusChange && (
                <ConfirmDialoag
                  onCancel={this.onCancelStatus}
                  onOk={this.onChangeStatus}
                  OkText={"Yes"}
                  CancelText={"No"}
                  title={this.props.translate("areYouWantChangeStatus")}
                  children={this.state.itemStatusChange}
                />
              )}

              {this.state.onMarkComplete && (
                <ConfirmDialoag
                  onCancel={this.onCancelMark}
                  onOk={this.onCompleteMark}
                  OkText={"Yes"}
                  CancelText={"No"}
                  title={this.props.translate(
                    "Do you want to Mark complete this maintenance"
                  )}
                  children={this.state.itemMarkComplete}
                />
              )}

              {this.state.onDeleteExpense && (
                <ConfirmDialoag
                  onCancel={this.onCancelExpense}
                  onOk={this.onDeleteExpenseItem}
                  title={this.props.translate("areYouWantToDelete")}
                  children={this.state.itemDeleteExpense}
                />
              )}

              {this.state.showColorTable && (
                <Grid container spacing={1}>
                  <CustomDialog
                    title="Imported Services"
                    themecolors={this.props.themecolors}
                    visable={true}
                    onClose={this.onCloseColorModel}
                    bodyPadding={10}
                    isVisableBtn
                    noFullScreen
                    maxWidth={500}
                    importServiceTbl
                    headerActions={
                      <Button
                        disabled={false}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={(e) => this.SubmitImport(e)}
                      >
                        {" "}
                        {this.props.translate("sharedAdd")}
                      </Button>
                    }
                  >
                    <div>
                      <Table
                        rows={this.state.importedData || []}
                        pagination={this.setDuePagination(
                          this.state.importedData || []
                        )}
                        handleChangeRowsPerPage={(n) =>
                          this.handleChangeRowsPerPage(n, "dueMaintenance")
                        }
                        handleChangePage={(n) =>
                          this.handleChangePage(n, "dueMaintenance")
                        }
                        logInUser={this.props.logInUser}
                        isEditable={false}
                        status={false}
                        completeMark={false}
                        themecolors={this.props.themecolors}
                        translate={this.props.translate}
                        hasAccessOfCreate={false}
                        hasAccessOfUpdate={false}
                        hasAccessOfDelete={false}
                        vehicles={this.props.vehicles}
                        rowDefinition={[
                          {
                            id: "name",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("service"),
                          },
                          {
                            id: "description",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("description"),
                          },
                          {
                            id: "refNum",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("refNum"),
                          },
                          {
                            id: "vehicleLicensePlate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("vehicleLicensePlate"),
                          },
                          {
                            id: "driverUniqueId",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("driverUniqueId"),
                          },
                          {
                            id: "contactNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("driverContactNo"),
                          },
                          {
                            id: "odometer",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalDistance"),
                          },
                          {
                            id: "engineHours",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("reportEngineHours"),
                          },
                          {
                            id: "serviceTime",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("serviceTime"),
                          },
                          {
                            id: "garageNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("garageNo"),
                          },
                          {
                            id: "areaId",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("areaId"),
                          },
                          {
                            id: "bookingStatus",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("bookingStatus"),
                          },
                          {
                            id: "cost",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate(
                              "sellingPriceWithoutVAT"
                            ),
                          },
                          {
                            id: "status",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("status"),
                          },
                          {
                            id: "expenseCollection",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("expenseCollection"),
                          },
                        ]}
                      />
                    </div>
                  </CustomDialog>
                </Grid>
              )}

              {this.state.enableImagePopup && (
                <CustomDialog
                  title={this.props.translate("Images")}
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.onCloseModal}
                  bodyPadding={10}
                  cancelText={this.props.translate("sharedCancel")}
                  noFullScreen
                >
                  {this.state.maintenanceHistForm &&
                  this.state.maintenanceHistForm.files ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <ImageList cols={2.5} style={{ flexWrap: "nowrap" }}>
                        {image.map(
                          (item, i) =>
                            item && (
                              <ImageListItem
                                key={item}
                                style={{ height: "auto", width: "auto" }}
                              >
                                <a href={item} download target="_blank">
                                  <Tooltip
                                    classes={{
                                      popper: "menu-popper",
                                      tooltip: "menu-popper-tooltip",
                                    }}
                                    title={this.props.translate("DOWNLOAD")}
                                  >
                                    <FileCopyIcon
                                      style={{
                                        width: 80,
                                        height: 80,
                                        color: "#ffffff",
                                      }}
                                    />
                                  </Tooltip>
                                </a>
                                <p
                                  style={{ textAlign: "center", marginTop: 0 }}
                                >
                                  File {i + 1}
                                </p>
                              </ImageListItem>
                            )
                        )}
                      </ImageList>
                    </div>
                  ) : (
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      No Image Selected
                    </h3>
                  )}
                </CustomDialog>
              )}

              {this.state.mode === "create" || this.state.mode === "update" ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                  }}
                >
                  <CreateMaintenanceForm
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    mode={this.state.mode}
                    form={this.state.maintenanceForm}
                    isVisible={this.state.isVisible}
                    servicesAttributes={expenseType}
                    subTypes={this.state.subTypes2 || []}
                    translate={this.props.translate}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    dispatch={this.props.dispatch}
                    saveToParent={this.saveToParent}
                    assignModal={this.assignModal}
                    submitForm={this.submitForm}
                    closeCreateFrom={this.closeCreateFrom}
                    handleChangeAttributes={this.handleChangeAttributes}
                    handleChange={this.handleChange}
                    onChangedDevice={this.onChangedDevice}
                    uploadImage={this.uploadImage}
                    imagePopup={this.imagePopup}
                    vehicles={vehiclesList}
                    allVehicles={vehiclesList}
                  />
                </div>
              ) : null}

              {this.state.mode === "updateHist" ? (
                <div>
                  <CreateExpense
                    searchsubTypeList={this.searchsubTypeList}
                    defaultAccountsList={this.state.defaultAccountsList}
                    // tabBtn={this.state.tabBtn}

                    handleChangeVAT={this.handleChangeVAT}
                    issueinvoice={this.state.issueinvoice}
                    zerovat={this.state.zerovat}
                    expenseForm={this.state.expenseForm}
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    isVisible={this.state.isVisible}
                    serviceExpenseCollection={
                      this.state.serviceExpenseCollection
                    }
                    mode={this.state.dueActive}
                    image64Type={this.state.image64Type}
                    dueActive={this.state.dueActive}
                    loader={this.state.loader}
                    drivers={driversList}
                    garageList={garageList}
                    statusTypes={statusTypes}
                    statusTypes1={statusTypes1}
                    notificationMode={
                      this.state.mode === "createExpense" ? "create" : "update"
                    }
                    saveToParent={this.saveToExpense}
                    dispatch={this.props.dispatch}
                    vehicles={vehiclesList}
                    subTypes={subTypes}
                    expenseType={
                      expenseType && expenseType.length
                        ? expenseType
                        : allExpenseTypes
                    }
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    logInUser={this.props.logInUser}
                    handleChangeExpense={this.handleChangeExpense}
                    onChangedDevice={this.onChangedDevice}
                    closeExpense={this.closeExpense}
                    closeCreateFrom={this.closeCreateFrom}
                    submitExpense={this.ud_expenseSubmit}
                    addMore={this.addMore}
                    uploadImage={this.uploadImage}
                    handleChangeServices={this.handleChangeServices}
                    deleteRow={this.deleteRow}
                    imagePopup={this.imagePopup}
                    uploadFile={this.uploadFile}
                    deleteImage={this.deleteImage}
                    showDueMaintenance={this.showDueMaintenance}
                    showDialogBtn={this.state.showDialogBtn}
                    vehicleDueMaintenance={this.state.vehicleDueMaintenance}
                    showDueDialog={this.state.showDueDialog}
                    showDueMaintenanceDialogFunc={
                      this.showDueMaintenanceDialogFunc
                    }
                    onCloseDueMaintenanceModel={this.onCloseDueMaintenanceModel}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    setDuePagination={this.setDuePagination}
                    historyEdit={this.state.historyEdit}
                    areaList={checkPrivileges("area") ? areaList : []}
                    tab={this.state.tab}
                    isValidVehicle={this.state.isValidVehicle}
                    onRetry={this.onRetry}
                    check
                    showRetry
                  />
                </div>
              ) : null}
              {(this.state.mode === "history" ||
                this.state.mode === "due" ||
                this.state.mode === "showMaintenance") &&
              !this.state.showStats ? (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {/* {(
                      (this.props.logInUser.customerType !== 3 ||
                  this.props.logInUser.userType !== 3 )&& 
                  this.state.tabBtn ==="tab2") &&( */}
                      <Filter
                        selectedDateTime={this.state.selectedDateTime}
                        showDates={this.showDates}
                        submitSearch={this.submitSearch}
                        clearFilter={this.clearFilter}
                        createdBy={this.state.createdBy}
                        // tabBtn={this.state.tabBtn}
                        logInUser={this.props.logInUser}
                        vehicles={this.props.vehicles}
                        themecolors={this.props.themecolors}
                        areas={this.props.areas}
                        handleChangeFilter={this.handleChangeFilter}
                        handleClickFilter={this.handleClickFilter}
                        handleCloseFilter={this.handleCloseFilter}
                        anchorEl={this.state.anchorEl}
                        translate={this.props.translate}
                        statusFilter={this.state.statusFilter}
                        searchVehicleId={this.state.searchVehicleId}
                        searchAreaId={this.state.searchAreaId}
                        SearchItem={this.SearchItem}
                        SearchVehicle={this.SearchVehicle}
                        SearchArea={this.SearchArea}
                        createExpense={this.createExpense}
                        countChecked={this.state.countChecked}
                        importFunc={this.importFunc}
                        filterStatusType={this.state.filterStatusType}
                      />
                      {/* )} */}
                    </Grid>
                    {/* {this.state.tabBtn  === "tab1" ?
                    <>
                    <Grid item sm={4} xs={12}>
                      <MaintenanceCompletedList
                      handleStates={this.handleStates}
                      zerovat={this.state.zerovat}
                      issueinvoice={this.state.issueinvoice}
                      handleChangeVAT={this.handleChangeVAT}
                        tabBtn = {this.state.tabBtn}
                        searchFilter={{
                          vehicleId: this.state.vehicleId,
                          searchAreaId: this.state.searchAreaId,
                          searchVehicleId: this.state.searchVehicleId,
                          areaId: this.state.areaId,
                          filterType: this.state.filterType,
                          itemSearch: this.state.itemSearch,
                          selectedVehicleId: this.state.selectedVehicleId,
                          selectedAreaId: this.state.selectedAreaId,
                          filterStatusType: this.state.filterStatusType,
                          fromDate: this.state.fromDate,
                          toDate: this.state.toDate,
                        }}
                        type="due"
                        ServerSetting={this.props.ServerSettings}
                        onMarkComplete={this.onSetMarkComplete}
                        logInUser={this.props.logInUser}
                        onEdit={this.editHistoryFrom}
                        onRemove={this.onRemovedExpense}
                        translate={this.props.translate}
                        onCreate={this.createExpense}
                        editHistoryFrom={this.editHistoryFrom}

                        dispatch={this.props.dispatch}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <MaintenanceCompletedList
                      handleStates={this.handleStates}

                        tabBtn = {this.state.tabBtn}
                        handleChangeVAT={this.handleChangeVAT}
                        zerovat={this.state.zerovat}
                        issueinvoice={this.state.issueinvoice}
                        searchFilter={{
                          vehicleId: this.state.vehicleId,
                          searchAreaId: this.state.searchAreaId,
                          searchVehicleId: this.state.searchVehicleId,
                          areaId: this.state.areaId,
                          filterType: this.state.filterType,
                          itemSearch: this.state.itemSearch,
                          selectedVehicleId: this.state.selectedVehicleId,
                          selectedAreaId: this.state.selectedAreaId,
                          filterStatusType: this.state.filterStatusType,
                          fromDate: this.state.fromDate,
                          toDate: this.state.toDate,
                        }}
                        type="history"
                        imagePopup={this.imagePopup}
                        ServerSetting={this.props.ServerSettings}
                        logInUser={this.props.logInUser}
                        onEdit={this.editHistoryFrom}
                        onRemove={this.onRemovedExpense}
                        translate={this.props.translate}
                        editHistoryFrom={this.editHistoryFrom}
                        serverTimeZoneName={"Asia/Dubai"}
                        dispatch={this.props.dispatch}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <MaintenanceCompletedList2
                      handleStates={this.handleStates}

                      handleChangeVAT={this.handleChangeVAT}
                      zerovat={this.state.zerovat}
                      issueinvoice={this.state.issueinvoice}
                        searchFilter={{
                          vehicleId: this.state.vehicleId,
                          searchAreaId: this.state.searchAreaId,
                          searchVehicleId: this.state.searchVehicleId,
                          areaId: this.state.areaId,
                          filterType: this.state.filterType,
                          itemSearch: this.state.itemSearch,
                          selectedVehicleId: this.state.selectedVehicleId,
                          selectedAreaId: this.state.selectedAreaId,
                          filterStatusType: this.state.filterStatusType,
                          fromDate: this.state.fromDate,
                          toDate: this.state.toDate,
                        }}
                        onCreate={this.openCreateFrom}
                        onStatusChange={this.onStatusChange}
                        logInUser={this.props.logInUser}
                        onEdit={this.editMaintenanceForm}
                        onRemove={this.onRemovedExpense}
                        onMarkComplete={this.onMarkComplete}
                        translate={this.props.translate}
                        editHistoryFrom={this.editHistoryFrom}
                        serverTimeZoneName={"Asia/Dubai"}
                        dispatch={this.props.dispatch}
                        vehicles={this.props.vehicles}
                      />
                    </Grid>
                    </>:this.state.tabBtn  === "tab2" ?
                    <> */}
                    <Grid item sm={12} xs={12}>
                      <MaintenanceCompletedList
                        itemSearch={this.state.itemSearch}
                        initSearch={this.state.initSearch}
                        clearFilters={this.state.clearFilters}
                        handleStates={this.handleStates}
                        createdBy={this.state.createdBy}
                        handleChangeVAT={this.handleChangeVAT}
                        zerovat={this.state.zerovat}
                        issueinvoice={this.state.issueinvoice}
                        tabBtn={this.state.tabBtn}
                        searchFilter={{
                          vehicleId: this.state.vehicleId,
                          searchAreaId: this.state.searchAreaId,
                          searchVehicleId: this.state.searchVehicleId,
                          areaId: this.state.areaId,
                          filterType: this.state.filterType,
                          itemSearch: this.state.itemSearch,
                          selectedVehicleId: this.state.selectedVehicleId,
                          selectedAreaId: this.state.selectedAreaId,
                          filterStatusType: this.state.filterStatusType,
                          fromDate: this.state.fromDate,
                          toDate: this.state.toDate,
                        }}
                        type="due"
                        estimation
                        ServerSetting={this.props.ServerSettings}
                        onMarkComplete={this.onSetMarkComplete}
                        logInUser={this.props.logInUser}
                        onEdit={this.editHistoryFrom}
                        onRemove={this.onRemovedExpense}
                        translate={this.props.translate}
                        onCreate={this.createExpense}
                        editHistoryFrom={this.editHistoryFrom}
                        dispatch={this.props.dispatch}
                      />
                    </Grid>
                    {/* </> : null} */}
                  </Grid>
                </>
              ) : null}

              {this.state.mode === "createExpense" ? (
                <div
                  style={{ height: "100%", position: "relative", zIndex: 9999 }}
                >
                  <CreateExpense
                    searchsubTypeList={this.searchsubTypeList}
                    //  tabBtn={this.state.tabBtn}
                    zerovat={this.state.zerovat}
                    issueinvoice={this.state.issueinvoice}
                    handleChangeVAT={this.handleChangeVAT}
                    expenseForm={this.state.expenseForm}
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    isVisible={this.state.isVisible}
                    serviceExpenseCollection={
                      this.state.serviceExpenseCollection
                    }
                    selectedVehilcesList={this.state.selectedVehilcesList}
                    // ServerSettings={this.props.ServerSettings.contractNumber}
                    defaultAccountsList={this.state.defaultAccountsList}
                    mode={this.state.dueActive}
                    image64Type={this.state.image64Type}
                    loader={this.state.loader}
                    dueActive={this.state.dueActive}
                    drivers={driversList}
                    garageList={garageList}
                    statusTypes={statusTypes}
                    statusTypes1={statusTypes1}
                    ServerSetting={this.props.ServerSetting}
                    notificationMode={
                      this.state.mode === "createExpense" ? "create" : "update"
                    }
                    saveToParent={this.saveToExpense}
                    dispatch={this.props.dispatch}
                    vehicles={vehiclesList}
                    subTypes={subTypes}
                    expenseType={
                      expenseType && expenseType.length
                        ? expenseType
                        : allExpenseTypes
                    }
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    logInUser={this.props.logInUser}
                    completeMode={this.state.completeMode}
                    handleChangeExpense={this.handleChangeExpense}
                    onChangedDevice={this.onChangedDevice}
                    closeExpense={this.closeExpense}
                    closeCreateFrom={this.closeCreateFrom}
                    submitExpense={this.submitExpense}
                    addMore={this.addMore}
                    uploadImage={this.uploadImage}
                    handleChangeServices={this.handleChangeServices}
                    deleteRow={this.deleteRow}
                    imagePopup={this.imagePopup}
                    uploadFile={this.uploadFile}
                    deleteImage={this.deleteImage}
                    showDueMaintenance={this.showDueMaintenance}
                    showDialogBtn={this.state.showDialogBtn}
                    vehicleDueMaintenance={this.state.vehicleDueMaintenance}
                    showDueDialog={this.state.showDueDialog}
                    showDueMaintenanceDialogFunc={
                      this.showDueMaintenanceDialogFunc
                    }
                    onCloseDueMaintenanceModel={this.onCloseDueMaintenanceModel}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    setDuePagination={this.setDuePagination}
                    check={false}
                    areaList={checkPrivileges("area") ? areaList : []}
                    tab={this.state.tab}
                    isValidVehicle={this.state.isValidVehicle}
                  />
                </div>
              ) : null}
              {this.state.showStats ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <MaintenanceComp
                    createdBy={this.state.createdBy}
                    selectedAccount={this.state.selectedAccount.contractNumber}
                    selectedAreaId={this.state.selectedAreaId}
                    filterStatusType={this.state.filterStatusType}
                    selectedVehicleId={this.state.selectedVehicleId}
                    from={this.state?.fromDate}
                    to={this.state?.toDate}
                    downloadWorkOrder={this.downloadWorkOrder}
                    ServerSettings={this.props.ServerSettings}
                    data={this.state.itemsPagination}
                    dueActive={this.state.dueActive}
                    isDataRecieved={this.state.isDataRecieved}
                    countChecked={this.state.countChecked}
                    statusFilter={this.state.statusFilter}
                    anchorEl={this.state.anchorEl}
                    mode={this.state.mode}
                    expenseForm={this.state.expenseForm}
                    searchVehicleId={this.state.searchVehicleId}
                    searchAreaId={this.state.searchAreaId}
                    // vehicles={vehiclesList}
                    // histFormVisible={this.state.histFormVisible}
                    translate={this.props.translate}
                    vehicles={this.props.vehicles}
                    areas={this.props.areas}
                    deviceRelatedData={this.props.deviceRelatedData}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    setPagination={this.setPaginationHistory}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    SearchItem={this.SearchItem}
                    SearchVehicle={this.SearchVehicle}
                    SearchArea={this.SearchArea}
                    openCreateFrom={this.openCreateFrom}
                    handleChangeFilter={this.handleChangeFilter}
                    handleClickFilter={this.handleClickFilter}
                    handleCloseFilter={this.handleCloseFilter}
                    imagePopup={this.imagePopup}
                    switchMode={this.switchMode}
                    onRemove={this.onRemovedExpense}
                    editHistoryFrom={this.editHistoryFrom}
                    createExpense={this.createExpense}
                  />
                </div>
              ) : null}

              {this.state.model ? (
                <Dialog
                  isVisableBtn={true}
                  headerActions={
                    <Button
                      onClick={this.submitAssignModel}
                      variant="outlined"
                      size="small"
                    >
                      {this.props.translate("assignMaintenance")}
                    </Button>
                  }
                  open={this.state.model}
                  onClose={this.closeAssignModal}
                  title={this.props.translate("assignMaintenance")}
                  disableFooter
                  cancelText={this.props.translate("sharedCancel")}
                >
                  <>
                    <div
                      style={{ padding: 10, fontWeight: 700, paddingBottom: 0 }}
                    >
                      <MaintenanceIcon
                        fill="currentColor"
                        width={20}
                        height={20}
                        style={{ verticalAlign: "middle", marginRight: 5 }}
                      />{" "}
                      {this.state.currentMaintenance &&
                        this.state.currentMaintenance.name}
                    </div>
                    {this.state.assignMode === "unit" ? (
                      <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        selectAll={(e) =>
                          selectAll(
                            e,
                            "deviceId",
                            "maintenanceId",
                            this.state.currentMaintenance.id,
                            this.props.selecteduser,
                            this.props.devices3,
                            this.props.dispatch,
                            "access",
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges("deviceDelete")}
                        canRemove={checkPrivileges("deviceLinkMaintenance")}
                        canAssign={checkPrivileges("deviceUnlinkMaintenance")}
                      />
                    ) : null}

                    {this.state.assignMode === "user" ? (
                      <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        selectAll={(e) =>
                          selectAll(
                            e,
                            "userId",
                            "maintenanceId",
                            this.state.currentMaintenance.id,
                            this.props.selecteduser,
                            this.props.users,
                            this.props.dispatch,
                            "access",
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges("userDelete")}
                        canRemove={checkPrivileges("userLinkMaintenance")}
                        canAssign={checkPrivileges("userUnlinkMaintenance")}
                        rowDefinition={[
                          {
                            id: "name",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("sharedName"),
                          },
                          {
                            id: "email",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("userEmail"),
                          },
                          {
                            id: "roleName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("roleName"),
                          },
                          {
                            id: "parentName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("parentName"),
                          },
                        ]}
                      />
                    ) : null}
                  </>
                </Dialog>
              ) : null}
              {this.state.mode === "" ? (
                <EmptyState
                  text={this.props.translate("noMaintenanceSelected")}
                />
              ) : null}
            </div>
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => ({
  maintenance: state.maintenance,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  roles: state.roles,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  drivers: state.drivers,
  vehicles: state.vehicles,
  ServerSettings: state.ServerSetting,
  areas: state.areas,
  ServerSetting: state.ServerSetting,
});
// this.state.tabBtn  === "tab2"
const MaintenanceCompletedList = React.memo(
  ({ tabBtn, estimation, type, ...props }) => {
    const [itemsPagination, setPagination] = useState({
      items: [],
      total: 0,
      // currentPage: 0,
      currentPage: 1,
      pageSize: 5,
      hasNext: true,
    });

    const [onDeleteExpense, setOnDeleteService] = useState(false);
    const [selectDeleteServiceItem, setSelectDeleteServiceItem] =
      useState(false);
    const [state, setState] = useState({
      onStatusAccept: false,
      onRejectAccept: false,
      expenseForm: "",
      rejectionMessage: "",
      sendToGarage: "",
      garageVerticals: [],
      statusMessage: "",
      statusValue: "",
    });

    const [serviceMarkComplete, setServiceMarkComplete] = useState(false);
    const [sendToGarage, setSendToGarage] = useState(false);
    const [issueInvoice, setIssueInvoice] = useState(false);

    const [showMsgLogs, setShowMsgLogs] = useState(false);
    const [servicesLogs, setServicesLogs] = useState({
      items: [],
      currentPage: 1,
      hasNext: false,
    });
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchServiceLogs = (id, page = 1, limit = 10) => {
      setLoading(true);
      const url = `/api/services/servicelogs/${id}?page=${page}&limit=${limit}`;
      instance({
        method: "GET",
        url,
        cancelToken: source.token,
      })
        .then((response) => {
          setServicesLogs((prevPagination) => ({
            ...response,
            items:
              page === 1
                ? response.data
                : [...prevPagination.items, ...response.data],
            currentPage: response.hasNext ? response.page + 1 : response.page,
          }));
          setLoading(false);
          if (page === 1) {
            setShowMsgLogs(true);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };
    const openMessageLogs = (item) => {
      if (item) {
        setSelectDeleteServiceItem(item[0]);
        fetchServiceLogs(item[0].id);
      }
    };

    const handleLoadMore = () => {
      if (servicesLogs.hasNext) {
        fetchServiceLogs(selectDeleteServiceItem.id, servicesLogs.currentPage);
      }
    };

    const openIssueInvoiceBox = (item) => {
      setIssueInvoice(true);
      setSelectDeleteServiceItem(item[0]);
    };
    const openServiceMarkCompleteBox = (item) => {
      setServiceMarkComplete(true);
      setSelectDeleteServiceItem(item[0]);
    };
    const openSendToGarageBox = (item) => {
      setSendToGarage(true);
      setSelectDeleteServiceItem(item[0]);
    };

    const handleChange = (name, value) => {
      if (name === "rejectionMessage" || name === "sendToGarage") {
        setState((prevState) => ({ ...prevState, [name]: value }));
      } else if (name === "garageVerticals") {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    };
    const updateServiceStatus = () => {
      const { expenseForm, statusValue, rejectionMessage } = state;
      let customerMsg = rejectionMessage ? rejectionMessage : "";
      let status, msg;

      if (statusValue === "sendToOp") {
        status =
          props.logInUser.customerType === 1
            ? "GarageToOp"
            : props.logInUser.customerType === 3
            ? "CustomerToOp"
            : props.logInUser.userType === 3
            ? "CustomerToOp"
            : props.logInUser.userType === 2
            ? "CustomerToOp"
            : "";

        msg =
          props.logInUser.customerType === 1
            ? "sentToOPs"
            : props.logInUser.customerType === 3
            ? "sentToOPs"
            : props.logInUser.userType === 3
            ? "sentToOPs"
            : props.logInUser.userType === 2
            ? "sentToOPs"
            : "";
      } else if (statusValue === "sendToCust") {
        status =
          props.logInUser.customerType === 1
            ? "GarageToCustomer"
            : props.logInUser.customerType === 2
            ? "OpToCustomer"
            : "";

        msg =
          props.logInUser.customerType === 1
            ? "sendToCustomer"
            : props.logInUser.customerType === 2
            ? "sendToCustomer"
            : "";
      } else if (statusValue === "sendToGarage") {
        status =
          props.logInUser.customerType === 2
            ? "OpToGarage"
            : props.logInUser.customerType === 3
            ? "CustomerToGarage"
            : props.logInUser.userType === 3
            ? "CustomerToGarage"
            : props.logInUser.userType === 2
            ? "CustomerToGarage"
            : "";

        msg =
          props.logInUser.customerType === 2
            ? "rejected"
            : props.logInUser.customerType === 3
            ? "rejected"
            : props.logInUser.userType === 3
            ? "rejected"
            : props.logInUser.userType === 2
            ? "rejected"
            : "";
      } else if (statusValue === "accept") {
        status =
          props.logInUser.customerType === 3
            ? "CustomerAccept"
            : props.logInUser.userType === 3
            ? "CustomerAccept"
            : props.logInUser.userType === 2
            ? "CustomerAccept"
            : "";

        msg =
          props.logInUser.customerType === 3
            ? "CustomerAccept"
            : props.logInUser.userType === 3
            ? "CustomerAccept"
            : props.logInUser.userType === 2
            ? "CustomerAccept"
            : "";
      }

      if (expenseForm) {
        instance({
          url: `/api/services/updatestatus/${expenseForm.id}?status=${status}&message=${customerMsg}`,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            dispatch(notifySuccess(msg));

            fetchMaintenanceHistory();
          })
          .catch((error) => {
            errorHandler(error, dispatch);
          });

        setState({
          onStatusAccept: false,
          onRejectAccept: false,
          expenseForm: "",
          rejectionMessage: "",
          sendToGarage: "",
          garageVerticals: [],
          statusMessage: "",
        });
      }
    };

    const openStatusFrom = (items, status) => {
      let item = items[0];
      let msg = "";
      if (item && status) {
        if (
          status === "sendToOp" &&
          (item.status === "GarageInitiate" ||
            item.status === "OpReject" ||
            item.status === "OpToGarage" ||
            item.status === "CustomerToGarage" ||
            item.status === "GarageToCustomer" ||
            item.status === "OpToCustomer")
        ) {
          msg = "Send to Operation";
        } else if (
          status === "sendToCust" &&
          (item.status === "GarageInitiate" ||
            item.status === "OpReject" ||
            item.status === "OpToGarage" ||
            item.status === "CustomerToGarage" ||
            item.status === "CustomerReject" ||
            item.status === "GarageToOp" ||
            item.status === "CustomerToOp" ||
            item.status === "WaitingForOpApproval")
        ) {
          msg = "Send to Customer";
        }

        // else if(status === 'sendToCust' && (item.status === 'WaitingForOpApproval' || item.status === 'CustomerReject'  )){
        //   msg = 'Send to Customer';
        // }
        else if (
          status === "sendToGarage" &&
          (item.status === "CustomerReject" ||
            item.status === "GarageToOp" ||
            item.status === "CustomerToOp" ||
            item.status === "WaitingForOpApproval" ||
            item.status === "GarageToCustomer" ||
            item.status === "OpToCustomer" ||
            item.status === "WaitingForCustomerApproval")
        ) {
          msg = "Send to Garage";
        }
        // else if(status === 'sendToGarage' && (item.status === 'WaitingForCustomerApproval')){
        //   msg = 'Send to Garage for Approval';
        // }
        // else if(status === 'sendToOp' && (item.status === 'WaitingForCustomerApproval')){
        //   msg = 'Send to Operation for Approval';
        // }
        else if (status === "accept") {
          msg = "Approved";
        }

        //  else if (status === 'accept' && (item.status === 'WaitingForOpApproval' || item.status === 'CustomerReject')) {
        //   msg = 'Send to Customer for Approval';
        // } else if (status === 'reject' && (item.status === 'WaitingForOpApproval' || item.status === 'CustomerReject')) {
        //   msg = 'Send Back to Garage';
        // } else if (status === 'accept' && item.status === 'WaitingForCustomerApproval') {
        //   msg = 'Approved';
        // } else if (status === 'reject' && item.status === 'WaitingForCustomerApproval') {
        //   msg = 'Send Back to Operation';
        // }
      }

      setState({
        onStatusAccept:
          status === "sendToOp" ||
          status === "sendToCust" ||
          status === "sendToGarage" ||
          status === "accept",
        onRejectAccept: status === "reject",
        expenseForm: item,
        statusValue: status,
        statusMessage: msg,
      });
    };

    const openDeleteBox = (item) => {
      setOnDeleteService(true);
      setSelectDeleteServiceItem(item[0]);
    };

    const onSendToGarage = (item) => {
      instance({
        url: `/api/services/sendtogarage/${selectDeleteServiceItem.id}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          props.dispatch(notifySuccess("sendToGarageSuccessfull"));
          fetchMaintenanceHistory(true);
          setOnDeleteService(false);
          setSendToGarage(false)
        })
        .catch((error) => {
          setSendToGarage(false)

          errorHandler(error, props.dispatch);
        });
    };
    const onDeleteServices = (item) => {
      instance({
        url: `/api/services/${selectDeleteServiceItem.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          props.dispatch(notifySuccess("serviceDeleted"));
          fetchMaintenanceHistory(true);
          setOnDeleteService(false);
        })
        .catch((error) => {
          errorHandler(error, props.dispatch);
        });
    };
    const onMarkCompleteServices = (item) => {
      let issueInvoice = props.issueinvoice ? true : false;
      let applyZeroVat = props.zerovat ? true : false;
      instance({
        url: `/api/services/markascomplete/${selectDeleteServiceItem.id}?issueinvoice=${issueInvoice}&zerovat=${applyZeroVat}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          props.dispatch(notifySuccess("serviceMarkedComplete"));
          fetchMaintenanceHistory(true);
          setServiceMarkComplete(false);
        })
        .catch((error) => {
          errorHandler(error, props.dispatch);
        });
    };

    const onIssueInvoice = (item) => {
      let applyZeroVat = props.zerovat ? true : false;
      instance({
        url: `/api/services/issueinvoice/${selectDeleteServiceItem.id}?zerovat=${applyZeroVat}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          props.dispatch(notifySuccess("invoiceIssuedSuccessfully"));
          fetchMaintenanceHistory(true);
          props.handleStates();
          setIssueInvoice(false);
        })
        .catch((error) => {
          errorHandler(error, props.dispatch);
        });
    };

    const fetchMaintenanceHistory = (reset = false, pagePos, flag) => {
      setLoading(true);
      let user = flag
        ? "me"
        : (props.logInUser.customerType === 1 ||
            props.logInUser.customerType === 2) &&
          props.createdBy
        ? "me"
        : "all";
      const { searchFilter } = props;
      let filterStatus = searchFilter.filterStatusType;
  
      let page =
        pagePos === "ist"
          ? 1
          : pagePos === "last"
          ? Math.ceil(itemsPagination.total / itemsPagination.pageSize)
          : pagePos === "next"
          ? itemsPagination.currentPage + 1
          : pagePos === "prev"
          ? itemsPagination.currentPage - 1
          : itemsPagination.currentPage || 1;
      let url =`/api/services/get?createdby=${user}&page=${
                reset ? 1 : page
              }&limit=10&search=${searchFilter.itemSearch}${filterStatus}`;

      if(searchFilter?.toDate){
        url = url +   `&from=${searchFilter?.fromDate}&to=${searchFilter?.toDate}`
      }

      source = CancelToken.source();

      instance({
        method: "GET",
        url,
        cancelToken: source.token,
      })
        .then((response) => {
          setLoading(false);
          props.handleStates();
          setPagination({
            // items: reset ? response.data : [...prevPagination.items, ...response.data],
            items: response.data,
            total: response.total,
            currentPage: response.page,
            pageSize: response.pageSize,
            hasNext: response.hasNext,
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            setPagination({
              ...itemsPagination,
              items: [],
              currentPage: 1,
            });
          }
        });
    };

    const scrollPositionRef = useRef(0);

    useEffect(() => {
      if (props.initSearch) {
        fetchMaintenanceHistory(true);
        scrollPositionRef.current = 0;
      }
    }, [props.initSearch, props.clearFilters]);

    useEffect(() => {
      let flag =
        props.logInUser.customerType === 1 || props.logInUser.customerType == 2
          ? true
          : false;
      if (props.logInUser?.id) {
        fetchMaintenanceHistory(true, "", flag);
      }
    }, [props.logInUser, estimation]);

    let serverTimeZoneName =
      props?.logInUser?.attributes?.timezone || "Asia/Riyadh";
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone;
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone;
    }

    return (
      <>
        {(state.onRejectAccept || state.onStatusAccept) && (
          <Dialog
            onClose={() =>
              setState({
                ...state,
                onRejectAccept: false,
                onStatusAccept: false,
              })
            }
            onOk={updateServiceStatus}
            title={props.translate("Change Status")}
            maxWidth={"sm"}
            headerActions={
              <Button onClick={updateServiceStatus}>
                {props.translate("submit")}
              </Button>
            }
            isVisableBtn
            cancelText={props.translate("sharedCancel")}
            children={
              <form autoComplete="off" style={{ padding: "10px" }}>
                <Grid container style={{ padding: "5px" }}>
                  <Grid item md={12}>
                    <h2>{props.translate(state.statusMessage) || ""}</h2>
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="message"
                      multiline
                      label={props.translate("message")}
                      placeholder=""
                      value={state.rejectionMessage || ""}
                      onChange={(e) =>
                        handleChange("rejectionMessage", e.target.value)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </form>
            }
          />
        )}
        {/* {loading ? <Loader/> :<> */}
        {showMsgLogs && (
          <Dialog
            onClose={() => setShowMsgLogs(false)}
            onOk={() => setShowMsgLogs(false)}
            title={`${props.translate("Services Logs : ")} ${
              selectDeleteServiceItem.name
            }`}
            //  title={props.translate('Change Status')}
            maxWidth={"md"}
            disableFooter
            cancelText={props.translate("sharedCancel")}
            headerActions={
              <Button onClick={() => setShowMsgLogs(false)}>
                {props.translate("Ok")}
              </Button>
            }
            isVisableBtn
            children={
              <div style={{ margin: "10px 10px 20px 10px" }}>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  style={{
                    // height: "calc(100vh - 120px - 68px)",
                    height: "300px",
                  }}
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id={"scrollableDeviceSelector" + type}
                        />
                      );
                    },
                  }}
                >
                  <InfiniteScroll
                    dataLength={servicesLogs?.items?.length || 0}
                    next={() => handleLoadMore()}
                    hasMore={servicesLogs.hasNext}
                    loader={<Loader defaultStyle={true} />}
                    scrollableTarget={"scrollableDeviceSelector" + type}
                    endMessage={null}
                  >
                    {servicesLogs?.items?.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          fontFamily: "Roboto",
                          margin: "10px 5px",
                        }}
                      >
                        {/* div 1 */}
                        <div
                          style={{
                            borderRadius: "50%",
                            // backgroundColor:item.customerType === 1 ? "#E33C30" : item.customerType === 2 ? "#1879EB" : "#28A745",
                            backgroundColor:
                              item.action === "GarageInitiate"
                                ? "#FFC107"
                                : item.action === "WaitingForOpApproval" ||
                                  item.action ===
                                    "WaitingForCustomerApproval" ||
                                  item.action === "GarageToOp" ||
                                  item.action === "OpToCustomer" ||
                                  item.action === "GarageToCustomer"
                                ? "#1879EB"
                                : item.action === "required" ||
                                  item.action === "completed" ||
                                  item.action === "scheduled" ||
                                  item.action === "CustomerAccept"
                                ? "#28A745"
                                : "#E33C30",
                            color: "#fff",
                            width: 48,
                            height: 48,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 21,
                            fontWeight: 800,
                          }}
                        >
                          {item.customerType === 1
                            ? "G"
                            : item.customerType === 2
                            ? "O"
                            : "C"}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            margin: "0px 10px",
                            borderBottom: "1px solid grey",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color:
                                  item.action === "GarageInitiate"
                                    ? "#FFC107"
                                    : item.action === "WaitingForOpApproval" ||
                                      item.action ===
                                        "WaitingForCustomerApproval" ||
                                      item.action === "GarageToOp" ||
                                      item.action === "OpToCustomer" ||
                                      item.action === "GarageToCustomer"
                                    ? "#1879EB"
                                    : item.action === "required" ||
                                      item.action === "completed" ||
                                      item.action === "scheduled" ||
                                      item.action === "CustomerAccept"
                                    ? "#28A745"
                                    : "#E33C30",
                              }}
                            >
                              {props.translate(
                                item.action === "expired"
                                  ? "overdue"
                                  : item.action === "GarageToOp"
                                  ? "WaitingForOpApproval"
                                  : item.action === "GarageToCustomer" ||
                                    item.action === "OpToCustomer"
                                  ? "WaitingForCustomerApproval"
                                  : item.action === "OpToGarage"
                                  ? "RejectedByOP"
                                  : item.action === "CustomerToOp" ||
                                    item.action === "CustomerToGarage"
                                  ? "RejectedByCustomer"
                                  : item.action
                              ) || ""}
                            </p>
                            <p
                              style={{
                                marginTop: "5px",
                                maxWidth: "700px",
                                fontSize: 14,
                              }}
                            >
                              <strong>{props.translate("message")} : </strong>{" "}
                              {item.message || ""}
                            </p>
                          </div>

                          {/* div 2 */}
                          <div style={{ fontStyle: "italic", fontSize: 14 }}>
                            <p>
                              {" "}
                              {moment(item.actionTime)
                                .tz(serverTimeZoneName)
                                .format("dddd, MMMM D, YYYY")}
                            </p>
                            <p>
                              {" "}
                              {moment(item.actionTime)
                                .tz(serverTimeZoneName)
                                .format("HH:mm:ss")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    {servicesLogs.hasNext && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={() => handleLoadMore()}
                          style={{
                            width: "120px",
                            height: "32px",
                            //  color:"black" ,backgroundColor:"white",border:"black",

                            color: "black",
                            backgroundColor: "white",
                            border: "2px solid #6FBD43",
                            borderRadius: "8px",
                          }}
                        >
                          {props.translate("Load More")}
                        </Button>
                      </div>
                    )}
                  </InfiniteScroll>
                </Scrollbar>
              </div>
            }
          />
        )}
        {/* </>} */}
        <h2 className={"alt-title"}>
          {sendToGarage && (
            <ConfirmDialoag
              onCancel={() => setSendToGarage(false)}
              onOk={onSendToGarage}
              title={props.translate("Confirm! Send to Garage")}
              // children={itemDeleteExpense}
            />
          )}
          {onDeleteExpense && (
            <ConfirmDialoag
              onCancel={() => setOnDeleteService(false)}
              onOk={onDeleteServices}
              title={props.translate("areYouWantToDelete")}
              // children={itemDeleteExpense}
            />
          )}
          {serviceMarkComplete && (
            <ConfirmDialoag
              onCancel={() => setServiceMarkComplete(false)}
              onOk={onMarkCompleteServices}
              title={props.translate("Mark As Complete Service ?")}
              children={<h3>{selectDeleteServiceItem.name}</h3>}
            />
          )}

          {issueInvoice && (
            <ConfirmDialoag
              onCancel={() => setIssueInvoice(false)}
              onOk={onIssueInvoice}
              title={props.translate("issueInvoice")}
              children={
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <h3>{selectDeleteServiceItem.name}</h3>
                  </Grid>

                  {/* {checkPrivileges("serviceMarkAsComplete") && checkPrivileges("serviceIssueDeliveryNote") &&(
                        <>
                        <Grid item md={6}>
                          <Checkbox
                              checked={props.issueinvoice && props.issueinvoice}
                              onChange={e => props.handleChangeVAT("issueinvoice", e.target.checked)}
                              color='primary'
                              label={props.translate('issueinvoice')}
                            />
                        </Grid> */}

                  {checkPrivileges("serviceZeroVAT") && (
                    <Grid item md={12}>
                      <Checkbox
                        checked={props.zerovat && props.zerovat}
                        onChange={(e) =>
                          props.handleChangeVAT("zerovat", e.target.checked)
                        }
                        color="primary"
                        label={props.translate("zerovat")}
                      />
                    </Grid>
                  )}
                  {/* </>
                      )} */}
                </Grid>
              }
            />
          )}
          {estimation ? (
            <>
              {" "}
              <>
                <span className="text-warning">
                  <Translate
                    i18nKey={
                      props.logInUser?.customerType === 1 ||
                      props.logInUser?.customerType === 2
                        ? "estimation"
                        : "workOrder"
                    }
                  />
                </span>
                {/* {((props.logInUser.customerType === 1  && checkPrivileges("serviceGarageInitiate") )|| (props.logInUser.customerType !== 1  && checkPrivileges("serviceCreate"))  )&& ( */}
                {checkPrivileges("serviceCreate") && (
                  <Button onClick={props.onCreate} style={{ marginLeft: 10 }}>
                    <PlusIcon style={{ width: 26 }} />
                  </Button>
                )}
              </>
            </>
          ) : (
            <>
              {type === "due" ? (
                <>
                  <span className="text-warning">
                    <Translate i18nKey={"maintenanceRequest"} />
                  </span>
                  {checkPrivileges("serviceCreate") && (
                    <Button onClick={props.onCreate} style={{ marginLeft: 10 }}>
                      <PlusIcon style={{ width: 26 }} />
                    </Button>
                  )}
                </>
              ) : (
                <span className="text-success">
                  <Translate i18nKey={"maintenanceHistory"} />
                </span>
              )}
            </>
          )}
        </h2>
        {loading ? (
          <Loader />
        ) : (
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              // height: "calc(100vh - 120px - 68px)",
              height: "100%",
              minHeight: "530px",
            }}
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id={"scrollableDeviceSelector" + type}
                  />
                );
              },
            }}
          >
            {itemsPagination.total ? (
              <InfiniteScroll
                dataLength={itemsPagination.items.length}
                // next={() => fetchMaintenanceHistory()}
                // hasMore={itemsPagination.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget={"scrollableDeviceSelector" + type}
                endMessage={null}
                // onScroll={handleScroll}
              >
                {itemsPagination.items.map((row, index) => (
                  <MaintenanceItem
                    imagePopup={props.imagePopup}
                    ServerSetting={props.ServerSetting}
                    type={type}
                    openMessageLogs={openMessageLogs}
                    // tabBtn={props.tabBtn}
                    openServiceMarkCompleteBox={openServiceMarkCompleteBox}
                    openSendToGarageBox={openSendToGarageBox}
                    index={index}
                    estimation={estimation}
                    onMarkComplete={props.onMarkComplete}
                    onEdit={props.onEdit}
                    onRemove={openDeleteBox}
                    data={row}
                    // serverTimeZoneName
                    serverTimeZoneName={serverTimeZoneName}
                    openStatusFrom={openStatusFrom}
                    translate={props.translate}
                    logInUser={props.logInUser}
                    editHistoryFrom={props.editHistoryFrom}
                    openIssueInvoiceBox={openIssueInvoiceBox}
                    serverTimeZone={props?.logInUser?.attributes?.timezone}
                  />
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => fetchMaintenanceHistory(false, "ist")}
                    style={{
                      color:
                        itemsPagination.currentPage === 1 ? "grey" : "black",
                      backgroundColor: "white",
                    }}
                    startIcon={<FirstPageIcon />}
                    disabled={itemsPagination.currentPage === 1}
                  >
                    {props.translate("gotoPage1")}
                  </Button>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        marginRight: "10px",
                        color: "black",
                      }}
                    >
                      {`Total Records  ${itemsPagination.total}`}
                    </p>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => fetchMaintenanceHistory(false, "prev")}
                      style={{
                        marginLeft: "10px",
                        // color:"black",
                        backgroundColor: "white",
                        // border: "1px solid black"  ,
                        color:
                          itemsPagination.currentPage === 1 ? "grey" : "black",
                        border:
                          itemsPagination.currentPage === 1
                            ? "1px solid grey"
                            : "1px solid black",
                      }}
                      disabled={itemsPagination.currentPage === 1}
                    >
                      <KeyboardBackspaceIcon />
                    </Button>

                    <Button
                      onClick={() => fetchMaintenanceHistory(false, "next")}
                      style={{
                        marginLeft: "10px",
                        height: "35px",
                      }}
                      endIcon={<ArrowRightAltIcon />}
                      disabled={itemsPagination.hasNext === false}
                    >
                      {props.translate("next")}
                    </Button>

                    <p
                      style={{
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {`Page  ${itemsPagination.currentPage}  of ${Math.ceil(
                        itemsPagination.total / itemsPagination.pageSize
                      )}`}
                    </p>
                  </div>

                  <Button
                    onClick={() => fetchMaintenanceHistory(false, "last")}
                    style={{
                      marginLeft: "10px",
                      color:
                        itemsPagination.hasNext === false ? "grey" : "black",
                      backgroundColor: "white",
                      border: "none",
                    }}
                    endIcon={<LastPageIcon />}
                    disabled={itemsPagination.hasNext === false}
                  >
                    {props.translate("gotoLastPage")}
                  </Button>
                </div>
              </InfiniteScroll>
            ) : (
              <MaintenanceItemEmpty />
            )}
          </Scrollbar>
        )}
        {/* } */}
      </>
    );
  }
);

// const MaintenanceCompletedList2 = React.memo((props) => {
//   const [itemsPagination, setPagination] = useState({
//     items: [],
//     total: 0,
//     currentPage: 0,
//     perPage: 10,
//     hasNext: true,
//   });
//   const [onDeleteExpense, setOnDeleteExpense] = useState(false);
//   const [selectDeleteItem, setSelectDeleteItem] = useState(false);

//   const fetchMaintenanceHistory = (reset) => {
//     const { searchFilter } = props;
//     let searchAreaid =
//       searchFilter.searchAreaId && searchFilter.searchAreaId.id
//         ? `&areaId=${searchFilter.searchAreaId.id}`
//         : "";
//     let searchVehicleid =
//       searchFilter.searchVehicleId && searchFilter.searchVehicleId.id
//         ? `&vehicleId=${searchFilter.searchVehicleId.id}`
//         : "";

//     let url =
//       (searchFilter.vehicleId && searchFilter.vehicleId.length) ||
//       (searchFilter.areaId && searchFilter.areaId.length)
//         ? `/api/maintenance/get?userId=${props.logInUser.id}&page=${
//             reset ? 1 : itemsPagination.currentPage
//           }&limit=10&search=${searchFilter.itemSearch}${
//             searchFilter.selectedVehicleId
//           }${searchFilter.selectedAreaId}${
//             searchFilter.filterStatusType
//           }&from=${searchFilter?.fromDate || ""}&to=${
//             searchFilter?.toDate || ""
//           }`
//         : (searchFilter.searchVehicleId && searchFilter.searchVehicleId.id) ||
//           (searchFilter.searchAreaId && searchFilter.searchAreaId.id)
//         ? `/api/maintenance/get?userId=${props.logInUser.id}&page=${
//             reset ? 1 : itemsPagination.currentPage
//           }&limit=10&search=${
//             searchFilter.itemSearch
//           }${searchVehicleid}${searchAreaid}${searchFilter.filterStatusType} `
//         : `/api/maintenance/get?userId=${props.logInUser.id}&page=${
//             reset ? 1 : itemsPagination.currentPage
//           }&limit=10&search=${searchFilter.itemSearch}${
//             searchFilter.filterStatusType
//           }`;

//     source = CancelToken.source();
//     let items = reset ? [] : itemsPagination.items;
//     instance({
//       method: "GET",
//       url,
//       cancelToken: source.token,
//     })
//       .then((response) => {
//         setPagination({
//           ...response,
//           items: items.concat(response && response.data),
//           currentPage: response.hasNext ? response.page + 1 : response.page,
//         });
//         //   }
//         // }
//       })
//       .catch((error) => {
//         // errorHandler(error,this.props.dispatch)
//       });
//   };

//   const openDeleteBox = (item) => {
//     setOnDeleteExpense(true);
//     setSelectDeleteItem(item[0]);
//   };

//   const onDeleteExpenseItem = (item) => {
//     instance({
//       url: `/api/maintenance/${selectDeleteItem.id}`,
//       method: "DELETE",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => {
//         props.dispatch(notifySuccess("expenseIsDeleted"));
//         fetchMaintenanceHistory(true);
//         setOnDeleteExpense(false);
//       })
//       .catch((error) => {
//         errorHandler(error, props.dispatch);
//       });
//   };

//   useEffect(() => {
//     if (props.logInUser?.id) {
//       fetchMaintenanceHistory(true);
//     }
//   }, [props.logInUser, props.searchFilter.itemSearch]);

//   return (
//     <>
//       <h2 className="text-mute alt-title">
//         <Translate i18nKey={"predictiveMaintenance"} />
//         {onDeleteExpense && (
//           <ConfirmDialoag
//             onCancel={() => setOnDeleteExpense(false)}
//             onOk={onDeleteExpenseItem}
//             title={props.translate("areYouWantToDelete")}
//             // children={itemDeleteExpense}
//           />
//         )}

//         {checkPrivileges("serviceCreate") && (
//           <Button onClick={props.onCreate} style={{ marginLeft: 10 }}>
//             <PlusIcon style={{ width: 26 }} />
//           </Button>
//         )}
//       </h2>
//       <Scrollbar
//         disableTracksWidthCompensation={true}
//         style={{
//           height: "calc(100vh - 120px - 68px)",
//         }}
//         scrollerProps={{
//           renderer: (props) => {
//             const { elementRef, ...restProps } = props;
//             return (
//               <div
//                 {...restProps}
//                 ref={elementRef}
//                 id="scrollableDeviceSelector"
//               />
//             );
//           },
//         }}
//       >
//         {itemsPagination.total ? (
//           <InfiniteScroll
//             dataLength={itemsPagination.items.length}
//             next={() => fetchMaintenanceHistory()}
//             hasMore={itemsPagination.hasNext}
//             loader={<Loader defaultStyle={true} />}
//             scrollableTarget="scrollableDeviceSelector"
//             endMessage={null}
//           >
//             {itemsPagination.items.map((r) => (
//               <RemiderItem
//                 onStatusChange={props.onStatusChange}
//                 logInUser={props.logInUser}
//                 translate={props.translate}
//                 onRemove={openDeleteBox}
//                 onEdit={props.onEdit}
//                 onMarkComplete={props.onMarkComplete}
//                 data={r}
//                 key={r.id}
//                 serverTimeZoneName={props.serverTimeZoneName}
//                 vehicles={props.vehicles}
//               />
//             ))}
//           </InfiniteScroll>
//         ) : (
//           <RemiderItemEmpty />
//         )}
//       </Scrollbar>
//     </>
//   );
// });

const MaintenanceItem = React.memo(
  ({
    type,
    ServerSetting,
    imagePopup,
    translate,
    data,
    onRemove,
    onEdit,
    onMarkComplete,
    logInUser,
    index,
    editHistoryFrom,
    serverTimeZone,
    openStatusFrom,
    estimation,
    tabBtn,
    openServiceMarkCompleteBox,
    openSendToGarageBox,
    openMessageLogs,
    openIssueInvoiceBox,
    serverTimeZoneName,
  }) => {
    const statusObj = {
      required: "due",
      expired: "overdue",
      completed: "completed",
      schedule: "scheduled",
    };
    const [collapsed, setCollapsed] = useState(index === 0 ? true : false);
    const dispatch = useDispatch();

    const downloadWorkOrder = (item) => {
      let pdfTitle =
        item.creationMode === "garage" ? "Estimation.pdf" : "WorkOrder.pdf";

      if (type === "history") {
        downloadInvoicePdf();
      } else {
        const header = {
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/pdf",
          }),
          method: "GET",
        };

        axios({
          method: "GET",
          url: `/api/services/workorder/export?token=${data.token}`,
          headers: {
            Accept: "application/pdf",
          },
          responseType: "blob",
        })
          .then((response) => {
            if (response.data.size) {
              const a = document.createElement("a");
              const url = window.URL.createObjectURL(new Blob([response.data]));
              a.setAttribute("download", pdfTitle);
              document.body.appendChild(a);
              a.href = url;
              a.click();
            } else {
              dispatch(notifyError("somethingWentWrong"));
            }
          })
          .catch((error) => {
            dispatch(notifyError("somethingWentWrong"));
          });
      }
    };

    const downloadInvoicePdf = () => {
      let invoice =
        ServerSetting && ServerSetting.contractType === 1
          ? "deliverynotes"
          : ServerSetting.contractType === 2
          ? "leaseinvoices"
          : "invoices";

      let fileName =
        ServerSetting && ServerSetting.contractType === 1
          ? "deliveryNote.pdf"
          : ServerSetting.contractType === 2
          ? "invoice.pdf"
          : "invoice.pdf";

      axios({
        method: "GET",
        url: `/api/${invoice}/export/${data.invoiceId}`,
        headers: {
          Accept: "application/pdf",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.data.size) {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            a.setAttribute("download", `${fileName}`);
            document.body.appendChild(a);
            a.href = url;
            a.click();
          } else {
            dispatch(notifyError("somethingWentWrong"));
          }
        })
        .catch((error) => {
          console.log("er =", error);
          dispatch(notifyError("somethingWentWrong"));
        });
    };

    const viewInvoice = (item) => {
      let leasing = ServerSetting.contractType === 1 ? false : true;
      let timezone = serverTimeZone || "Asia/Riyadh";
      let invoiceType =
        ServerSetting.contractType === 1 ? "delivery-detail" : "invoiceDetail";
      // this.props?.ServerSetting?.contractType === 1
      if (item.creationMode === "garage") {
        window.open(`estimation.html?token=${data.token}`, "_blank");
      } else {
        if (type === "history") {
          window.open(
            `${invoiceType}?&token=${data.invoiceId}&leasing=${leasing}&timezone=${timezone}`,
            "_blank"
          ); //invoiceDetail
        } else {
          window.open(`workOrder.html?token=${data.token}`, "_blank");
        }
      }
    };

    const requestHistory = type === "due" ? true : false;
    return (
      <>
        {!estimation ? (
          <div className={"mainten-section mainten-" + statusObj[data.status]}>
            <div className="mainten-section-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1E72B8",
                    borderRadius: "50%",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          width: "200px",
                          height: "150px",
                          padding: "5px 0px",
                        }}
                      >
                        {data.creationMode === "user"
                          ? translate("createdbyUser")
                          : data.creationMode === "garage"
                          ? translate("createdbyGarage")
                          : ""}
                      </span>
                    }
                  >
                    {data.creationMode === "user" ? (
                      <GarageIcon width={18} height={18} />
                    ) : data.creationMode === "garage" ? (
                      <CustomerIcon width={18} height={18} />
                    ) : (
                      <CustomerIcon width={18} height={18} />
                    )}
                  </HtmlTooltip>
                </div>
                <span style={{ marginLeft: "10px" }}>
                  <label>{translate("workOrderID")}</label>
                  <br />
                  <strong>{data.id}</strong>
                </span>
              </div>
              {/* <span>
            <label>{translate("createdBy")}</label>
            <br />
            <strong>{data.creationMode === "user" ? "User" : data.creationMode === "garage" ? "Garage" : "User" } </strong>
          </span> */}
              <span>
                {translate("sellingPriceWithoutVAT")}{" "}
                <span className="reminder-cost">
                  {data.cost}{" "}
                  <small style={{ fontWeight: "normal" }}>
                    {logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.currencyLabel}
                  </small>
                </span>
                <a
                  className={"toggle-section-button"}
                  href={null}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </a>
              </span>
            </div>
            <div className="mainten-section-body">
              {collapsed ? (
                <>
                  <div className="mainten-split-section">
                    <div>
                      {translate("serviceMaintenance")} <br />
                      <strong>{data.name}</strong>
                    </div>
                    <div>
                      {translate("date")}{" "}
                      <strong>
                        <em>{moment(data.serviceTime).format("DD-MM-YYYY")}</em>
                      </strong>{" "}
                      <br />
                      {translate("status")}{" "}
                      <strong className={"text-" + statusObj[data.status]}>
                        {statusObj[data.status]}
                      </strong>
                    </div>
                  </div>
                  <div className="mainten-highlight-section">
                    <div>
                      <span>{translate("totalDistance")}</span>
                      <br />
                      <strong style={{ color: "#28A745" }}>
                        {/* {data.odometer
                      ? setAttributeFormat("mileage", data.odometer)
                      : 0} */}
                        {data.odometer?.toFixed(2)}
                      </strong>
                    </div>
                    <div>
                      <span>{translate("reportEngineHours")}</span>
                      <br />
                      <strong>
                        {data && data.engineHours
                          ? data.engineHours / (1000 * 3600)
                          : 0}
                      </strong>
                    </div>
                  </div>
                  <div
                    className="mainten-column-section"
                    style={{ marginTop: "2px" }}
                  >
                    <div>
                      <strong>{translate("sharedVehicle")}</strong>
                      <br />
                      {data.vehicleLicensePlate}
                    </div>
                    <div>
                      <span>{translate("bookingStatus")}</span>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {translate("Dex")}{" "}
                          {data.bookingMessegeDex && (
                            <strong>{data.bookingMessegeDex}</strong>
                          )}
                        </span>

                        <span>
                          {translate("Mac")}{" "}
                          {data.bookingMessegeMac && (
                            <strong>{data.bookingMessegeMac}</strong>
                          )}
                        </span>
                      </div>
                      {/* <div style={{ marginTop: 10}}>
                  <Button
                    style={{ width: "85%"}}
                      className="theme-red"
                      onClick={() => openStatusFrom([data],"accept")}
                    >
                      {data.status}
                    </Button>
                    </div> */}
                      {/* <br /> */}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="mainten-column-section"
                  style={{ margin: 0, padding: 0 }}
                >
                  <div>
                    {translate("sharedVehicle")}{" "}
                    <strong>{data.vehicleLicensePlate}</strong> <br />
                    {translate("totalDistance")}{" "}
                    <strong style={{ color: "#28A745" }}>
                      {/* {data.odometer
                    ? setAttributeFormat("mileage", data.odometer)
                    : 0} */}
                      {data.odometer?.toFixed(2)}
                    </strong>
                  </div>
                  <div>
                    {translate("date")}{" "}
                    <strong>
                      <em>{moment(data.createdTime).format("DD-MM-YYYY")}</em>
                    </strong>{" "}
                    <br />
                    {translate("status")}{" "}
                    <strong className={"text-" + statusObj[data.status]}>
                      {statusObj[data.status]}
                    </strong>
                  </div>
                </div>
              )}
            </div>
            {collapsed ? (
              <div className="mainten-section-footer">
                <div>
                  {type === "history" ? (
                    <>
                      {/* {checkPrivileges("SendToGarage") && ( */}

                      {checkPrivileges("serviceIssueDeliveryNote") &&
                        (data.issueInvoice === undefined ||
                          data.issueInvoice === false) && (
                          <Button
                            className="theme-green"
                            onClick={() => openIssueInvoiceBox([data])}
                          >
                            <Tooltip title={translate("issueInvoice")}>
                              <IssueInvoiceIcon width={18} height={18} />
                            </Tooltip>
                          </Button>
                        )}

                      {checkPrivileges("serviceSendToGarage") &&
                        (data.status === "required" ||
                          data.status === "completed") &&
                        !data.bookingMessegeDex &&
                        !data.bookingMessegeMac && (
                          <Button
                            className="theme-green"
                            onClick={() => openSendToGarageBox([data])}
                          >
                            <Tooltip title={translate("sendToGarage")}>
                              <SendIcon width={18} height={18} />
                            </Tooltip>
                          </Button>
                        )}
                      {/* <Button
                    className="theme-green"
                    onClick={() => imagePopup(data.files)}
                  >
                    <Tooltip title={translate("Images")}>
                      <PhotoLibraryIcon fontSize="small" />
                    </Tooltip>
                  </Button> */}
                      {data.invoiceId !== 0 ? (
                        <>
                          {/* <Button className="theme-green" onClick={viewInvoice}> */}
                          <Button
                            className="theme-green"
                            onClick={() => viewInvoice(data)}
                          >
                            <Tooltip
                              title={
                                ServerSetting.contractType === 1
                                  ? translate("ViewWorkDeliveryNote")
                                  : translate("ViewWorkInvoice")
                              }
                            >
                              <LinkIcon fontSize="small" />
                            </Tooltip>
                          </Button>

                          <Button
                            className="theme-green"
                            onClick={() => downloadWorkOrder(data)}
                          >
                            <Tooltip title={translate("downloadInvoice")}>
                              <PictureAsPdf fontSize="small" />
                            </Tooltip>
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* {(logInUser?.customerType === 1 || 
                  logInUser?.customerType === 2 ||
                  logInUser?.customerType === 3) &&(
                  <Button
                      className="theme-green"
                      onClick={() => openStatusFrom([data],"accept")}
                    >
                        Accept
                    </Button>)}
                    {(logInUser?.customerType === 1 || 
                      logInUser?.customerType === 2) && (
                    <Button
                      className="theme-red"
                      onClick={() => openStatusFrom([data],"reject")}
                    >
                          Reject
                    </Button>)} */}
                      {/* {checkPrivileges("serviceMarkAsComplete") && ( */}

                      {checkPrivileges("serviceIssueDeliveryNote") &&
                        (data.issueInvoice === undefined ||
                          data.issueInvoice === false) && (
                          <Button
                            className="theme-green"
                            onClick={() => openIssueInvoiceBox([data])}
                          >
                            <Tooltip title={translate("issueInvoice")}>
                              <IssueInvoiceIcon width={18} height={18} />
                            </Tooltip>
                          </Button>
                        )}

                      {checkPrivileges("serviceSendToGarage") &&
                        (data.status === "required" ||
                          data.status === "completed") &&
                        !data.bookingMessegeDex &&
                        !data.bookingMessegeMac && (
                          <Button
                            className="theme-green"
                            onClick={() => openSendToGarageBox([data])}
                          >
                            <Tooltip title={translate("sendToGarage")}>
                              <SendIcon width={18} height={18} />
                            </Tooltip>
                          </Button>
                        )}

                      {checkPrivileges("serviceMarkAsComplete") && (
                        <Button
                          className="theme-green"
                          onClick={() => openServiceMarkCompleteBox([data])}
                          // onClick={() => editHistoryFrom([data])}
                          // openServiceMarkCompleteBox
                        >
                          <Tooltip title={translate("markAsComplete")}>
                            <CheckIcon width={18} height={18} />
                          </Tooltip>
                        </Button>
                      )}
                      {/* <Button className="theme-green" onClick={viewInvoice}> */}
                      <Button
                        className="theme-green"
                        onClick={() => viewInvoice(data)}
                      >
                        <Tooltip title={translate("viewWorkOrder")}>
                          <LinkIcon fontSize="small" />
                        </Tooltip>
                      </Button>
                      <Button
                        className="theme-green"
                        onClick={downloadWorkOrder}
                      >
                        <Tooltip
                          title={
                            type === "history"
                              ? translate("Download Invoice")
                              : translate("downloadWorkOrder")
                          }
                        >
                          <PictureAsPdf fontSize="small" />
                        </Tooltip>
                      </Button>
                    </>
                  )}
                  {data.creationMode === "user" && (
                    <>
                      <Button
                        className="theme-green"
                        onClick={() => onEdit([data], requestHistory)}
                      >
                        <Tooltip title={translate("edit")}>
                          <EditIcon width={18} height={18} />
                        </Tooltip>
                      </Button>
                      <Button
                        className="theme-green"
                        onClick={() => onRemove([data])}
                      >
                        <Tooltip title={translate("delete")}>
                          <DeleteIcon width={18} height={18} />
                        </Tooltip>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : estimation ? (
          <div
            className={"mainten-section mainten-" + statusObj[data.status]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "10px",
              borderLeft:
                data.status === "GarageInitiate"
                  ? "5px solid #FFC107"
                  : data.status === "WaitingForOpApproval" ||
                    data.status === "WaitingForCustomerApproval" ||
                    data.status === "GarageToOp" ||
                    data.status === "OpToCustomer" ||
                    data.status === "GarageToCustomer"
                  ? "5px solid #1879EB"
                  : // data.status === "WaitingForOpApproval" ||  data.status === "WaitingForCustomerApproval"  ? "5px solid #1879EB" :
                  data.status === "required" ||
                    data.status === "completed" ||
                    data.status === "scheduled"
                  ? "5px solid #28A745"
                  : "5px solid #E33C30",
            }}
          >
            <div
              style={{
                display: "flex",
                // alignItems: 'center',
                width: "54%",
              }}
            >
              <div style={{ width: "35%", display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1E72B8",
                    borderRadius: "50%",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          width: "200px",
                          height: "150px",
                          padding: "5px 0px",
                        }}
                      >
                        {data.creationMode === "user"
                          ? translate("createdbyUser")
                          : data.creationMode === "garage"
                          ? translate("createdbyGarage")
                          : ""}
                      </span>
                    }
                  >
                    {data.creationMode === "user" ? (
                      <GarageIcon width={18} height={18} />
                    ) : data.creationMode === "garage" ? (
                      <CustomerIcon width={18} height={18} />
                    ) : (
                      <CustomerIcon width={18} height={18} />
                    )}
                  </HtmlTooltip>
                </div>
                <div style={{ width: "150px" }}>
                  <div>
                    <HtmlTooltip
                      title={
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            width: "200px",
                            height: "150px",
                            padding: "5px 0px",
                          }}
                        >
                          {/* {translate(data?.attributes?.messege ? data?.attributes?.messege : "")}  */}
                          {translate(data.name)}
                        </span>
                      }
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "230px",
                        }}
                      >
                        {data.name}{" "}
                      </div>
                    </HtmlTooltip>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span>
                      <label style={{ fontSize: 14 }}>
                        {translate("estimateId")}{" "}
                      </label>
                      &nbsp;
                      <strong style={{ fontSize: 14, fontWeight: 700 }}>
                        {data.id}
                      </strong>
                    </span>
                  </div>
                  {/* <div>
        <HtmlTooltip
         title={
          <span style={{fontSize:"18px", fontWeight: "bold", width:"200px", height:"150px", padding:"5px 0px" }}> 
            {translate(data.workOrderNumber)}
          </span>
        }
      > */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "240px",
                    }}
                  >
                    <span>
                      <label style={{ fontSize: 14 }}>
                        {translate("showworkOrderNumber")}{" "}
                      </label>
                      &nbsp;
                      <strong style={{ fontSize: 14, fontWeight: 700 }}>
                        {data.workOrderNumber}
                      </strong>
                    </span>
                  </div>
                  {/* </HtmlTooltip>
        </div>  */}
                </div>
              </div>
              <div style={{ width: "20%", marginLeft: "5px" }}>
                <div
                  className="mainten-highlight-section"
                  style={{
                    borderRadius: "6px",
                    marginTop: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <AirportShuttleIcon style={{ color: "#6FBD43" }} /> &nbsp;
                    &nbsp; &nbsp;
                    <strong style={{ fontSize: 16, fontWeight: 700 }}>
                      {data.vehicleLicensePlate}
                    </strong>
                  </span>
                </div>
              </div>
              {/* sdiv 3 */}
              <div
                style={{
                  width: "45%",
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <div > */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <ul
                    style={{
                      color:
                        data.status === "GarageInitiate"
                          ? "#FFC107"
                          : data.status === "WaitingForOpApproval" ||
                            data.status === "WaitingForCustomerApproval" ||
                            data.status === "GarageToOp" ||
                            data.status === "OpToCustomer" ||
                            data.status === "GarageToCustomer"
                          ? "#1879EB"
                          : data.status === "required" ||
                            data.status === "completed" ||
                            data.status === "scheduled"
                          ? "#28A745"
                          : "#E33C30",
                    }}
                  >
                    <li style={{ fontSize: 14, fontWeight: 600 }}>
                      {translate(
                        data.status === "required"
                          ? "customerApprove"
                          : data.status === "expired"
                          ? "overdue"
                          : data.status === "GarageToOp"
                          ? "WaitingForOpApproval"
                          : data.status === "GarageToCustomer" ||
                            data.status === "OpToCustomer"
                          ? "WaitingForCustomerApproval"
                          : data.status === "OpToGarage"
                          ? "RejectedByOP"
                          : data.status === "CustomerToOp" ||
                            data.status === "CustomerToGarage"
                          ? "RejectedByCustomer"
                          : data.status
                      )}
                    </li>
                    {/* <li  style={{fontSize:14, fontWeight:600 }}> {statusObj[data.status]}</li> */}
                  </ul>
                  {/* </div>  */}
                </div>
              </div>
            </div>

            {/* div 2 */}
            <div style={{ width: "46%", minWidth: "250px", fontSize: "14px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {translate("estimationPrce")}{" "}
                  <span className="reminder-cost">
                    {data.saleNetAmount?.toFixed(2)}{" "}
                    <small style={{ fontWeight: "normal" }}>
                      {logInUser &&
                        logInUser.attributes &&
                        logInUser.attributes.currencyLabel}
                    </small>
                  </span>
                </span>
                <span style={{ marginLeft: "15px" }}>
                  {translate("date")}{" "}
                  <strong>
                    <em>
                      {data.createdTime
                        ? moment(data.createdTime)
                            .tz(serverTimeZoneName)
                            .format("DD-MM-YYYY HH:mm:ss")
                        : "--"}
                    </em>
                  </strong>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {/* {((logInUser.customerType === 1 && checkPrivileges('serviceGarageAccept') && (data.status ==="GarageInitiate" || data.status === "OpReject") ) || 
                   (logInUser.customerType === 2 && checkPrivileges('serviceOpAccept') && (data.status ==="WaitingForOpApproval" || data.status === "CustomerReject"))||
                   (logInUser.customerType === 3 && checkPrivileges('serviceCustomerAccept') && data.status ==="WaitingForCustomerApproval") ||
                   ((logInUser.userType === 3 || logInUser.userType === 2)  && checkPrivileges('serviceCustomerAccept') && data.status ==="WaitingForCustomerApproval")
                  ) &&(
                    <Button
                      style={{marginRight:"5px"}}
                      onClick={() => openStatusFrom([data], "accept")}
                      startIcon={<CheckIcon width={16} height={16} />}
                    >
                      {logInUser.customerType === 1  ? translate("submit") : translate("approve")}
                    </Button>)}
                 
                 {((logInUser.customerType === 2 && checkPrivileges('serviceOpReject') &&( data.status ==="WaitingForOpApproval" || data.status === "CustomerReject"))||
                   (logInUser.customerType === 3 && checkPrivileges('serviceCustomerReject') && data.status ==="WaitingForCustomerApproval") ||
                   ((logInUser.userType === 3 || logInUser.userType === 2)  && checkPrivileges('serviceCustomerReject') && data.status ==="WaitingForCustomerApproval")
                  ) &&(
                    <Button
                      className="theme-red"
                      style={{marginRight:"5px", marginLeft: "5px", background: '#dc3545', color: "white" }}
                      onClick={() => openStatusFrom([data], "reject")}
                      startIcon={<ClearIcon width={16} height={16} />}
                    >
                      {translate("reject")}
                    </Button>)} */}

                {((logInUser.customerType === 1 &&
                  checkPrivileges("serviceGarageToOp") &&
                  (data.status === "GarageInitiate" ||
                    data.status === "OpToGarage" ||
                    data.status === "CustomerToGarage")) ||
                  ((logInUser.customerType === 3 ||
                    logInUser.userType === 2 ||
                    logInUser.userType === 3) &&
                    checkPrivileges("serviceCustomerToOp") &&
                    (data.status === "OpToCustomer" ||
                      data.status === "GarageToCustomer" ||
                      data.status === "WaitingForCustomerApproval"))) && (
                  <Button
                    style={{
                      marginRight: "5px",
                      background:
                        data.status === "WaitingForCustomerApproval"
                          ? "#dc3545"
                          : "",
                    }}
                    onClick={() => openStatusFrom([data], "sendToOp")}
                    // startIcon={data.status ==="WaitingForCustomerApproval" ?
                    //             <ClearIcon width={16} height={16} />
                    //           : <CheckIcon width={16} height={16} />}
                  >
                    {translate("sendToOp")}
                  </Button>
                )}

                {((logInUser.customerType === 1 &&
                  checkPrivileges("serviceGarageToCustomer") &&
                  (data.status === "GarageInitiate" ||
                    data.status === "OpToGarage" ||
                    data.status === "CustomerToGarage")) ||
                  (logInUser.customerType === 2 &&
                    checkPrivileges("serviceOpToCustomer") &&
                    (data.status === "GarageToOp" ||
                      data.status === "CustomerToOp" ||
                      data.status === "WaitingForOpApproval"))) && (
                  <Button
                    style={{ marginRight: "5px" }}
                    onClick={() => openStatusFrom([data], "sendToCust")}
                    // startIcon={<CheckIcon width={16} height={16} />}
                  >
                    {translate("sendToCust")}
                  </Button>
                )}

                {((logInUser.customerType === 2 &&
                  checkPrivileges("serviceOpToGarage") &&
                  (data.status === "GarageToOp" ||
                    data.status === "CustomerToOp" ||
                    data.status === "WaitingForOpApproval")) ||
                  ((logInUser.customerType === 3 ||
                    logInUser.userType === 2 ||
                    logInUser.userType === 3) &&
                    checkPrivileges("serviceCustomerToGarage") &&
                    (data.status === "WaitingForCustomerApproval" ||
                      data.status === "OpToCustomer" ||
                      data.status === "GarageToCustomer"))) && (
                  <Button
                    className="theme-red"
                    style={{ marginRight: "5px", background: "#dc3545" }}
                    onClick={() => openStatusFrom([data], "sendToGarage")}
                    // startIcon={<ClearIcon width={16} height={16} />}
                  >
                    {translate("reject")}
                  </Button>
                )}

                {(logInUser.customerType === 3 ||
                  logInUser.userType === 2 ||
                  logInUser.userType === 3) &&
                  checkPrivileges("serviceCustomerAccept") &&
                  (data.status === "WaitingForCustomerApproval" ||
                    data.status === "OpToCustomer" ||
                    data.status === "GarageToCustomer") && (
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={() => openStatusFrom([data], "accept")}
                      // startIcon={<CheckIcon width={16} height={16} />}
                    >
                      {translate("approve")}
                    </Button>
                  )}

                {/* <Button className="theme-green" onClick={viewInvoice} style={{marginLeft:"5px"}}> */}
                {checkPrivileges("serviceIssueDeliveryNote") &&
                  (data.status === "required" || data.status === "completed") &&
                  (data.issueInvoice === undefined ||
                    data.issueInvoice === false) && (
                    <Button
                      style={{ marginRight: "5px" }}
                      className="theme-green"
                      onClick={() => openIssueInvoiceBox([data])}
                    >
                      <Tooltip title={translate("issueInvoice")}>
                        <IssueInvoiceIcon width={18} height={18} />
                      </Tooltip>
                    </Button>
                  )}

                {checkPrivileges("serviceSendToGarage") &&
                  (data.status === "required" || data.status === "completed") &&
                  !data.bookingMessegeDex &&
                  !data.bookingMessegeMac && (
                    <Button
                      className="theme-green"
                      onClick={() => openSendToGarageBox([data])}
                      style={{ marginRight: "5px" }}
                    >
                      <Tooltip title={translate("sendToGarage")}>
                        <SendIcon width={18} height={18} />
                      </Tooltip>
                    </Button>
                  )}

                {checkPrivileges("serviceMarkAsComplete") &&
                  data.status === "required" && (
                    <Button
                      className="theme-green"
                      onClick={() => openServiceMarkCompleteBox([data])}
                      // onClick={() => editHistoryFrom([data])}
                      // openServiceMarkCompleteBox
                    >
                      <Tooltip title={translate("markAsComplete")}>
                        <CheckIcon width={18} height={18} />
                      </Tooltip>
                    </Button>
                  )}

                {/* <Button className="theme-green" onClick={viewInvoice} style={{marginLeft:"5px"}}> */}

                <Button
                  className="theme-green"
                  onClick={() => viewInvoice(data)}
                  style={{ marginLeft: "5px" }}
                >
                  <Tooltip
                    title={translate(
                      data.creationMode === "garage"
                        ? "viewEstimate"
                        : "View WorkOrder"
                    )}
                  >
                    <LinkIcon fontSize="small" />
                  </Tooltip>
                </Button>

                <Button
                  className="theme-green"
                  onClick={() => downloadWorkOrder(data)}
                  style={{ marginLeft: "5px" }}
                >
                  <Tooltip
                    title={
                      type === "history"
                        ? translate("Download Invoice")
                        : translate(
                            data.creationMode === "garage"
                              ? "downloadEstimate"
                              : "Download WorkOder"
                          )
                    }
                  >
                    <PictureAsPdf fontSize="small" />
                  </Tooltip>
                </Button>

                {/* {
                  ((data.creationMode ==='garage' && logInUser.customerType  ===  1 &&
                   (data.status === "GarageInitiate" ||data.status === "OpReject" ||
                     data.status ==="OpToGarage" ||data.status ==="CustomerToGarage" ) ) || 
                    (data.creationMode ==='garage' && logInUser.customerType  ===  2 &&
                    ( data.status === "WaitingForOpApproval" || data.status === "CustomerReject" || 
                      data.status ==="GarageToOp" ||data.status ==="CustomerToOp"
                    ))
                     ||
                     (data.creationMode ==='user') 
                  ) && ( 
                     <Button
                        className="theme-green"
                        onClick={() => onEdit([data], requestHistory)}
                        style={{marginLeft:"5px"}}
                      >
                        <Tooltip title={translate("edit")}>
                          <EditIcon width={18} height={18} />
                        </Tooltip>
                      </Button>)} */}

                {data.creationMode === "garage" && (
                  <Button
                    className="theme-green"
                    onClick={() => openMessageLogs([data])}
                    style={{ marginLeft: "5px" }}
                  >
                    <HtmlTooltip
                      title={
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            width: "200px",
                            height: "150px",
                            padding: "5px 0px",
                          }}
                        >
                          {/* {translate(data?.attributes?.messege ? data?.attributes?.messege : "")}  */}
                          {translate("showServiceLogs")}
                        </span>
                      }
                    >
                      {/* <Badge badgeContent={data.attributes.messege ? 1 : 0} color="secondary"> */}
                      <MailIcon width={18} height={18} />
                      {/* </Badge> */}
                    </HtmlTooltip>
                  </Button>
                )}

                {((data.creationMode === "garage" &&
                  logInUser.customerType === 1 &&
                  data.status === "GarageInitiate") ||
                  data.creationMode === "user") && (
                  <Button
                    className="theme-green"
                    onClick={() => onRemove([data])}
                    style={{ marginLeft: "5px" }}
                  >
                    <Tooltip title={translate("delete")}>
                      <DeleteIcon width={18} height={18} />
                    </Tooltip>
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);

const MaintenanceItemEmpty = React.memo(() => {
  return (
    <div className={"mainten-section mainten"}>
      <div className="mainten-section-header">
        <span>
          <Skeleton className="skeleton-pulse" width={150} height={35} />{" "}
        </span>
        <span>
          <Skeleton className="skeleton-pulse" width={150} height={35} />{" "}
        </span>
      </div>

      <div className="mainten-section-body">
        <div className="mainten-split-section">
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={35} />{" "}
          </div>
        </div>
        <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div>
        <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div>
      </div>
    </div>
  );
});

const RemiderItem = ({
  onStatusChange,
  translate,
  data,
  onRemove,
  onEdit,
  onMarkComplete,
  logInUser,
  serverTimeZoneName,
  vehicles,
}) => {
  let findVehicle =
    vehicles && vehicles.find((item) => item.id === data.vehicleId);

  return (
    <div className="reminder-section">
      <div className="reminder-section-header">
        <span>
          <label>{translate("vehicle")}</label>
          <strong>
            {data.vehicleName ? data.vehicleName : findVehicle?.label || ""}
          </strong>
        </span>
        <span
          onClick={() => onStatusChange(data)}
          style={{ cursor: "pointer" }}
        >
          {translate("status")}{" "}
          <span
            className={
              "custom-badge " + (data.attributes.status ? "on" : "off")
            }
          >
            {data.attributes.status ? "on" : "off"}
          </span>
        </span>
      </div>
      <div className="reminder-section-body">
        <div style={{ width: 120 }}>
          <strong>{translate("targetValue")}</strong>
          <div className="custom-mileage-list">
            <span>
              {data.attributes.mileage && data.attributes.mileageInterval
                ? setAttributeFormat(
                    "mileage",
                    data.attributes.mileage + data.attributes.mileageInterval
                  )
                : data.attributes.mileage
                ? setAttributeFormat("mileage", data.attributes.mileage)
                : "-"}{" "}
            </span>
            <span>
              {data.attributes.hours && data.attributes.hoursInterval
                ? (
                    (data.attributes.hours + data.attributes.hoursInterval) /
                    (1000 * 3600)
                  ).toFixed(2) + " hours"
                : data.attributes.hours
                ? (data.attributes.hours / (1000 * 3600)).toFixed(2) + " hours"
                : "-"}
            </span>
            <span>
              {data.attributes.date
                ? moment(data.attributes.date)
                    .tz(serverTimeZoneName)
                    .format("DD-MM-YYYY")
                : "-"}
            </span>
          </div>
        </div>
        <div>
          <br />
          <br />
          <label>{translate("sharedName")}</label>
          <strong>{data.name} </strong>
          <br />
          <br />
          {translate("sellingPriceWithoutVAT")}:{" "}
          <span className="reminder-cost">
            {data.cost}{" "}
            <small>
              {logInUser &&
                logInUser.attributes &&
                logInUser.attributes.currencyLabel}
            </small>
          </span>
          <br />
          {translate("repeat")}:{" "}
          <strong>{data.attributes.repeat ? "Yes" : "No"}</strong>
        </div>
      </div>
      <div className="reminder-section-footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div>
            {checkPrivileges("serviceMarkAsComplete") && (
              <Button
                className="theme-green"
                onClick={() => onMarkComplete(data)}
              >
                <Tooltip title={translate("markAsComplete")}>
                  <CheckIcon width={18} height={18} />
                </Tooltip>
              </Button>
            )}
            <Button className="theme-green" onClick={() => onEdit([data])}>
              <Tooltip title={translate("edit")}>
                <EditIcon width={18} height={18} />
              </Tooltip>
            </Button>
            <Button className="theme-green" onClick={() => onRemove([data])}>
              <Tooltip title={translate("delete")}>
                <DeleteIcon width={18} height={18} />
              </Tooltip>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const RemiderItemEmpty = ({}) => {
  return (
    <div className="reminder-section">
      <div className="reminder-section-header">
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
        <span>
          <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
        </span>
      </div>
      <div className="reminder-section-body">
        <div style={{ width: 120 }}>
          <strong>
            <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
          </strong>
          <span>
            <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
          </span>
        </div>
        <div>
          <br />
        </div>
      </div>
      <div style={{}}>
        {" "}
        <span>
          {" "}
          <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
        </span>{" "}
      </div>
    </div>
  );
};

// import React, { useEffect, useState } from 'react';
// import { Menu, MenuItem, FormControlLabel, Checkbox, TextField, Button } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Filter = React.memo((props) => {
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    if (
      props.logInUser.customerType === 1 ||
      props.logInUser.customerType === 2
    ) {
      props.handleChangeFilter("createdBy", { target: { checked: true } });
      // setInitialCheckDone(true);
    }
  }, [props.logInUser.customerType, props.handleChangeFilter]);

  const makeSearch = (event, vehicle) => {
    if (event === "vehicles") {
      props.SearchVehicle(event, vehicle);
    } else if (event === "areas") {
      props.SearchArea(event, vehicle);
    } else {
      props.SearchItem(event.target.value);
    }
  };



  const html = (
    <>
      <div>
      <DateRangePicker
            maintenanceTop
            fill
            error={ props.timeError || false}
            selectedDate={props.selectedDateTime}
            label={
              props.translate('reportFrom') +
              ' ' +
              props.translate('reportTo')
            }
            onEvent={props.showDates}
          />
      </div>
      <Button 
         onClick={props.clearFilter} 
         variant="outlined"
         size="small"
         disabled={!props.selectedDateTime}
       >
        {props.translate("clearDate")}
      </Button>
      <div 
      tyle={{ width: 200 }}>
    
        <p
          aria-owns={props.anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={props.handleClickFilter}
          style={{
            display: "flex",
            alignItems: "center",
            border: `1px solid ${props.themecolors.themeListingColor}4D`,
            backgroundColor: `${props.themecolors.themeListingColor}1A`,
            flex: 1,
            padding: "5px 14px",
            fontSize: 12,
            lineHeight: 2,
            borderRadius: 4,
            margin: "8px 0 4px",
            justifyContent: "space-between",
          }}
        >
          {props.translate("selected")}
          {": "}
          {(props.createdBy &&
            (props.logInUser.customerType === 1 ||
              props.logInUser.customerType === 2)) ||
          ((props.logInUser.userType === 1 ||
            props.logInUser.userType === 2 ||
            props.logInUser.userType === 3) &&
            props.filterStatusType ===
              "&status=OpToCustomer&status=GarageToCustomer")
            ? props.countChecked + 1
            : props.countChecked}{" "}
          <ArrowDropDownIcon />
        </p>
        <Menu
          id="simple-menu"
          anchorEl={props.anchorEl}
          open={Boolean(props.anchorEl)}
          onClose={props.handleCloseFilter}
        >
          {(props.logInUser.customerType === 1 ||
            props.logInUser.customerType === 2) && (
            <MenuItem
              component={FormControlLabel}
              style={{ margin: 0 }}
              control={
                <Checkbox
                  name="createdBy"
                  checked={
                    props.createdBy === true
                      ? true
                      : props.createdBy === false
                      ? false
                      : true
                  }
                  onChange={(e) => props.handleChangeFilter("createdBy", e)}
                />
              }
              label={
                <span style={{ marginLeft: 8 }}>
                  {props.translate("Created By Me")}
                </span>
              }
            />
          )}
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="GarageInitiate"
                checked={props.statusFilter.GarageInitiate}
                onChange={(e) => props.handleChangeFilter("GarageInitiate", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("GarageInitiate")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="GarageToOp"
                checked={props.statusFilter.GarageToOp}
                onChange={(e) => props.handleChangeFilter("GarageToOp", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("WaitingForOpApproval")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="OpToCustomer"
                checked={
                  (props.logInUser.userType === 1 ||
                    props.logInUser.userType === 2 ||
                    props.logInUser.userType === 3) &&
                  props.filterStatusType ===
                    "&status=OpToCustomer&status=GarageToCustomer"
                    ? true
                    : props.statusFilter.OpToCustomer
                }
                onChange={(e) => props.handleChangeFilter("OpToCustomer", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("WaitingForCustomerApproval")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="OpToGarage"
                checked={props.statusFilter.OpToGarage}
                onChange={(e) => props.handleChangeFilter("OpToGarage", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("OpReject")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="CustomerToOp"
                checked={props.statusFilter.CustomerToOp}
                onChange={(e) => props.handleChangeFilter("CustomerToOp", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("CustomerReject")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="Required"
                checked={props.statusFilter.required}
                onChange={(e) => props.handleChangeFilter("required", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("customerApprove")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="Expired"
                checked={props.statusFilter.expired}
                onChange={(e) => props.handleChangeFilter("expired", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("overdue")}
              </span>
            }
          />
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0 }}
            control={
              <Checkbox
                name="Completed"
                checked={props.statusFilter.completed}
                onChange={(e) => props.handleChangeFilter("completed", e)}
              />
            }
            label={
              <span style={{ marginLeft: 8 }}>
                {props.translate("completed")}
              </span>
            }
          />
        </Menu>
      </div>
      <div>
        <TextField
          label={props.translate("sharedSearch")}
          onChange={makeSearch}
        />
      </div>
    
      <Button onClick={props.submitSearch} variant="outlined" size="small">
        {props.translate("applyFilters")}
      </Button>

      {/* <SingleItem collection={"vehicles"} field={"id"} value={0} item={"multi"}>
        {(id) => {
          let vehiclesList = id.map((item) => {
            return {
              id: item.id,
              label: item.label,
              value: item.id,
              vehicleLP: item.vehicleLicensePlate,
              valueType: "string",
            };
          });
          vehiclesList.unshift({
            id: "",
            label: "All Vehicles",
            value: 0,
            vehicleLP: "00000",
            valueType: "string",
          });
          return (
            <AsyncSelect
              value={props.searchVehicleId}
              placeholder={"Search Vehicles"}
              selectName="vehicles"
              // resultMessage={resultMessage}
              defaultOptions={vehiclesList}
              handleChange={makeSearch}
              loadOptions={searchList}
              isMulti={false}
            />
          );
        }}
      </SingleItem>
      <SingleItem collection={"areas"} field={"id"} value={0} item={"multi"}>
        {(id) => {
          if (id && id.data) {
            let areasList =
              id &&
              id.data.map((item) => {
                return {
                  id: item.id,
                  label: item.name,
                  value: item.id,
                  country: item.country,
                  parentId: item.parentId,
                  valueType: "string",
                };
              });
            areasList.unshift({
              id: "",
              label: "All Areas",
              value: 0,
              country: "00000",
              parentId: "00",
              valueType: "string",
            });
            return (
              <AsyncSelect
                value={props.searchAreaId}
                placeholder={"Search Areas"}
                selectName="areas"
                // resultMessage={resultMessage}
                defaultOptions={areasList}
                handleChange={makeSearch}
                loadOptions={searchAreaList}
                isMulti={false}
              />
            );
          }
        }}
      </SingleItem> */}
      {props.logInUser.customerType !== 1 &&
        props.logInUser.customerType !== 2 && (
          <ExportImportMenu
            fileName="services"
            checkPrivilege="serviceCreate"
            isMaintenance
            data={[]}
            themecolors={props.themecolors}
            importFunc={props.importFunc}
          />
        )}
    </>
  );

  return <div className="v-filter-field2">{html}</div>;
});

// export default Filter;

// const maintenance = withStyles(styles);

// export default connect(mapStateToProps)(
//   withSnackbar((Maintenance))
// );

const StyledMaintenance = withStyles(styles)(Maintenance);

export default connect(mapStateToProps)(
  withTranslationWrapper(StyledMaintenance)
);
