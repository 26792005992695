import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "./Checkbox";
import Style from "style-it";
// import SearchField from "./SearchField";
// import { Trans as Translate} from 'react-i18next';
import Scrollbar from "react-scrollbars-custom";
import withTranslationWrapper from "../../HOC/withTranslation";
class MaintenanceSelectors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list:
        this.props.allPreSelected && this.props.allPreSelected
          ? { scheduled: true, expired: true, required: true, completed: true }
          : {},
      search: "",
      isChecked:
        this.props.allPreSelected && this.props.allPreSelected ? true : false,
    };
  }
  componentWillMount() {
    let list = {};
    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list });
    }
  }

  componentWillReceiveProps(nextprops) {
    let list = {};
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map((d) => (list[d] = true));
    }
    this.setState({ list });
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      search: "",
      isChecked: false,
    });
  }
  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list), "maintenance");
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list };
    if (e.target.checked) {
      list[v.key] = true;
    } else {
      delete list[v.key];
    }
    this.setState(
      {
        list,
        isChecked:
          this.props.maintenanceTypes &&
          this.props.maintenanceTypes.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
  searchItem = (text) => {
    this.setState({ search: text.target.value });
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      this.props.maintenanceTypes &&
        this.props.maintenanceTypes.map((d) => (list[d.key] = true));
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  render() {
    let list =
      this.props.maintenanceTypes &&
      this.props.maintenanceTypes.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
            -1
      );
    return (
      <Style>
        {`
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}
        <div>
          <div
            className="section-head section-head-filter"
            style={{ padding: 0,  }}
          >
            <ul className="filter-row">
              {/* <li>
                <SearchField
                  label={this.props.translate('searchMaintenance')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li> */}
              <li style={{ paddingRight: 0, paddingTop: 3 }}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("selectAll")}
                />
              </li>
            </ul>
          </div>
          <div className="selector-count">
            {" "}
            {Object.keys(this.state.list).length}{" "}
            {this.props.translate("maintenanceSelected")}
          </div>
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height:
                (this.props.minHeight
                  ? parseInt(this.props.minHeight)
                  : this.props.sidebar
                  ? 15
                  : 15) * (this.props.rows || 10),
            }}
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div {...restProps} ref={elementRef} id="scrollableDiv" />
                );
              },
            }}
          >
            <ul className="selector-list">
              {list &&
                list.map((row) => (
                  <li key={row.id}>
                    <label
                      className={this.state.list[row.key] ? "active" : null}
                    >
                      <span className="selector-check">
                        <Checkbox
                          checked={this.state.list[row.key]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                      <span className="selector-label">
                        {" "}
                        {this.props.translate(row.name)}
                      </span>
                    </label>
                  </li>
                ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    );
  }
}

const mapState = (state) => ({
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(MaintenanceSelectors));
