import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getGroups } from './../../Actions/Groups'
import DeleteIcon from '@mui/icons-material/Delete';
import SupervisedUserCircleIcon from '@mui/icons-material/DeviceHub';
import { NavLink } from 'react-router-dom'
import { removeGroup } from '../../Actions/Groups'
import { checkPrivileges, errorHandler } from '../../Helpers'
import ConfirmDialoag from '../common/ConfirmDialoag'
import Style from 'style-it'
import axios from 'axios'
import instance from '../../axios';
import { notifySuccess } from '../../Utils/CustomNotifcations';
const CancelToken = axios.CancelToken;
let source;
class GroupsShortList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      usesId: ''
    }
  }

  componentWillReceiveProps (n) {
    if (
      n.logInUser &&
      this.props.logInUser &&
      n.logInUser.id &&
      n.logInUser.id !== this.props.logInUser.id
    ) {
      this.fatchGroups(this.props.logInUser.id)
    }
  }
  componentWillMount () {
    source = CancelToken.source();
  }

  deleteItem = () => {
    let { selectedItem } = this.state
    if (selectedItem.id) {
      // fetch(`/api/groups/${selectedItem.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...selectedItem
      //   })
      // })
      instance({
        url: `/api/groups/${selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data :{
          ...selectedItem
        }
      })
        .then(response => {
          // if (response.ok) {
            this.props.dispatch(removeGroup(selectedItem))

            this.props.dispatch(
            //   Notifications.success({
            //   message: this.props.translate('resourceDeleted'),
            //   autoDismiss: 10
            // })
            notifySuccess('resourceDeleted')

          );

            this.setState({
              onDeleteConfirmation: false
            })
            this.props.history.push('/resources');
            this.props.closeScreen();
          // } else {
          //   throw response
          // }
        })
        .catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  onRemovedGroup = item => {
    this.setState({
      onDeleteConfirmation: true,
      selectedItem: item
    })
  }
  componentWillUnmount() {
    if(source) {
      source.cancel();      
    }
  }

  fatchGroups = id => {
    if(source) {
      source.cancel();      
    }
    source = CancelToken.source();
    if (id) {
      instance({
        method: "GET",
        url: `/api/groups?userId=${id}`,
        cancelToken: source.token 
      }).then(response => {
        // if (response.status === 200) {
          this.props.dispatch(getGroups(response))
          this.setState({
            usesId: this.props.logInUser.id
          })
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  render () {
    let data = ''
    if (this.props.groups.length) {
      data = this.props.groups.map(group => (
        <ListRow
          key={group.id}
          data={group}
          {...this.props}
          deleteItem={this.onRemovedGroup}
          editItem={this.props.editItem}
        />
      ))
    }

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div>
          {data && (
            <div className='individual-view'>
              <ul className='list-view'>{data}</ul>
            </div>
          )}
          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.deleteItem}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.selectedItem.name}
            />
          )}
        </div>
      </Style>
    )
  }
}

export class ListRow extends Component {
  render () {
    return (
      <li>
        <NavLink
          activeClassName='active'
          to={'/resources/' + this.props.data.id}
          className={'clearfix list-row'}
        >
          <label className='checkbox'>
            <span
              className='unit-name'
            >
              <span
                style={{
                  marginRight: '10px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <SupervisedUserCircleIcon style={{fontSize:16, verticalAlign: 'middle'}}/>
              </span>{' '}
              {this.props.data.name} {this.props.data.uniqueId}
            </span>
          </label>
          <div className='pull-right'>
            {checkPrivileges('groupDelete') && (
              <span
                className='action-item '
                title={this.props.translate('delete')}
                onClick={() => this.props.deleteItem(this.props.data)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}
          </div>
        </NavLink>
      </li>
    )
  }
}

const mapState = state => {
  var groups = state.groups.filter(
    item => item.name && item.name.toLowerCase().indexOf(state.searchRecently.toLowerCase()) !== -1
  )
  return {
    groups: groups,
    logInUser: state.logInUsers
  }
}

const mapStateToProps = connect(mapState)

export default mapStateToProps(GroupsShortList)
