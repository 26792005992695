import React, { Component, Fragment } from 'react'
import Button from '../../../Components/common/Button'
import { connect } from 'react-redux'
;
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import MaintenanceFilterModal from '../Components/MaintenanceFilterModal'
import Tooltip from '@mui/material/Tooltip'
import { Grid , FormControlLabel} from '@mui/material'
import Skeleton from '@mui/material/Skeleton';

import CloseIcon from '@mui/icons-material/Close'
import MenuItem from '../../../Components/common/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '../../../Components/common/Checkbox'
import Menu from '../../../Components/Menu/CommonMenu'
import MaintenanceFilter from '../../../Components/common/MaintenanceFilter'

class Maintenance extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}  
          .infinite-scroll-component .event-list-row {
            border-bottom: 1px solid ${this.props.themecolors.textColor}22
          }
          .infinite-scroll-component .event-list-row.active,
          .infinite-scroll-component .event-list-row:hover {
              background-color: ${this.props.themecolors.themeLightColor}88;
          } 
                
        `}
        <div className='fms-sidebar sidebar-bg-layer'>
             {/* {(this.props.logInUser.userType === 3 ||  this.props.logInUser.userType === 4 )&& (
                <div style={{display:"flex", alignItems:"center", margin:"10px 5px 0  40px"}}>
                    
                    <Button 
                      onClick={()=>{this.props.handleStatusBtns("tab2")}}
                      style={{width:"49%", height:"36px", marginLeft:"5px",
                      // color:"white" ,backgroundColor:"black",border:"2px solid green",
                      color:this.props.tabBtn === "tab2" ? "white" : "black",
                      backgroundColor:this.props.tabBtn === "tab2" ?"#6FBD43" :"white" ,
                      border:"2px solid #6FBD43" ,
                      borderRadius:"8px"
                      }}
                  > 
                  {this.props.translate("estimation")}
        
                    </Button>
                    <Button 
                          onClick={()=>{this.props.handleStatusBtns("tab1")}}
                          style={{width:"49%", height:"36px", marginLeft:"5px",
                        //  color:"black" ,backgroundColor:"white",border:"black",

                          color:this.props.tabBtn === "tab1" ?  "white" : "black",
                          backgroundColor:this.props.tabBtn === "tab1" ?  "#6FBD43" :"white" ,
                          border:"2px solid #6FBD43" ,
                          borderRadius:"8px"
                          }}
                      >
                        {this.props.translate('orders')}
                     </Button>
                </div>)} */}
          {this.props.showStats  ? (
              <Tooltip
                title={this.props.translate('serviceFilter')}
              >
                <span style={{ position: 'absolute', right: 10, top: 10, zIndex: 5 }}>
                <Button
                style={{minWidth: 'inherit' }}
                  color='inherit'
                  onClick={this.props.toggleFilterForm}
                >
                  <CloseIcon style={{ fontSize: 18 }} />
                </Button></span>
              </Tooltip>
            ) : null}
            {checkPrivileges('maintenance') && (
                <div>
                  <div className='section-head section-head-filter'>
                    <ul className='list-view with-padding-right geofence-sidebar-list' id='scrollableDiv'>
                        {this.props.itemPagination ? (
                          <Scrollbar
                                disableTracksWidthCompensation={true}
                                scrollerProps={{
                                  renderer: props => {
                                    const { elementRef, ...restProps } = props
                                    return (
                                      <div
                                        {...restProps}
                                        ref={elementRef}
                                        id='scrollableDiv'
                                      />
                                    )
                                  }
                                }}
                              >
                            <InfiniteScroll
                              dataLength={this.props.itemPagination.items.length}
                              // next={this.props.fetchMoreItems}
                              // hasMore={this.props.itemPagination.hasNext}
                              loader={<Loader defaultStyle={true} imgSize={50} />}
                              scrollableTarget='scrollableDiv'
                            >
                              <ListRow
                                tabBtn = {this.props.tabBtn}
                                analyticData={this.props.analyticData}
                                translate={this.props.translate}
                                themecolors={this.props.themecolors}
                                currencyLabel={this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.currencyLabel}
                              />
                            
                            <MaintenanceFilter
                                {...this.props}
                                formSubmit={this.props.submitSideBarSearch}
                                sidebar
                                loader={this.props.loadingSidebar}
                              />
                          </InfiniteScroll>
                        </Scrollbar>
                        ) : null}
                    </ul>
                  </div>
                </div>
              )}
            
        </div>
      </Style>
    )
  }
}

class ListRow1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      deviceSelector: false
    }
  }
  openDeviceSelector = (key, name, total, type) => {
    // this.resetSelectors()
    // if (this.state.deviceSelector === false) {
    //   this.showSelectedUnits(key, 'device')
    // }
    this.setState({
      deviceSelector: !this.state.deviceSelector,
      deviceSelectorKey: name,
      filterType: key,
      totalDevices: total&&total.count,
      statusType: type
    })
  }

  render () {

    const {completed,required,scheduled,expired,reminder} = this.props && this.props.analyticData || {}
    return (
      <>
      <Grid container style={{ position: this.state.deviceSelector ? 'relative' : 'static', zIndex: 9 }}>
        <Grid item xs={12}>
            <div style={{ marginTop: 0 }}>
            {/* {this.props.tabBtn === "tab2" ? null :<> */}
              {!this.state.deviceSelector ? (<div>
                    <h3
                      style={{
                        fontSize: 21,
                        fontWeight: 600,
                        // textAlign: 'center',
                        marginBottom: 5,
                        marginTop: 7,
                      }}
                      className="sidebar-icon-gap"
                    >
                      {(scheduled&&scheduled.count || 0)+(completed&&completed.count || 0)+(required&&required.count || 0)+(expired&&expired.count || 0)+(reminder&&reminder.count || 0)}
                      <span style={{ fontWeight: 'normal', fontSize: 18, fontStyle: 'italic', marginLeft: 5 }}>{this.props.translate('total')}</span> <span className='alt-title'>{this.props.translate('sharedMaintenance')}</span>
                    </h3>
              </div>) : 
                <>
                <h3
                  style={{
                    fontSize: 21,
                    display: 'flex',
                    fontWeight: 600,
                    textAlign: 'left',
                    marginBottom: 5,
                    marginTop: 7,
                    marginRight: 5,
                    justifyContent: 'space-between'
                  }}
                  className="sidebar-icon-gap"
                >
                  <span>
                    {this.state.totalDevices}
                    <span style={{ fontWeight: 'normal', fontSize: 16, fontStyle: 'italic', marginLeft: 2, marginRight: 5 }}>{this.props.translate(this.state.deviceSelectorKey)}</span>
                    <span className='alt-title'>{this.props.translate('sharedMaintenance')}</span>

                  </span>
                  <Button onClick={e => this.openDeviceSelector(null)} style={{ marginTop: -2, minWidth: 'inherit' }}>
                    <CloseIcon style={{ fontSize: 18 }} />
                  </Button>
                </h3>
              </>
              }
              {/* </>} */}

{/* {this.props.tabBtn === "tab2" ? null :<> */}
              {!this.state.deviceSelector && (
                <div className='driver-state-wrap'>
                  <div className='driver-state-container maintenace-state-container'>
                    {/* {checkPrivileges('maintenance') && (<div
                      className='driver-state2 driver-state-inline not-register hover'
                      onClick={e =>
                        this.openDeviceSelector(
                          'reminder',
                          this.props.translate('reminder'),
                          reminder
                        )
                      }
                    >
                      <p>{this.props.translate('reminder')}</p>
                      <h3>{reminder && reminder.count || 0}</h3>
                    </div>)} */}

                    {/* {checkPrivileges('maintenance') && (<div
                      className='driver-state2 driver-state-inline not-register hover'
                      style={{background:'#1919FF'}}
                      onClick={e =>
                        this.openDeviceSelector(
                          'due',
                          this.props.translate('scheduled'),
                          scheduled,
                          'scheduled'
                        )
                      }
                    >
                      <p>{this.props.translate('scheduled')}</p>
                      <h3>{scheduled && scheduled.count || 0}</h3>
                    </div>)} */}
                  {checkPrivileges('service') &&(<>
                    <div
                      className='driver-state2 driver-state-inline  not-update hover'
                      style={{background: '#F7A35C'}}
                      onClick={e =>
                        this.openDeviceSelector(
                          'due',
                          this.props.translate('due'),
                          required,
                          'required'
                        )
                      }
                    >
                      <p>{this.props.translate('customerApprove')}</p>
                      <h3>{(required && required.count) || 0}</h3>
                    </div>
                    <div
                      className='driver-state2 driver-state-inline online hover'
                      onClick={e =>
                        this.openDeviceSelector(
                          'history',
                          this.props.translate('completed'),
                          completed
                        )
                      }
                    >
                      <p>
                        {this.props.translate('completed')}
                      </p>
                      <h3>{completed && completed.count || 0}</h3>
                    </div>
                    <div
                      className='driver-state2 driver-state-inline offline hover'
                      onClick={e =>
                        this.openDeviceSelector(
                          'due',
                          this.props.translate('overdue'),
                          expired,
                          'expired'
                        )
                      }
                    >
                      <p>
                        {this.props.translate('overdue')}
                      </p>
                      <h3>{expired && expired.count || 0}</h3>
                    </div>
                  </>)}
                  </div>
                </div>
              )}
              {/* </>} */}
              {this.state.deviceSelector ? (
              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 10
                }}
              >
                <MaintenanceFilterModal readOnly rows={8} filterType={this.state.filterType} statusType={this.state.statusType || ''}/>
              </div>
                ) : null}
            </div>
        </Grid>
      </Grid>

                    
          {/* <Grid item xs={12} >
            <div
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
                height: '100%'
              }}
            >
                <h3
                  style={{
                    fontSize: 21,
                    fontWeight: 600,
                    // textAlign: 'center',
                    marginBottom: 5,
                    marginTop: 0
                  }}
                >
                  {(scheduled&&scheduled.cost || 0)+(completed&&completed.cost || 0)+(required&&required.cost || 0)+(expired&&expired.cost || 0)}
                  <span style={{ fontWeight: 'normal', fontSize: 18, fontStyle: 'italic', marginLeft: 5 }}>{(this.props.currencyLabel||'')}</span> <span className='alt-title'>{this.props.translate('cost')}</span>

                </h3>

                <Scrollbar
                      disableTracksWidthCompensation
                      style={{
                        height: 20 * 8
                      }}
                    >
                   {this.props.analyticData ? (
                    <MotionList
                      data={this.props.analyticData}
                      showSelectedUnits={this.openMotionSelector}
                      translate={this.props.translate}
                      currencyLabel={this.props.currencyLabel}
                    />
                  ) : (
                    <BarGraphSkeleton2 />
                  )}
                  </Scrollbar>
            </div>
          </Grid> */}
        </>
    )
  }
}
function BarGraphSkeleton2 () {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
    </div>
  )
}
const MotionList = ({ data, className, translate,currencyLabel }) => {
  if(data.completed){
    const html = 
    <div className='maintenance-motion-list'>
      <div
        className={'motion-status-bar hover ' + className}
        style={{ borderRadius: 0 }}
      >
        <span style={{ background: '#566374' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('reminder')} <span className='text-lowercase'>{translate('work')}</span></span>
        <span className='motion-status-count'>{data.reminder.cost}</span>
        <span className='motion-status-currency'>{(currencyLabel||'')}</span>
      </div>
      
      <div
        className={'motion-status-bar hover ' + className}
        style={{ borderRadius: 0 }}
      >
        <span style={{ background: '#1919FF' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('schedule')} <span className='text-lowercase'>{translate('work')}</span></span>
        <span className='motion-status-count'>{data.scheduled.cost}</span>
        <span className='motion-status-currency'>{(currencyLabel||'')}</span>
      </div>

      {checkPrivileges('service') && (<><div
        className={'motion-status-bar hover ' + className}
        style={{ borderRadius: 0 }}
      >
        <span style={{ background: '#28a745' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('completed')} <span className='text-lowercase'>{translate('work')}</span></span>
        <span className='motion-status-count'>{data.completed.cost}</span>
        <span className='motion-status-currency'>{(currencyLabel||'')}</span>
      </div>
      <div
        className={'motion-status-bar hover ' + className}
        style={{ borderRadius: 0 }}
      >
        <span style={{ background: '#F7A35C' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'> {translate('due')} <span className='text-lowercase'>{translate('work')}</span></span>
        <span className='motion-status-count'>{data.required.cost}</span>
        <span className='motion-status-currency'>{(currencyLabel||'')}</span>
      </div></>)}
      {/* <div
        className={'motion-status-bar hover ' + className}
        style={{ borderRadius: 0 }}
      >
        <span style={{ background: '#dc3545' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'> {translate('overdue')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.expired.cost}</span>
        <span className='motion-status-currency'>{(currencyLabel||'')}</span>
      </div> */}
    </div>
  return <div>{html}</div>
  }
  return null
  
}
const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps((ListRow1))
export default mapStateToProps(Maintenance)

  const Filter = (props) => {
    const html = <div>
    <a
    href={null}
    aria-owns={props.anchorEl ? 'simple-menu' : undefined}
    aria-haspopup='true'
    onClick={props.handleClickFilter}
  >
    <p className='v-tab-heading pull-left' style={{width: 225}}>{props.translate('selected')}{": "}{props.countChecked}</p>
    <ArrowDropDownIcon
    style={{color: '#FFFFFF'}}
    />
  </a>
    <Menu
    id='simple-menu'
    anchorEl={props.anchorEl}
    open={Boolean(props.anchorEl)}
    onClose={props.handleCloseFilter}
  >
    {/* <MenuItem component={FormControlLabel} style={{margin: 0, paddingTop: 0, paddingBottom: 0}}
        control={<Checkbox 
          name='Completed'
          checked={props.statusFilter.history}
          onChange={e=>props.handleChangeFilter('history',e)} 
          canAssign
          canRemove
        />}
        label={props.translate('completed')}
      /> */}
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Required' 
          checked={props.statusFilter.required}
          onChange={e=>props.handleChangeFilter('required',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('due')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Expired' 
          checked={props.statusFilter.expired}
          onChange={e=>props.handleChangeFilter('expired',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('overdue')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Completed' 
          checked={props.statusFilter.completed}
          onChange={e=>props.handleChangeFilter('completed',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('completed')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Scheduled' 
          checked={props.statusFilter.scheduled}
          onChange={e=>props.handleChangeFilter('scheduled',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('scheduled')}</span>}
      />
  </Menu></div>
  return <div className="v-filter-field">{html}</div>
  }