  import React, { Component, Fragment } from 'react'
  import Button from '../../../Components/common/Button'
  import CustomDialog from '../../../Components/common/Dialog'
  import { connect } from 'react-redux'
  import Ignition from './ignition'
  import OtherSensors from './otherSensors'
  import AggregateSensors from './aggregateSensors'
  import isEqual from 'react-fast-compare'
  import Tooltip from '@mui/material/Tooltip'
  import EditIcon from '@mui/icons-material/Edit'
  import FileCopyIcon  from '@mui/icons-material/FileCopy'
  import DeleteIcon from '@mui/icons-material/Delete'
  import { toast } from 'react-toastify';
  import parse from 'html-react-parser';  import {
    updateComputedAttributeDeviceWise,
    RemoveComputedAttributeDeviceWise
  } from './../../../Actions/ComputedAttributes'
  import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
  import { ReactComponent as DiscreteIcon } from '../../../assets/sensors/discrete.svg'
  import { ReactComponent as MeasurementIcon } from '../../../assets/sensors/centimeter.svg'
  import { ReactComponent as OtherSensorIcon } from '../../../assets/sensors/sensor.svg'
  import { ReactComponent as AggregateIcon } from '../../../assets/sensors/chip.svg'
  import { ReactComponent as DecoderIcon } from '../../../assets/sensors/decoder.svg'
  import Measurement from './measurement'
  import { checkPrivileges, errorHandler } from '../../../Helpers/index'
  import DecoderSensors from './decoderSensors'
  import Table from '../../../Components/common/TableMultiCheckbox'
  import instance from '../../../axios'
import { notifySuccess } from '../../../Utils/CustomNotifcations'

  class Sensors extends Component {
    constructor (props) {
      super(props)
      this.state = {
        isEnableBtn: true,
        selecteItem: '',
        scale: {},
        modal: '',
        isVisableMeasurementBtn: false,
        isVisableDiscreteBtn: false,
        isVisableOthersBtn: false,
        isVisableAggregateBtn: false,
        isVisableDecoderBtn: false,
        form: {
          attribute:'',
          attributes: {
            minThreshold: 0,
            copyFromLast: false,
            applyInverse:false,
            decodeToDecimal: false,
            resetDriver: 'disabled',
            allowMinMax: true
          }
        },
        operation: '',
        isSensorError: false,
        addButton: 'sharedSave',
        defaultData: '',
        showDialg:false,
        copySensor:{},
      }
      this.handleChange = this.handleChange.bind(this)
      this.changeAttribute = this.changeAttribute.bind(this)
      this.updateComputedAttributes = this.updateComputedAttributes.bind(this)
      this.addComputedAttributes = this.addComputedAttributes.bind(this)
    }

    componentWillMount () {
      if (
        this.props.positions.length &&
        parseInt(this.props.selectedDeviceId) !== parseInt(this.state.selecteItem)
      ) {
        this.setState({
          selecteItem: this.props.selectedDeviceId
        })
      }
    }

    componentWillReceiveProps (n, NextState) {
      if (
        n.positions.length &&
        parseInt(n.selectedDeviceId) !== parseInt(this.state.selecteItem)
      ) {
        this.setState({
          selecteItem: n.selectedDeviceId
        })
      }
    }
    openDialog = type => {
      if (type === 'measurement') {
        this.setState({
          modal: type,
          addButton: 'sharedSave',
          operation: '',
          isVisableMeasurementBtn: false,
          form: {
            attributes: {
              input: '',
              copyFromLast: false,
              resetDriver: 'disabled'
            }
          }
        })
      } else if (type === 'others') {
        this.setState({
          modal: type,
          addButton: 'sharedSave',
          operation: '',
          isVisableOthersBtn: false,
          copyFromLast: false,
          resetDriver: 'disabled',
          form: {
            attributes: {
              input: '',
              copyFromLast: false,
              decodeToDecimal: false,
              resetDriver: 'disabled'
            }
          }
        })
      } else if (type === 'aggregate') {
        this.setState({
          modal: type,
          addButton: 'sharedSave',
          operation: '',
          isVisableAggregateBtn: false,
          form: {
            attribute:'',
            copyFromLast: false,
            resetDriver: 'disabled',
            attributes: {
              minThreshold: 0,
              copyFromLast: false,
              decodeToDecimal: false,
              resetDriver: 'disabled'
            }
          }
        })
      } else if (type === 'decoder') {
        this.setState({
          modal: type,
          addButton: 'sharedSave',
          operation: '',
          isVisableDecoderBtn: false,
          form: {
            attribute: '',
            input: '',
              copyFromLast: false,
              valueFrom: "",
              arithmeticOpp: "",
              arithmeticArgument: "",
              "1stConv": "",
              "1stLittleEndian": true,
              "1stFullValue": true,
              "1stStartBit": "",
              "1stEndBit": "",
              "2ndConv": "",
              "2ndLittleEndian": true,
              "2ndFullValue": true,
              "need2ndConv": false,
              "2ndStartBit": "",
              "2ndEndBit": "",
            attributes: {
              input: '',
              copyFromLast: false,
              valueFrom: "",
              arithmeticOpp: "",
              arithmeticArgument: "",
              "1stConv": "",
              "1stLittleEndian": true,
              "1stFullValue": true,
              "1stStartBit": "",
              "1stEndBit": "",
              "2ndConv": "",
              "2ndLittleEndian": true,
              "2ndFullValue": true,
              "need2ndConv": false,
              "2ndStartBit": "",
              "2ndEndBit": ""
            }
          }
        })
      } else {
        this.setState({
          modal: type,
          addButton: 'sharedSave',
          operation: '',
          isVisableDiscreteBtn: false,
          form: {
            copyFromLast: false,
            applyInverse:false,
            resetDriver: 'disabled',
            attributes: {
              minThreshold: 0,
              copyFromLast: false,
              decodeToDecimal: false,
              resetDriver: 'disabled'
            }
          }
        })
      }
    }
    onClose = () => {
      this.setState({ modal: '' })
    }

    handleChange (name, event) {
      if (name === 'sensorType' && this.state.modal === 'measurement') {
        if (this.state.form[name] !== event.target.value) {
          let unit = ''
          if (event.target.value === 'fuel') {
            unit = 'L'
          } else if (event.target.value === 'temperature') {
            unit = '℃'
          } else if (event.target.value === 'rpm') {
            unit = 'rpm'
          } else if (
            event.target.value === 'averagFuelConsumption' ||
            event.target.value === 'instantFuelConsumption' ||
            event.target.value === 'mileage'
          ) {
            unit = 'L/100km'
          } else if (
            event.target.value === 'boardPower' ||
            event.target.value === 'humidity' ||
            event.target.value === 'counter'
          ) {
            unit = 'custom'
          } else if (event.target.value === 'speed') {
            unit = 'km/h'
          } else if (event.target.value === 'flowMeter') {
            unit = 'L'
          } else if (event.target.value === 'custom') {
            unit = 'custom'
          }
          let filterdataForm = this.state.form
          delete filterdataForm['accuracy']
          delete filterdataForm['customUnit']
          delete filterdataForm['drainByTime']
          delete filterdataForm['drainByDistance']
          let filterdata = this.state.form.attributes
          delete filterdata['accuracy']
          delete filterdata['customUnit']
          delete filterdata['drainByTime']
          delete filterdata['drainByDistance']
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.target && event.target.value,
                attributes: {
                  ...this.state.form.attributes,
                  unit: unit,
                  [name]: event.target && event.target.value
                },
                unit
              }
            },
            () => this.checkVisablity()
          )
        }
      } else if(name === 'attribute' && this.state.modal === 'decoder'){
        const urlRegex = "^[a-zA-Z0-9]*$";
        if(event.target.value.match(urlRegex)){
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.target && event.target.value,
                attributes: { ...this.state.form.attributes }
              }
            },
            () => this.checkVisablity()
          )
        }
      }else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target?event.target.value:event,
              attributes: { ...this.state.form.attributes }
            }
          },
          () => this.checkVisablity()
        )
      }
    }
    checkVisablityOfSensor = (value, scale) => {
      if (scale) {
        this.setState(
          {
            isSensorError: value,
            scale
          },
          () => this.checkVisablity()
        )
      } else {
        this.setState(
          {
            isSensorError: value
          },
          () => this.checkVisablity()
        )
      }
    }
    checkVisablity = () => {
      const {
        modal,
        isSensorError,
        form,
        scale,
        defaultData,
        operation
      } = this.state
      let {
        attribute,
        description,
        input,
        unit,
        type,
        customUnit,
        attributes,
        category,
        check,
        expression,
        id,
        sensorType ,
        valueFrom,
        arithmeticOpp,
        arithmeticArgument,
        need2ndConv,
        '1stFullValue': firstFullValue,
        '2ndFullValue': secondFullValue,
        '1stConv': firstConv,
        '2ndConv': secondConv,
        '1stEndBit': firstEndBit,
        '2ndEndBit': secondEndBit,
        '1stStartBit': firstStartBit,
        '2ndStartBit': secondStartBit,
      } = form
      if (modal === 'discrete') {
        if (
          attribute &&
          attribute.trim().length > 0 &&
          description &&
          description.trim().length > 0 &&
          input &&
          input.trim().length > 0
        ) {
          if (operation === 'edit') {
            let obj = {
              attribute,
              attributes,
              category,
              check,
              description,
              expression,
              id
            }
            let changed = !isEqual(
              obj,
              defaultData || obj.attributes,
              defaultData.attributes
            )
            this.setState({
              isVisableDiscreteBtn: changed
            })
          } else {
            this.setState({
              isVisableDiscreteBtn: true
            })
          }
        } else {
          this.setState({
            isVisableDiscreteBtn: false
          })
        }
      } else if (modal === 'others') {
        if (
          attribute &&
          attribute.trim().length > 0 &&
          description &&
          description.trim().length > 0
        ) {
          if (operation === 'edit') {
            let obj = {
              attribute,
              attributes,
              type,
              category,
              check,
              description,
              expression,
              id
            }
            let changed = !isEqual(obj, defaultData)
            this.setState({
              isVisableOthersBtn: changed
            })
          } else {
            this.setState({
              isVisableOthersBtn: true
            })
          }
        } else {
          this.setState({
            isVisableOthersBtn: false
          })
        }
      } else if (modal === 'aggregate') {
        if (
          attribute &&
          attribute.trim().length > 0 &&
          description &&
          description.trim().length > 0
        ) {
          if (operation === 'edit') {
            let obj = {
              attribute,
              attributes,
              type,
              category,
              check,
              description,
              expression,
              id
            }
            let changed = !isEqual(obj, defaultData)
            this.setState({
              isVisableAggregateBtn: changed
            })
          } else {
            this.setState({
              isVisableAggregateBtn: true
            })
          }
        } else {
          this.setState({
            isVisableAggregateBtn: false
          })
        }
      }
      else if(modal === 'decoder') {
        this.setState({isVisableDecoderBtn: false})
        if(
          (""+description).trim().length > 0 &&
          valueFrom
        ) {
          if( description && input && valueFrom === 'decimal') {
            if(firstConv === 'decimal'){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }
            }else if(firstConv === 'binary' || firstConv === 'hexDecimal'){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv &&secondFullValue && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv &&secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && !secondFullValue && secondConv === 'decimal' && secondStartBit && secondEndBit){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && !secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal') && secondStartBit && secondEndBit){
                this.setState({isVisableDecoderBtn: true})
              }
            }
          }
          
          else if(description && input && (valueFrom === 'binary' || valueFrom === 'hexDecimal')){
            if(firstFullValue && firstConv === 'decimal'){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }
            }else if(firstFullValue && (firstConv === 'binary' || firstConv === 'hexDecimal') ){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }
            }else if(!firstFullValue && firstStartBit && firstEndBit && firstConv === 'decimal'){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }
            }else if(!firstFullValue && firstStartBit && firstEndBit && (firstConv === 'binary' || firstConv === 'hexDecimal')){
              if(!need2ndConv){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv &&secondFullValue && secondConv === 'decimal'){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv &&secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal')){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && !secondFullValue && secondConv === 'decimal' && secondStartBit && secondEndBit){
                this.setState({isVisableDecoderBtn: true})
              }else if(need2ndConv && !secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal') && secondStartBit && secondEndBit){
                this.setState({isVisableDecoderBtn: true})
              }
            }
          }
        }
      } 

      if (modal === 'measurement') {
        if (
          sensorType &&
          sensorType.trim() &&
          description &&
          description.trim() &&
          unit &&
          unit.trim() &&
          input &&
          input.trim() &&
          !isSensorError
        ) {
          let unitError = unit
            ? unit === 'custom' &&
              (!customUnit || (customUnit && !customUnit.trim().length))
            : true
          if (unit !== 'custom') {
            delete attributes.customUnit
          } else {
            attributes.customUnit = customUnit
          }
          if (attributes && !attributes.sensorType) {
            attributes.sensorType = attribute
          }
          let formObj = {
            attribute,
            type,
            attributes,
            category,
            description,
            expression,
            id,
            scale
          }
          this.setState({
            isVisableMeasurementBtn: !isEqual(formObj, defaultData) && !unitError
          })
        } else {
          this.setState({
            isVisableMeasurementBtn: false
          })
        }

        /*if (attribute && attribute.trim() && description && description.trim()
          && unit && unit.trim() && input && input.trim() && !isSensorError) {



          if (unit === 'custom' && customUnit && customUnit.trim().length > 0) {
            if (operation === 'edit') {
              let obj0 = { attribute, attributes, category, check, description, expression, id }
              let changed0 = !isEqual((obj0, defaultData) || (obj0.attributes, defaultData.attributes))
              let a0 = !isEqual(scale, defaultData.scale)
              changed0 = a0
              this.setState({
                isVisableMeasurementBtn: changed0
              })

            } else {

              this.setState({
                isVisableMeasurementBtn: true
              })
            }
          } else {
            if (operation === 'edit') {
              let obj1 = { attribute, attributes, category, check, description, expression, id }

              let changed1 = !isEqual((obj1, defaultData) || !isEqual(obj1.attributes, defaultData.attributes)) || !isEqual(scale, defaultData.scale)
              console.log(changed1)
              console.log('222')
              this.setState({
                isVisableMeasurementBtn: changed1
              })

            } else {

              this.setState({
                isVisableMeasurementBtn: false
              })
            }
          }
          if (unit !== 'custom') {
            if (operation === 'edit') {
              let obj2 = { attribute, attributes, category, check, description, expression, id, scale }
              let changed2 = !isEqual(obj2, defaultData || obj2.attributes, defaultData.attributes)
              console.log(changed2);
              let a2 = !isEqual(scale, defaultData.scale)
              changed2 = a2
              this.setState({
                isVisableMeasurementBtn: changed2
              })
            } else {
              this.setState({
                isVisableMeasurementBtn: true
              })
            }
          }

        } else {

          if (operation === 'edit' && !isSensorError) {
            let obj3 = { attribute, attributes, category, check, description, expression, id, scale }
            let changed3 = !isEqual(obj3, defaultData || obj3.attributes, defaultData.attributes)
            let a3 = !isEqual(scale, defaultData.scale)
            changed3 = a3
            this.setState({
              isVisableMeasurementBtn: changed3
            })

          } else {
            this.setState({
              isVisableMeasurementBtn: false
            })
          }
        }*/
      }
    }
    changeAttribute (name, value) {
      if (
        name === 'multiplier' ||
        name === 'minThreshold' ||
        name === 'maxThreshold' ||
        name === 'accuracy'
      ) {
        value = parseFloat(value)
      }

      if (this.state.modal === 'measurement') {
        if(name === 'allowMinMax'){
          this.setState({
            form:{
              ...this.state.form,
              attributes:{
                ...this.state.form.attributes,
                allowMinMax: !value
              },
              deviceId: this.props.deviceId
            }
          },() => this.checkVisablity() )
        }else{this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
              attributes: { ...this.state.form.attributes, [name]: value },
              deviceId: this.props.deviceId
            }
          },
          () => this.checkVisablity()
        )}
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
              attributes: { ...this.state.form.attributes, [name]: value },
              deviceId: this.props.deviceId
            }
          },

          () => this.checkVisablity()
        )
      }
    }

    save = () => {
      const { modal, scale } = this.state
      if (modal === 'measurement') {
        const { description, id, attributes, attribute } = this.state.form
        let scaleObj = {}
        if (scale && scale.length) {
          scale.map(item => {
            let key = item.x,
              value = item.y
            scaleObj = { ...scaleObj, [key]: value }
            return null
          })
        }
        if (Object.keys(scaleObj).length) {
          attributes.scale = scaleObj
        } else {
          attributes.scale = { '0': 0 }
        }
        let form = {
          attribute:
            attributes && attributes.sensorType
              ? attributes.sensorType + '' + (attributes.index || '')
              : attribute,
          description,
          attributes,
          type: 'number',
          expression: '',
          category: 2,
          id: id || 0
        }
        if(!attributes.allowMinMax){
          delete form.attributes.maxThreshold
          delete form.attributes.minThreshold
        }
        if (id) {
          form.id = id
          // method put
          this.updateComputedAttributes(this.props.deviceId, id, form)
        } else {
          this.addComputedAttributes(this.props.deviceId, form)
        }
      } else if (modal === 'others') {
        const {
          attribute,
          description,
          id,
          attributes
        } = this.state.form
        let form = {
          attribute,
          description,
          attributes,
          expression: '',
          type: 'string',
          category: 3,
          id: id || 0
        }
        if (id) {
          form.id = id
          // method put
          this.updateComputedAttributes(this.props.deviceId, id, form)
        } else {
          this.addComputedAttributes(this.props.deviceId, form)
        }
      } else if (modal === 'aggregate') {
        const {
          attribute,
          expression,
          description,
          id,
          attributes,
          type,
          expStart,
          expOpt,
          expEnd
        } = this.state.form
        let form = {
          attribute,
          description,
          attributes,
          expression: " "+(expStart || "") + " "+(expOpt || "") + " "+(expEnd || ""),
          type: type || 'string',
          category: 4,
          id: id || 0
        }
        if (id) {
          form.id = id
          // method put
          this.updateComputedAttributes(this.props.deviceId, id, form)
        } else {
          this.addComputedAttributes(this.props.deviceId, form)
        }
      }else if (modal === 'decoder') {
        const {
          attribute,
          description,
          id,
          attributes:{
            '1stEndBit': firstEndBit,
            '2ndEndBit': secondEndBit,
            '1stStartBit': firstStartBit,
            '2ndStartBit': secondStartBit,
            arithmeticArgument,
            ...attributes
          },
          type,
        } = this.state.form
        let form = {
          attribute,
          description,
          attributes:{
            ...attributes,
            '1stStartBit': Number(firstStartBit) ,
            '1stEndBit': Number(firstEndBit),
            '2ndStartBit': Number(secondStartBit),
            '2ndEndBit': Number(secondEndBit),
            arithmeticArgument: Number(arithmeticArgument)
          },
          expression: "",//+(expStart || "") + " "+(expOpt || "") + " "+(expEnd || ""),
          type: type || 'string',
          category: -1,
          id: id || 0
        }
        if (id) {
          form.id = id
          // method put
          this.updateComputedAttributes(this.props.deviceId, id, form)
        } else {
          this.addComputedAttributes(this.props.deviceId, form)
        }
      } else {
        const { attribute, description, id, attributes } = this.state.form
        let form = {
          // attribute,
          attribute: attribute === 'customDiscrete' ? attribute + '' + (attributes&&attributes.index || ''): attribute,
          description,
          attributes,
          type: 'boolean',
          expression: '',
          category: 1,
          id: id || 0
        }
        if (id) {
          form.id = id
          // method put
          this.updateComputedAttributes(this.props.deviceId, id, form)
        } else {
          this.addComputedAttributes(this.props.deviceId, form)
        }
      }
    }

    updateComputedAttributes (deviceId, id, form) {
      if (form.attribute !== 'driverUniqueId') delete form.attributes.resetDriver
      if (
        form.attributes &&
        form.attributes.resetDriver &&
        form.attributes.resetDriver === 'disabled'
      )
        delete form.attributes.resetDriver
      instance({
        url: `/api/sensors/${id}/${deviceId}`,
        method: 'PUT',
        data: {
          ...form
        }
      })
            .then(Attribute => {
              this.props.dispatch(updateComputedAttributeDeviceWise(Attribute))
              this.props.dispatch(
                notifySuccess("sensorIsUpdated")

      
              )

              this.setState({
                isVisableMeasurementBtn: false,
                isVisableDiscreteBtn: false,
                isVisableOthersBtn: false,
                isVisableAggregateBtn: false,
                isVisableDecoderBtn: false,
                modal: ''
              })
        })
        .catch(error => {errorHandler(error,this.props.dispatch,this.errorCallBack)})
    }
    errorCallBack = (data) => {
      this.setState({
            isVisableMeasurementBtn: false,
            isVisableDiscreteBtn: false,
            isVisableOthersBtn: false,
            isVisableAggregateBtn: false,
            isVisableDecoderBtn: false,
            modal: ''
          })
      }

    addComputedAttributes (deviceId, formData) {
      let form = formData;
      if (form.attribute !== 'driverUniqueId') delete form.attributes.resetDriver
      if (
        form.attributes &&
        form.attributes.resetDriver &&
        form.attributes.resetDriver === 'disabled'
      )delete form.attributes.resetDriver 
      if(form.attributes.valueFrom === 'decimal'){form.attributes['1stLittleEndian'] = false}
      if(form.attributes['1stConv'] === 'decimal'){form.attributes['2ndLittleEndian'] = false}
      instance({
        url: `/api/sensors/${deviceId}`,
        method: 'POST',
        data: {
          ...form
        }
      })
        .then(Attribute => {
          //this.props.dispatch(addComputedAttributeDeviceWiseSelectedList(Attribute));
          // this.props.dispatch(addComputedAttribute(Attribute));
          this.props.dispatch(
            notifySuccess("sensorAdded")
          )
          this.props.dispatch(updateComputedAttributeDeviceWise(Attribute))
          this.setState({
            isVisableMeasurementBtn: false,
            isVisableDiscreteBtn: false,
            isVisableOthersBtn: false,
            isVisableAggregateBtn: false,
            isVisableDecoderBtn: false,
            modal: ''
          })
        }).catch(error => {errorHandler(error,this.props.dispatch,this.errorCallBack)})
        // .catch(e => {
        //   this.setState({
        //     isVisableMeasurementBtn: false,
        //     isVisableDiscreteBtn: false,
        //     isVisableOthersBtn: false,
        //     isVisableAggregateBtn: false,
        //     isVisableDecoderBtn: false,
        //     modal: ''
        //   })
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate(
        //         'invalidParametersOrConstraintsViolation'
        //       ),
        //       autoDismiss: 10
        //     })
        //   )
          /* e.text().then(err => {
            this.setState({
              isVisableMeasurementBtn: false,
              isVisableDiscreteBtn: false,
              isVisableOthersBtn: false,
              isVisableAggregateBtn: false,
              isVisableDecoderBtn: false,
              modal: ''
            })
            if (err.includes('Invalid parameters or constraints violation')) {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(
                    'invalidParametersOrConstraintsViolation'
                  ),
                  autoDismiss: 10
                })
              )
            } else if (err.split('- IllegalArgumentException')) {
              let a = err.split('- IllegalArgumentException')
              if (a && a[0]) {
                this.props.dispatch(
                  Notifications.error({
                    message: a[0],
                    autoDismiss: 10
                  })
                )
              }
            }
          }) */
        // })
    }
    onEdit = (item, mood) => {
      let scales = []
      let att = item.attributes
      if (mood === 'measurement') {
        for (let key1 in att.scale) {
          let a = {
            x: '',
            y: '',
            e: false
          }
          a.x = parseFloat(key1)
          a.y = att.scale[key1]
          scales.push(a)
        }
        item['scale'] = JSON.parse(JSON.stringify(scales))
        if (item.scale.length === 0) {
          item.scale.push({ x: 0, y: 0, e: false })
          scales.push({ x: 0, y: 0, e: false })
        }
        if (!att.sensorType) {
          att.sensorType = item.attribute
        }
      }

      const ex = item.expression.trim().split(' ');
      const exp = {};
      if(ex && ex.length && ex.length === 3) {
        exp.expStart = ex[0]
        exp.expOpt = ex[1]
        exp.expEnd = ex[2]
      }

      this.setState({
        form: { ...item, ...exp, ...att },
        modal: mood,
        operation: 'edit',
        addButton: 'update',
        defaultData: item,
        scale: scales,
        isVisableDiscreteBtn: false,
        isVisableMeasurementBtn: false,
        isVisableAggregateBtn: false,
        isVisableDecoderBtn: false,
        isVisableOthersBtn: false
      })
    }
    onRemove = item => {
      this.setState({
        computedAttForm: item,
        onDeleteConfirmationAttributes: true
      })
    }
    removeComputedAttributes = () => {
      instance({
        url: `/api/attributes/computed/${this.state.computedAttForm.id}`,
        method: 'DELETE'
      })
        .then(response => {
            this.props.dispatch(
              RemoveComputedAttributeDeviceWise(this.state.computedAttForm)
            )
            this.props.dispatch(
             notifySuccess('attributeIsDelete')
            )
            this.setState({
              onDeleteConfirmationAttributes: false
            })
        }).catch(error => {errorHandler(error,this.props.dispatch)})
    }

    drawSensors = () => {
      let obj = {}
      this.props.computedAttributesDeviceWise.map((item, index) => {
        obj[item.category] = obj[item.category] || []
        obj[item.category].push(item)
        return null
      })

      let html = []
      Object.keys(obj).map(key => {
        if (parseInt(key) === 1) {
          html.push(
            <tr key={key + '_parent'} className='sensor_type_head'>
              <th colSpan={4} className='c_table_title'>
                {this.props.translate('discrete')}
              </th>
            </tr>
          )
          obj[key].map((i, k) => {
            html.push(
              <tr key={k + 'dis'}>
                <td style={{ width: '12%' }}>
                  {/* {this.props.translate('sensorsTranslation.' + i.attribute)} */}
                  {this.props.translate('sensorsTranslation.' + i.attribute)}
                </td>
                <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
                <td>{parse(i.description)}</td>
                <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={this.props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={e => this.props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                  {checkPrivileges('sensorUpdate')  &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('edit')}
                    >
                      <EditIcon
                        fontSize='small'
                        onClick={e => this.onEdit(i, 'discrete')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorDelete')  &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('delete')}
                    >
                      <DeleteIcon
                        fontSize='small'
                        onClick={e => this.onRemove(i)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            )

            return null
          })
        } else if (parseInt(key) === 2) {
          html.push(
            <tr key={key + '_parentmes'} className='sensor_type_head'>
              <th colSpan={4} className='c_table_title'>
                {this.props.translate('measurement')}
              </th>
            </tr>
          )
          obj[key].map((i, k) => {
            html.push(
              <tr key={i.id + 'mes'}>
                <td style={{ width: '12%' }}>
                  {i.attributes && i.attributes.sensorType
                    ? this.props.translate(
                        'sensorsTranslation.' + i.attributes.sensorType
                      )
                    : this.props.translate('sensorsTranslation.' + i.attribute)}
                </td>
                <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
                <td>{parse(i.description)}</td>
                <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={this.props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={e => this.props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                  {checkPrivileges('sensorUpdate')  &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('edit')}
                    >
                      <EditIcon
                        fontSize='small'
                        onClick={e => this.onEdit(i, 'measurement')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorDelete')  &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('delete')}
                    >
                      <DeleteIcon
                        fontSize='small'
                        onClick={e => this.onRemove(i)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            )

            return null
          })
        } else if (parseInt(key) === 3) {
          html.push(
            <tr key={key + '_parent'} className='sensor_type_head'>
              <th colSpan={4} className='c_table_title'>
                {this.props.translate('otherSensors')}
              </th>
            </tr>
          )
          obj[key].map((i, k) => {
            html.push(
              <tr key={k + 'mes'}>
                <td style={{ width: '12%' }}>
                  {this.props.translate('sensorsTranslation.' + i.attribute)}
                </td>
                <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
                <td>{parse(i.description)}</td>
                <td width='90' align='right'>
                  {checkPrivileges('sensorCreate') && (
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('copySensor')}
                    >
                      <FileCopyIcon
                        fontSize='small'
                        onClick={e => this.props.onCopy(i, 'copySensor')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorUpdate') &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('edit')}
                    >
                      <EditIcon
                        fontSize='small'
                        onClick={e => this.onEdit(i, 'others')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorDelete') && (
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('delete')}
                    >
                      <DeleteIcon
                        fontSize='small'
                        onClick={e => this.onRemove(i)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            )

            return null
          })
        } else if (parseInt(key) === 4) {
          html.push(
            <tr key={key + '_parent'} className='sensor_type_head'>
              <th colSpan={4} className='c_table_title'>
                {this.props.translate('aggregateSensors')}
              </th>
            </tr>
          )
          obj[key].map((i, k) => {
            html.push(
              <tr key={k + 'mes'}>
                <td style={{ width: '12%' }}>
                  {this.props.translate(i.attribute)}
                </td>
                <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
                <td>{parse(i.description)}</td>
                <td width='90' align='right'>
                  {checkPrivileges('sensorCreate') && (
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('copySensor')}
                    >
                      <FileCopyIcon
                        fontSize='small'
                        onClick={e => this.props.onCopy(i, 'copySensor')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorUpdate') &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('edit')}
                    >
                      <EditIcon
                        fontSize='small'
                        onClick={e => this.onEdit(i, 'aggregate')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorDelete') &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('delete')}
                    >
                      <DeleteIcon
                        fontSize='small'
                        onClick={e => this.onRemove(i)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            )

            return null
          })
        } else if (parseInt(key) === -1) {
          html.push(
            <tr key={key + '_parent'} className='sensor_type_head'>
              <th colSpan={4} className='c_table_title'>
                {this.props.translate('decoderSensors')}
              </th>
            </tr>
          )
          obj[key].map((i, k) => {
            html.push(
              <tr key={k + 'mes'}>
                <td style={{ width: '12%' }}>
                  {this.props.translate(i.attribute)}
                </td>
                <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
                <td>{parse(i.description)}</td>
                <td width='90' align='right'>
                  {checkPrivileges('sensorCreate') && (
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('copySensor')}
                    >
                      <FileCopyIcon
                        fontSize='small'
                        onClick={e => this.props.onCopy(i, 'copySensor')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorUpdate') &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('edit')}
                    >
                      <EditIcon
                        fontSize='small'
                        onClick={e => this.onEdit(i, 'decoder')}
                      />
                    </Tooltip>
                  )}
                  {checkPrivileges('sensorDelete') &&(
                    <Tooltip
                      classes={{
                        popper: 'menu-popper',
                        tooltip: 'menu-popper-tooltip'
                      }}
                      title={this.props.translate('delete')}
                    >
                      <DeleteIcon
                        fontSize='small'
                        onClick={e => this.onRemove(i)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            )

            return null
          })
        }
        return null
      })

      return (
        <table className='custom-table-1'>
          <tbody>{html}</tbody>
        </table>
      )
    }

    onCancel = () => {
      this.setState({
        onDeleteConfirmationAttributes: false
      })
    }
    render () {
      const keys = {
        discrete: "addDiscrete",
        measurement: "addMeasurement",
        others: "addBinders",
        aggregate: "aggregateSensors",
        decoder: "decoderSensors"
      }
      let { suggestions, computedAttributesDeviceWise } = this.props
      let sug = []
      if (computedAttributesDeviceWise.length) {
        suggestions.map(item => {
          let prsntvalue = false
          computedAttributesDeviceWise.map(item1 => {
            if (item === item1.attributes.input) {
              prsntvalue = true
            }
            return null
          })
          if (!prsntvalue) {
            sug.push(item)
          }
          return null
        })
      } else {
        sug = suggestions
      }
      return (
        <div style={{ width: '100%' }}>
          <div
            style={{ textAlign: 'right', padding: '10px 0' }}
            className='clearfix'
          >
            <h4 className='v-tab-heading pull-left with-border'>
              {this.props.translate(keys[this.state.modal] || 'sensors')}
            </h4>
          </div>
          <div
            style={{ width: '100%', boxSizing: 'border-box', padding: '0' }}
          >
            <div style={{ display: 'flex' }}>
            {this.state.modal === 'discrete' && (
              <div style={{flex: 1}}>
                <Ignition
                  {...this.props}
                  suggestions={sug}
                  handleChange={this.handleChange}
                  changeAttribute={this.changeAttribute}
                  {...this.state}
                />
                <div className='modal-buttons'>
                  <Button onClick={this.onClose} className="button-dark">
                    {this.props.translate('close')}
                  </Button>
                  <Button
                      disabled={!this.state.isVisableDiscreteBtn}
                      onClick={this.save}
                      className="button-dark"
                    >
                    {this.props.translate(this.state.addButton)}
                  </Button>
                </div>
              </div>
            )}
            {this.state.modal === 'measurement' && (
              <div style={{flex: 1}}>
                <Measurement
                  {...this.props}
                  suggestions={sug}
                  handleChange={this.handleChange}
                  changeAttribute={this.changeAttribute}
                  checkVisablity={this.checkVisablityOfSensor}
                  {...this.state}
                />
                <div className='modal-buttons'>
                  <Button onClick={this.onClose} className="button-dark">
                    {this.props.translate('close')}
                  </Button>
                  <Button
                      disabled={!this.state.isVisableMeasurementBtn}
                      onClick={this.save}
                      className="button-dark"
                    >
                    {this.props.translate(this.state.addButton)}
                  </Button>
                </div>
              </div>
            )}
            {this.state.modal === 'others' && (
              <div style={{ flex: 1 }}>
                <OtherSensors
                  {...this.props}
                  suggestions={sug}
                  handleChange={this.handleChange}
                  changeAttribute={this.changeAttribute}
                  {...this.state}
                />
                <div className='modal-buttons'>
                  <Button onClick={this.onClose} className="button-dark">
                    {this.props.translate('close')}
                  </Button>
                  <Button
                      disabled={!this.state.isVisableOthersBtn}
                      onClick={this.save}
                      className="button-dark"
                    >
                    {this.props.translate(this.state.addButton)}
                  </Button>
                </div>
              </div>
            )}
            {this.state.modal === 'aggregate' && (
              <div style={{ flex: 1 }}>
                <AggregateSensors
                  {...this.props}
                  suggestions={sug}
                  handleChange={this.handleChange}
                  changeAttribute={this.changeAttribute}
                  {...this.state}
                />
              <div className='modal-buttons'>
                  <Button onClick={this.onClose} className="button-dark">
                    {this.props.translate('close')}
                  </Button>
                  <Button
                      disabled={!this.state.isVisableAggregateBtn}
                      onClick={this.save}
                      className="button-dark"
                    >
                    {this.props.translate(this.state.addButton)}
                  </Button>
                </div>
              </div>
            )}
            { this.state.modal === 'decoder' && (
              <div style={{ flex: 1 }}>
                <DecoderSensors
                  {...this.props}
                  suggestions={sug}
                  handleChange={this.handleChange}
                  changeAttribute={this.changeAttribute}
                  {...this.state}
                />
                <div className='modal-buttons'>
                  <Button onClick={this.onClose} className="button-dark">
                    {this.props.translate('close')}
                  </Button>
                  <Button
                      disabled={!this.state.isVisableDecoderBtn}
                      onClick={this.save}
                      className="button-dark"
                    >
                    {this.props.translate(this.state.addButton)}
                  </Button>
                </div>
              </div>
            )}
            {this.props.computedAttributesDeviceWise.length > 0 && !this.state.modal && (
              <div style={{ flex: 1 }}>{this.drawSensors()}</div>
            )}

              {this.props.showDialg && (
                  <CustomDialog
                      title ='Devices'
                      themecolors={this.props.themecolors}
                      visable={true}
                      onClose={this.props.closeDialog}
                      bodyPadding={10}
                      isVisableBtn
                      noFullScreen
                      headerActions={
                        <Button
                          disabled={!(this.props.sensorDeviceids && this.props.sensorDeviceids.length)}
                          onClick={this.props.submitCopySenors}
                        >
                          {this.props.translate('copySensor')}
                        </Button>
                      }
                        >
                        <div>
                          <Table
                              insideDialog
                              disableHead
                              showCheckbox= {true}
                              showGroupAccess={this.props.showGroupAccess}
                              checkHandleChange={this.props.Devices_Selection|| ''}
                              rows={this.props?.allDevices || []}
                              componentData={this.props?.allDevices || []}
                              isEditable
                              canRemove={true}
                              canAssign={true}
                              className='custom-table2'
                              rowsPerPage={this.props.rowsPerPage}
                              handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                              selectAll={this.props.selectAllCopySensors}
                              copySensor
                              searchable
                              rowDefinition={[
                                {
                                  id: 'label',
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate('sharedName')
                                },
                                {
                                  id: 'category',
                                  numeric: false,
                                  disablePadding: false,
                                  label: this.props.translate('category')
                                }
                              ]}
                              />
                          </div>
                        </CustomDialog>
              )}

              {this.props.openResponseDialg && (
                <CustomDialog
                    title ='Devices'
                    themecolors={this.props.themecolors}
                    visable={true}
                    onClose={this.props.closeDialog}
                    bodyPadding={10}
                    isVisableBtn
                    noFullScreen
                      >
                      <div>
                      <Table
                          insideDialog
                          disableHead
                          // showCheckbox= {true}
                          showGroupAccess={this.props.showGroupAccess}
                          rows={this.props?.copiedData || []}
                          componentData={this.props?.copiedData || []}
                          isEditable
                          // canRemove={true}
                          // canAssign={true}
                          className='custom-table2'
                          rowsPerPage={this.props.rowsPerPage}
                          handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                          // selectAll={this.props.selectAll}
                          rowDefinition={[
                            {
                              id: 'label',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('sharedName')
                            },
                            {
                              id: 'message',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('message')
                            }
                          ]}
                          />
                        </div>
                </CustomDialog>
              )}

            {checkPrivileges('sensorCreate') && (
              <div className='sensors-buttons'>
                <Button
                  disabled={!this.state.isEnableBtn}
                  onClick={e => this.openDialog('discrete')}
                  className={'button-with-icon button-dark '+(this.state.modal === 'discrete' ? 'active' : '')}
                >
                  <DiscreteIcon fill='currentColor' width={40} height={40} />
                  {this.props.translate('addDiscrete')}
                </Button>
                <Button
                  disabled={!this.state.isEnableBtn}
                  onClick={e => this.openDialog('measurement')}
                  className={'button-with-icon button-dark '+(this.state.modal === 'measurement' ? 'active' : '')}
                >
                  <MeasurementIcon fill='currentColor' width={40} height={40} />
                  {this.props.translate('addMeasurement')}
                </Button>
                <Button
                  disabled={!this.state.isEnableBtn}
                  onClick={e => this.openDialog('others')}
                  className={'button-with-icon button-dark '+(this.state.modal === 'others' ? 'active' : '')}
                >
                  <OtherSensorIcon fill='currentColor' width={40} height={40} />

                  {this.props.translate('addBinders')}
                </Button>
                <Button
                  disabled={!this.state.isEnableBtn}
                  onClick={e => this.openDialog('aggregate')}
                  className={'button-with-icon button-dark '+(this.state.modal === 'aggregate' ? 'active' : '')}
                >
                  <AggregateIcon fill='currentColor' width={40} height={40} />
                  {this.props.translate('aggregateSensors')}
                </Button>
                <Button
                  disabled={!this.state.isEnableBtn}
                  onClick={e => this.openDialog('decoder')}
                  className={'button-with-icon button-dark '+(this.state.modal === 'decoder' ? 'active' : '')}
                >
                  <DecoderIcon fill='currentColor' width={40} height={40} />
                  {this.props.translate('decoderSensors')}
                </Button>
              </div>
            )}
            </div>
            {this.state.onDeleteConfirmationAttributes && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removeComputedAttributes}
                title={this.props.translate('areYouWantToDelete')}
                children={this.props.translate(
                  this.state.computedAttForm.description
                )}
              />
            )}

          
            
          </div>
        </div>
      )
    }
  }

  const mapState = state => ({
    positions: state.positions,
    computedAttributes: state.computedAttributes,
    computedAttributesDeviceWise: state.computedAttributesDeviceWise
  })
  const mapStateToProps = connect(mapState)
  export default mapStateToProps(Sensors)
