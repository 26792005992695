export const getTypes = types => ({
    type: 'GET_TYPES',
    types
});
export const addTypes = types => ({
    type: 'ADD_TYPES',
    types
});
export const removedTypes = types => ({
    type: 'REMOVED_TYPES',
    types
});

export const serviceUsersWiseSelectedList = services => ({
    type: 'SERVICE_USER_WISE_SELECTED_LIST',
    services
});
export const updateServiceUsersWiseSelectedList = services => ({
    type: 'UPDATE_SERVICE_USER_WISE_SELECTED_LIST',
    services
});
export const addServicesUsersWiseSelectedList = services => ({
    type: 'ADD_TEMPLATE_SERVICE_WISE_SELECTED_LIST',
    services
});
export const removeServiceUsersWiseSelectedList = services => ({
    type: 'REMOVED_TEMPLATE_SERVICE_WISE_SELECTED_LIST',
    services
});