function parepareResponsePOI (arr, old) {
  var gf = []

  arr.map(item => {
    var circle = []
    var polygon = []
    var polyline = []

    const d = old.find(e => e.id === item.id)

    const common = {
      ...item,
      visible: d && d.visible !== undefined ? d.visible : false,
      attributes: { ...item.attributes, latlng: [] }
    }

    polyline = item.area.split('LINESTRING ')

    if (polyline.length > 1) {
      var p = polyline[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
        .split(', ')
      let positions = []
      if (p.length) {
        p.map(pos => {
          positions.push(pos.split(' '))
          return null
        })
      }

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polyline',
          latlng: positions
        }
      })
    }

    circle = item.area.split('CIRCLE')

    if (circle.length > 1) {
      var c = circle[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
      let a = c.split(', ')
      let b = a[0].split(' ')

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'circle',
          latlng: { lat: b[0], lng: b[1] },
          radius: parseFloat(a[1])
        }
      })
    }

    polygon = item.area.split('POLYGON')

    if (polygon.length > 1) {
      var poly = {
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polygon'
        }
      }

      let a = polygon[1].replace('((', '').replace('))', '')
      let b = a.split(', ')

      b.forEach(obj => {
        c = obj.split(' ')
        poly.attributes.latlng.push([parseFloat(c[0]), parseFloat(c[1])])
      })

      gf.push(poly)
    }

    return item
  })
  return gf
}

export const POI = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    // Reducer for UPDATE_POI
    case 'UPDATE_POI':
      const POI = []

      state.map(geo => {
        if (action.POI.id === geo.id) {
          POI.push(action.POI)
        } else {
          POI.push(geo)
        }
        return ''
      })

      return POI

    case 'DELETE_POI':
      var array = []
      state.map((POI, index) => {
        if (POI.id !== action.POI.id) {
          array.push(POI)
        }
        return ''
      })

      return array

    case 'ADD_POI':
      return state.concat(parepareResponsePOI(action.POI, state))

    case 'GET_POI':
      return parepareResponsePOI(action.POI, state)

    case 'POI_VISIBLE':
      return state.map(obj => {
        if (obj['id'] === action.payload.id) {
          return {
            ...obj,
            visible: action.payload.visible || false
          }
        } else {
          return obj
        }
      })

    default:
      return state
  }
}
