import React from 'react'
import { connect } from 'react-redux'
;
import Modal from './modal'
import {isEmpty} from "lodash"

class screen2 extends React.Component {
  constructor () {
    super()
    this.state = {}
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    let { wizardData } = this.props
    if (wizardData && wizardData.form2) {
      let geoLenght = this.checkForLogic(wizardData.form2.form.geoFences)
      this.setState({
        ...wizardData.form2,
        showLogicFields: geoLenght.length > 1 ? true : false
      })
    }
  }

  handleChange = (name, event, subField, inputType) => {
    if (name === 'type' || name === 'input') {
      if (subField) {
        if (subField === 'ecodriving') {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event,
                input: inputType
              }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: inputType+this.state.form.index,
                type: event
              }
            },
            () => this.checkRequiredFields()
          )
        }
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event
            }
          },
          () => {
            if (event === 'deviceStopped') {
              this.setState(
                {
                  form: {
                    ...this.state.form,
                    threshold: 5
                  }
                },
                () => this.checkRequiredFields()
              )
            } else if (event === 'deviceIdling') {
              this.setState(
                {
                  form: {
                    ...this.state.form,
                    threshold: 10
                  }
                },
                () => this.checkRequiredFields()
              )
            } else if (event === 'deviceParking') {
              this.setState(
                {
                  form: {
                    ...this.state.form,
                    threshold: 24
                  }
                },
                () => this.checkRequiredFields()
              )
            } else if (event === 'deviceOverspeed') {
              this.setState(
                {
                  form: {
                    ...this.state.form,
                    tolerance: 0.1,
                    minLimit: 60,
                    maxLimit: 300
                  }
                },
                () => this.checkRequiredFields()
              )
            } else if (
              event === 'deviceEnterGeofence' ||
              event === 'deviceExitGeofence'
            ) {
              this.setState(
                {
                  form: {
                    ...this.state.form,
                    logic: ''
                  }
                },
                () => this.checkRequiredFields()
              )
            } else {
              this.checkRequiredFields()
            }
          }
        )
      }
    } 
    else if(name === 'logic'){
      this.setState(
        {
          form: {
            ...this.state.form,
            logic: event.target.value,
          }
        },
        () => this.checkRequiredFields()
      )
    }
    else if (inputType === 'exactValue'){
      this.setState(
        {
          form: {
            ...this.state.form,
            type:inputType
          }
        },
        () => this.checkRequiredFields()
      )
    }
    else if(name === 'valueType'){
      this.setState(
        {
          form: {
            ...this.state.form,
            valueType:event,
            value:'' 

          }
        },
        () => this.checkRequiredFields()
      )
    }
    else if (inputType === 'parkingSecurity' || name === "startTime" ||name === "endTime" || name === 'fenceRadius'
      || name === 'ps_plateNumber'
      || name === 'ps_trafficFileNumber'
      || name === 'ps_brand'
      || name === 'ps_model'
      || name === 'ps_color'
      || name === 'ps_state'){
     
      if (name === 'startTime' || name === 'endTime' || name ==='fenceRadius' || name === 'ps_plateNumber'
        || name === 'ps_trafficFileNumber'
        || name === 'ps_brand'
        || name === 'ps_model'
        || name === 'ps_color'
        || name === 'ps_state') {
          let val = ''
          if(name === 'endTime' || name === 'startTime'){
              val = event.target.value
          }
          else{
            val = name ==='fenceRadius'? parseInt(event) : event
          }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]:val
            }
          },
          () => this.checkRequiredFields()
        )
      } 
      if(event === 'parkingSecurity') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  type:event,
                }
              },
              () => this.checkRequiredFields()
            )
        }
    }
    else if(name === 'speedRange') {
      if(event && event.length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              minLimit: event[0],
              maxLimit: event[1]
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    else {
      const { target } = event
      if (target && target.type === 'checkbox') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: target.checked
            }
          },
          () => this.checkRequiredFields()
        )
      } 
      else if (name === 'expiration'){
          this.setState({
            form:{
              ...this.state.form,
              type:event
            }
          })
      }
      else if(name === 'threshold' || name==='minLimit' || name === 'maxLimit' || name==='tolerance'){
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value
            }
          },
          () => this.checkRequiredFields()
        )
      }
      else if (name === 'functionalType') {
        if (name === 'functionalType') {
          if (event !== this.state.form[name]) {
            let value = event
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: value,
                  index: ""
                }
              },
              () => {
                if (value === 'digital') {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        type: '',
                        logic: '',
                        input: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                } else if (value === 'analog') {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        type: '',
                        minLimit: 0,
                        maxLimit: 10,
                        input: '',
                        logic: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                } else if (value === 'ecodriving') {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        type: '',
                        minLimit: 0.1,
                        maxLimit: 0.1,
                        tolerance: 0.1,
                        input: '',
                        logic: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                } else if (value === 'fuel') {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        input: '',
                        type: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                } 
                // else if (value === 'maintenanceReminder') {
                //   this.setState(
                //     {
                //       form: {
                //         ...this.state.form,
                //         type: 'deviceMaintenanceReminder',
                //         minLimit: '',
                //         maxLimit: ''
                //       }
                //     },
                //     () => this.checkRequiredFields()
                //   )
                // } 
                else if (value === 'output') {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        type: '',
                        input: '',
                        logic: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                } else {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        type: '',
                        input: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )
                }
              }
            )
          }
        }
      } else if(name === 'index') {
        if(Types && this.state.form && this.state.form.functionalType && this.state.form.type) {
          const currentRow = Types[this.state.form.functionalType].find(row => row.value === this.state.form.type)
          
          this.setState(
            {
              form: {
                ...this.state.form,
                input: ((currentRow && currentRow.input) || this.state.form.type) +(target.value || ""),
                [name]: target.value
              }
            },
            () => {
              this.checkRequiredFields()}
          )
        }
      } else {
        if(this.state.form.type ==="exactValue" && this.state.form.valueType){
          let {valueType} = this.state.form
          let findBool = false
          if(valueType==='boolean'){
              findBool = (event.toLowerCase() === 'true'); // true
            }
          this.setState(
            {
              form: {
                ...this.state.form,
                value:valueType==='number'?parseInt(event):valueType==='boolean'? findBool : event.toLocaleString() 
              }
            },
            () => this.checkRequiredFields()
          )
        }
        else{
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event
              }
            },
            () => this.checkRequiredFields()
          )
        }
      }
    }
  }

  onChangedMaintence = item => {
    this.setState(
      {
        form: {
          ...this.state.form,
          maintenanceId: item && item.id ? item.id : '',
          maintenanceData: item || ''
        }
      },
      () => this.checkRequiredFields()
    )
  }
  geofencesHandelChange = groFeneList => {
    let geoLenght = this.checkForLogic(groFeneList)
    let showFiels = false,
      logicdata = ''
    if (geoLenght.length > 1) {
      showFiels = true
      logicdata = this.state.form.logic
    } else {
      showFiels = false
      logicdata = ''
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          geoFences: groFeneList,
          logic: logicdata
        },
        showLogicFields: showFiels
      },
      () => this.checkRequiredFields()
    )
  }
  checkRequiredFieldsForFristForm = (data, fristFormChanges) => {
    this.setState(
      {
        formFirstData: data,
        formFirstChanges: fristFormChanges
      },
      () => this.checkRequiredFields()
    )
  }
  checkRequiredFields () {
    let { formFirstData, formFirstChanges } = this.state
    let { form1, form2 } = this.props.wizardData
    let {
      type,
      functionalType,
      tolerance,
      minLimit,
      maxLimit,
      geoFences,
      logic,
      threshold,
      input,
      endTime,
      startTime,
      fenceRadius,
      valueType,
    } = this.state.form
    let value = true,
      finalVailadtion = false
    if (functionalType) {
      if (functionalType === 'deviceStatus' || functionalType === 'fuel') {
        if (type) {
          finalVailadtion = true
        } else {
          finalVailadtion = false
        }
      } else if (functionalType === 'ecodriving') {
        if (
          type &&
          tolerance &&
          (minLimit || minLimit === 0) &&
          (maxLimit || maxLimit === 0)
        ) {
          finalVailadtion = true
        } else {
          finalVailadtion = false
        }
      } else if (functionalType === 'digital' && input && logic) {
        finalVailadtion = true
      } else if (
        functionalType === 'analog' &&
        input &&
        logic &&
        (minLimit || minLimit === 0) &&
        maxLimit
      ) {
        finalVailadtion = true
      } else if (functionalType === 'other' && input) {
        finalVailadtion = true
      } else if (functionalType === 'output' && input && logic) {
        finalVailadtion = true
      } 
      // else if (
      //   functionalType === 'maintenanceReminder' &&
      //   maintenanceId &&
      //   (minLimit || minLimit === 0) &&
      //   maxLimit
      // ) {
      //   finalVailadtion = true
      // } 
      else if (type) {
        if (type === 'deviceOverspeed') {
          if (tolerance && (minLimit || minLimit === 0)) {
            finalVailadtion = true
          } else {
            finalVailadtion = false
          }
        } else if (type === 'deviceMoving' || type === 'deviceTowing') {
          finalVailadtion = true
        } else if (
          type === 'deviceStopped' ||
          type === 'deviceParking' ||
          type === 'deviceIdling'
        ) {
          if (threshold) {
            finalVailadtion = true
          } else {
            finalVailadtion = false
          }
        } else if (
          type === 'deviceEnterGeofence' ||
          type === 'deviceExitGeofence'
        ) {
          if (geoFences.length) {
            let chdata = this.checkForLogic(geoFences)
            if (chdata.length > 1) {
              if (logic) {
                finalVailadtion = true
              } else {
                finalVailadtion = false
              }
            } else if (chdata.length === 0) {
              finalVailadtion = false
            } else {
              finalVailadtion = true
            }
          } else {
            finalVailadtion = false
          }
        }
      } else {
        finalVailadtion = false
      }
    }
    // if (functionalType && functionalType === 'maintenanceReminder') {
    //   if (value && finalVailadtion) {
    //     this.setState({
    //       validatedForm: true
    //     })
    //   } else {
    //     this.setState({
    //       validatedForm: false
    //     })
    //   }
    // } else {
      if (
        value &&
        finalVailadtion &&
        ((formFirstData && formFirstData.validatedForm) || !formFirstChanges)
      ) {
        this.setState({
          validatedForm:  true
        })
      } else {
        if (functionalType === "expiration") {
          if(formFirstData?.validatedForm){
              this.setState({
                validatedForm: true
              })
          }
        }
      else if (functionalType === "customAlert") {
          let deviceIdsTye = typeof (formFirstData?.form?.deviceIds)
          let chck = form1?.form?.deviceIds !== formFirstData?.form?.deviceIds
          let chck2 = form2&&form2.form !== this.state.form
          if((this.props.editOption) && (chck || chck2)){
            this.setState({
              validatedForm: true
            })
          } 
           else if(!this.props.editOption && formFirstData?.form?.deviceIds && fenceRadius && startTime && endTime){
             if(deviceIdsTye === 'object'){
                if(!isEmpty(formFirstData?.form?.deviceIds)){
                  this.setState({
                    validatedForm: true
                  })
                }
             }
          }
      }
      // else{
      //   this.setState({
      //     validatedForm: false
      //   })
      // }
      
      else if (functionalType=== "otherAlert"){
           let deviceIdsCheck = formFirstData&&formFirstData.form&&formFirstData.form.deviceIds&&formFirstData.form.deviceIds.length
          if(type && valueType && (this.state.form&&this.state.form.value) && (deviceIdsCheck>0)){
              this.setState({validatedForm:true})
          }
          else this.setState({validatedForm:false})
         }
      }
    // }
  }
  checkForLogic = list => {
    let array = []
    if (list.length) {
      list.map(item => {
        if (item.visible) {
          array.push(item.id)
        }
        return null
      })
    }
    return array
  }
  next = () => {
    let FnData = []
    FnData.push({ ...this.state, prv: true })
    FnData.push({ ...this.state.formFirstData })
    this.props.combineAllData('form2', FnData, '', true)
  }

  render () {
    let assignData = ''
    let { mode, itemData } = this.props
    if (mode && mode === 'update') {
      assignData = {
        id: itemData.id,
        name: itemData.name
      }
    }
    return (
      <>
        <Modal
          {...this.props}
          {...this.state}
          next={this.next}
          assignData={assignData}
          handleChange={this.handleChange}
          onAddAnotherMail={this.onAddAnotherMail}
          geofencesHandelChange={this.geofencesHandelChange}
          onChangedMaintence={this.onChangedMaintence}
          checkRequiredFieldsForFristForm={this.checkRequiredFieldsForFristForm}
        />
      </>
    )
  }
}

const mapState = state => {
  return {
    notificationType: state.notificationType
  }
}
const mapStateToProps = connect(mapState)
export const Screen2 = mapStateToProps(((screen2)))

export const notificationFunctionTypes = [
  {
    key: 'movement',
    value: 'movement',
    field: 'type'
  },
  // {
  //   key: 'maintenanceReminder',
  //   value: 'maintenanceReminder'
  // },
  {
    key: 'digital',
    value: 'digital',
    field: 'input',
    subField: 'digital'
  },
  {
    key: 'analog',
    field: 'input',
    subField: 'analog'
  },
  {
    key: 'other',
    value: 'other',
    field: 'input',
    subField: 'other'
  },
  // {
  //   key: 'output'
  // },
  { key: 'deviceStatus', value: 'deviceStatus', field: 'type' },
  {
    key: 'ecodriving',
    value: 'ecodriving',
    field: 'type',
    subField: 'ecodriving'
  },
  {
    value: 'fuel',
    key: 'fuel',
    field: 'type'
  },
  {
    value: 'expiration',
    key: 'expiration',
    field: 'expiration'
  },
  {
    value: 'otherAlert',
    key: 'otherAlert',
    field: 'otherAlert'
  },
]

export const digitalAdvTypes = [
  {
    key: 'ON'
  },
  {
    key: 'OFF'
  },
  {
    key: 'BOTH'
  }
]
export const Types = {
  movement: [
    {
      label: 'notification.deviceEnterGeofence',
      value: 'deviceEnterGeofence',
      input: 'deviceEnterGeofence'
    },
    {
      label: 'notification.deviceExitGeofence',
      value: 'deviceExitGeofence',
      input: 'deviceExitGeofence'
    },
    {
      label: 'notification.deviceOverspeed',
      value: 'deviceOverspeed',
      input: 'deviceOverspeed'
    },
    {
      label: 'notification.deviceStopped',
      value: 'deviceStopped',
      input: 'deviceStopped'
    },
    {
      label: 'notification.deviceMoving',
      value: 'deviceMoving',
      input: 'deviceMoving'
    },
    {
      label: 'notification.deviceTowing',
      value: 'deviceTowing',
      input: 'deviceTowing'
    },
    {
      label: 'notification.deviceIdling',
      value: 'deviceIdling',
      input: 'deviceIdling'
    },
    {
      label: 'notification.deviceParking',
      value: 'deviceParking',
      input: 'deviceParking'
    }
  ],
  deviceStatus: [
    {
      label: 'notification.deviceOnline',
      value: 'deviceOnline',
      input: 'deviceOnline'
    },
    {
      label: 'notification.deviceUnknown',
      value: 'deviceUnknown',
      input: 'deviceUnknown'
    },
    {
      label: 'notification.deviceOffline',
      value: 'deviceOffline',
      input: 'deviceOffline'
    }
  ],
  digital: [
    {
      label: 'notification.deviceIgnition',
      value: 'deviceIgnition',
      input: 'ignition'
    },
    {
      label: 'notification.devicePanicButton',
      value: 'devicePanicButton',
      input: 'panicButton'
    },
    {
      label: 'notification.deviceExternalPower',
      value: 'deviceExternalPower',
      input: 'externalPower'
    },
    {
      label: 'notification.deviceEngineStatus',
      value: 'deviceEngineStatus',
      input: 'enginStatus'
    },
    {
      label: 'notification.deviceCarAlarm',
      value: 'deviceCarAlarm',
      input: 'carAlarm'
    },
    {
      label: 'notification.deviceDoors',
      value: 'deviceDoors',
      input: 'doors'
    },
    {
      label: 'notification.work',
      value: 'deviceWorkMode',
      input: 'workMode'
    },
    {
      label: 'custom',
      value: 'digitalCustom',
      input: ''
    },
    {
      label: 'notification.powerCut',
      value: 'devicePowerCut',
      input: 'devicePowerCut'
    }
  ],
  analog: [
    {
      label: 'notification.deviceFuel',
      value: 'deviceFuel',
      input: 'fuel'
    },
    {
      label: 'notification.deviceTemperature',
      value: 'deviceTemperature',
      input: 'temperature'
    },
    { label: 'notification.deviceRpm', value: 'deviceRpm', input: 'rpm' },
    {
      label: 'notification.deviceBoardPower',
      value: 'deviceBoardPower',
      input: 'boardPower'
    },
    {
      label: 'notification.deviceMileage',
      value: 'deviceMileage',
      input: 'mileage'
    },
    {
      label: 'notification.deviceCounter',
      value: 'deviceCounter',
      input: 'counter'
    },
    {
      label: 'notification.deviceAverageFuelConsumption',
      value: 'deviceAverageFuelConsumption',
      input: 'averagFuelConsumption'
    },
    {
      label: 'notification.deviceInstantFuelConsumption',
      value: 'deviceInstantFuelConsumption',
      input: 'instantFuelConsumption'
    },
    {
      label: 'notification.deviceFlowMeter',
      value: 'deviceFlowMeter',
      input: 'flowMeter'
    },
    {
      label: 'sensorsTranslation.humidity',
      value: 'humidity',
      input: 'humidity'
    },
    {
      label: 'sensorsTranslation.weight',
      value: 'weight',
      input: 'weight'
    },
    {
      label: 'custom',
      value: 'analogCustom',
      input: ''
    }
  ],
  other: [
    {
      label: 'notification.driverChanged',
      value: 'driverChanged',
      input: 'driverUniqueId'
    },
    {
      label: 'notification.passengerChanged',
      value: 'passengerChanged',
      input: 'passengerUniqueId'
    },
    {
      label: 'notification.trailerChanged',
      value: 'trailerChanged',
      input: 'trailerUniqueId'
    },
    {
      label: 'notification.binChanged',
      value: 'binChanged',
      input: 'binUniqueId'
    }
  ],
  fuel: [
    {
      label: 'notification.deviceFuelFilling',
      value: 'deviceFuelFilling',
      input: 'deviceFuelFilling'
    },
    {
      label: 'notification.deviceFuelDrain',
      value: 'deviceFuelDrain',
      input: 'deviceFuelDrain'
    }
  ],
  ecodriving: [
    {
      label: 'notification.harshBrake',
      value: 'harshBrake',
      input: 'accelBrake'
    },
    { label: 'notification.harshTurn', value: 'harshTurn', input: 'accelTurn' },
    {
      label: 'notification.harshAcceleration',
      value: 'harshAcceleration',
      input: 'accel'
    },
    { label: 'notification.harshBump', value: 'harshBump', input: 'accelBump' }
  ],
  expiration: [
    {
      label: 'notification.deviceExpire',
      value: 'deviceExpire',
      input: 'deviceExpire'
    }],
  customAlert: [
    {
      label: 'notification.parkingSecurity',
      value: 'parkingSecurity',
      input: 'parkingSecurity'
    },
    // {
    //   label: 'notification.driverExpire',
    //   value: 'driverExpire',
    //   input: 'driverExpire'
    // },
    // {
    //   label: 'notification.vehicleExpire',
    //   value: 'vehicleExpire',
    //   input: 'vehicleExpire'
    // },
    // {
    //   label: 'notification.binderExpire',
    //   value: 'binderExpire',
    //   input: 'binderExpire'
    // },
    // {
    //   label: 'notification.userExpire',
    //   value: 'userExpire',
    //   input: 'userExpire'
    // }
  ],
  otherAlert: [
    {
      label: 'exactValue',
      value: 'exactValue',
      input: 'exactValue'
    }
  ],
}
