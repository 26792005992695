export const users = (state = [], action) => {
  switch (action.type) {
    case 'GET_USERS':
      let userArray = [],
        singleUser = {}
      action.users.map(item => {
        let parent = {
          parentId: (item.parent && item.parent.id) || '',
          parentName: (item.parent && item.parent.name) || ''
        }
        singleUser = { ...item.entity, ...parent }
        userArray.push(singleUser)
        return null
      })
      return userArray

    case 'ADD_USER':
      return state.concat(action.user)

    case 'UPDATE_USER':
      let _users = state.filter(use => use.id !== action.user.id)

      return _users.concat(action.user)

    case 'REMOVE_USER':
      let _usersList = state.filter(use => use.id !== action.user.id)

      return _usersList
    case 'SORT_USERS':
      let users1 = []
      if (action.users.sort === 'DESC') {
        users1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        users1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return users1

    case 'RESET_APP':
      return []
    default:
      return state
  }
}

export const selecteduseruser = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'ADD_USER_USER_WISE_LIST':
      let userArray = [],
        singleUser = {}
      action.users.map(item => {
        let parent = {
          parentId: item.parent.id,
          parentName: item.parent.name
        }
        singleUser = { ...item.entity, ...parent }
        userArray.push(singleUser)
        return null
      })

      return userArray

    case 'REMOVE_USER_USER_WISE':
      let rData = state.filter(m => m.id !== action.user.id)
      return rData

    default:
      return state
  }
}

export const searchUser = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'SEARCH_USER':
      return action.payload
    default:
      return state
  }
}

export const roles = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_ROLES':
      return action.roles
    default:
      return state
  }
}
