import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash';
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from '../common/Checkbox'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Place'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../common/Tooltip'
import MenuItem from '../common/MenuItem'
import MarkerMap from '../common/Maps'
import Button from '../common/Button'
import { logInUserInfo } from './../../Actions/Users'
import CustomDialog from '../common/Dialog'
import { checkPrivileges, errorHandler } from '../../Helpers'
import isEqual from 'react-fast-compare'
import { toast } from 'react-toastify';
import { updateLayer } from '../../Actions/Maps'
import instance from '../../axios'
import ConfirmDialoag from '../common/ConfirmDialoag'
import { notifySuccess } from '../../Utils/CustomNotifcations'

const coordinateFormatTypes = [
  {
    key: 'dd',
    name: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    name: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    name: 'sharedDegreesMinutesSeconds'
  }
]

const styles = theme => ({})

class ServicePreferences extends Component {
  constructor () {
    super()
    this.state = {
      form: {
        name: '',
        map: 'osm',
        latitude: '',
        longitude: '',
        zoom: '',
        twelveHourFormat: '',
        coordinateFormat: '',
        poiLayer: '',
        customerNumber:'',
        attributes: { notificationEnabled: true}
      },
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      isVisableAddBtn: false,
      maps: [],
      onDeleteAccountConfirmation:false,
      userName:""
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
  }
  componentWillMount () {
    let mapsArr = []
    this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.maps&&this.props.logInUser.attributes.maps.map((item)=>{
      if(item!=='baidu'){
        mapsArr.push(item)
      }
    })
    this.setState({
      form: {
        ...this.props.logInUser,
        attributes: {
          ...this.state.form.attributes,
          ...this.props.logInUser.attributes
        }
      },
      maps:mapsArr
    })
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }
  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom
          },
          isMapModal: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields = () => {
    let { zoom } = this.state.form
    let value = !isEqual(this.state.form, this.props.logInUser)
    this.setState({
      isVisableAddBtn: value
    })
      if (zoom) {
        if (value && zoom > 2 && zoom < 19) {
          this.setState({
            isVisableAddBtn: value
          })
        } else {
          this.setState({
            isVisableAddBtn: false
          })
        }
      } else {
        this.setState({
          isVisableAddBtn: false
        })
      }
  }
  formReset = () => {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    const { form } = this.state
    logInUser = { ...form }
    // fetch(`api/users/${logInUser.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...logInUser
    //   })
    // })
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...logInUser
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(user => {
            this.props.dispatch(logInUserInfo(user))
            this.props.dispatch(updateLayer({ id: user.map }))
            this.props.dispatch(
             notifySuccess('servicePerferenceInfoIsUpdated')
            )
          // })
          this.setState({
            isVisableAddBtn: false
          })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }
  handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (target.type === 'number') {
      value = parseFloat(value)
    }
    if (name === 'notificationEnabled' || name === 'soundAlert') {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...this.state.form.attributes, [name]: value }
          }
        },
        () => this.checkRequiredFields()
      )
    }
    else if(name === "userName"){
      this.setState({
        [name] : target.value
      })
    }
    else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }

  openDeleteAccountModal =()=>{
    this.setState({
      onDeleteAccountConfirmation: true
    })
  }
  onCloseAccountDeleteModal = () => {
    this.setState({
      onDeleteAccountConfirmation: false
    })
  }

  deleteAccount = (item) => {
    instance({
      url: `/api/users`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.setState({
          onDeleteAccountConfirmation: false
        })
        this.props.dispatch(
          notifySuccess('accountDeleted')
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };
  render () {
    // const { classes } = this.props
    return (
      <div>
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate('setLocation')}
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            isVisableBtn={true}
            draggable={true}
            CancelText={this.props.translate('sharedBack')}
            headerActions={
              <Button
                size='small'
                variant='contained'
                color='secondary'
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {' '}
                {this.props.translate('sharedAdd')}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.form.zoom || 0}
              lat={this.state.form.latitude || 0}
              lng={this.state.form.longitude || 0}
              height={'100%'}
            />
          </CustomDialog>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              value={this.state.form?.name || ''}
              onChange={this.handleChange('name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          {/* {checkPrivileges('accountCustomerAndContractNumUpdate') &&(
              <Grid item xs={12} md={3} sm={6}>
              <TextField
                id='customerNumber'
                label={this.props.translate('Customer Number')}
                type='text'
                value={this.state.form?.customerNumber || ''}
                onChange={this.handleChange('customerNumber')}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            )
          }
          {checkPrivileges('accountCustomerAndContractNumUpdate') &&(
              <Grid item xs={12} md={3} sm={6}>
              <TextField
                id='contractNumber'
                label={this.props.translate('Contract Number')}
                type='text'
                value={this.state.form?.contractNumber || ''}
                onChange={this.handleChange('contractNumber')}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            )
          } */}
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='map'
              select
              label={this.props.translate('mapTitle')}
              value={this.state.form?.map||''}
              onChange={this.handleChange('map')}
              margin='dense'
              fullWidth
            >
              {this.state.maps && this.state.maps.map(option => (
                  <MenuItem key={option} value={option}>
                    {this.props.translate(option)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='coordinateFormat'
              select
              label={this.props.translate('settingsCoordinateFormat')}
              value={this.state.form?.coordinateFormat||''}
              onChange={this.handleChange('coordinateFormat')}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>{this.props.translate('none')}</em>
              </MenuItem>
              {coordinateFormatTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={12} md={3} sm={6}>
                        <TextField
                            id='poiLayer'
                            label={this.props.translate('mapPoiLayer')}
                            type="text"
                            placeholder=""
                            value={this.state.form.poiLayer}
                            onChange={this.handleChange('poiLayer')}
                            variant="outlined"
                            margin='dense'
                            fullWidth
                        />
                    </Grid> */}
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='zoom'
              label={this.props.translate('serverZoom')}
              type='number'
              placeholder=''
              error={
                 this.state.form.zoom > 18 || this.state.form.zoom < 3
                   ? true
                   : false
               }
              helperText={this.props.translate('Enter Value 3 to 18')}
              value={this.state.form?.zoom||''}
              onChange={this.handleChange('zoom')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='latitude'
              type='number'
              label={this.props.translate('positionLatitude')}
              placeholder=''
              value={this.state.form?.latitude||''}
              onChange={this.handleChange('latitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='longitude'
              type='number'
              label={this.props.translate('positionLongitude')}
              value={this.state.form?.longitude||''}
              onChange={this.handleChange('longitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>

          <Grid item md={4} sm={6} xs={12} style={{display:"flex", alignItems:"center"}}>
            <Tooltip title={this.props.translate('addLocation')}>
              <IconButton color='inherit' onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip>
            <div style={{ display: 'inline-flex' }}>
              <div style={{ marginRight: 20 }}>
                <Checkbox
                  checked={this.state.form?.twelveHourFormat||''}
                  onChange={this.handleChange('twelveHourFormat')}
                  value='twelveHourFormat'
                  canAssign
                  canRemove
                  label={this.props.translate('settingsTwelveHourFormat')}
                />
              </div>
              <div style={{ marginRight: 20 }}>
              <Checkbox
                checked={this.state.form.attributes.notificationEnabled}
                onChange={this.handleChange('notificationEnabled')}
                value='twelveHourFormat'
                canAssign
                canRemove
                label={this.props.translate('settingsNotificationEnabled')}
              />
              </div>
              <div style={{ marginRight: 20 }}>
              <Checkbox
                checked={this.state.form.attributes?.soundAlert||false}
                onChange={this.handleChange('soundAlert')}
                canAssign
                canRemove
                label={this.props.translate('soundAlert')}
              />
              </div>
            </div>
          </Grid>
          {this.state.onDeleteAccountConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCloseAccountDeleteModal}
                  onOk={this.deleteAccount}
              disabled={this.state.userName !== this.props?.logInUser?.name}
                  title={this.props.translate("areYouWantToDelete")}
                  children={
                    <Grid container>
                      <Grid item xs={12}>
                        <h3>
                          {this.props?.logInUser?.name || ''}
                        </h3>
                      </Grid>
                      <Grid item xs={12}>
                         <TextField
                            id='userName'
                            type='text'
                            label={this.props.translate('enterUserName')}
                            value={this.state.userName||''}
                            onChange={this.handleChange('userName')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                      </Grid>
                    </Grid>
                  }
                />
              )}
          
          <Grid item md={3} sm={6} xs={12}>
            {this.props.logInUser.parentId === 473 && ( 
            <Button
              variant='contained'
              onClick={this.openDeleteAccountModal}
              style={{ marginRight: 8 }}
              // disabled={this.state.userName !== this.props?.logInUser?.name}
            >
              {' '}
              {this.props.translate('deleteAccount')}
            </Button>
            )} 
          </Grid>
        </Grid>

        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance((ServicePreferences))
