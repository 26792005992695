export function maintenance(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_MAINTENANCE':
      let data = action.maintenance
      data.map(item => {
        item.check = false;
        return null;
      })
      return data;
    case 'ADD_MAINTENANCE':
      let tdata = state.filter(m => m.id !== action.maintenance.id)
      let item = action.maintenance
      item.check = item.check ? item.check : false
      return tdata.concat(item)
    case 'REMOVED_MAINTENANCE':
      let alldata = state.filter(m => m.id !== action.maintenance.id)
      return alldata
    default:
      return state;

  }
}

export function maintenanceDeviceWise(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_MAINTENANCE_DEVICE_WISE':
      return state.concat(action.maintenance);

    case 'REMOVE_MAINTENANCE_DEVICE_WISE':
      let data = state.filter(m => m.id !== action.maintenance.id)
      return data
    case 'GET_MAINTENANCE_DEVICE_WISE':
      return action.maintenance
    default:
      return state;
  }
}

export function selectedMaintenance(state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'MAINTENANCE_DEVICE_WISE_SELECTED_LIST':
      return action.maintenance
    case 'UPDATE_MAINTENANCE_DEVICE_WISE_SELECTED_LIST':
    let updatedata = state.map(m => {
        if(m.id !== action.maintenance.id) {
          return m;
        }
        else {
          return action.maintenance
        }
      })
      return updatedata;
    case 'ADD_MAINTENANCE_DEVICE_WISE_SELECTED_LIST':
      action.maintenance.check = false
      return state.concat(action.maintenance)
    case 'REMOVED_MAINTENANCE_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.maintenance.id)
      return rData
    default:
      return state;
  }
}