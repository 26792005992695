import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import moment from 'moment'
;
import Layout from './../../Layout'
import Loader from './../../Layout/Loader'
import Tabs from './../../Components/common/Tabs'
import EmptyState from './../../Components/common/EmptyState'
import { updateTemplates, removeTemplate } from './../../Actions/Templates'

import { checkPrivileges, errorHandler } from '../../Helpers'
import { toast } from 'react-toastify';
import instance from '../../axios'
import axios from 'axios'
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations';

const reportTypes = [
  { id: 'tripssummary', label: 'Trips', type: 'reportTrip' },
  { id: 'stopssummary', label: 'Stops', type: 'reportStop' },
  { id: 'eventssummary', label: 'Events', type: 'reportEvent' },
  { id: 'enginehourssummary', label: 'Engine Hours', type: 'reportEngineHour' },
  { id: 'geosummary', label: 'Geofence', type: 'reportGeo' },
  { id: 'fuelsummary', label: 'Fuel', type: 'reportFuel' }
]

const itemGroupTypes = {
  device: 'Device',
  driver: 'Driver',
  vehicle: 'Vehicle',
}

function checkReportTypes () {
  let array = reportTypes
  array.map((item, index) => {
    if (!checkPrivileges(item.type)) {
      array.splice(index)
    }
    return null
  })
}
let columns = []
let source;
const ReportData = {}

class Reports extends Component {
  constructor (props) {
    super(props)
    checkReportTypes()
    this.state = {
      templatesCall: false,
      view: 'displayForm',
      from: '',
      to: '',
      selectedDate: '',
      reportType: 0,
      reportType1: 0,
      deviceId: [],
      vehicleId: [],
      groupId: [],
      driverId: [],
      geofenceId: [],
      sensorId: [],
      sensorValues: [{input: "", index: "", value: ""}],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      groupView: 1,
      reportUnits: 'units',
      notificationIds: [],
      types: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      processInvalidPositions: false,
      useIgnition: false,
      controlDays: [1, 2, 3, 4, 5, 6, 7],
      controlTimeStart: '00:00',
      controlTimeEnd: '23:59',
      controlTime: [0, 1439],
      geoTimeThreshold: 1,
      isSensorTracing: false,
      timeError: false,
      isSubmit: false,
      tripsColumns: {
        averageSpeed: { checked: true },
        distance: { checked: false },
        driverName: { checked: false },
        driverUniqueId: { checked: false },
        duration: { checked: true },
        maxSpeed: { checked: true },
        spentFuel: { checked: false },
        endAddress: { checked: true },
        endOdometer: { checked: false },
        endPositionId: { checked: false },
        endLat: { checked: false },
        endLon: { checked: false },
        startAddress: { checked: true },
        startOdometer: { checked: false },
        startPositionId: { checked: false },
        startLat: { checked: false },
        startLon: { checked: false },
        endTime: { checked: false },
        startTime: { checked: false }
      },
      reportDevices: [],
      advanceSettingsForm: {
        // minimalNoDataDuration: 0,
        // minimalParkingDuration: 0,
        // minimalTripDuration: 0,
        // minimalTripDistance: 0,
        // speedThreshold: 0.01,
        // advanceSettings: false
        // processInvalidPositions: false,
        // useIgnition: true
      },
      allowFields: [],
      eventType: 'notification',
      steps: [{id: 1, title: 'selectReportTimeControl'}, {id: 2, title: 'selectReportTemplate'}],
      activeStep: 0,
      allSensors: false
    }
    this.showDates = this.showDates.bind(this)
    this.onDismiss = this.onDismiss.bind(this)
    this.selectedDevices = this.selectedDevices.bind(this)
    this.selectedGroups = this.selectedGroups.bind(this)
    this.reportTypeChange = this.reportTypeChange.bind(this)
    this.createReport = this.createReport.bind(this)
    this.advanceFormChange = this.advanceFormChange.bind(this)
    this.childRendered = this.childRendered.bind(this)
    this.saveData = this.saveData.bind(this)
    this.reportDetail = this.reportDetail.bind(this)
    this.reportTypeSelection = this.reportTypeSelection.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  componentWillUnmount () {
    if(source)
      {
        source.cancel()
      }
  }

  switchGroupView = type => {
    this.setState({
      groupView: type,
      groupId:[],
      vehicleId: [],
      deviceId: [],
      driverId: []
    });
  }

  advanceFormChange (event, name) {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({
      advanceSettingsForm: { ...this.state.advanceSettingsForm, [name]: value }
    })
  }

  handleChange = (name, event) => {
    let v = event.target.value;
    if(name === 'geoTimeThreshold') v = event.target.value < 0 ? 0 : event.target.value
    this.setState({
      [name]: v
    })
  }

  reportTypeSelection (type) {
    this.setState({ reportUnits: '' }, () => {
      this.setState({
        reportUnits: type,
        vehicleId: [],
        deviceId: [],
        groupId: [],
        driverId: []
      })
    })
  }

  onCloseModal (e, name) {
    this.setState({ [name]: false })
  }

  saveData (blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        this.setState({ loading: false })
      }, 0)
    }
    this.setState({ loading: false })
  }

  calcTime = (date, offset) => {
    let d = new Date(date)

    let utc = d.getTime() + d.getTimezoneOffset() * 60000

    let nd = new Date(utc + 3600000 * offset)

    return nd.toISOString()
  }

  createReport (type) {
    let fileName = 'download'
    const duration = moment.duration((moment(this.state.to)).diff(this.state.from));
    const hours = duration.asHours();
    this.setState({
      isSubmit: true
    })
    if(!(this.state.isSensorTracing && (hours >= 24))){
    let header
    let { reportType } = this.state

    this.setState({
      detail1:
        reportType === 1 ||
        reportType === 2 ||
        reportType === 3 ||
        reportType === 4 ||
        reportType === 5 ||
        reportType === 6 ||
        reportType === 13
          ? true
          : false
    })

    let form = {
      rpTmplId: this.state.reportType,
      to: this.state.to,
      from: this.state.from,
      controlDays: this.state.controlDays,
      controlTimeStart: moment()
        .startOf('day')
        .add(this.state.controlTime[0], 'minutes')
        .format('HH:mm'),
      controlTimeEnd: moment()
        .startOf('day')
        .add(this.state.controlTime[1], 'minutes')
        .format('HH:mm'),
      deviceIds: this.state.deviceId || [],
      vehicleIds: this.state.vehicleId || [],
      itemGroupIds: this.state.groupId || [],
      types: this.state.types,
      itemGroupType: this.state.groupView === 2 ? itemGroupTypes[this.state.reportUnits] : '',
      notificationIds: this.state.notificationIds,
      geofenceIds: this.state.geofenceId || [],
      sensors: this.state.sensorId || [],
      detail: false,
      driverIds: this.state.driverId || [],
      mail: false,
      geoTimeThreshold: (this.state.geoTimeThreshold || 0) * 60000
    }

    let temp = ''

    this.props.templates.map(t => {
      if (form.rpTmplId === t.rpTmpl.id) {
        temp = t.rpTmpl.name
      }
      return null
    })
    let accept = 'application/pdf'
    let fileName = 'report'
    if(type === 'downloadPdf' || type === 'downloadExcel'){
    if(type === 'downloadPdf'){
        accept='application/pdf'
    }
    else{
      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    // if (type === 'downloadPdf') {
      // header = {
      //   headers: new Headers({
      //     'Content-Type': 'application/json',
      //     Accept:
      //       'application/pdf'
      //   }),
      //   method: 'POST',
      //   responseType: 'blob',
      //   data: {
      //     ...form
      //   }
      // }
      header = {
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: accept
        }),
        method: 'POST',
        body: JSON.stringify(form)
      }

      this.setState({ loading: true, reportsData: {} }, () => {
        fetch('/api/reports/v2/dynamic', { ...header })
        .then(response1 =>{
          if(response1.status === 200) {
          response1.blob().then(response => {
            if(type === 'downloadPdf'){
              this.saveData(
                response,
                (temp || this.state.reportType) + '-reports.pdf'
              )
            }
            else{
              this.saveData(
                response,
                (temp || this.state.reportType) + '-reports.xlsx'
              )
            }
            this.setState({ loading: false, detail: this.state.detail1 })
          })
        } else {
          throw response1
        }
      })
          // .then(url => {
            // this.setState({ loading: false, detail: this.state.detail1 })
          // })
          .catch(error => {
            // errorHandler(error,this.props.dispatch)
            if (error && error.text) {
              error.text().then(err => {
                let message = err.split("-")[0]
                if(message){
                  this.props.dispatch(
                    notifyError(message)

                  )
                }
                })
              }
              this.setState({ loading: false })
          })
      })
    }
    //  else if (type === 'downloadExcel') {
      // header = {
      //   headers: new Headers({
      //     'Content-Type': 'application/json',
      //     Accept:
      //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      //   }),
      //   method: 'POST',
      //   body: JSON.stringify(form)
      // }

    //   this.setState({ loading: true, reportsData: {} }, () => {
    //     // fetch('/api/reports/dynamic', { ...header })
    //     instance({
    //       url: `/api/reports/dynamic`,
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept:
    //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //       },
    //       responseType: 'blob',
    //       data: {
    //         ...form
    //       }
    //     })
    //       // .then(response => response.blob())
    //       .then(response =>
    //         this.saveData(
    //           response,
    //           (temp || this.state.reportType) + '-reports.xlsx'
    //         )
    //       )
    //       .then(url => {
    //         this.setState({ loading: false, detail: this.state.detail1 })
    //       })
    //       .catch(error => {errorHandler(error,this.props.dispatch)})
    //   })
    // }

      else if (type === 'email') {
      form.mail = true

      header = {
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }),
        method: 'POST',
        body: JSON.stringify(form)
      }

      this.setState({ loading: true, reportsData: {} }, () => {
        fetch('/api/reports/v2/dynamic', { ...header })
        .then(response => {
          this.setState({ loading: false })
          if (response.ok) {
            this.setState({ detail: this.state.detail1 })
            this.props.dispatch(
             notifySuccess( this.props.translate('emailSentSuccessfully')
              ))
          }
          else{
            throw response
          }
        }).catch(error => {
          // errorHandler(error,this.props.dispatch)
          if (error && error.text) {
            error.text().then(err => {
              let message = err.split("-")[0]
              if(message){
                this.props.dispatch(
                  notifyError(message)
                )
              }
              })
            }
            this.setState({ loading: false })
        })
      })
    } else {
      // header = {
      //   headers: new Headers({
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json'
      //   }),
      //   method: 'POST',
      //   body: JSON.stringify(form)
      // }
      this.setState(
        { loading: true, reportsData: {}, reportType1: '', detail: false },
        () => {
          instance({
            url: `/api/reports/v2/dynamic`,
            method: 'POST',
            data: {
              ...form
            }
          })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
              .then(reports => {
                if (reports.success) {
                  this.setState({
                    reportsData: reports.report,
                    reportType1: this.state.reportType,
                    detail: this.state.detail1
                  })
                }
            //   })
            // } else {
            //   this.setState({ loading: false })
            //   this.props.dispatch(
            //     Notifications.error({
            //       message: this.props.translate('error'),
            //       autoDismiss: 10
            //     })
            //   )
            //     throw response
            //   }
            })
            .catch(error => {
              errorHandler(error,this.props.dispatch)
              this.setState({ loading: false })
            })
        }
      )
    }
  }else{
    this.validatedCurrentStep(1, '', -1);
    this.props.dispatch(
      notifyError("sensorTracingLimitError")
    )
    this.setState({
      timeError: true
    })
  }
  }

  showDates (e, date) {
    const duration = moment.duration((moment(this.state.to)).diff(this.state.from));
    const hours = duration.asHours();
    if(this.state.isSubmit && this.state.isSensorTracing && hours >= 24){
      this.setState({
        timeError: true
      })
    }else{
      this.setState({
        timeError: false
      })
    }
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = 'HH:mm'

    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }

    this.setState(
      {
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate
      },
      () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.reportType &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      }
    )
  }

  onDismiss () {}

  selectedNotifications = types => {
    this.setState({ types }, () => {
      if (
        (this.state.groupId.length ||
          this.state.vehicleId.length ||
          this.state.deviceId.length ||
          this.state.driverId.length) &&
        this.state.reportType &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true })
      } else {
        this.setState({ validatedForm: false })
      }
    })
  }

  selectedEvents = notificationIds => {
    this.setState({ notificationIds }, () => {
      if (
        (this.state.groupId.length ||
          this.state.vehicleId.length ||
          this.state.deviceId.length ||
          this.state.driverId.length) &&
        this.state.reportType &&
        this.state.selectedDate
      ) {
        this.setState({ validatedForm: true })
      } else {
        this.setState({ validatedForm: false })
      }
    })
  }

  selectedDevices (deviceId) {
    if (deviceId && deviceId.length) {
      this.setState({ deviceId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ deviceId: [] })
    }
  }

  selectedGroups (groupId) {
    if (groupId && groupId.length) {
      this.setState({ groupId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ groupId: [] })
    }
  }
  
  selectedVehicles = (vehicleId) => {
    if (vehicleId && vehicleId.length) {
      this.setState({ vehicleId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ vehicleId: [] })
    }
  }

  selectedDrivers = driverId => {
    if (driverId && driverId.length) {
      this.setState({ driverId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ driverId: [] })
    }
  }

  selectedGeofences = geofenceId => {
    if (geofenceId && geofenceId.length) {
      this.setState({ geofenceId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ geofenceId: [] })
    }
  }
  
  selectedSensors = sensorId => {
    if (sensorId && sensorId.length) {
      if(sensorId.length === 1 && sensorId[0] && sensorId[0].value === 'allSensors') {
        this.setState({allSensors: true})
      } else {
        this.setState({allSensors: false})
      }
      this.setState({ sensorId: sensorId.map(e => e.value), sensorValues: sensorId }, () => {
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
    } else {
      this.setState({ sensorId: [] })
    }
  }

  reportTypeChange (event) {
    let tpl = this.props.templates.find(
      temp => temp.rpTmpl.id === event.target.value
    )
    let allowFields = []
    if (
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields
    }
    this.setState(
      {
        reportType: event.target.value,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(this.state.eventType)
          ? this.state.eventType
          : allowFields.includes('notification')
          ? 'notification'
          : allowFields.includes('eventType')
          ? 'eventType'
          : ''
      },
      () => {

        let currentSteps = this.state.steps.filter(step => step.id < 3)

        if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
          // add notification step
          currentSteps.push({id: 3, title: "selectEventOrNotification"})
        }
        else if(allowFields.some(r=> ["geofence"].includes(r))) {
          // add notification step
          currentSteps.push({id: 4, title: "selectGeofence"})
        }
        else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
          // add notification step
          currentSteps.push({id: 5, title: "selectSensor"})
        }



        this.reportTypeSelection(this.state.reportUnits)
        if (
          (this.state.groupId.length ||
            this.state.vehicleId.length ||
            this.state.deviceId.length ||
            this.state.driverId.length) &&
          this.state.reportType &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true, steps: currentSteps })
        } else {
          this.setState({ validatedForm: false, steps: currentSteps })
        }
      }
    )
    tpl.rpTmplTblWrappers.map(i => {
      if(i.rpTmplTbl.rpId === 8){
        this.setState({
          isSensorTracing: true
        })
       }else{
        this.setState({
          isSensorTracing: false
        })
       }
      })
    }

  reportDetail (checked) {
    this.setState({ detail1: checked })
  }

  handleChangeType = eventType => {
    this.setState(
      {
        eventType
      },
      () => {
        this.selectedNotifications([])
        this.selectedEvents([])
      }
    )
  }
  
  childRendered () {
    if (this.state.loading === true && this.state.reportsData.head) {
      this.setState({ loading: false })
    }
  }

  deleteTemplate = id => {
    // fetch('/api/report/templates/' + id, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/report/templates/${id}`,
      method: 'DELETE',
    })
    .then(response => {
      // if (response.ok) {
        this.props.dispatch(removeTemplate({ id }))
      // }
      // else{
      //   throw response
      // }
    })
    .catch(error => {errorHandler(error,this.props.dispatch)})
  }

  onDayChange = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (this.state.controlDays || []).concat(parseInt(obj.id))
    } else {
      dayArry = (this.state.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
    this.setState({
      controlDays: dayArry
    })
  }

  handleTimeControl = (event, controlTime) => {
    this.setState({ controlTime })
  }

  goToNextStep = (step, type, index) => {
    this.validatedCurrentStep(step, type, index);
  }
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      // check datetime
      if(this.state.from && this.state.to) {
        validated = true
        errorMsg= null
      }
      else {
        validated = false
        errorMsg= <span className="text-warning">Please select 'from to' from above</span>
      }
      
    }
    else if(step === 2) {
      
      // check template and unit, group or staff selected
      if(this.state.reportType) {
        if(this.state.allowFields.some(r=> ["device", "driver", "group", "vehicle"].includes(r)) && !this.state.groupId.length && !this.state.deviceId.length && !this.state.driverId.length && !this.state.vehicleId.length) {
          validated = false
          errorMsg= <span className="text-warning">Please must select anything from the above list section</span>
        }
        else {
          errorMsg= null
          if(this.state.steps.length === 2){
            this.createReport(type)
          }else{
            validated = true
          }
        }        
        
      }
      else {
        validated = false
        errorMsg= <span className="text-warning">Please select a 'template' from above</span>
       
      }
    }
    else if(step === 3) {
      // optional step
      // allow fields containing events, types make sure select any of.
      errorMsg= null
      if(this.state.steps.length === 3){
        this.createReport(type)
      } else {
        validated = true
      }
    }
    else if(step === 4) {
      // allow fields containing geofence, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please select a 'geofence' in above list</span>
      if(this.state.geofenceId.length){
        errorMsg= null
        this.createReport(type)
        // validated = true
      }
    }
    else if(step === 5) {
      // allow fields containing sensor, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please enter a 'sensor' in above form</span>
      if(this.state.sensorId.length) {
        this.createReport(type)
        errorMsg = null;
        // validated = true
      }
    }

    if(validated === true) {
      this.setState({activeStep: index + 1})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

    this.setState({steps}) 

  }

  render () {
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
    return (
      <div>
        <Layout
          {...this.props}
          {...this.state}
          switchGroupView={this.switchGroupView}
          handleChange={this.handleChange}
          goToNextStep={this.goToNextStep}
          goToBackStep={this.goToBackStep}
          deleteTemplate={this.deleteTemplate}
          handleChangeType={this.handleChangeType}
          reportTypeChange={this.reportTypeChange}
          createReport={this.createReport}
          advanceSettings={this.state.advanceSettingsForm.advanceSettings}
          advanceSettingsForm={this.state.advanceSettingsForm}
          advanceFormChange={this.advanceFormChange}
          reportTypeSelection={this.reportTypeSelection}
          showDates={this.showDates}
          selectedDevices={this.selectedDevices}
          eventType={this.state.eventType}
          selectedNotifications={this.selectedNotifications}
          selectedEvents={this.selectedEvents}
          selectedGroups={this.selectedGroups}
          selectedDrivers={this.selectedDrivers}
          selectedVehicles={this.selectedVehicles}
          selectedGeofences={this.selectedGeofences}
          selectedSensors={this.selectedSensors}
          handleTimeControl={this.handleTimeControl}
          reportTypes={reportTypes}
          reportDetail={this.reportDetail}
          validatedForm={this.state.validatedForm}
          reportUnits={this.state.reportUnits}
          onDayChange={this.onDayChange}
        >
          <div className='main-content-page'>
            {!this.state.loading && !this.state.reportsData.head ? (
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  borderRadius: 6,
                  color: this.props.themecolors.textColor
                }}
              >
                <EmptyState text={this.props.translate('noReportSelected')} />
              </div>
            ) : null}
            <Tabs
              heading={this.state.reportsData.head}
              onRender={this.childRendered}
              tabs={this.state.reportsData.sheets}
              columns={columns}
              reportType={this.state.reportType1}
              detail={this.state.detail}
              themecolors={this.props.themecolors}
              serverTimeZone={serverTimeZoneName}
              timeFormat= {this.props.logInUser&&this.props.logInUser.twelveHourFormat}
              fetching={this.state.loading}
              translate={this.props.translate}
            />
            <div
              style={{
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor
              }}
            >
              {this.state.loading ? <Loader /> : null} 
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  devices: state.devices.data,
  logInUser: state.logInUsers,
  groups: state.groups,
  templates: state.templates,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(((Reports)))
