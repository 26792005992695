import React, { Component } from 'react'
;
import L from 'leaflet'
import { 
  MapContainer as Map,

   TileLayer, Marker, LayersControl } from 'react-leaflet'
import { connect } from 'react-redux'
import { updateLayer } from '../../Actions/Maps'
import { MapTileLayers } from '../../Reducers/Maps'
import Style from 'style-it'

class MarkerMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 13,
      minZoom: 3,
      currentLayer: null,
      MarkerDOM: null
    }
    this.setBoundOptions = this.setBoundOptions.bind(this)
  }

  setBoundOptions () {
    return {}
  }

  componentDidMount () {
    const { lat, lng } = this.props.match.params
    const MarkerDOM = (
      <Marker
        position={[lat || 0, lng || 0]}
        icon={L.icon({
          iconUrl: '/assets/images/location-pin.svg',
          iconAnchor: [15, 60],
          iconSize: [60, 60]
        })}
      />
    )
    this.setState({
      lat: lat,
      lng: lng,
      MarkerDOM
    })
  
    const userInfo = localStorage.getItem('userInfoFront');
    if(userInfo) {
      const u = JSON.parse(userInfo).map;
      console.log(u)
      this.props.dispatch(updateLayer({id: u}))
    }
  }


  changeBaseLayer = event => {
    
    if(this.map) {
      const center = this.map.getCenter(); 
      this.map.options.crs = ['yandexMap', 'yandexSat'].includes(event.layer.options && event.layer.options.id) ? L.CRS.EPSG3395 : L.CRS.EPSG3857;
      this.map.setView(center);
    }
    
    this.setState({currentLayer: event.layer.options});
    
  }

  myRef = el => {
    if (el) {
      this.map = el.leafletElement
    }
  }

  render () {
    const position = [this.state.lat, this.state.lng]
    


    const thisMap = [
      <Map
        ref={this.myRef}
        className='full-height-container'
        key={2}
        center={position}
        zoom={this.state.zoom}
        onBaselayerchange={this.changeBaseLayer}
        maxZoom={this.props.mapLayer.maxZoom}
      >
        <LayersControl position="topleft">
          {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
            <TileLayer {...layer} minZoom={this.state.minZoom} />    
          </LayersControl.BaseLayer>)}
        </LayersControl> 
        {this.state.MarkerDOM}
      </Map>
    ]

    


    return (
      <div style={{ position: 'relative' }}>
      <Style>{`
        .leaflet-control-layers-toggle {
            background: url('/assets/images/maps/layers.png') no-repeat center;
        } 
      `}
      </Style>
        {['osm', ''].includes(this.props.mapLayer.id) && thisMap}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) &&
          thisMap}
        {['carto'].includes(this.props.mapLayer.id) && thisMap}
        {[
          'googleTerrain',
          'googleHybrid',
          'googleSatellite',
          'googleRoad',
          'gccStreet'
        ].includes(this.props.mapLayer.id) && thisMap}
        {['baidu'].includes(this.props.mapLayer.id) && thisMap}        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer
})

export default connect(mapStateToProps)((MarkerMap))
