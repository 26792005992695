import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend"; // Import the Backend module
import translationFrench from "../src/lang/fr.json";
import translationEnglish from "../src/lang/en.json";
import translationUrdu from "../src/lang/urdu.json";
import translationArabic from "../src/lang/arabic.json";
import translationAlbanian from "../src/lang/sq.json";
import translationBurmese from "../src/lang/my.json";
import translationChinese from "../src/lang/zh.json";
// import translationCambodian from "../src/lang/ko.json";
import translationGerman from "../src/lang/de.json";
import translationGujarati from "../src/lang/gu.json";
import translationHebrew from "../src/lang/he.json";
import translationHindi from "../src/lang/hi.json";
import translationHungarian from "../src/lang/hu.json";
import translationIndonesian from "../src/lang/id.json";
import translationItalian from "../src/lang/it.json";
import translationJapanese from "../src/lang/ja.json";
import translationKorean from "../src/lang/ko.json";
import translationMongolian from "../src/lang/mn.json";
import translationNepali from "../src/lang/ne.json";
import translationPersian from "../src/lang/fa.json";
import translationPortuguese from "../src/lang/pt.json";
import translationRussian from "../src/lang/ru.json";
import translationSerbian from "../src/lang/sr.json";
import translationSpanish from "../src/lang/es.json";
import translationTelugu from "../src/lang/te.json";
import translationThai from "../src/lang/th.json";
import translationTurkish from "../src/lang/tr.json";
import translationAmharic from "../src/lang/amharic.json";
import translationKurdish from "../src/lang/kurdish.json";

//get selected language from local storage
const selectedLanguage = localStorage.getItem("SelectedLanguage") || "en";

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    translation: translationFrench,
  },
  en: {
    translation: translationEnglish,
  },
  ur: {
    translation: translationUrdu,
  },
  ar: {
    translation: translationArabic,
  },
  sq: {
    translation: translationAlbanian,
  },
  my: {
    translation: translationBurmese,
  },
  zh: {
    translation: translationChinese,
  },
  // km: {
  //   translation: translationCambodian,
  // },
  de: {
    translation: translationGerman,
  },
  gu: {
    translation: translationGujarati,
  },
  he: {
    translation: translationHebrew,
  },
  hi: {
    translation: translationHindi,
  },
  hu: {
    translation: translationHungarian,
  },
  id: {
    translation: translationIndonesian,
  },
  it: {
    translation: translationItalian,
  },
  ja: {
    translation: translationJapanese,
  },
  ko: {
    translation: translationKorean,
  },
  mn: {
    translation: translationMongolian,
  },
  ne: {
    translation: translationNepali,
  },
  fa: {
    translation: translationPersian,
  },
  pt: {
    translation: translationPortuguese,
  },
  ru: {
    translation: translationRussian,
  },
  sr: {
    translation: translationSerbian,
  },
  es: {
    translation: translationSpanish,
  },
  te: {
    translation: translationTelugu,
  },
  th: {
    translation: translationThai,
  },
  tr: {
    translation: translationTurkish,
  },
  am: {
    translation: translationAmharic,
  },
  ku: {
    translation: translationKurdish,
  }
  
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    resources,
    lng: selectedLanguage || 'en',
    ns: ['translation'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      // format: (value, format, lng) => { // legacy usage
      //   if (value instanceof Date) {
      //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
      //   }
      //   return value;
      // }
    },
  });

export default i18n;