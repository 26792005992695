  import React, { Component } from "react";
  import { connect } from "react-redux";
  import axios from "axios";
  import { checkPrivileges, errorHandler } from "../../Helpers";
  import Layout from "../../Layout";
  import TextField from "../../Components/common/TextField";
  import Button from "../../Components/common/Button";
  import Grid from "@mui/material/Grid";
  import instance from "../../axios";
  import Table from "../../Components/common/tableWithBackEndPagination";
  import Loader from "../../Layout/Loader";
  import ExportImportMenu from "../../Components/Maps/ExportImportMenu";
  import CustomDialog from "../../Components/common/Dialog";
  // import { CreateInvoice } from './CreateInvoice'
  const CancelToken = axios.CancelToken;
  let source = "";

  class Invoice extends Component {
    constructor(props) {
      super(props);
      this.state = {
        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        showStats: false,
        selectedAreaId: "",
        selectedvehicleId: "",
        to: "",
        from: "",
        loader: false,
        openCreateForm: false,
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
        showImportedInvoiceError: false,
        importedInvoiceData: {},
        status:"",
        selected:"missedOpportunity"
        // invoiceForm:{
        //   plateNumber:"",
        //   customer:{},
        //   Invoice:[],
        //   Services:[defaultServices],
        //   items:[defaultItems]
        // },
        // Package:packageDefaultForm,
      };
    }

    componentWillUnmount() {
      this.setState({
        assignMode: "",
        currentPage: 1,
        pagesize: 50,
        selectedvehicleId: "",
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: this.props.deviceId,
          hasNext: true,
          searchText: "",
        },
      });
    }

    componentWillMount() {
      if (
        this.props.logInUser &&
        this.props.logInUser.id &&
        this.state.initFetch === false
      ) {
        this.setState(
          {
            initFetch: true,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    }

    componentWillReceiveProps(n) {
      if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
        this.setState(
          {
            initFetch: true,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    }

    fetchMoreItems = (a, b, c) => {
      let pagination = true;
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize,
        pagination
      );
    };

    searchItems = (text) => {
      this.setState(
        {
          searchText: text,
        },
        () => {
          this.fetchData(this.props.logInUser, 1, this.state.pagesize);
        }
      );
    };

    
    submitSideBarSearch = (data, type) => {
      this.setState(
        {
          loader: true,
          to: data.to ? `&to=${data.to}` : '',
          from: data.from ?  `&from=${data.from}` : '',
          status: `${data.status}`,
          selectedAreaId: data.selectedAreaId,
          selectedvehicleId: data.selectedvehicleId,
        },
        () => {
          if(type==="downloadExcel"){
              this.downloadCreditDebit()
          }else{
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );

          }
        
        }
      );
    };


    downloadCreditDebit = (type) => {
      let accept;
      let dateto = this.state.to || "";
      let datefrom =  this.state.from || "";
      let userId =  this.state.selectedUser || "";
      // let status =  this.state.status || 0;
  
  
      if (type === "downloadPdf") {
        accept = "application/pdf";
      } else {
        accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      let header;
      header = {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: accept,
        }),
        method: "GET",
      };

      let status =  this.state.selected ==="missedOpportunity" ? 1 : this.state.selected ==="potentialOpportunity" ? 2 : 0;

  
      let apiUrl;
      apiUrl = `/api/predictions/export?status=${status}&contractNumber=${this.props.ServerSetting.contractNumber}`;
  
      if (dateto) apiUrl += `${dateto}`;
      if (datefrom) apiUrl += `${datefrom}`;
      // if (userId) apiUrl += `&contractNumber=${userId}`;
  
      fetch(
        apiUrl,
        { ...header }
      )
        .then((response1) => {
          if (response1.status === 200) {
            if (type === "downloadPdf") {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "CreditDebit.pdf");
                  }
                );
              });
            } else {
              response1.blob().then((response) => {
                this.setState(
                  {
                    selectedAreaId: "",
                    selectedvehicleId: "",
                    to: "",
                    from: "",
                    loader: false,
                  },
                  () => {
                    this.saveData(response, "Missed_Opportunity.xlsx");
                  }
                );
              });
            }
          } else {
            throw response1;
          }
        })
  
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    };
  
    saveData = (blob, filename) => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    };


    handleChangeTabs = (e) => {
      // let contractType;
      // contractType =   e ==="maintenance" ? 1:e ==="leasing" ? 2:""
      this.setState({ selected: e }, () => { 
        this.fetchData(  
          this.props.logInUser,
          1,
          this.state.pagesize);
        // this.props.setContractType(contractType);
      });
    };
    fetchData = (logInUser, page, perPage, pagi, reset) => {
      // console.log("this.props.logInUser=====",this.props.ServerSetting.contractNumber);

      const { contractType } = this.props.ServerSetting;
      source = CancelToken.source();
      let url = "";
      let searchText = this.state.searchText;
      if (searchText) {
        searchText = "&search=" + searchText;
      } else {
        searchText = "";
      }

      let items = this.state.itemPagination.items;
      if (reset) {
        items = [];
      }

      let dateto = this.state.to || "";
      let datefrom =  this.state.from || "";
      // let status =  this.state.status || 0;

      // url = this.state.itemSearch
      //   ? `/api/${invoice}/get?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`
      //   : `/api/${invoice}/get?page=${page}&limit=${perPage}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`;
      let status =  this.state.selected ==="missedOpportunity" ? 1 : this.state.selected ==="potentialOpportunity" ? 2 : 0;
    
        url = `/api/predictions/get?page=${page}&limit=${perPage}&contractNumber=${this.props.ServerSetting.contractNumber}&status=${status}`;
        if (dateto) url += `${dateto}`;
        if (datefrom) url += `${datefrom}`;
        // if (status) url += `${status}`;
        // if (userId) url += `&search=${userId}`;
      instance({
        url: url,
        method: "GET",
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          // console.log("res====", res);
          this.setState({
            loader: false,
            invoices: pagi ? items.concat(res.data) : res.data,
            isDataRecieved: true,
            itemPagination: {
              ...res,
              items: pagi ? items.concat(res.data) : res.data,
            },
            currentPage: res.hasNext ? res.page  : 1,
          });
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    };



    setPagination = (user) => {
      const { itemPagination } = this.state;
      if (itemPagination && itemPagination.items && itemPagination.items.length) {
        let nUser = { ...user };
        let lastPage = nUser.total / this.state.pagesize;
        let IsFloate = this.checkFloteNumber(lastPage);
        delete nUser.data;
        nUser.pageSize = this.state.pagesize;
        nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
        return nUser;
      }
      return {};
    };

    handleChangeRowsPerPage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "deliverynotes") {
        this.setState(
          {
            pagesize: value,
            currentPage: 1,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    handleChangePage = (value, mode) => {
      if (source) {
        source.cancel();
      }
      if (mode === "deliverynotes") {
        this.setState(
          {
            currentPage: value,
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        );
      }
    };

    SearchItem = (searchValue, mode) => {
      if (source) {
        source.cancel();
      }
      this.setState(
        {
          itemSearch: searchValue,
          currentPage: 1,
        },
        () => {
          if (mode === "deliverynotes") {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            );
          }
        }
      );
    };

    checkFloteNumber(n) {
      return Number(n) === n && n % 1 !== 0;
    }

    toggleFilterForm = () => {
      this.setState({
        showStats: !this.state.showStats,
      });
    };

    

    

    openImportTable = (item) => {
      this.setState({
        showImportedInvoiceError: true,
        importedInvoiceData: item,
      });
    };

    onCloseImportedModal = () => {
      this.setState({ showImportedInvoiceError: false });
    };

    render() {
      const endMessage =
        this.state.itemPagination && this.state.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {this.props.translate("notFound")}
          </p>
        );
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            modeEmpty={this.modeEmpty}
            toggleFilterForm={this.toggleFilterForm}
            submitSideBarSearch={this.submitSideBarSearch}
            downloadInvoice={this.downloadInvoice}
            {...this.state}
          >
            <div className="main-content-page">
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  paddingTop:5
                }}
              >
                <div style={{ maxWidth:500, margin:"5px", }}>
                          <span style={{display: "table-cell",
                                    width: "1%",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: "inherit",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    // background: "#0a83a1",
                                    color: "#ffffff",
                                    
                                    background: this.state.selected==='missedOpportunity'? this.props.themecolors.menuActiveBackground : this.props.themecolors.themeDarkColor,
                                    padding:8

                            }} 
                            onClick={() => this.handleChangeTabs("missedOpportunity")}
                            // onClick={()=>{this.setState({selected:"missedOpportunity"})}}
                            >{this.props.translate("Overdue Vehicle services")}
                            </span>
                            <span style={{display: "table-cell",
                                    width: "1%",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: "inherit",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    // background: "#0a83a1",
                                    color:"#ffffff",
                                    padding:8,
                                    background: this.state.selected==='potentialOpportunity'? this.props.themecolors.menuActiveBackground  : this.props.themecolors.themeDarkColor,

                            }}
                            onClick={() => this.handleChangeTabs("potentialOpportunity")}
                            // onClick={()=>{this.setState({selected:"potentialOpportunity"})}}
                            >
                              {this.props.translate("Vehicles Due for Services")}
                              </span>
                        </div>
                {
                  <Grid container style={{ height: "100%" }}>
                    <Grid item sm={12} xs={12}>
                      {this.state.isDataRecieved ? (
                        <Table
                          rows={
                            (this.state.itemPagination &&
                              this.state.itemPagination.items) ||
                            []
                          }
                          pagination={this.setPagination(
                            this.state.itemPagination && this.state.itemPagination
                          )}
                          handleChangeRowsPerPage={(n) =>
                            this.handleChangeRowsPerPage(n, "deliverynotes")
                          }
                          handleChangePage={(n) =>
                            this.handleChangePage(n, "deliverynotes")
                          }
                          // SearchItem={(n) => this.SearchItem(n, "deliverynotes")}
                          logInUser={this.props.logInUser}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          vehicles={this.props?.vehicles || []}
                          // viewInvoice
                          // missedOpportunityLabel
                          // searchable
                          invoiceModule
                          ServerSetting={this.props.ServerSetting}
                          // invoiceRow
                          // downloadInvoicePdf={this.downloadInvoicePdf}
                          // createButton={
                          //   checkPrivileges('invoiceCreate') && (
                          //     <div style={{ marginLeft: 10 }}>
                          //       <Button onClick={this.createForm}>
                          //         {this.props.translate('Create')}
                          //       </Button>
                          //     </div>
                          //   )
                          // }
                          // isDownload={
                          //   <ExportImportMenu
                          //     fileName="deliverynotes"
                          //     checkPrivilege
                          //     isInvoices
                          //     data={[]}
                          //     themecolors={this.props.themecolors}
                          //     fetchData={this.fetchData}
                          //     openImportTable={this.openImportTable}
                          //   />
                          // }
                          rowDefinition={
                            [
                              {
                                id: "contractNumber",
                                // numeric: false,
                                // disablePadding: false,
                                label: this.props.translate("contractNumber"),
                              },
                              {
                                id: "plateNumber",
                                // numeric: false,
                                // disablePadding: false,
                                label: this.props.translate("plateNumber"),
                              },
                              {
                                id: "categoryName",
                                // numeric: false,
                                // disablePadding: false,
                                label: this.props.translate("categoryName"),
                              },
                            
                              {
                                id: "itemCode",
                                numeric: false,
                                disablePadding: false,
                                label: this.props.translate("itemCode"),
                              },
                             
                              {
                                id: "lastServiceDate",
                                // numeric: false,
                                // disablePadding: false,
                                label: this.props.translate("serviceDate"),
                              },
                              {
                                id: "nextExecutionDate",
                                // numeric: false,
                                // disablePadding: false,
                                label: this.props.translate("nextExecutionDate"),
                              },
                              ]
                          }
                        />
                      ) : (
                        <Loader />
                      )}
                    </Grid>
                  </Grid>
                }
         
              </div>
            </div>
            {this.state.showImportedInvoiceError && (
              <CustomDialog
                title="Imported Delivery Notes"
                visable={true}
                onClose={this.onCloseImportedModal}
                bodyPadding={10}
                hideDragIcon
                fullWidth
                isVisableBtn
                noFullScreen
              >
                <div>
                  <table>
                    <tr
                      style={{
                        borderBottom: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td>
                        <strong>Delivery Note no</strong>
                      </td>
                      <td>
                        <strong>Error</strong>
                      </td>
                    </tr>
                    {Object.entries(this.state.importedInvoiceData).map(
                      (item, i) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "1px solid black",
                              paddingTop: 2,
                            }}
                          >
                            <td style={{ padding: 10 }}>
                              <strong>{item[0]}</strong>
                            </td>
                            <td style={{ padding: 10 }}>{item[1]}</td>
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              </CustomDialog>
            )}
          </Layout>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
    themecolors: state.themeColors,
    logInUser: state.logInUsers,
    ServerSetting: state.ServerSetting,
    vehicles: state.vehicles,
  });

  export default connect(mapStateToProps)(((Invoice)));
