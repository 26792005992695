import React from 'react'
import L, { Marker as LeafletMarker } from 'leaflet'
// import { Marker } from 'react-leaflet'
import { LeafletContext } from '@react-leaflet/core';

import 'leaflet-rotatedmarker'

// save these original methods before they are overwritten
var proto_initIcon = L.Marker.prototype._initIcon
var proto_setPos = L.Marker.prototype._setPos

L.Marker.addInitHook(function () {
  this.options.animationTime = this.options.animationTime || 0

  this.on('drag', function (e) {
    e.target._applyAnimationTime()
  })
})

L.Marker.include({
  _initIcon: function () {
    proto_initIcon.call(this)
  },

  _setPos: function (pos) {
    proto_setPos.call(this, pos)
    this._applyAnimationTime()
  },

  _applyAnimationTime: function () {
    if (this.options.animationTime) {
      this._icon.style[L.DomUtil.TRANSITION] =
        'transform ' + this.options.animationTime + 's linear'
    }
  },

  setAnimationTime: function (animationTime) {
    this.options.animationTime = animationTime
    this.update()
    return this
  }
})
class RotatedMarker extends React.PureComponent {
  static defaultProps = {
    rotationOrigin: 'center'
  }

  createLeafletElement (props) {
    const el = new LeafletMarker(props.position, this.getOptions(props))
    this.contextValue = { ...props.leaflet, popupContainer: el }
    return el
  }

  updateLeafletElement (fromProps, toProps) {
    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position)
    }
    if (toProps.animationTime !== fromProps.animationTime) {
      this.leafletElement.setAnimationTime(toProps.animationTime)
    }
    if (toProps.icon !== fromProps.icon) {
      this.leafletElement.setIcon(toProps.icon)
    }
    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset)
    }
    if (toProps.opacity !== fromProps.opacity) {
      this.leafletElement.setOpacity(toProps.opacity)
    }
    if (toProps.draggable !== fromProps.draggable) {
      if (toProps.draggable === true) {
        this.leafletElement.dragging.enable()
      } else {
        this.leafletElement.dragging.disable()
      }
    }
    if (toProps.rotationAngle !== fromProps.rotationAngle) {
      this.leafletElement.setRotationAngle(toProps.rotationAngle)
    }
    if (toProps.rotationOrigin !== fromProps.rotationOrigin) {
      this.leafletElement.setRotationOrigin(toProps.rotationOrigin)
    }
  }

  render () {
    const { children } = this.props
    return children == null || this.contextValue == null ? null : (
      <LeafletContext.Provider value={this.contextValue}>{children}</LeafletContext.Provider>
    )
  }
}

export default (RotatedMarker)
