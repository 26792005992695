import React from 'react'
;
import Icon from '@mui/material/Icon'
import TrafficIcon from '@mui/icons-material/Traffic';
import { connect } from 'react-redux'
import { Tooltip } from '@mui/material'
import { toggleTrafficLayer } from '../../Actions/Maps'
function TrafficMenu (props) {
  
  const toggleMapTraffic = () => {
      props.dispatch(toggleTrafficLayer())
  }
    const empty = null
    return <li className={props.mapTraffic ? 'active' : ''}>
            <a href={empty} title='' onClick={props.selectedMap !== 'gccStreet' ? toggleMapTraffic: null}>
                {/* <span>{props.translate('toggleTraffic')}</span> */}
                <TrafficIcon style={{ fontSize: 24, height: 24 }}/>
            </a>
        </li>
}
const mapStateToProps = ({mapTraffic}) => ({
  mapTraffic
})

export default connect(mapStateToProps)((TrafficMenu))
