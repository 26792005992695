import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { fetchSummery } from "../../../Actions/Devices";
import { BarChart } from "../../../Components/common/Charts/BarChart";
import Card from "../../Dashboard/components/Card/CardPremium";
class ComponentBase extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            summery: null,
        }
    }
    
    componentDidMount() {
        const { dispatch, positions, ServerSetting, logInUsers }  = this.props;
        dispatch(fetchSummery({positions, ServerSetting, logInUsers}))
    }
    
    render() {

        const { engineHourSummery, gadget, themecolors }  = this.props;
        let reports = engineHourSummery?.data;

        let data = [];
        if(reports && reports.length) {
            console.log('reports', reports);
            reports.map(map => {
                if(map.type === "totalDistance") {
                    data = map?.data?.map(d => ({...d, totalDistance: parseFloat((d.totalDistance /  1000).toFixed(2))}))
                }
            });
        }
    
    return <Card themecolors={themecolors}>
        <h3 className="cart-title">{gadget.name}</h3>
        <BarChart data={data} xKey="name" yKey="totalDistance" dKey="totalDistance" fillColor={'#22b2d4'} xAxisFill={themecolors.textColor} />
    </Card>;
    }

}



const mapStateToProps = state => {
return {
    ServerSetting: state.ServerSetting,
    logInUsers: state.logInUsers,
    engineHourSummery: state.engineHourSummery,
    themecolors: state.themeColors
}
}
  

export const Component = connect(mapStateToProps)(ComponentBase)
