import { toast } from 'react-toastify';
import { getI18n } from 'react-i18next';

export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const NOTIFY_INFO = 'NOTIFY_INFO';
export const NOTIFY_WARNING = 'NOTIFY_WARNING';

const notify = (type, messageKey, dispatch, actionType) => {
  const i18n = getI18n();
  const t = i18n.t.bind(i18n);
  const translatedMessage = t(messageKey) || messageKey;

  switch (type) {
    case NOTIFY_SUCCESS:
      toast.success(translatedMessage, { autoClose: 2000 });
      break;
    case NOTIFY_ERROR:
      toast.error(translatedMessage, { autoClose: 2000 });
      break;
    case NOTIFY_INFO:
      toast.info(translatedMessage, { autoClose: 2000 });
      break;
    case NOTIFY_WARNING:
      toast.warn(translatedMessage, { autoClose: 2000 });
      break;
    default:
      break;
  }

  dispatch({ type: actionType, payload: messageKey });
};

export const notifySuccess = (messageKey) => (dispatch) => {
  notify(NOTIFY_SUCCESS, messageKey, dispatch, NOTIFY_SUCCESS);
};

export const notifyError = (messageKey) => (dispatch) => {
  notify(NOTIFY_ERROR, messageKey, dispatch, NOTIFY_ERROR);
};

export const notifyInfo = (messageKey) => (dispatch) => {
  notify(NOTIFY_INFO, messageKey, dispatch, NOTIFY_INFO);
};

export const notifyWarning = (messageKey) => (dispatch) => {
  notify(NOTIFY_WARNING, messageKey, dispatch, NOTIFY_WARNING);
};
